import React, { Component } from "react";

import moment from "moment";
import { GlobalContext } from "../../../mycontext";
import {getQueryParam} from '../../utils/utility';

var Plotly = require("plotly.js-dist-min");

const PlanetNames = {
  Ju: "Jupiter",
  Mo: "Moon",
  Ve: "Venus",
  Ma: "Mars",
  Su: "Sun",
  Me: "Mercury",
  Ra: "Rahu",
  Ke: "Ketu",
  Sa: "Saturn",
};

class AgeMahaDashaPlot extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      view_type: "ordered",
      horoscopeData:
      JSON.parse(window.localStorage.getItem("horoscopeData")) || null,

      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let queryString = getQueryParam('cb');
    if(queryString){
      this.setState({horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
    }
    else{
      this.setState({horoscopeData: JSON.parse(window.localStorage.getItem("horoscopeData"))});
    }
    if (Object.keys(this.state.horoscopeData).length === 0) {
      this.props.history.push("/horoscope/");
    } else {
      this.apidata_format();
    }
  }

  apidata_format = () => {
    let gd1 = document.getElementById("myDivAgeMahaDasha");
    let traces = [];
    let mahadashas =
      this.state.horoscopeData.vimsottari_dasha_days_only.mahadashalist;
    let CombinedAge = [0];
    let count = 1;
    for (var i of mahadashas) {
      let Obj1 = {};
      let a = moment(i.start_date, "DD-MM-YYYY");
      let b = moment(i.end_date, "DD-MM-YYYY");
      let years = b.diff(a, "years");

      Obj1[i.lord] = years;
      traces.push(Obj1);
      CombinedAge.push(CombinedAge[count - 1] + years);
      count = count + 1;
    }
    var trace1 = {
      y: [1],
      x: [Object.values(traces[0])[0]],
      name: Object.keys(traces[0])[0],
      type: "bar",
      labels: [Object.keys(traces[0])[0]],
      texttemplate: "%{labels}",
      text: [CombinedAge[1]],
      hovertemplate: "Upto %{text} years <extra></extra>",
      orientation: "h",
    };
    var trace2 = {
      y: [1],
      x: [Object.values(traces[1])[0]],
      name: Object.keys(traces[1])[0],
      type: "bar",
      text: [CombinedAge[2]],
      hovertemplate: "Upto %{text} years <extra></extra>",
      orientation: "h",
    };
    var trace3 = {
      y: [1],
      x: [Object.values(traces[2])[0]],
      name: Object.keys(traces[2])[0],
      type: "bar",
      text: [CombinedAge[3]],
      hovertemplate: "Upto %{text} years <extra></extra>",
      orientation: "h",
    };
    var trace4 = {
      y: [1],
      x: [Object.values(traces[3])[0]],
      name: Object.keys(traces[3])[0],
      type: "bar",
      text: [CombinedAge[4]],
      hovertemplate: "Upto %{text} years <extra></extra>",
      orientation: "h",
    };
    var trace5 = {
      y: [1],
      x: [Object.values(traces[4])[0]],
      name: Object.keys(traces[4])[0],
      type: "bar",
      text: [CombinedAge[5]],
      hovertemplate: "Upto %{text} years <extra></extra>",
      orientation: "h",
    };
    var trace6 = {
      y: [1],
      x: [Object.values(traces[5])[0]],
      name: Object.keys(traces[5])[0],
      type: "bar",
      text: [CombinedAge[6]],
      hovertemplate: "Upto %{text} years <extra></extra>",
      orientation: "h",
    };
    var trace7 = {
      y: [1],
      x: [Object.values(traces[6])[0]],
      name: Object.keys(traces[6])[0],
      type: "bar",
      text: [CombinedAge[7]],
      hovertemplate: "Upto %{text} years <extra></extra>",
      orientation: "h",
    };
    var trace8 = {
      y: [1],
      x: [Object.values(traces[7])[0]],
      name: Object.keys(traces[7])[0],
      type: "bar",
      text: [CombinedAge[8]],
      hovertemplate: "Upto %{text} years <extra></extra>",
      orientation: "h",
    };
    var trace9 = {
      y: [1],
      x: [Object.values(traces[8])[0]],
      name: Object.keys(traces[8])[0],
      type: "bar",
      text: [CombinedAge[9]],
      hovertemplate: "Upto %{text} years <extra></extra>",
      orientation: "h",
    };

    var data = [
      trace1,
      trace2,
      trace3,
      trace4,
      trace5,
      trace6,
      trace7,
      trace8,
      trace9,
    ];
    var layout = {
      showgridlines: false,
      xaxis: {
        title: "Age",
      },
      yaxis: {
        title: "Maha Dasha",
        showticklabels: false,
      },
      barmode: "stack",
      title: "Age Maha Dasha Plot",
      height: 500,
      hovermode: "closest",
    };

    Plotly.newPlot(gd1, data, layout);
  };

  render() {
    return (
      <>
        {/* <button onClick={this.apidate_format}>click me</button> */}
        <div className="col-lg-14" style={{ overflow: "hidden" }}>
          <div id="MahaDasha">
            <div className="card">
              <div className="card-body" style={{ padding: "10px" }}>
                <center>
                  <div id="myDivAgeMahaDasha"></div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AgeMahaDashaPlot;
