import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import "./CelebrityManagement.css";
import DashboardTopBar from "../DashBoardComponents/DashboardTopBar";
import { CreateHoroscope } from "./CreateHoroscope";
import { Redirect } from "react-router";
import { useHistory } from "react-router";
import { searchbyName, searchbyAlphabet, getAllData , getPendingEntryCount} from "../../API/CelebrityManagement_API";
import CelebrityManagementSideBar from "../DashBoardComponents/DashboardSideBarTypes/CelebrityManagementSidebar";
import axios from "axios";
import Pagination from "./Pagination";
import {useTranslation} from "react-i18next";


const SearchByAlphabet = (props) =>{
    const [alldata, setAlldata] = useState([]);
    const [page_no, setpage_no] = useState(parseInt(localStorage.getItem("page_no"))||1);
    const [total_no_of_pages, setTotal_no_of_pages] = useState(1);
    const [alphabet, setCurrentAlphabet] = useState(props.location.state.alphabet);
    const [inputCategory, setinputCategory] = useState(props.location.state.inputCategory);
    const [pending_entries, setPendingentries] = useState(null)
    let history = useHistory();
    const { t, i18n } = useTranslation();

    useEffect(()=>{
        SearchByAlphabet(alphabet);
        getPendingEntries();
    },[])

    const getPendingEntries = async ()=>{
        let p_entries = await getPendingEntryCount(alphabet,inputCategory);
        setPendingentries(p_entries);
    }
    const fetchHorscopeDetail = async (oldId, collection_name, completion_flag) => {
        let res = await getAllData(oldId, collection_name);
        history.push({
            pathname: "/celebrityManagement/createHoroscope",
            state: { res: res, oid: oldId, collectionName: collection_name, completionFlag : completion_flag }
        });
    }

    const handleCelebrityManagementView = (selectedName) => {
        console.log("handleCelebrityManagementView....", selectedName);
        props.history.push(`/CelebrityManagement/${selectedName}`);
    };
    const SearchByAlphabet = async (val) => {
        if (inputCategory === "select" || inputCategory === "") {
            alert("Please select category");
        }
        else {
            // console.log(page_no,'page');
            if (val !== null) {
                setCurrentAlphabet(val)
                let res = await searchbyAlphabet(val, page_no, inputCategory);

                setAlldata(res.data);
                setTotal_no_of_pages(res.no_of_pages)


            }
        }
    }

    useEffect(() => {
        // console.log('in useffect')
        // only call func on page changes, on the intial call for page 1,
        // SearchByAlphabet is directly called from the click event handler
        // alldata will not be empty array on page change since there will be data from the previous page_no call
        if(!(alldata.length===0)){
            SearchByAlphabet(alphabet);
        }
        else{
            // do nothing
        }
        // SearchByAlphabet(alphabet);
    }, [page_no]);
    return (
        <>
            <DashboardTopBar />
            <CelebrityManagementSideBar handleView={handleCelebrityManagementView} history={props.history} />

            <div className="eventWrapper col-lg-12">
                <div className="card" style={{ width: "calc(100% - 240px)" }}>
                        <div className="card-body">
                            <div className="searchData">
                                <h2 className="text-center">{t("Horoscope Data")} </h2>
                                <div
                                    style={{
                                    textAlign: "end",
                                    color: "black",
                                        paddingBottom:"20px"
                                }
                                }
                                    disabled={true}>
                                    <span style={{background:"yellow"}}>Pending Entries: <b>{pending_entries}</b></span>
                                </div>
                                <ul>
                                    {(alldata).map((item) => {
                                            return (
                                                <li className="alphabetSearch" key={item.oid}
                                                    onClick={() => (
                                                        fetchHorscopeDetail(item.oid, item["collection_name"], item["completion_flag"])
                                                    )}
                                                    style={item["completion_flag"] === "True" ? { color: "green" } : { color: "red" }} >{item["horoscope_name"]}</li>
                                            )
                                        }
                                    )}
                                </ul>
                                <div>
                                    {/*center align pagination component later*/}
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={page_no}
                                        totalCount={total_no_of_pages}
                                        onPageChange={(page) => {setpage_no(page);localStorage.setItem("page_no", page);}}
                                    />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(SearchByAlphabet);