import React, { Component } from "react";
import "./relation.css";
import Table from "react-bootstrap/Table";
import { withTranslation } from "react-i18next";
import ImageMapper from "../ImageMapper.json";

const deduceColor = (value) => {
  if (value === "Friend" || value === "मित्र") {
    return "color_friend";
  } else if (value === "Neutral" || value === "सम") {
    return "color_neutral";
  } else if (value === "Enemy" || value === "शत्रु") {
    return "color_enemy";
  } else if (value === "Intimate" || value === "अधिमित्र") {
    return "color_intimate";
  } else if (value === "Bitter" || value === "अधिशत्रु") {
    return "color_bitter";
  } else {
    return "color_dash";
  }
};

function extract_image_link(name) {
  let imglink = "/assets/Planets/";
  return imglink + ImageMapper[name] + ".png";
}
class RelationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { data } = this.props;
    const { t, i18n } = this.props;
    // console.log(data);
    return (
      <div>
        <Table responsive="xs" className="content_table_pr">
          <thead>
            <tr>
              <th>{t("Main Planet")}</th>
              <th>{t("Sun")}</th>
              <th>{t("Moon")}</th>
              <th>{t("Mars")}</th>
              <th>{t("Mercury")}</th>
              <th>{t("Jupiter")}</th>
              <th>{t("Venus")}</th>
              <th>{t("Saturn")}</th>
            </tr>
          </thead>
          <tbody>
            {data.map(function (val, i) {
              return (
                <tr key={val + i}>
                  <td className="left_align">
                    {val["Main Planet"] !== "Ascendant" &&
                    val["Main Planet"] !== "लग्न" ? (
                      <img
                        className="pr-images"
                        alt={val["Main Planet"]}
                        src={extract_image_link(val["Main Planet"])}
                      ></img>
                    ) : (
                      <></>
                    )}
                    {val["Main Planet"]}
                  </td>
                  <td className={`${deduceColor(val[t("Sun")])} bold`}>
                    {val[t("Sun")]}
                  </td>
                  <td className={`${deduceColor(val[t("Moon")])} bold`}>
                    {val[t("Moon")]}
                  </td>
                  <td className={`${deduceColor(val[t("Mars")])} bold`}>
                    {val[t("Mars")]}
                  </td>
                  <td className={`${deduceColor(val[t("Mercury")])} bold`}>
                    {val[t("Mercury")]}
                  </td>
                  <td className={`${deduceColor(val[t("Jupiter")])} bold`}>
                    {val[t("Jupiter")]}
                  </td>
                  <td className={`${deduceColor(val[t("Venus")])} bold`}>
                    {val[t("Venus")]}
                  </td>
                  <td className={`${deduceColor(val[t("Saturn")])} bold`}>
                    {val[t("Saturn")]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default withTranslation()(RelationTable);
