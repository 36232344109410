
import { HouseConstraint } from "./generalConstraints";

export var exhaustive = 'EXHAUSTIVE';
export var nonExhaustive = 'NON EXHAUSTIVE';
//TODO: if an operator has already been selected do not touch it
export class Operator{
    constructor(name,type,selected,callFunction,alias,deselectFunction,values){
        this.name = name;
        this.type=type;
        this.selected=selected;
        this.callFunction= callFunction;
        this.alias=alias;
        this.deselectFunction=deselectFunction;
        this.values=values;
        this.defaultValues = values;
    }

    deselectValue=(val)=>{
        if(this.type===exhaustive){
            //set to default
            this.values=[...this.defaultValues];
        }
        else{
            //add deselected value to options
            var t = [...this.values];
        t.push(val);
        this.values=t;

        //update selected values
        this.selectedValues = this.selectedValues.filter(function(item) {
            return item !== val;
        })
        }
        if(this.selected){
            this.selected=false;
        }
    }
}

export class InRashi extends Operator{
    constructor(values,callFunction,deselectFunction){
        super('In Rashi',exhaustive,false,callFunction,'r',deselectFunction,values);
        // this.values = values;
    }
}

export class InNakshatra extends Operator{
    constructor(values,callFunction,deselectFunction){
        super('In Nakshatra',exhaustive,false,callFunction,'nk',deselectFunction,values);
        // this.values = values;
    }
}

export class InHouse extends Operator{
    constructor(values,callFunction,deselectFunction){
        super('In House',exhaustive,false,callFunction,'h',deselectFunction,values);
        // this.values=values;
        // this.generalConstraint=new HouseConstraint();
    }

}

export class hasMotion extends Operator{
    constructor(value,callFunction,deselectFunction){
        super('Motion',exhaustive,false,callFunction,'m',deselectFunction,value);
        // this.values=value;
    }
}

export class ConjunctWith extends Operator{
    constructor(values,callFunction,deselectFunction){
        //Values will be array of names of the planets
        super('Conjunct with',nonExhaustive,false,callFunction,'conj',deselectFunction,values);
        // this.values=values;
        this.selectedValues=[]
        this.selected=false;
    }
    updateselectedValues=(val)=>{
        var t = [...this.selectedValues];
        t.push(val);
        this.selectedValues=t;

        //update options
        this.values = this.values.filter(function(item) {
            return item !== val
        })
        
    }
    setOptions=(options)=>{
        this.values=options;
    }
}

export class AspectsPlanet extends Operator{
    constructor(values,callFunction,deselectFunction){
        //Values will be array of names of the planets
        super('Aspects planet',nonExhaustive,false,callFunction,'asp_p',deselectFunction,values);
        // this.values=values;
        this.selectedValues=[]
        this.selected=false;
       
    }
    
    updateSelectedValues=(val)=>{
        var t = [...this.selectedValues];
        t.push(val);
        this.selectedValues=t;
        //update options
        this.values = this.values.filter(function(item) {
            return item !== val
        })
    }
    setOptions=(options)=>{
        this.values=options;
    }
}

export class AspectsHouse extends Operator{
    constructor(values,callFunction,deselectFunction){
        //Values will be array of house numbers
        super('Aspects house',nonExhaustive,false,callFunction,'asp_h',deselectFunction,values);
        // this.values=values;
        this.selected=false;
        this.selectedValues=[]
    }
    updateSelectedValues=(val)=>{
        var t = [...this.selectedValues];
        t.push(val);
        this.selectedValues=t;
        //update options and remove selected value for further options
        this.values = this.values.filter(function(item) {
            return item !== val
        })
        
    }
    setOptions=(options)=>{
        this.values=options;
    }
}