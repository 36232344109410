import React, { useEffect, useState } from "react";
import "./CelebrityManagement.css";
import loadjs from "loadjs";
import Select from "react-select/async";
// import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "../../mycontext";
import DashboardTopBar from "../DashBoardComponents/DashboardTopBar";
import DashboardSideBar from "../DashBoardComponents/DashboardSideBar";
import { useTranslation } from "react-i18next";
import { CreateHoroscope } from "./CreateHoroscope";
import { Redirect } from "react-router";
import { useHistory } from "react-router";
import { searchbyName, searchbyAlphabet, getAllData } from "../../API/CelebrityManagement_API";
import CelebrityManagementSideBar from "../DashBoardComponents/DashboardSideBarTypes/CelebrityManagementSidebar";
import axios from "axios";
import Pagination from "./Pagination";
import SearchByNameAutosuggest from "./Autosuggests/SearchByNameAutosuggest";

const CelebrityEvent = (props) => {

  const context = React.useContext(GlobalContext);
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const [page_pass, setpage_pass] = useState(false);
  const [passinput, setpassInput] = useState(''); // '' is the initial state value
  const [active, setActive] = useState(false);
  const [inputCategory, setinputCategory] = useState("");
  const [userName, setuserName] = useState("");
  const [page_no, setpage_no] = useState(1);
  const [total_no_of_pages, setTotal_no_of_pages] = useState(1);
  const [alphabet, setCurrentAlphabet] = useState("1");
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState([]);
  const [alldata, setAlldata] = useState([]);
  const [oid, setOid] = useState(null);
  const [collectionName, setCollectionName] = useState(null);
  const [completionFlag, setCompletionFlag] = useState(null);
  const [horoscopeData, setHoroscopeData] = useState();

 
  useEffect(() => {
    loadjs("assets/js/vendor.min.js", () => { });
    const value = localStorage.getItem("devpass");
    if (value == "1") {
      setpage_pass(true);
    }
    else {
      setpage_pass(false);
    }
    // page_no is set in local storage to retain page_no while entering data alphabetically,
    // but page number should be reset if alphabet is changed
    localStorage.removeItem("page_no");

    // const loadNames = async () => {
    //   let response;
    //   if (inputCategory == "1") {
    //     response = await axios.get('https://apiv2.omparashar.com/celebrity/searchbyname?name=' + userName + "&category=1");
    //     setAlldata(response.data.data);
    //   }
    //   else if (inputCategory == "2") {
    //     response = await axios.get('https://apiv2.omparashar.com/celebrity/searchbyname?name=' + userName + "&category=2");
    //     setAlldata(response.data.data);
    //   }
    // }
    // loadNames();
  }, [inputCategory])

  const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  const pass_click = () => {
    if (passinput === "devteam@123") {
      localStorage.setItem("devpass", "1");
      // Redirect to the desired route
      history.push('/celebrityManagement');
      window.location.reload();
    }
    else {
      setpassInput('');
      alert("Incorrect password, please try again ");
    }
  }
  const ResetAll = () => {
    setuserName("");
    setinputCategory("Select Category");
  }

  const handleCategorychange = (value) => {
    setinputCategory(value);
    localStorage.setItem("category", value);
  }
  const SearchbyName = async () => {
    if (inputCategory === "select" || inputCategory === "") {
      alert("Please select category");
    }
    else {
      if (userName === "") {
        // setActive(true);
        alert("Please enter a valid name");
      }
      else {
        // localStorage.setItem(collectionName, collectionName);
        (async () => {
          const res = await getAllData(oid, collectionName);
          history.push({
            pathname: "/celebrityManagement/createHoroscope",
            state: { res: res, oid: oid, collectionName: collectionName, completionFlag : completionFlag }
          });
        })();
      }
    }
  }
  const SearchByAlphabet = async (val) => {
    if (inputCategory === "select" || inputCategory === "") {
      alert("Please select category");
    }
    else {
      if (val !== null) {
        history.push({
          pathname: "/celebrityManagement/alphabeticalHoroscopes",
          state: { alphabet : val, inputCategory:inputCategory}
        });
        // setActive(true);
        // setCurrentAlphabet(val)
        // let res = await searchbyAlphabet(val, page_no, inputCategory);
        //
        // setAlldata(res.data);
        // setTotal_no_of_pages(res.no_of_pages)


      }
    }
  }

  // const fetchHorscopeDetail = async (oldId, collection_name, completion_flag) => {
  //   let res = await getAllData(oldId, collection_name);
  //   history.push({
  //     pathname: "/celebrityManagement/createHoroscope",
  //     state: { res: res, oid: oldId, collectionName: collection_name, completionFlag : completion_flag }
  //   });
  // }

  const handleCelebrityManagementView = (selectedName) => {
    console.log("handleCelebrityManagementView....", selectedName);
    props.history.push(`/CelebrityManagement/${selectedName}`);
  };
  // Autocomplete
  const onChangeHandler = (text) => {
    let matches = []
    if (text.length > 0) {
      matches = alldata.filter(data => {
        const regex = new RegExp(`${text}`, "gi");
        return data.horoscope_name.match(regex);
      });
    }
    setSuggestions(matches);
    setuserName(text);
  }
  const onSuggestHandler = (text) => {
    setuserName(text);
    setSuggestions([]);
    alldata.map((item, i) => {
      if (text === item.horoscope_name) {
        setOid(item.oid);
        setuserName(item.horoscope_name);
        setCollectionName(item.collection_name);
        setCompletionFlag(item.completion_flag);
      }
    });
  }

  const createNewHoroscope =()=>{
    // console.log("create");
    history.push({
          pathname: "/celebrityManagement/createHoroscope",
          state: { res: null, oid: null, collectionName: null, completionFlag : null,emptyData:true }
        });
  }

  return (
    <>
      <DashboardTopBar />
      <CelebrityManagementSideBar handleView={handleCelebrityManagementView} history={props.history} />

      <div className="eventWrapper col-lg-12">
        <div className="card" style={{ width: "calc(100% - 240px)" }}>

          {page_pass ? (
            <>
                        <div className="card-body">
                <div>
                  <div className="row mb-4" style={{ "marginTop": "-40px" }}>
                    <div className="col-md-12 col-12">
                      <h1>{t("Celebrity Management")} </h1>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-2 col-12">
                      <label>{t("Select Category")}</label>
                    </div>
                    <div className="col-md-3 col-12">
                      <div className="select-container">
                        <select value={inputCategory} onChange={(e) => handleCategorychange(e.target.value)}>
                          <option value="0">{("Select Category")}</option>
                          <option value="1">{("Human")}</option>
                          <option value="2">{("Mundane")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-md-3 offset-md-2 col-12">
                      {/* <input
                        type="text"
                        value={userName}
                        className="form-control"
                        placeholder="Search by Name..."
                        //  onInputChange={handleInputchange}
                        onChange={(e) => setuserName(e.target.value)}
                      /> */}
                      {/*<div className="autocompleteWrapper">*/}
                      {/*  <input type="text" className="form-control col-md-12 autocomplete"*/}
                      {/*    onChange={e => onChangeHandler(e.target.value)}*/}
                      {/*    value={userName}*/}
                      {/*    placeholder="Search Name"*/}
                      {/*  />*/}
                      {/*  <ul className="suggestionWrapper" style={{ "z-index": "99" }}>*/}
                      {/*    {suggestions && suggestions.map((suggestion, i) =>*/}
                      {/*      <li key={suggestion.horoscope_name} className="suggestion col-md-12 justify-content-md-center"*/}
                      {/*        onClick={() => onSuggestHandler(suggestion.horoscope_name)}>{suggestion.horoscope_name}</li>*/}
                      {/*    )}*/}
                      {/*  </ul>*/}
                      {/*</div>*/}
                      <SearchByNameAutosuggest setLocation={(data,val)=>{
                        setOid(data.oid);
                        setuserName(data.horoscope_name);
                        setCollectionName(data.collection_name);
                        setCompletionFlag(data.completion_flag);
                      }} category = {inputCategory} name = {userName}/>
                    </div>
                    <div className="col-md-3 col-12">
                      <button type="button" className="btn btn-theme" style={{ width: "auto" }} onClick={SearchbyName}>
                        {t("Search")}
                      </button>
                    </div>
                  </div>
                  <hr />
                  {/* Search By Alphabets */}
                  <center>
                    <fieldset className="form-group border p-3">
                      <legend className="w-auto px-2">Search By Alphabets</legend>
                      <div className="row mb-4">
                        <div className="col-md-12 col-12">
                          <ul className="inline-list alphabetSearch">
                            {alphabets.map((item) => {
                              return (
                                <li key={item} onClick={(e) => SearchByAlphabet(item)} className="px-2" >
                                  {item}
                                </li>
                              )
                            }
                            )}
                          </ul>
                        </div>
                      </div>
                    </fieldset>
                  </center>
                  <div className="col-md-3 col-12">
                    <button type="button" className="btn btn-theme" style={{ width: "auto" }} onClick={createNewHoroscope}>
                      {t("Create New Horoscope")}
                    </button>
                  </div>
                </div>
            </div>
            </>
           
          ) : (
            <>
              <div className="card-body">
                <div className="row mb-4">
                  <div className="col-md-12 col-12">
                    <div className="col-md-8 col-12">
                      <label>Enter Password to access this section </label>
                      <div className="input-group mb-3">
                        <input
                          type="password"
                          value={passinput}
                          onChange={(event) => setpassInput(event.target.value)}
                          className="form-control"
                          placeholder="enter security password "
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-warning"
                            type="button"
                            onClick={pass_click}
                          >
                            Submit Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CelebrityEvent;

