import React from "react";
import { useTranslation } from "react-i18next";

const langMapper = {
  Auspicious: "Auspicious",
  Inauspicious: "Inauspicious",
  Neutral: "Neutral",
  अशुभ: "Inauspicious",
  शुभ: "Auspicious",
  सम: "Neutral",
};
const ChoghadiyaHTML = (props) => {
  const { t, i18n } = useTranslation();
  if (typeof props.cho !== "undefined") {
    return (
      <>
        <table
          className="tablesaw table mb-0 tablesaw-stack"
          id="tablesaw-802"
          style={{ border: "none", borderTop: "none" }}
        >
          <tbody>
            <tr className="table_head_tr">
              <th scope="col" colSpan="5" className="sectionheader">
                <span>{t("Choghadiya")}</span>
                <span className="spancolor">{t("Auspicious")}</span>
                <span
                  className="color-box"
                  style={{ backgroundColor: "#8db332" }}
                ></span>
                <span className="spancolor">{t("Inauspicious")}</span>
                <span
                  className="color-box"
                  style={{ backgroundColor: "#cc3036" }}
                ></span>
                <span className="spancolor">{t("Neutral")}</span>
                <span
                  className="color-box"
                  style={{ backgroundColor: "#585151" }}
                ></span>
              </th>
            </tr>
            <tr>
              <td className="td1 td1cho">
                <span className="tablelabel">
                  <img
                    src={require("../../assets/img/sunrise.png")}
                    alt="Sunrise"
                    className="TableIconSun"
                  />
                </span>
              </td>
              <td className="td2">
                <span className="tablevalue td2cho">{t("Day Choghadiya")}</span>
              </td>
              <td className="td3 td3cho">
                <span className="tablelabel">
                  <img
                    src={require("../../assets/img/moonrise.png")}
                    alt="MoonRise"
                    className="TableIconMoon"
                  />
                </span>{" "}
              </td>
              <td className="td4">
                <span className="tablevalue td4cho">
                  {t("Night Choghadiya")}
                </span>
              </td>
            </tr>
            <tr>
              <td className="td1">
                <span className={"tablelabel " + langMapper[props.cho[1]]}>
                  {props.cho[0]}
                </span>
              </td>
              <td className="td2">
                <span className="tablevalue">{props.cho[2]}</span>
              </td>
              <td className="td3">
                <span className={"tablelabel " + langMapper[props.cho[25]]}>
                  {props.cho[24]}
                </span>{" "}
              </td>
              <td className="td4">
                <span className="tablevalue">{props.cho[26]}</span>
              </td>
            </tr>
            <tr>
              <td className="td1">
                <span className={"tablelabel " + langMapper[props.cho[4]]}>
                  {props.cho[3]}
                </span>
              </td>
              <td className="td2">
                <span className="tablevalue">{props.cho[5]}</span>
              </td>
              <td className="td3">
                <span className={"tablelabel " + langMapper[props.cho[28]]}>
                  {props.cho[27]}
                </span>{" "}
              </td>
              <td className="td4">
                <span className="tablevalue">{props.cho[29]}</span>
              </td>
            </tr>
            <tr>
              <td className="td1">
                <span className={"tablelabel " + langMapper[props.cho[7]]}>
                  {props.cho[6]}
                </span>
              </td>
              <td className="td2">
                <span className="tablevalue">{props.cho[8]}</span>
              </td>
              <td className="td3">
                <span className={"tablelabel " + langMapper[props.cho[31]]}>
                  {props.cho[30]}
                </span>{" "}
              </td>
              <td className="td4">
                <span className="tablevalue">{props.cho[32]}</span>
              </td>
            </tr>
            <tr>
              <td className="td1">
                <span className={"tablelabel " + langMapper[props.cho[10]]}>
                  {" "}
                  {props.cho[9]}{" "}
                </span>
              </td>
              <td className="td2">
                <span className="tablevalue">{props.cho[11]}</span>
              </td>
              <td className="td3">
                <span className={"tablelabel " + langMapper[props.cho[34]]}>
                  {" "}
                  {props.cho[33]}{" "}
                </span>{" "}
              </td>
              <td className="td4">
                <span className="tablevalue">{props.cho[35]}</span>
              </td>
            </tr>
            <tr>
              <td className="td1">
                <span className={"tablelabel " + langMapper[props.cho[13]]}>
                  {props.cho[12]}
                </span>
              </td>
              <td className="td2">
                <span className="tablevalue">{props.cho[14]}</span>
              </td>
              <td className="td3">
                <span className={"tablelabel " + langMapper[props.cho[37]]}>
                  {props.cho[36]}
                </span>{" "}
              </td>
              <td className="td4">
                <span className="tablevalue">{props.cho[38]}</span>
              </td>
            </tr>
            <tr>
              <td className="td1">
                <span className={"tablelabel " + langMapper[props.cho[16]]}>
                  {props.cho[15]}
                </span>
              </td>
              <td className="td2">
                <span className="tablevalue">{props.cho[17]}</span>
              </td>
              <td className="td3">
                <span className={"tablelabel " + langMapper[props.cho[40]]}>
                  {props.cho[39]}
                </span>{" "}
              </td>
              <td className="td4">
                <span className="tablevalue">{props.cho[41]}</span>
              </td>
            </tr>
            <tr>
              <td className="td1">
                <span className={"tablelabel " + langMapper[props.cho[19]]}>
                  {props.cho[18]}
                </span>
              </td>
              <td className="td2">
                <span className="tablevalue">{props.cho[20]}</span>
              </td>
              <td className="td3">
                <span className={"tablelabel " + langMapper[props.cho[43]]}>
                  {props.cho[42]}{" "}
                </span>{" "}
              </td>
              <td className="td4">
                <span className="tablevalue">{props.cho[44]}</span>
              </td>
            </tr>
            <tr>
              <td className="td1">
                <span className={"tablelabel " + langMapper[props.cho[22]]}>
                  {props.cho[21]}
                </span>
              </td>
              <td className="td2">
                <span className="tablevalue">{props.cho[23]}</span>
              </td>
              <td className="td3">
                <span className={"tablelabel " + langMapper[props.cho[46]]}>
                  {props.cho[45]}{" "}
                </span>{" "}
              </td>
              <td className="td4">
                <span className="tablevalue">{props.cho[47]}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  } else {
    return null;
  }
};

export default ChoghadiyaHTML;
