import React from "react";
import { useTranslation } from "react-i18next";
const RituAndAyanHTML = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <table
        className="tablesaw table mb-0 tablesaw-stack"
        id="tablesaw-802"
        style={{ border: "none", borderTop: "none" }}
      >
        <tbody>
          <tr className="table_head_tr" style={{}}>
            <th scope="col" colSpan="5" className="sectionheader">
              {t("Ritu and Ayan")}
            </th>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Drik Ayan")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.dkayan}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Day Duration")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">{props.daytime}</span>
            </td>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Vedic Ayan")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.vdayan}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Night Duration")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">{props.nighttime}</span>
            </td>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Ritu")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.ritu}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Madhyahna")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">{props.madhya}</span>
            </td>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Gola")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.gola}</span>
            </td>
            <td className="td3">
              <span className="tablelabel"></span>
            </td>
            <td className="td4">
              <span className="tablevalue"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default RituAndAyanHTML;
