import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import { withTranslation } from "react-i18next";

class VimshopakTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <div>
          <Table responsive="xs" className="content_table_bhav">
            <thead>
              <tr>
                <th></th>
                <th>{t("Sun")}</th>
                <th>{t("Moon")}</th>
                <th>{t("Mars")}</th>
                <th>{t("Mercury")}</th>
                <th>{t("Jupiter")}</th>
                <th>{t("Venus")}</th>
                <th>{t("Saturn")}</th>
              </tr>
            </thead>
            <tbody>
              {this.props.data.map(function (val, i) {
                return (
                  <tr key={val + i}>
                    <td className="left-align-bhav">{t(val["Bala"])}</td>
                    <td className="center-align-bhav">{t(val["Sun"])}</td>
                    <td className="center-align-bhav">{t(val["Moon"])}</td>
                    <td className="center-align-bhav">{t(val["Mars"])}</td>
                    <td className="center-align-bhav">{t(val["Mercury"])}</td>
                    <td className="center-align-bhav">{t(val["Jupiter"])}</td>
                    <td className="center-align-bhav">{t(val["Venus"])}</td>
                    <td className="center-align-bhav">{t(val["Saturn"])}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default withTranslation()(VimshopakTable);
