import React from "react";
import { useTranslation } from "react-i18next";
const SunriseMoonrise = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <table
        className="tablesaw table mb-0 tablesaw-stack"
        id="tablesaw-802"
        style={{ border: "none", borderTop: "none" }}
      >
        <tbody>
          <tr className="table_head_tr" style={{}}>
            <th
              scope="col"
              colSpan="5"
              className="sectionheader"
              style={{ borderTop: "none" }}
            >
              <img
                src={require("../../assets/img/Sun.png")}
                alt="Sunrise"
                className="TableIcon"
              />{" "}
              {t("Sun and Moon")}
              <img
                src={require("../../assets/img/Moon.png")}
                alt="Sunrise"
                className="TableIcon"
              />
            </th>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Sun Rise")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">
                <img
                  src={require("../../assets/img/sunrise.png")}
                  alt="Sunrise"
                  className="TableIcon"
                />
                {props.sunriseTimedisplay}
              </span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Sun Set")}</span>{" "}
            </td>
            <td className="td4">
              <span className="tablevalue">
                <img
                  src={require("../../assets/img/sunset.png")}
                  alt="Sunset"
                  className="TableIcon"
                />
                {props.sunsetTimedisplay}
              </span>
            </td>
          </tr>
          <tr className="lastrow">
            <td className="td1">
              <span className="tablelabel">{t("Moon Rise")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">
                <img
                  src={require("../../assets/img/moonrise.png")}
                  alt="MoonRise"
                  className="TableIcon"
                />
                {props.moonriseTimedisplay}
              </span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Moon Set")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">
                <img
                  src={require("../../assets/img/moonset.png")}
                  alt="Moonset"
                  className="TableIcon"
                />
                {props.moonsetTimedisplay}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SunriseMoonrise;
