/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useState, useEffect } from "react";
import { GlobalContext } from "../../../mycontext";
import bg_img from "../../../assets/img/bg_naks.jpg";
// import { Link } from 'react-router-dom'
import Chart1 from "./chart";
import Sidetable from "../../SideComponents/sidetable";

import Sideform from "../../SideComponents/sideform";
import { withRouter } from "react-router-dom";
import "../GalacticView/d3graph.css";
import { useTranslation } from "react-i18next";
const D3graph = (props) => {
  const { t, i18n } = useTranslation();
  const rashi = [
    t("Pis"),
    t("Aqu"),
    t("Cap"),
    t("Sag"),
    t("Sco"),
    t("Lib"),
    t("Vir"),
    t("Leo"),
    t("Can"),
    t("Gem"),
    t("Tau"),
    t("Ari"),
  ];
  const nakshatra = [
    t("Revathi"),
    t("U. Bhadrapada"),
    t("P. Bhadrapada"),
    t("Shatbhisha"),
    t("Dhanishta"),
    t("Sravana"),
    t("P. Ashadha"),
    t("U. Ashadha"),
    t("Mula"),
    t("Jyeshtha"),
    t("Anuradha"),
    t("Visakha"),
    t("Swathi"),
    t("Chitra"),
    t("Hasta"),
    t("U. Phalguni"),
    t("P. Phalguni"),
    t("Magha"),
    t("Ashlesha"),
    t("Pushya"),
    t("Punarvasu"),
    t("Ardra"),
    t("Mrigashirsha"),
    t("Rohini"),
    t("Krittika"),
    t("Bharani"),
    t("Ashwini"),
  ];
  const context = React.useContext(GlobalContext);
  const [speed, setSpeed] = useState("mop");
  const [language, setLanguage] = useState(null);
  const [showResetForm,setShowResetForm] = useState(false);

  useEffect(() => {
    setLanguage(localStorage.getItem("UserDefaultLanguage"));
  }, [localStorage.getItem("UserDefaultLanguage")]);

  const play_array = () => {
    var current_action = context.playicon;
    if (current_action === "mdi mdi-play") {
      //play the wheel
      context.playplanet();
      context.changeSideTableDisplay(true);
      //setplaybuttonIcon = 'mdi mdi-pause'
    } else if (current_action === "mdi mdi-pause") {
      context.pauseplanet();
    }
  };

  const forward_array = () => {
    context.forwardPlanet();
  };

  const backward_array = () => {
    context.backwardPlanet();
  };

  const handleSpeed = (speed) => {
    setSpeed(speed.target.value);
    var current_action = context.playicon;
    if (
      speed.target.value === "Very Fast" ||
      speed.target.value === "अतितीव्रगति"
    ) {
      if (current_action === "mdi mdi-pause") {
        //play the wheel
        context.pauseplanet();
        //setplaybuttonIcon = 'mdi mdi-pause'
      }
      context.play_speed_fn(0.5);
    } else if (
      speed.target.value === "Fast" ||
      speed.target.value === "तीव्रगति"
    ) {
      setSpeed(speed.target.value);
      if (current_action === "mdi mdi-pause") {
        //play the wheel
        context.pauseplanet();
        //setplaybuttonIcon = 'mdi mdi-pause'
      }

      context.play_speed_fn(1.5);
    } else if (
      speed.target.value === "Normal" ||
      speed.target.value === "समगति"
    ) {
      setSpeed(speed.target.value);
      if (current_action === "mdi mdi-pause") {
        //play the wheel
        context.pauseplanet();
        //setplaybuttonIcon = 'mdi mdi-pause'
      }

      context.play_speed_fn(3);
    } else if (
      speed.target.value === "Slow" ||
      speed.target.value === "मन्दगति"
    ) {
      setSpeed(speed.target.value);
      if (current_action === "mdi mdi-pause") {
        //play the wheel
        context.pauseplanet();
        //setplaybuttonIcon = 'mdi mdi-pause'
      }

      context.play_speed_fn(5);
    } else if (
      speed.target.value === "Very Slow" ||
      speed.target.value === "अतिमन्दगति"
    ) {
      setSpeed(speed.target.value);
      if (current_action === "mdi mdi-pause") {
        //play the wheel
        context.pauseplanet();
        //setplaybuttonIcon = 'mdi mdi-pause'
      }
      context.play_speed_fn(6);
    }
  };

  const resetForm = () => {
    if (Object.keys(context.requestParams.length !== 0)) {
      if (context.transitCountry === null || context.transitCountry === "") {
        context.setCountry(context.requestParams.country, "Transit");
      }
      if (
        context.transitCityState === null ||
        context.transitCityState === ""
      ) {
        context.setCityAndState(
          `${context.requestParams.name},${context.requestParams.state}`,
          "Transit"
        );
      }
    }
    // context.handleSideFormTransition(true);
    setShowResetForm(true);
  };

  const currentTopDetails = () => {
    return (
      <form
        className="form-inline text-white ml-5"
        style={{ alignItems: "start" }}
      >
        <div className="form-group mx-md-3">
          <label className="mr-2">{t("Location")}:</label>
          <label className="mr-2">{context.requestParams.name}</label>
        </div>
        <div className="mx-md-3">
          <span className="mr-2">{t("Start Date")}:</span>
          <label className="mr-2">{context.startDate}</label>
        </div>
        <div className="mx-md-3">
          <span className="mr-2">{t("End Date")}:</span>
          <label className="mr-2">{context.endDate}</label>
        </div>
        <button
          type="button"
          class="btn btn-purple-moon ml-20"
          onClick={() => resetForm()}
        >
          <i class="mdi mdi-refresh"></i>
          {t("Reset")}
        </button>
      </form>
    );
  };

  const SideTableView = () => {
    if (context.sideTableDisplay) {
      return <Sidetable />;
    } else {
      return "";
    }
  };

  return (
    <>
      <Sideform
        // show={context.showSideFormTransition}
        mode="TransitionView"
        view="galacticView"
        // handleClose={context.handleSideFormTransition}
        show={showResetForm}
        handleClose={i=>setShowResetForm(false)}
        reset={true}
        history={props.history}
      />
      <div className="col-lg-14 ">
        <div id="d3graph">
          <div
            className="card"
            style={{
              paddingBottom:50,
              backgroundImage: "url(" + bg_img + ")",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="card-body" style={{ padding: "10px" }}>
              <div className="row d-flex justify-content-start ml-20">
                <span
                  className="header-title d-flex flex-column"
                  style={{ marginLeft: "14px" }}
                >
                  <div className="btn-group ">
                    <span class="border-gradient border-gradient-purple">
                      <a
                        href=""
                        onClick={backward_array}
                        className={context.IsActive}
                      >
                        <i className="font-size-18 mdi mdi-skip-previous large"></i>
                      </a>{" "}
                    </span>

                    <a
                      href="#"
                      style={{ border: "0px" }}
                      onClick={(t) => play_array(props.t)}
                      className={(context.IsActive, "btn btn-purple-moon2")}
                    >
                      <i className={context.playicon}> {t("Visualize")}</i>
                    </a>

                    <span class="border-gradient border-gradient-purple">
                      <a
                        href="#"
                        onClick={forward_array}
                        className={context.IsActive}
                      >
                        <i className="font-size-18 mdi mdi-skip-next "></i>
                      </a>{" "}
                    </span>
                  </div>
                  <span style={{ marginTop: "10px" }}>
                    {t("Transition speed")}
                  </span>
                  <div className="btn-group">
                    <select
                      style={{
                        width: "150px",
                        backgroundColor: "#232740",
                        color: "white",
                      }}
                      className="form-control"
                      value={speed}
                      onChange={handleSpeed}
                    >
                      <option value="Normal">{t("Normal")}</option>
                      <option value="Very Slow">{t("Very Slow")}</option>
                      <option value="Slow">{t("Slow")}</option>
                      <option value="Fast">{t("Fast")}</option>
                      <option value="Very Fast">{t("Very Fast")}</option>
                    </select>
                  </div>
                </span>
                {currentTopDetails()}
              </div>

              <div className="row">
                <div className="col-lg-12">
                  {SideTableView()}
                  {language !== null ? (
                    <>
                      <h2
                        style={{
                          textAlign: "center",
                          //  marginBottom: "30px",
                          color: "white",
                          
                        }}
                      >
                        {t("Galactic View")}
                      </h2>
                      <Chart1
                        city={context.requestParams.name}
                        start={context.startDate}
                        end={context.endDate}
                        t={t}
                        language={language}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <Ploty_1 /> */}
        </div>
      </div>
    </>
  );
};

export default withRouter(D3graph);
