import React, { Component, Fragment } from "react";
import { GoogleLogin } from "react-google-login";
import { refreshTokensetup } from "./refreshToken";
import { checkIfUserExist, registerUser } from "../../API/UserAPIs";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { GlobalContext } from "../../mycontext";
import { Button, Modal } from "react-bootstrap";

const clientID =
  "1029976973836-6fbs7jup27deu5qsl2s1rmgc5vpuu9ff.apps.googleusercontent.com";

const languageOptions = [
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
];

class Login extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      userExist: false,
      showRegisterModal: false,
      googleResponse: null,
      selectedLanguage: { value: "English", label: "English" },
      username: "",
    };
  }
  onSuccess = (res) => {
    // console.log("[LoginSuccess] currenUser:", res.profileObj);
    this.context.setGoogleAuthObj(res);
    //from header component
    //save token to local storage

    localStorage.setItem("omp_token", res.tokenId);
    localStorage.setItem("omp_email", res.profileObj.email);
    localStorage.setItem("omp_name", res.profileObj.name);
    localStorage.setItem("omp_profile_url", res.profileObj.imageUrl);
    localStorage.setItem("omp_google_id", res.googleId);

    refreshTokensetup(res);

    this.setState({ username: res.profileObj.givenName });

    try {
      checkIfUserExist(res.googleId).then((result) => {
        if (result.data.message === "User Exist") {
          localStorage.setItem("UserDefaultLanguage", result.data.language);
          this.context.closeLoginModal();
          this.context.setTokenExpiredCounter();
          this.props.history.push(this.props.page);
        } else {
          this.setState({ showRegisterModal: true, googleResponse: res });
        }
      });
    } catch (err) {
      //console.log(
      // "issue with CheckUser EndPoint in User APIs. API link - http://apiv2.omparashar.com/user/api-doc/"
      //);
      // console.log(err);
    }
  };

  onFailure = (res) => {
    //console.log("[LoginFailed]:", res);
  };

  handleMultiChange = (option) => {
    this.setState((state) => {
      return {
        selectedLanguage: option,
      };
    });
  };

  registerUser = () => {
    try {
      registerUser(
        this.state.googleResponse.tokenId,
        this.state.username,
        this.state.selectedLanguage.value
      ).then((res) => {
        if (res === true) {
          // console.log(this.state.selectedLanguage.value);
          this.props.history.push(this.props.page);
          localStorage.setItem(
            "UserDefaultLanguage",
            this.state.selectedLanguage.value
          );
        } else {
        }
      });
    } catch (err) {
      // console.log(
      //  "issue with RegisterUser EndPoint in User APIs. API link - http://apiv2.omparashar.com/user/api-doc/"
      //);
      //console.log(err);
    }
  };

  onChangeUserName = (e) => {
    this.setState({ username: e.currentTarget.value });
  };

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this.props.handleClose} className="popup-style">
          {this.state.showRegisterModal ? (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Please Confirm Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  <span style={{ marginRight: "60px" }}>
                    <strong>Email:</strong>
                  </span>
                  <span>{this.state.googleResponse.profileObj.email}</span>
                </p>
                <p>
                  {/* <span style={{ marginRight: "17px", float: "left" }}>
                    <strong>Given Name:</strong>
                  </span> */}

                  <Fragment>
                    <input
                      id="auto_complete1"
                      ref="cpDev1"
                      type="text"
                      onChange={this.onChangeUserName}
                      value={this.state.username}
                      className="form-control"
                      style={{
                        width: "40%",
                        float: "left",
                        marginTop: "-10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Fragment>
                </p>
                <label>
                  Select Preferred Language:
                  <Select
                    value={this.state.selectedLanguage}
                    options={languageOptions}
                    onChange={this.handleMultiChange}
                  />
                </label>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center">
                <center  className="d-flex justify-content-center">
                  <Button variant="primary" onClick={this.registerUser}>
                    Confirm
                  </Button>
                </center>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Please Login</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Welcome to Omparashar, to start your journey </p>
                <GoogleLogin
                  clientId={clientID}
                  render={(renderProps) => (
                    <button
                      className="btn btn-success"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      Login with Google <i class="fe-log-in"></i>
                    </button>
                  )}
                  buttonText="Login with Google"
                  onSuccess={this.onSuccess}
                  onFailure={this.onFailure}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={true}
                  redirectUri="http://localhost:3000/"
                  // accessType="offline"
                  // responseType="code"
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.props.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      </>
    );
  }
}

export default withRouter(Login);

//  ) : (
//
//         )}
