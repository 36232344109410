import React from "react";
import { useTranslation } from "react-i18next";
const InauspiciousTimingsHTML = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <table
        className="tablesaw table mb-0 tablesaw-stack"
        id="tablesaw-802"
        style={{ border: "none", borderTop: "none" }}
      >
        <tbody>
          <tr className="table_head_tr" style={{}}>
            <th scope="col" colSpan="5" className="sectionheader">
              {t("Inauspicious Timings")}
            </th>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Kulika")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.kulika}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Varjya Kaal")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">{props.varjya}</span>
            </td>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Raahu Kaal")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.rkaal}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Yamaganda Kaal")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">{props.ykaal}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default InauspiciousTimingsHTML;
