import { InHouse, InNakshatra, InRashi, hasMotion, ConjunctWith, AspectsHouse, AspectsPlanet } from "./operators";
import { Ascendant,Sun,Moon,Mars,Mercury,Jupiter,Venus,Saturn,Rahu,Ketu,
    House1Lord,House2Lord,House3Lord,House4Lord,House5Lord,House6Lord,House7Lord,House8Lord,
House9Lord,House10Lord,House11Lord,House12Lord } from "./planets";
import { Variables } from "./variables";
export class Chart {
    constructor(chart_name, operators,ascendant_rashi=undefined) {
        //ascendant_rashi will be an array of length 1 containg only asc rashi number
        //operators will be the list of operator classes(NOT Object of that class but the class itself)
        this.chart_name = chart_name;
        this.operator_classes = operators;
        var operator_names=[]
        operators.forEach(element => {
            operator_names.push(element.name);
        });
        var operator_names_without_motion=operator_names;
        // arr = arr.filter(val => val !== "red");
        operator_names_without_motion = operator_names_without_motion.filter(op=>op!==hasMotion.name)
        this.planets=[]
        //Variable Object should be unique to each chart
        this.variable_object = new Variables()
        var asc = new Ascendant(this.variable_object,operator_names,this.chart_name)
        if(ascendant_rashi){
            asc.onrashiSelect(ascendant_rashi,this.variable_object)
        }
        // var this.variable_object = this.variable_object.getHouseToRashiMap();

        var sun = new Sun(this.variable_object,operator_names_without_motion,this.chart_name);
        var moon = new Moon(this.variable_object,operator_names_without_motion,this.chart_name);
        var mars = new Mars(this.variable_object,operator_names,this.chart_name);
        var mercury = new Mercury(this.variable_object,operator_names,this.chart_name);
        var jupiter = new Jupiter(this.variable_object,operator_names,this.chart_name);
        var venus = new Venus(this.variable_object,operator_names,this.chart_name);
        var saturn = new Saturn(this.variable_object,operator_names,this.chart_name);
        var rahu=new Rahu(this.variable_object,operator_names_without_motion,this.chart_name);
        var ketu = new Ketu(this.variable_object,operator_names_without_motion,this.chart_name);

        var house1lord = new House1Lord(this.variable_object,operator_names,this.chart_name);
        var house2lord = new House2Lord(this.variable_object,operator_names,this.chart_name);
        var house3lord = new House3Lord(this.variable_object,operator_names,this.chart_name);
        var house4lord = new House4Lord(this.variable_object,operator_names,this.chart_name);
        var house5lord = new House5Lord(this.variable_object,operator_names,this.chart_name);
        var house6lord = new House6Lord(this.variable_object,operator_names,this.chart_name);
        var house7lord = new House7Lord(this.variable_object,operator_names,this.chart_name);
        var house8lord = new House8Lord(this.variable_object,operator_names,this.chart_name);
        var house9lord = new House9Lord(this.variable_object,operator_names,this.chart_name);
        var house10lord = new House10Lord(this.variable_object,operator_names,this.chart_name);
        var house11lord = new House11Lord(this.variable_object,operator_names,this.chart_name);
        var house12lord = new House12Lord(this.variable_object,operator_names,this.chart_name);
        
        this.planet_name_to_object_map={"Ascendant":asc, 'Sun':sun, 'Moon':moon,
         'Mars':mars, 'Mercury':mercury, 'Jupiter':jupiter, 'Venus':venus, 'Saturn':saturn,
          'Rahu':rahu, 'Ketu':ketu,
          [house1lord.planetName]:house1lord,[house2lord.planetName]:house2lord,
          [house3lord.planetName]:house3lord,[house4lord.planetName]:house4lord,
          [house5lord.planetName]:house5lord,[house6lord.planetName]:house6lord,
          [house7lord.planetName]:house7lord,[house8lord.planetName]:house8lord,
          [house9lord.planetName]:house9lord,[house10lord.planetName]:house10lord,
          [house11lord.planetName]:house11lord,[house12lord.planetName]:house12lord}

    }
    setPlanets(planet_class_array){
        if(['D30,D60'].includes(this.chart_name)){
            //this will have to change the that rahu and ketu cannot 
            //be conjunct since they can be conjunct in D30 and D60
        }

        // this.planets=planet_class_array;
    }
}


export class D1 extends Chart {
    constructor(ascendant_rashi=undefined) {
        super('D1', [InHouse, InNakshatra, InRashi, hasMotion, ConjunctWith, AspectsHouse, AspectsPlanet],ascendant_rashi)
    }
}


export class D2 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D2', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D3 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D3', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D4 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D4', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D7 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D7', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D9 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D9', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D10 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D10', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D12 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D12', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D16 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D16', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D20 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D20', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D24 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D24', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D27 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D27', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D30 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D30', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D40 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D40', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}

export class D45 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D45', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
     }
}

export class D60 extends Chart {
    constructor() {
        // Div charts don't have motion and nakshatra
        super('D60', [InHouse, InRashi, ConjunctWith,AspectsHouse, AspectsPlanet])
    }
}