import { useState,useEffect } from "react"
import {MultiSelect} from "react-multi-select-component"
import React from "react";
import { reset } from "loadjs";

export const TagSelector = (props)=>{

    const [options,setOptions]=useState([]);
    const [selectedValues,setSelectedValues]=useState(props.selectedValues||[]);

    useEffect(()=>{
    },[])
    useEffect(()=>{
        var op = []
        for(var i in props.tags){
            op.push({label:i,value:props.tags[i]})
        }
        // console.log(op);
        setOptions(op);
        
    },[props.tags]
)

    useEffect(()=>{
        if(props.reset){
            setSelectedValues([]);
            props.setReset(false);
        }
    },[props.reset])

    useEffect(()=>{
        props.setter(selectedValues);
    },[selectedValues])
    return(<>
    <MultiSelect options={options}
    value={selectedValues}
    onChange={setSelectedValues}
    labelledBy="Select"
     />
    </>);

}

// export default withRouter(TagSelector);