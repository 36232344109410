import React, { useEffect, useState, useContext } from 'react';
import axios from "axios";
import { GlobalContext } from "../../../mycontext";
// import Plot from 'react-plotly.js';
import Plotly from "plotly.js";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import Sideform from '../../SideComponents/sideform';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { get } from 'jquery';
import { MultiSelect } from "react-multi-select-component";
import { Height } from '@material-ui/icons';
// import CountryAutocomplete from "./Autocomplete/CountryAutocomplete";
import "../PlanetaryConjunctions/conjunction.css";
// import {XLSX} from 'xlsx';
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

const ConjunctionGraph = props => {
    const { t, i18n } = useTranslation();
    const contextType = useContext(GlobalContext);
    // const [startYear, setStartYear] = useState(props.start !== null ? props.start : undefined);
    const [startYear, setStartYear] = useState(props.start);
    console.log(props.start);
    // const [endYear, setEndYear] = useState(props.end !== null ? props.end: undefined);
    const [endYear, setEndYear] = useState(props.end);
    const [selectedConjunction, setSelectedConjunction] = useState();
    const [startYearArray, setStartYearArray] = useState([]);
    const [endYearArray, setEndYearArray] = useState([]);
    const [errorMessage, seterrorMessage] = useState("");
    const [showModal, setShowModal] = useState(startYear === undefined || endYear === undefined);
    const [planetaryCombination, setPlanetaryCombination] = useState(localStorage.getItem('planetaryCombination') !== null ? localStorage.getItem('planetaryCombination') : "");

    // const [apiData, setApiData] = useState(JSON.parse(localStorage.getItem("planetaryConjunction")) || []);
    const [apiData, setApiData] = useState(props.apiData);
    //stores the parameter passed to render graph
    const [graphData, setGraphData] = useState([]);
    //graphDataArray is an array of arrays with each subarray containg to from and rashi number for conunction of selected planets
    const [graphDataArray, setGraphDataArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloadLoader, setDownloadLoader] = useState(false)
    // const [planetOptions, setPlanetOptions] = useState([{ "label": "Jupiter", "value": "ju" }, {
    //     "label":
    //         "Saturn", "value": "sa"
    // }, { "label": "Rahu", "value": 'ra' }, { "label": "Ketu", "value": 'ke' }
    // ])
    const [selectedArray, setSelectedArray] = useState(JSON.parse(window.localStorage.getItem("selectedConjunctionArray")) || [])
    const [fastplanets, setFastPlanets] = useState([]);
    const [showResetForm, setshowResetForm] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem("UserDefaultLanguage") || "English")
    let rashi_to_name_map = {
        1: t("Aries") + "(1)",
        2: t("Taurus") + "(2)",
        3: t("Gemini") + "(3)",
        4: t("Cancer") + "(4)",
        5: t("Leo") + "(5)",
        6: t("Virgo") + "(6)",
        7: t("Libra") + "(7)",
        8: t("Scorpio") + "(8)",
        9: t("Sagittarius") + "(9)",
        10: t("Capricorn") + "(10)",
        11: t("Aquarius") + "(11)",
        12: t("Pisces") + "(12)"
    }

    useEffect(() => {
        if (props.apiData === null) {
            return;
        }
        // setEndYear(props.end);
        // setStartYear(props.start);
        // getData();
        let selectedArray = props.selectedConjunctionArray;
        var x = ""
        if (selectedArray.includes("Jupiter")) {
            x = x + "ju_"
        }
        if (selectedArray.includes("Saturn")) {
            x = x + "sa"
        }
        if (selectedArray.includes("Rahu")) {
            if (x == "ju_") {
                x = x + "ra"
            } else {
                x = x + "_ra"
            }
        }
        if (selectedArray.includes("Ketu")) {
            if (x == "ju_") {
                x = x + "ke"
            } else {
                x = x + "_ke"
            }
        }
        // alert(x);
        setPlanetaryCombination(x);
        setSelectedArray(selectedArray);
        setStartYear(props.start);
        setEndYear(props.end);
        createGraph(props.apiData.result[x]);
        setApiData(props.apiData)
        setLanguage(localStorage.getItem("UserDefaultLanguage"))
    }, [props.apiData, localStorage.getItem("UserDefaultLanguage"),])


    // useEffect(() => {
    //     // setLanguage(localStorage.getItem("UserDefaultLanguage"));
    //   }, [localStorage.getItem("UserDefaultLanguage")]);

    const formatDate = (dateArray) => {
        var formattedDateArray = []
        dateArray.forEach(i => {
            var x = i.split("/");
            var y = x[2] + "-" + x[1] + "-" + x[0];
            formattedDateArray.push(y)
        })
        return formattedDateArray;
    }

    var layout = {
        height: "300px",
        margin: "60px",
        xaxis: {
            range: formatDate([`1/1/${startYear}`, `31/12/${endYear}`]),
            type: 'date',
            tickformat: '%d/%m/%Y',
            showgrid: false
        },
        yaxis: {
            // autorange: false,
            showgrid: true,
            dtick: 1,
            tick0: 1,
            tickmode: "array", // If "array", the placement of the ticks is set via `tickvals` and the tick text is `ticktext`.
            tickvals: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            ticktext: [t('Aries'), t('Taurus'), t('Gemini'), t('Cancer'), t('Leo'), t('Virgo'), t('Libra'), t('Scorpio'),
            t('Sagittarius'),
            t('Capricorn'), t('Aquarius'), t('Pisces')]
        }
    };

    // var data1 = [trace1, trace2, trace3];
    const c = () => {
        var x_array = []
        for (var i = startYear; i <= endYear; i++) {
            x_array.push(i);
        }
        var temp = [];
        for (var i = 1900; i <= 2399; i++) {
            temp.push(i);
        }
        setStartYearArray(temp);
        setEndYearArray(temp);
        if (apiData.length !== 0) {
            createGraph(apiData.result[planetaryCombination]);
        }
    }
    useEffect(() => {

    }, [])

    const getData = async () => {
        setLoading(true);
        var j = false;
        var s = false;
        var r = false;
        var k = false;
        // planetaryCombination.forEach(element => {
        //     if (element.label === "Jupiter") { j = true; }
        //     if (element.label === "Saturn") { j = true; }
        //     if (element.label === "Jupiter") { j = true; }
        //     if (element.label === "Jupiter") { j = true; }
        // });
        var x = ""
        if (selectedArray.includes("Jupiter")) {
            x = x + "ju_"
        }
        if (selectedArray.includes("Saturn")) {
            x = x + "sa"
        }
        if (selectedArray.includes("Rahu")) {
            if (x == "ju_") {
                x = x + "ra"
            } else {
                x = x + "_ra"
            }
        }
        if (selectedArray.includes("Ketu")) {
            if (x == "ju_") {
                x = x + "ke"
            } else {
                x = x + "_ke"
            }
        }
        alert(x);
        setPlanetaryCombination(x);
        window.localStorage.setItem('planetaryCombination', x);
        let requestParams = JSON.parse(
            window.localStorage.getItem("requestParams")
        );
        if (
            requestParams !== null &&
            Object.keys(contextType.requestParams).length === 0
        ) {
            contextType.setRequestParams(requestParams);
        }

        if (requestParams === null) {
            props.history.push("/dashboard");
        } else {
            let lat = requestParams.latitude;
            let long = requestParams.longitude;
            let offset = requestParams.offset;
            let cityname = requestParams.name;
            let url_string = "https://apiv2.omparashar.com/core/transit/conjunctionreport/" + x;
            //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
            let params =
                "?start_year=" +
                props.start +
                "&end_year=" +
                props.end +
                "&lat=" +
                lat +
                "&long=" +
                long +
                "&offset=" +
                offset +
                "&city=" +
                requestParams.name +
                "&state=" +
                requestParams.state +
                "&country=" +
                requestParams.country +
                "&reflong=" +
                requestParams.reference_longitude_for_timezone +
                "&timezone=" +
                requestParams.timezone
            // +
            // "&token=" +
            // localStorage.getItem("omp_token");
            const url = url_string + params;
            // console.log(url);

            const result = await axios(url);
            if (result.data.message === "Token expired") {
                contextType.handleShow(`/dashboard/snapshotView`);
            } else {
                // setData(result.data.result);
                localStorage.setItem("planetaryConjunction", JSON.stringify(result.data))
                setApiData(result.data);
                createGraph(result.data.result[x]);
            }
            // console.log(result);
            setLoading(false);

        }
    }

    const createGraph = (result) => {
        // setGraphDataArray(result);
        var temp = []
        var dataArray = []
        result.forEach(dataPoint => {
            if (contextType.selectedRashiArray.includes(parseInt(dataPoint[2]))) {
                temp.push(dataPoint);
                
                var label = "";
                var hoverText = ""
                if (localStorage.getItem("UserDefaultLanguage") == "Hindi") {
                    
                    label = `${dataPoint[0]} `+ t("From")+ ` ${dataPoint[1]} `+ t("To");
                    hoverText = 
                    [`${dataPoint[0]} ${t("From")} ${dataPoint[1]} ${t("To")}`,
                    `${dataPoint[0]} ${t("From")} ${dataPoint[1]} ${t("To")}`]
                } else {
                    label = `${dataPoint[0]} to ${dataPoint[1]}`;
                    hoverText = [`${dataPoint[0]} to ${dataPoint[1]}`, `${dataPoint[0]} to ${dataPoint[1]}`]
                }

                var data_p = {
                    x: formatDate([dataPoint[0], dataPoint[1]]),
                    y: [dataPoint[2], dataPoint[2]],
                    type: 'line',
                    // color:"#dd1100"
                    name: label,
                    text: hoverText,
                    hoverinfo:"text"

                }
                dataArray.push(data_p);
            }
        })
        setGraphDataArray(temp);
        setGraphData(dataArray);
        var graph_div = document.getElementById('myDiv');
        return Plotly.newPlot(graph_div, dataArray, layout, { "scrollZoom": true });
    };

    const resetForm = () => {
        if (Object.keys(contextType.requestParams.length !== 0)) {
            if (
                contextType.transitCountry === null ||
                contextType.transitCountry === ""
            ) {
                contextType.setCountry(contextType.requestParams.country, "Transit");
            }
            if (
                contextType.transitCityState === null ||
                contextType.transitCityState === ""
            )
                contextType.setCityAndState(
                    `${contextType.requestParams.name},${contextType.requestParams.state}`,
                    "Transit"
                );
        }
        //TODO:Add other form fields as well
        // contextType.handleSideFormTransition(true);
        setshowResetForm(true);
    };

    const formatDateToString = (d) => {
        //dd/mm/yyyy is the returned format
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }
    const getDateObjectFromString = (d) => {
        var x = d.split('/')
        let k = new Date(parseInt(x[2]), parseInt(x[1]) - 1, parseInt(x[0]));
        console.log(d, k, x)
        return k;
    }
    const overlapFastPlanets = (planetAlias, dataArray) => {
        //todo:remove points that don't overlap, like if ju_sa is 1/1/2001-5/5/2001 
        //and ma in same rashi is in 6/5/2001 to 8/6/2001 then show no data
        //aren't we already doing that, test with small data
        console.log("hello")
        var newDataArray = []

        dataArray.forEach(dataPoint => {
            // var rashi = parseInt(dataPoint.y[0])
            var rashi = parseInt(dataPoint[2])
            // var api_response = JSON.parse(localStorage.getItem("planetaryConjunction"))
            var api_response = apiData;
            var planet_data = api_response['result']['fast_moving_planets_rashis'][planetAlias]
            var same_rashis = []
            planet_data.forEach(i => {
                if (parseInt(i[2]) === rashi) {
                    // var x = [formatDate([i[0]])[0], formatDate([i[1]])[0], parseInt(i[2])]
                    var x = [i[0], i[1], parseInt(i[2])]
                    same_rashis.push(x)
                }
            })
            console.log(same_rashis)
            // var start = new Date(dataPoint.x[0])
            // var end = new Date(dataPoint.x[1])

            //by default takes mm/dd/yy not dd/mm/yy
            var start = getDateObjectFromString(dataPoint[0])
            var end = getDateObjectFromString(dataPoint[1])
            console.log(start, end)
            same_rashis.forEach(i => {
                //ddate formats are not samer between 1st and second calls,
                //either don't format here and only do when creating graph-yes this sounds good
                var fast_start = getDateObjectFromString(i[0])
                var fast_end = getDateObjectFromString(i[1])
                if (start >= fast_end || end <= fast_start) {
                    return
                }
                else {
                    var s = fast_start >= start ? fast_start : start;
                    var e = fast_end <= end ? fast_end : end;
                    var t = [formatDateToString(s), formatDateToString(e), i[2]]
                    newDataArray.push(t);
                }
            })
        })

        console.log(newDataArray)
        // createGraph(newDataArray)
        return newDataArray;
    }

    const handleCheckBox = (e, planetAlias) => {
        var temp = fastplanets;
        if (e.target.checked) {
            temp.push(planetAlias);
            setFastPlanets(temp);
        } else {
            temp = temp.filter(i =>
                i !== planetAlias
            )
            setFastPlanets(temp);
        }
        // var dataArray=apiData.result[planetaryCombination];
        var dataArray = apiData.result[planetaryCombination];
        temp.forEach(planet => {
            dataArray = overlapFastPlanets(planet, dataArray);
        })
        // setGraphDataArray(dataArray);

        // //TODO:create a state called data array, that will store array to make graph from
        // dataArray = overlapFastPlanets(planetAlias,graphData);
        console.log(dataArray);
        createGraph(dataArray);
    }


    const downloadExcel = () => {
        setDownloadLoader(true);
        var planetnames = ""
        selectedArray.forEach(i => {
            planetnames += i + " ";
        })
        fastplanets.forEach(i => {
            var alias_to_name_map = { su: "Sun", ve: "Venus", ma: "Mars", me: "Mercury" };
            planetnames += alias_to_name_map[i] + " ";
            // alert(alias_to_name_map.i)
        })
        const temp = [[`Conjunction Data for ${planetnames}`], ["From", "To", "Rashi Num"]]
        temp.push(...graphDataArray);
        const workbook = XLSX.utils.book_new();
        const sheet = XLSX.utils.aoa_to_sheet(temp);
        XLSX.utils.book_append_sheet(workbook, sheet, "data");
        const blob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        var b = new Blob([blob], { type: 'xlsx' })
        saveAs(b, "conjunctionReport.xlsx");
        setDownloadLoader(false);
    }
    // render()
    // {
    return (
        <>
            <Sideform
                show={showResetForm}
                mode="PlanetaryConjunctionMode"
                view="planetaryConjunctions"
                handleClose={i => setshowResetForm(false)}
                reset={true}
                history={props.history}
            // t={t}
            />
            <div className="card">
                <div className="card-body">
                    <form
                        className="form-inline"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <div className="form-group mx-md-3">
                            <label className="mr-2">
                                {t("Location")}:
                            </label>
                            <label className="mr-2" style={{ color: "#343a40" }}>
                                {contextType.requestParams.name}
                            </label>
                        </div>
                        <div className="mx-md-3">
                            <label className="mr-2">{t("Start Year")}:</label>
                            <label className="mr-2" style={{ color: "#343a40" }}>{startYear}</label>
                        </div>
                        <div className="mx-md-3">
                            <label className="mr-2">{t("End Year")}:</label>
                            <label className="mr-2" style={{ color: "#343a40" }}>{endYear}</label>
                        </div>

                        <div className="mx-md-3">
                            <label className="mr-2">{t("Selected Planets")}:</label>
                            <label className="mr-2" style={{ color: "#343a40" }}>{selectedArray.map((i, index) => {
                                if (index === selectedArray.length - 1) {
                                    return (t(i) + " ")
                                } else {
                                    return (t(i) + ", ")
                                }

                            })} </label>
                        </div>

                        <div className="mx-md-3 my-md-1">
                            <label className="mr-2">{t("Selected Rashis")}:</label>
                            <label className="mr-2" style={{ color: "#343a40" }}>{contextType.selectedRashiArray.map((i, index) => {
                                if (index === contextType.selectedRashiArray.length - 1) {
                                    return (rashi_to_name_map[i] + " ")
                                }
                                else {
                                    return (rashi_to_name_map[i] + ", ")
                                }

                            })} </label>
                        </div>

                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => resetForm()}
                            style={{
                                marginLeft: "40px",
                            }}
                        >
                            <i className="mdi mdi-refresh"></i>
                            {t("Reset")}
                        </button>

                        <button
                            type="button"
                            // className="btn btn-outline-primary"
                            className="btn btn-success"
                            onClick={() => downloadExcel()}
                            style={{
                                marginLeft: "40px",
                            }}
                            disabled={downloadLoader}
                        >
                            {/* <i className="mdi mdi-refresh"></i> */}


                            {downloadLoader ?
                                <span>Downloading <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                                </span> : <span>{t("Download Excel")}</span>}
                        </button>

                    </form>

                    {/* <Sideform
        show={contextType.showSideFormTransition}
        mode="PlanetaryConjunctionMode"
        view="planetaryConjunctions"
        handleClose={contextType.handleSideFormTransition}
        reset={true}
        history={props.history}
      /> */}
                    <div className="checkbox-input">
                        {/* add CSS */}
                        <div display="inline" style={{ "textAlign": "center" }}>

                            <input className="checkbox-input" name='ma' type="checkbox" onClick={e => handleCheckBox(e, 'ma')} />
                            <label className="checkbox-label" for="ma">{t("Mars")}</label>


                            <input className="checkbox-input" type='checkbox' name='me' onClick={e => handleCheckBox(e, 'me')} />
                            <label className="checkbox-label" for="me">{t("Mercury")}</label>


                            <input className="checkbox-input" type='checkbox' name='su' onClick={e => handleCheckBox(e, 'su')} />
                            <label className="checkbox-label" for="su">{t("Sun")}</label>

                            <input className="checkbox-input" type='checkbox' name='ve' onClick={e => handleCheckBox(e, 've')} />
                            <label className="checkbox-label" for="ve">{t("Venus")}</label>

                        </div>
                    </div>

                    <center><div id='myDiv' class='graph'></div></center>
                </div>
            </div>

        </>
    );
    // }
}
export default withRouter(ConjunctionGraph);