import React from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import { Component } from "react";
import moment from "moment";
import * as cities from "../cities.json";
import Autocomplete from "./Autocomplete/autocomplete";
import { GlobalContext } from "../../mycontext";
import "./sideform.css";
import Modal from "react-bootstrap/Modal";
import HoroscopeForm from "./SideFormTypes/HoroscopeForm";
import Api from "../../API/Api";
import Select from "react-select";
import CountryAutocomplete from "./Autocomplete/CountryAutocomplete";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { MultiSelect } from "react-multi-select-component";
import { IMaskInput } from "react-imask";
import DatePicker from "react-date-picker";

class sideform extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    // let yeararray = []
    // for (var i = 1900; i < 2400; i++) {
    //   yeararray.push(i);
    // }
    // console.log(props,"hh");
    // var t = []
    // var x = JSON.parse(window.localStorage.getItem("selectedConjunctionArray")) || []
    // if (x.includes("Jupiter")) {
    //   t.push({ "label": props.t("Jupiter"), "value": "Jupiter" });
    // }
    // if (x.includes("Saturn")) {
    //   t.push({ "label": props.t("Saturn"), "value": "Saturn" });
    // }
    // if (x.includes("Rahu")) {
    //   t.push({ "label": props.t("Rahu"), "value": "Rahu" });
    // }
    // if (x.includes("Ketu")) {
    //   t.push({ "label": props.t("Ketu"), "value": "Ketu" });
    // }
    // let rashi_to_name_map = {
    //   1: this.props.t("Aries")+"(1)",
    //   2: this.props.t("Taurus")+"(2)", 
    //   3:this.props.t( "Gemini")+"(3)",
    //   4: this.props.t("Cancer")+"(4)",
    //   5: this.props.t("Leo")+"(5)", 
    //   6: this.props.t("Virgo")+"(6)", 
    //   7: this.props.t("Libra")+"(7)", 
    //   8: this.props.t("Scorpio")+"(8)", 
    //   9: this.props.t("Sagittarius")+"(9)",
    //   10: this.props.t("Capricorn")+"(10)", 
    //   11: this.props.t("Aquarius")+"(11)", 
    //   12: this.props.t("Pisces")+"(12)"
    // }
    // let rashiArray = JSON.parse(window.localStorage.getItem("selectedRashiArray")) || [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    // var selectedRashiArray = []
    // rashiArray.forEach(i => {
    //   selectedRashiArray.push({ "label": rashi_to_name_map[i], "value": i })
    // })
    // let rashi_options = []
    // for (var i = 1; i < 13; i++) {
    //   rashi_options.push({ "label": rashi_to_name_map[i], "value": i })
    // }
    this.state = {
      startDate: null,
      endDate: null,
      panchangDate: null,
      displaypanchangDate: null,
      city: "",
      focusedEndDate: false,
      isValidDateRange: false,
      errors: {},
      open: "",
      resetInputText: false,
      userInput: null,
      panchangError: null,
      // yeararray: [],
      planetOptions: [{ "label": this.props.t("Jupiter"), "value": "Jupiter" }, 
        {"label":this.props.t("Saturn"), "value": "Saturn"}, 
        { "label": this.props.t("Rahu"), "value": 'Rahu' }, 
        { "label": this.props.t("Ketu"), "value": 'Ketu' }
      ],
      // selectedValues: t,
      // planetArray: x,
      // selectedRashis: selectedRashiArray,
      // rashiOptions: rashi_options,
      // selectedRashiArray: rashiArray,
      selectedValues: [],
      planetArray: [],
      selectedRashis: [],
      rashiOptions: [],
      selectedRashiArray: [],
      language:window.localStorage.getItem("UserDefaultLanguage")
        };

    // this.setState({selectedValues:t})
    // this.setConjunctionstates();
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Api = new Api();
  }


  componentDidMount() {
    this.setConjunctionstates();
   }

  componentDidUpdate(){
    if(window.localStorage.getItem("UserDefaultLanguage")!==this.state.language){
      // alert("update"+this.state.language)
      this.setState({language:window.localStorage.getItem("UserDefaultLanguage")})
     this.setConjunctionstates();
  }
  }
  // checkLanguageUpdate=()=>{
  //   if(window.localStorage.getItem("UserDefaultLanguage")!==this.state.language){
  //        this.setConjunctionstates();
  //     }
  // }

  setConjunctionstates=()=>{
    // let yeararray = []
    // for (var i = 1900; i < 2400; i++) {
    //   yeararray.push(i);
    // }
    // console.log(props,"hh");
    var t = []
    var x = JSON.parse(window.localStorage.getItem("selectedConjunctionArray")) || []
    if (x.includes("Jupiter")) {
      t.push({ "label": this.props.t("Jupiter"), "value": "Jupiter" });
    }
    if (x.includes("Saturn")) {
      t.push({ "label": this.props.t("Saturn"), "value": "Saturn" });
    }
    if (x.includes("Rahu")) {
      t.push({ "label": this.props.t("Rahu"), "value": "Rahu" });
    }
    if (x.includes("Ketu")) {
      t.push({ "label": this.props.t("Ketu"), "value": "Ketu" });
    }
    let rashi_to_name_map = {
      1: this.props.t("Aries")+"(1)",
      2: this.props.t("Taurus")+"(2)", 
      3:this.props.t( "Gemini")+"(3)",
      4: this.props.t("Cancer")+"(4)",
      5: this.props.t("Leo")+"(5)", 
      6: this.props.t("Virgo")+"(6)", 
      7: this.props.t("Libra")+"(7)", 
      8: this.props.t("Scorpio")+"(8)", 
      9: this.props.t("Sagittarius")+"(9)",
      10: this.props.t("Capricorn")+"(10)", 
      11: this.props.t("Aquarius")+"(11)", 
      12: this.props.t("Pisces")+"(12)"
    }
    let rashiArray = JSON.parse(window.localStorage.getItem("selectedRashiArray")) || [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    var selectedRashiArray = []
    rashiArray.forEach(i => {
      selectedRashiArray.push({ "label": rashi_to_name_map[i], "value": i })
    })
    let rashi_options = []
    for (var i = 1; i < 13; i++) {
      rashi_options.push({ "label": rashi_to_name_map[i], "value": i })
    }
    this.setState({
      planetOptions: [{ "label": this.props.t("Jupiter"), "value": "Jupiter" }, 
        {"label":this.props.t("Saturn"), "value": "Saturn"}, 
        { "label": this.props.t("Rahu"), "value": 'Rahu' }, 
        { "label": this.props.t("Ketu"), "value": 'Ketu' }
      ],
      selectedValues: t,
      planetArray: x,
      selectedRashis: selectedRashiArray,
      rashiOptions: rashi_options,
      selectedRashiArray: rashiArray,
    })
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
    this.context.changeView(event.target.value);
  }

  alertclick = async (e) => {
    e.preventDefault();
    let start_Date = "";
    let end_Date = "";
    let from_year = "",
      from_month = "",
      from_day = "";
    let to_year = "",
      to_month = "",
      to_day = "";
    let lat = "",
      long = "";
    let offset = "";
    start_Date = this.state.startDate || this.context.startDate;
    end_Date = this.state.endDate || this.context.endDate;
    let country = this.context.transitCountry;
    let state = this.context.transitCityState;
    // console.log(this.context.transitCityState,'<-',document.getElementById("auto_complete1").value,'<------')
    //  let names = document.getElementById("auto_complete1").value;
    let names = this.context.transitCityState;
    this.setState({ city: names });
    from_year = moment(start_Date).format("YYYY");
    from_month = moment(start_Date).format("MM");
    from_day = moment(start_Date).format("DD");
    to_year = moment(end_Date).format("YYYY");
    to_month = moment(end_Date).format("MM");
    to_day = moment(end_Date).format("DD");
    let errors = this.state.errors;
    let isFormValid = true;
    // console.log(this.state.userInput);
    // console.log(country, state);
    if (country === null) {
      errors["error"] = "Please enter valid location";
      isFormValid = false;
      return;
    } else if (state === null) {
      errors["error"] = "Please enter valid location";
      isFormValid = false;
      return;
    } else if (start_Date == null || end_Date == null) {
      errors["error"] = this.props.t("Please select date range.");
      isFormValid = false;
      return;
    } else {
      this.context.resetLoading(true);
      // let cityAndCountry = "";

      // if (this.state.userInput === null) {
      //   cityAndCountry = [
      //     this.context.requestParams.name,
      //     this.context.requestParams.country,
      //   ];
      // } else {
      //   cityAndCountry = this.state.userInput.includes(",")
      //     ? this.state.userInput.split(",")
      //     : [
      //         this.context.requestParams.name,
      //         this.context.requestParams.country,
      //       ];
      // }
      // let result = await this.Api.fetchLocationDetails(
      //   cityAndCountry[0].trim(),
      //   cityAndCountry[1].trim()
      // );
      // console.log(result);
      if (Object.keys(this.context.requestParams).length === 0) {
        errors["error"] = "Please select valid place of observation";
        this.context.resetLoading(false);
        isFormValid = false;
      } else {
        lat = this.context.requestParams.latitude;
        long = this.context.requestParams.longitude;
        offset = this.context.requestParams.offset;
        this.setState({ errors: errors });
        let url_string =
          "https://apiv2.omparashar.com/core/transit/multi/positions/overdaterange";
        //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
        let params =
          "?from_year=" +
          from_year +
          "&from_month=" +
          from_month +
          "&from_day=" +
          from_day +
          "&to_year=" +
          to_year +
          "&to_month=" +
          to_month +
          "&to_day=" +
          to_day +
          "&p_nums=0&p_nums=1&p_nums=2&p_nums=3&p_nums=4&p_nums=5&p_nums=6&p_nums=10&p_nums=100" +
          "&lat=" +
          lat +
          "&long=" +
          long +
          "&offset=" +
          offset +
          "&city=" +
          this.context.requestParams.name +
          "&state=" +
          this.context.requestParams.state +
          "&country=" +
          this.context.requestParams.country +
          "&reflong=" +
          this.context.requestParams.reference_longitude_for_timezone +
          "&timezone=" +
          this.context.requestParams.timezone +
          "&token=" +
          localStorage.getItem("omp_token");

        // console.log(url_string + params);
        // submit form and send reqest is valid date range and location
        //formating start and end date for updateing api conetxt
        var startDate = from_year + "/" + from_month + "/" + from_day;
        var endDate = to_year + "/" + to_month + "/" + to_day;
        //----end
        this.context
          .callAPI_daterange(url_string + params, names, startDate, endDate)
          .then((result) => {
            if (result) {
              // console.log(result);
              if (
                Object.keys(result).includes("message") &&
                result.data.message === "Token expired"
              ) {
                this.context.handleShow(`/dashboard/galacticView`);
              } else {
                this.context.changeSideTableDisplay(false);
                this.props.handleClose();
                this.props.history.push(`/dashboard/${this.props.view}`);
                this.context.resetLoading(false);
              }
            }
          });
        // this.setState({open: false});

        this.context.setCountry(this.context.requestParams.country, "Transit");

        this.context.setCityAndState(
          `${this.context.requestParams.name},${this.context.requestParams.state}`,
          "Transit"
        );
      }
      this.context.resetForm();
    }
  };

  //TODO:find use of this function
  getData = async (e) => {
    // e.preventDefault()
    // this.context.resetLoading(true);

    // // setPlanetaryCombination(x);
    // // window.localStorage.setItem('planetaryCombination',x);
    // let requestParams = JSON.parse(
    //     window.localStorage.getItem("requestParams")
    // );
    // if (
    //     requestParams !== null &&
    //     Object.keys(this.context.requestParams).length === 0
    // ) {
    //     this.context.setRequestParams(requestParams);
    // }

    // if (requestParams === null) {
    //     this.context.history.push("/dashboard");
    // } else {
    //     // this.context.call_planetary_conjunction_API(this.state.planetConjunctionStart,this.state.planetConjunctionEnd,x,
    //     //   this.context.requestParams.latitude,this.context.requestParams.longitude,
    //     //   this.context.requestParams.offset,this.context.requestParams.name,this.context.requestParams.state,
    //     //   this.context.requestParams.country,this.context.requestParams.reference_longitude_for_timezone,this.context.requestParams.timezone
    //     // ).then((res)=>{if(res){
    //     //   this.context.changeSideTableDisplay(false);
    //     //   localStorage.setItem("planetaryConjunction",JSON.stringify(res))
    //     //         this.props.handleClose();

    //     //         this.context.resetLoading(false);
    //     // }else{
    //     //   alert("Error")
    //     // }})
    //     this.context.resetLoading(false);
    //     this.context.resetForm();
    // }
    this.context.resetLoading(true);
    e.preventDefault();
    this.props.handleClose();
    this.props.history.push(`/dashboard/${this.props.view}`);
    this.context.resetLoading(false);
    this.context.resetForm();
  }

  callClose = () => {
    this.props.close();
  };

  alertclickPanchang = (e) => {
    e.preventDefault();
    let errors = this.state.errors;
    window.localStorage.setItem(
      "PanchangDate",
      this.state.panchangDate || this.props.date
    );
    if (Object.keys(this.context.requestParamsPanchang).length !== 0) {
      this.props.setPlaceDetails(this.context.requestParamsPanchang);
      this.props.changeDate(this.state.panchangDate || this.props.date);
      this.context.handlePanchangForm(false);
    } else {
      // console.log("error");
      this.setState({ panchangError: "Please select a valid location" });
    }
  };

  handlePanchangClose = () => {
    if (Object.keys(this.context.requestParamsPanchang).length === 0) {
      this.props.history.push("/dashboard");
    } else {
      this.context.handlePanchangForm(false);
    }
  };

  alertclickSnapshot = async (e) => {
    this.context.resetLoading(true);
    e.preventDefault();
    this.props.handleClose();
    this.props.history.push(`/dashboard/${this.props.view}`);
    this.context.resetLoading(false);
  };

  alertclickPlanetaryConjunction = async (e) => {
    //TODO: do not close form on clicking get data, if there is error
    e.preventDefault();
    let start_year = "";
    let end_year = "";
    start_year = this.context.planetConjunctionStart;
    end_year = this.context.planetConjunctionEnd;
    let country = this.context.transitCountry;
    let state = this.context.transitCityState;
    // console.log(this.context.transitCityState,'<-',document.getElementById("auto_complete1").value,'<------')
    //  let names = document.getElementById("auto_complete1").value;
    let names = this.context.transitCityState;
    let errors = this.state.errors;
    let isFormValid = true;
    let planet_array = this.state.planetArray;
    let rashiarray = this.state.selectedRashiArray;

    // console.log(this.state.userInput);
    // console.log(country, state);
    if (country === null) {
      errors["error"] = this.props.t("Please enter valid location");
      isFormValid = false;
      // return;
    } else if (state === null) {
      errors["error"] = this.props.t("Please enter valid location");
      isFormValid = false;
      // return;
    } else if (start_year == null || end_year == null) {
      errors["error"] = this.props.t("Please enter year range.");
      isFormValid = false;
      // return;
    } else if (planet_array.includes("Rahu") && planet_array.includes("Ketu")) {
      errors["error"] = this.props.t("Please select only one of Rahu or Ketu");
      isFormValid = false;
      // return;
    } else if (planet_array.length < 2) {
      errors["error"] = this.props.t("Please select atleast two planets.");
      isFormValid = false;
      // return;
    } else if (start_year.length !== 4 || end_year.length !== 4 || parseInt(start_year) > parseInt(end_year) || parseInt(start_year) < 1900 || parseInt(start_year) > 2399 || parseInt(end_year) > 2399 || parseInt(end_year) < 1900) {
      errors["error"] = this.props.t("Invalid Year Range.");
      isFormValid = false;
      // return;
    } else if (rashiarray.length < 1) {
      errors["error"] = this.props.t("Please select atleast one rashi.");
      isFormValid = false;
      // return;
    } else {
      errors["error"] = ""
      let selectedArray = this.context.selectedConjunctionArray;
      var x = ""
      if (selectedArray.includes("Jupiter")) {
        x = x + "ju_"
      }
      if (selectedArray.includes("Saturn")) {
        x = x + "sa"
      }
      if (selectedArray.includes("Rahu")) {
        if (x == "ju_") {
          x = x + "ra"
        } else {
          x = x + "_ra"
        }
      }
      if (selectedArray.includes("Ketu")) {
        if (x == "ju_") {
          x = x + "ke"
        } else {
          x = x + "_ke"
        }
      }
      this.context.resetLoading(true);
      let res = await this.Api.call_planetary_conjunction_API(start_year, end_year, x, this.context.requestParams.latitude,
        this.context.requestParams.longitude, this.context.requestParams.offset,
        this.context.requestParams.name, state, country, this.context.requestParams.reference_longitude_for_timezone,
        this.context.requestParams.timezone
      )
      if (res) {
        this.context.setPlanetaryConjunctionAPIData(res);
      } else {
        this.context.resetLoading(false);
        alert("An error occured");
      }

      e.preventDefault();
      this.props.handleClose();
      this.props.history.push(`/dashboard/${this.props.view}`);
      this.context.resetLoading(false);
      this.context.resetForm();
    }
    this.setState({ errors: errors });

  };

  // set all variable values

  onDateChange = (name, date) => {
    let errors = this.state.errors;
    let isValidDateRange = true;
    errors["dateError"] = "";
    this.setState({ errors });
    if (name === "startDate") {
      this.setState({ startDate: date });
      if (this.state.endDate || this.context.endDate) {
        let isBefore = moment(date).isBefore(
          this.state.endDate || this.context.endDate
        );
        if (!isBefore) {
          errors["dateError"] = "End date should be greater than start date";
          isValidDateRange = false;
        } else if (date.diff(this.state.endDate, "days") > 365) {
          errors["dateError"] =
            "Please select date range with in 365 days / 1 year";
          isValidDateRange = false;
        }
      } else {
        isValidDateRange = false;
      }
    } else if (name === "endDate") {
      this.setState({ endDate: date });
      if (this.state.startDate || this.context.startDate) {
        let isAfter = moment(date).isAfter(
          this.state.startDate || this.context.startDate
        );
        if (!isAfter) {
          errors["dateError"] = "End date should be greater than start date";
          isValidDateRange = false;
        } else if (date.diff(this.state.startDate, "days") > 365) {
          errors["dateError"] =
            "Please select date range with in 365 days / 1 year";
          isValidDateRange = false;
        }
      } else {
        errors["dateError"] = "Please select start date";
        isValidDateRange = false;
        this.setState({ disableButton: true });
      }
    } else if (name === "panchangDate") {
      // console.log(date);
      this.setState({ panchangDate: date });
    }

    this.setState({ errors: errors });
    this.setState({ isValidDateRange: isValidDateRange });
  };

  // handleSelect = address => {
  //   geocodeByAddress(address)
  //     .then(results => getLatLng(results[0]))
  //     .then(latLng => console.log('Success', latLng))
  //     .catch(error => console.error('Error', error));
  // };

  handleAutoCompleterChange = (type) => {
    if (type === "userInput") {
      let errors = this.state.errors;
      errors["observation"] = "";
      this.setState({ errors: errors });
    } else if (type === "reset") {
      this.setState({ resetInputText: false });
    }
  };

  resetForm() {
    // this.context.dataLoaded = false;
    // this.context.apidataState = {};
    this.setState({ open: "" });
    this.setState({ startDate: null });
    this.setState({ endDate: null });
    this.setState({ city: "" });
    this.setState({ panchangDate: null });
  }

  handleFocus = (focused) => {
    this.setState({ focused });
  };

  handlePlaceDetailsChange = (place) => {
    this.setState({ placeDetails: place });
  };

  handleUserInput = (input) => {
    this.setState({ userInput: input });
  };

  handlePlanetSelect = (i) => {
    var temp = this.state.selectedValues;
    var t = []
    i.forEach(i => {
      t.push(i.value)
    })
    let clearError = true
    if (t.length < 2) {
      this.setState({ errors: { error: this.props.t("Please select atleast two planets.") } });
      // return;
      clearError = false;
    }
    if (t.includes("Rahu") && t.includes("Ketu")) {
      this.setState({ errors: { error: this.props.t("Please select only one of Rahu or Ketu") } });
      this.setState({ selectedValues: temp });
      return;
    }
    if (clearError) {
      this.setState({ errors: { error: "" } });
    }

    this.setState({ planetArray: t })
    this.setState({ selectedValues: i })
    this.context.setSelectedConjunctionPlanets(t);
    window.localStorage.setItem("selectedConjunctionArray", JSON.stringify(t))
  }

  handleRashiSelect = (i) => {
    var t = []
    i.forEach(i => {
      t.push(i.value)
    })
    this.setState({ selectedRashiArray: t, selectedRashis: i })
    this.context.setSelectedRashiArray(t);
  }

  render() {
    const { t, i18n } = this.props;
    //console.log(cities.default);
    var city = cities.default[0];
    var cities_name = Object.keys(city);
    //console.log(cities_name);
    const falseFunc = () => false;
    const min = "1900-01-01";
    const max = "2500-12-30";
    const returnYears = () => {
      let years = [];
      for (
        let i = parseInt(moment(min).format("YYYY"));
        i <= parseInt(moment(max).format("YYYY"));
        i++
      ) {
        years.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
      return years;
    };
    const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <select
              value={
                moment(month).isBefore(moment(min))
                  ? moment(min).month()
                  : month.month()
              }
              onChange={(e) => {
                if (month.isBefore(moment(min))) {
                  onMonthSelect(moment(min), moment(min).month());
                } else {
                  onMonthSelect(month, e.target.value);
                }
              }}
            >
              {moment.months().map((label, value) => (
                <option key={label} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              value={month.year()}
              onChange={(e) => {
                onYearSelect(month, e.target.value);
              }}
            >
              {returnYears()}
            </select>
          </div>
        </div>
      );
    };


    //  if(this.props.flag==="datesideform"){
    //         this.setState({displaypanchangDate:null})
    // }
    // if(this.props.flag==="placesideform"){
    //         this.setState({displaypanchangDate:this.state.panchangDate})
    // }
    const mode = this.props.mode;
    if (mode === "TransitionView") {
      return (
        <>
          <div className="">
            <Modal
              animation={false}
              size="md"
              dialogClassName={"primaryModal"}
              show={this.props.show}
              onHide={this.props.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t("Update Details")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{}}>
                <div className="card">
                  <div className="card-body">
                    <form autoComplete="off">
                      <div className="form-group mb-1">
                        <label htmlFor="example-input-small">
                          {t("Country of Observation")}

                        </label>
                        <CountryAutocomplete
                          autoComplete="new-password"
                          defaultValue={
                            this.context.transitCountry !== null
                              ? this.context.transitCountry
                              : ""
                          }
                          mode={"Transit"}
                          resetInputText={this.state.resetInputText}
                          handleChange={this.handleAutoCompleterChange}

                          city={
                            this.context.transitCountry !== null
                              ? this.context.transitCountry
                              : ""
                          }
                        />
                      </div>
                      <div className="form-group mb-1">
                        <label htmlFor="example-input-small">
                          {t("City of Observation")}
                        </label>
                        <Autocomplete
                          autoComplete="new-password"
                          defaultValue={
                            this.context.transitCityState !== null
                              ? this.context.transitCityState
                              : ""
                          }
                          mode={"Transit"}
                          resetInputText={this.state.resetInputText}
                          handleChange={this.handleAutoCompleterChange}
                          id=""
                          city={
                            this.context.transitCityState !== null
                              ? this.context.transitCityState
                              : ""
                          }
                          country={this.context.transitCountry}
                        />
                      </div>
                      {/* {this.props.view=="planetaryConjunctions"?(
                        <>
                        <div className="">
                            <label
                              htmlFor="example-input-small"
                              style={{ marginBottom: "0px" }}
                            >
                              {t("Select Year Range")}
                            </label>
                          </div>
                          <label htmlFor="example-input-small">{t("From")}</label>
                          <div className="mb-1">
                            <select onChange={i => { this.context.setPlanetConjunctionStartorEnd("start", parseInt(i.target.value)) }}
                              value={this.context.planetConjunctionStart}>
                              {this.state.yeararray.map((y, index) => {
                                return (<option value={y} key={index}>{y}</option>)
                              })}
                            </select>
                          </div>
                          <label htmlFor="example-input-small">{t("to")}</label>
                          <div className="">
                            <select onChange={i => { this.context.setPlanetConjunctionStartorEnd("end", parseInt(i.target.value)) }}
                              value={this.context.planetConjunctionEnd}>
                              {this.state.yeararray.map((y, index) => {
                                return (<option value={y} key={index}>{y}</option>)
                              })}
              
                            </select>
                          </div>
    
                          <div>
                          <MultiSelect options={this.state.planetOptions}
                                                    value={this.state.selectedValues}
                                                    onChange={i=>this.handlePlanetSelect(i)}
                                                    labelledBy="Select"
                                                />
                            {/* <input type="checkbox" name="jup"/><label for="jup">Jupiter</label> */}
                      {/* </div>
                          <div>
                            {this.state.errors.error && (
                              <p className="form-error">
                                {" "}
                                {this.state.errors.error}
                              </p>
                            )}
                          </div>
                          <button
                            type="submit"
                            className="ladda-button btn"
                            style={{
                              backgroundColor: "#03428D",
                              color: "#fff",
                              marginTop: "5%",
                            }}
                            onClick={e=>this.alertclick}
                            disabled={this.context.IsLoading}
                          >
                            {this.context.IsLoading ? (
                              <span>
                                {t("Getting Data")}{" "}
                                <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                              </span>
                            ) : (
                              t("Get Data")
                            )}
                          </button>
                      </>
                      ""
                      ):(<> */}
                      <div className="">
                        <label
                          htmlFor="example-input-small"
                          style={{ marginBottom: "0px" }}
                        >
                          {t("Select Date Range")}
                        </label>
                      </div>
                      <label htmlFor="example-input-small">{t("From")}</label>
                      <div className="mb-1">
                        <SingleDatePicker
                          numberOfMonths={1}
                          date={
                            this.state.startDate ||
                            (this.context.startDate !== null
                              ? moment(this.context.startDate)
                              : null)
                          } // momentPropTypes.momentObj or null
                          onDateChange={(date) =>
                            this.onDateChange("startDate", date)
                          } // PropTypes.func.isRequired
                          focused={this.state.focused} // PropTypes.bool
                          onFocusChange={({ focused }) =>
                            this.setState({ focused })
                          } // PropTypes.func.isRequired
                          id="start_date" // PropTypes.string.isRequired,
                          placeholder={t("Start Date")}
                          isOutsideRange={falseFunc}
                          displayFormat={() => "DD-MM-YYYY"}
                          // minDate={moment(min)}
                          // maxDate={moment(max)}
                          renderMonthElement={renderMonthElement}
                          readOnly={true}
                        />
                      </div>
                      <label htmlFor="example-input-small">{t("to")}</label>
                      <div className="">
                        <SingleDatePicker
                          numberOfMonths={1}
                          date={
                            this.state.endDate ||
                            (this.context.endDate !== null
                              ? moment(this.context.endDate)
                              : null)
                          } // momentPropTypes.momentObj or null
                          onDateChange={(date) =>
                            this.onDateChange("endDate", date)
                          } // PropTypes.func.isRequired
                          focused={this.state.focusedEndDate} // PropTypes.bool
                          onFocusChange={({ focused: focusedEndDate }) =>
                            this.setState({ focusedEndDate })
                          } // PropTypes.func.isRequired
                          id="end_date" // PropTypes.string.isRequired,
                          placeholder={t("End Date")}
                          isOutsideRange={falseFunc}
                          displayFormat={() => "DD-MM-YYYY"}
                          // minDate={moment(min)}
                          // maxDate={moment(max)}
                          renderMonthElement={renderMonthElement}
                          readOnly={true}
                        />
                      </div>
                      <div>
                        <p className="sub-header">
                          {t("Maximum Date Range allowed is 1year")}
                        </p>
                      </div>
                      <div>
                        {this.state.errors.error && (
                          <p className="form-error">
                            {" "}
                            {this.state.errors.error}
                          </p>
                        )}
                      </div>
                      <button
                        type="submit"
                        className="ladda-button btn"
                        style={{
                          backgroundColor: "#03428D",
                          color: "#fff",
                          marginTop: "5%",
                        }}
                        onClick={this.alertclick}
                        disabled={this.context.IsLoading}
                      >
                        {this.context.IsLoading ? (
                          <span>
                            {t("Getting Data")}{" "}
                            <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                          </span>
                        ) : (
                          t("Get Data")
                        )}
                      </button>
                      {/* </>)} */}
                    </form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>

          {/* New div for showing table data in this component only  */}
        </>
      );
    }
    else if (mode === "PlanetaryConjunctionMode") {
      return (
        <>
          <div className="">
            <Modal
              animation={false}
              size="md"
              dialogClassName={"primaryModal"}
              show={this.props.show}
              onHide={this.props.handleClose}
              centered

            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t("Update Details")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{}}>
                <div className="card">
                  <div className="card-body">
                    <form autoComplete="off">
                      <div className="form-group mb-1">
                        <label htmlFor="example-input-small">
                          {t("Country of Observation")}

                        </label>
                        <CountryAutocomplete
                          autoComplete="new-password"
                          defaultValue={
                            this.context.transitCountry !== null
                              ? this.context.transitCountry
                              : ""
                          }
                          mode={"Transit"}
                          resetInputText={this.state.resetInputText}
                          handleChange={this.handleAutoCompleterChange}

                          city={
                            this.context.transitCountry !== null
                              ? this.context.transitCountry
                              : ""
                          }
                        />
                      </div>
                      <div className="form-group mb-1">
                        <label htmlFor="example-input-small">
                          {t("City of Observation")}
                        </label>
                        <Autocomplete
                          autoComplete="new-password"
                          defaultValue={
                            this.context.transitCityState !== null
                              ? this.context.transitCityState
                              : ""
                          }
                          mode={"Transit"}
                          resetInputText={this.state.resetInputText}
                          handleChange={this.handleAutoCompleterChange}
                          id=""
                          city={
                            this.context.transitCityState !== null
                              ? this.context.transitCityState
                              : ""
                          }
                          country={this.context.transitCountry}
                        />
                      </div>
                      <div className="">
                        <label
                          htmlFor="example-input-small"
                          style={{ marginBottom: "0px" }}
                        >
                          {t("Enter Year Range")}
                        </label>
                      </div>
                      <div>
                        <p className="sub-header">
                          {t("Year should be in range 1900-2399")}
                        </p>
                      </div>
                      <label htmlFor="example-input-small">{t("From")}</label>
                      <div className="mb-1">
                        {/* <select onChange={i => { this.context.setPlanetConjunctionStartorEnd("start", parseInt(i.target.value)) }}
                          value={this.context.planetConjunctionStart}>
                          {this.state.yeararray.map((y, index) => {
                            return (<option value={y} key={index}>{y}</option>)
                          })}
                        </select> */}
                        <input className="yearInput-input" type="number" min={1900} max={2399} placeholder="YYYY" value={this.context.planetConjunctionStart || ""} onChange={i => this.context.setPlanetConjunctionStartorEnd("start", i.target.value)} />
                      </div>
                      <label htmlFor="example-input-small">{t("to")}</label>
                      <div className="">
                        {/* <select onChange={i => { this.context.setPlanetConjunctionStartorEnd("end", parseInt(i.target.value)) }}
                          value={this.context.planetConjunctionEnd}>
                          {this.state.yeararray.map((y, index) => {
                            return (<option value={y} key={index}>{y}</option>)
                          })}
          
                        </select> */}
                        <input className="yearInput-input" type="number" min={1900} max={2399} placeholder="YYYY" value={this.context.planetConjunctionEnd || ""} onChange={i => this.context.setPlanetConjunctionStartorEnd("end", i.target.value)} />
                      </div>

                      <div>
                        <div>
                          {this.state.errors.error && (
                            <p className="form-error">
                              {" "}
                              {this.state.errors.error}
                            </p>
                          )}
                        </div>
                        <div className="" style={{"padding-top":"10px"}}>
                        <label htmlFor="example-input-small">{t("Planets")}</label>
                        <MultiSelect options={this.state.planetOptions}
                          value={this.state.selectedValues}

                          onChange={i => this.handlePlanetSelect(i)}
                          labelledBy="Select Planets"
                        />
</div>
<div className="" style={{"padding-top":"10px"}}>
                        <label htmlFor="example-input-small">{t("Rashis")}</label>
                        <MultiSelect options={this.state.rashiOptions}
                          value={this.state.selectedRashis}

                          onChange={i => this.handleRashiSelect(i)}
                          labelledBy="Select Rashis"
                        />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="ladda-button btn"
                        style={{
                          backgroundColor: "#03428D",
                          color: "#fff",
                          marginTop: "5%",
                        }}
                        onClick={this.alertclickPlanetaryConjunction}
                        disabled={this.context.IsLoading}
                      >
                        {this.context.IsLoading ? (
                          <span>
                            {t("Getting Data")}{" "}
                            <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                          </span>
                        ) : (
                          t("Get Data")
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </>
      );
    }
    else if (mode === "panchangView") {
      return (
        <>
          <Modal
            show={this.context.panchangForm}
            onHide={() => this.handlePanchangClose()}
            centered

          >
            <Modal.Header
              closeButton
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Modal.Title
                style={{ color: "rgb(3, 66, 141)", fontWeight: "bold" }}
              >
                {t("Please Update Date and Location")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <div className="card" style={{ display: this.state.open }}>
                  <div className="card-body">
                    <form>
                      <div className="form-group mb-1">
                        <label htmlFor="example-input-small">
                          {t("Country of Observation")}
                        </label>
                        <CountryAutocomplete
                          autoComplete="new-password"
                          defaultValue={
                            this.context.panchangCountry !== null
                              ? this.context.panchangCountry
                              : this.props.placeDetails !== null
                                ? this.props.placeDetails.country
                                : ""
                          }
                          mode={"Panchang"}
                          resetInputText={this.state.resetInputText}
                          handleChange={this.handleAutoCompleterChange}
                          id=""
                          city={
                            this.context.panchangCountry !== null
                              ? this.context.panchangCountry
                              : this.props.placeDetails !== null
                                ? this.props.placeDetails.country
                                : ""
                          }
                        />
                      </div>
                      <div className="form-group mb-1">
                        <label htmlFor="example-input-small">
                          {t("City of Observation")}
                        </label>
                        <Autocomplete
                          autoComplete="new-password"
                          defaultValue={
                            this.context.panchangCityState !== null
                              ? this.context.panchangCityState
                              : ""
                          }
                          mode={"Panchang"}
                          resetInputText={this.state.resetInputText}
                          handleChange={this.handleAutoCompleterChange}
                          id=""
                          city={
                            this.context.panchangCityState !== null
                              ? this.context.panchangCityState
                              : ""
                          }
                          country={this.context.panchangCountry}
                        />
                      </div>
                      <label htmlFor="example-input-small">
                        {t("Select Date")}
                      </label>
                      <div className="mb-1">
                        <SingleDatePicker
                          numberOfMonths={1}
                          date={
                            this.state.panchangDate ||
                            moment(moment(this.props.date).format("MM-DD-YYYY"))
                          } // momentPropTypes.momentObj or null
                          onDateChange={(date) =>
                            this.onDateChange("panchangDate", date)
                          } // PropTypes.func.isRequired
                          focused={this.state.focused} // PropTypes.bool
                          onFocusChange={({ focused }) =>
                            this.setState({ focused })
                          } // PropTypes.func.isRequired
                          id="panchang_date" // PropTypes.string.isRequired,
                          placeholder="Date"
                          isOutsideRange={falseFunc}
                          displayFormat={() => "DD-MM-YYYY"}
                          // minDate={moment(min)}
                          // maxDate={moment(max)}
                          renderMonthElement={renderMonthElement}
                          readOnly={true}
                        />
                      </div>
                      {this.state.panchangError && (
                        <p className="form-error">{this.state.panchangError}</p>
                      )}
                      <center>
                        <button
                          type="submit"
                          className="ladda-button btn"
                          style={{ backgroundColor: "#03428D", color: "#fff" }}
                          onClick={this.alertclickPanchang}
                          disabled={this.context.IsLoading}
                        >
                          {this.context.IsLoading ? (
                            <span>
                              {t("Getting Data")}{" "}
                              <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                            </span>
                          ) : (
                            t("Update")
                          )}
                        </button>
                      </center>
                    </form>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </>
      );
    } else if (mode === "Snapshot") {
      return (
        <>
          <div className="">
            <Modal
              animation={false}
              size="md"
              dialogClassName={"primaryModal"}
              show={this.props.show}
              onHide={this.props.handleClose}
              centered

            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {t("Update Details")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{}}>
                <div className="card" style={{ display: this.state.open }}>
                  <div className="card-body">
                    <form>
                      <div className="form-group mb-1">
                        <label htmlFor="example-input-small">
                          {t("Country of Observation")}
                        </label>
                        <CountryAutocomplete
                          autoComplete="new-password"
                          defaultValue={
                            this.context.transitCountry !== null
                              ? this.context.transitCountry
                              : ""
                          }
                          mode={"Transit"}
                          resetInputText={this.state.resetInputText}
                          handleChange={this.handleAutoCompleterChange}
                          id=""
                          city={
                            this.context.transitCountry !== null
                              ? this.context.transitCountry
                              : ""
                          }
                        />
                      </div>
                      <div className="form-group mb-1">
                        <label htmlFor="example-input-small">
                          {t("City of Observation")}
                        </label>
                        <Autocomplete
                          autoComplete="new-password"
                          defaultValue={
                            this.context.transitCityState !== null
                              ? this.context.transitCityState
                              : ""
                          }
                          mode={"Transit"}
                          resetInputText={this.state.resetInputText}
                          handleChange={this.handleAutoCompleterChange}
                          id=""
                          city={
                            this.context.transitCityState !== null
                              ? this.context.transitCityState
                              : ""
                          }
                          country={this.context.transitCountry}
                        />
                      </div>
                      <button
                        type="submit"
                        className="ladda-button btn"
                        style={{ backgroundColor: "#03428D", color: "#fff" }}
                        onClick={this.alertclickSnapshot}
                        disabled={
                          this.context.IsLoading ||
                          Object.keys(this.context.requestParams).length === 0
                        }
                      >
                        {this.context.IsLoading ? (
                          <span>
                            {t("Getting Data")}{" "}
                            <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                          </span>
                        ) : (
                          t("Update")
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </>
      );
    } else if (mode === "Horoscope") {
      return (
        <HoroscopeForm
          show={this.props.show}
          renderMonthElement={renderMonthElement}
          falseFunc={falseFunc}
          focused={this.state.focused}
          handleFocus={this.handleFocus}
          errors={this.state.errors}
          cities_name={cities_name}
          handleAutoCompleterChange={this.handleAutoCompleterChange}
          resetInputText={this.resetInputText}
          view={this.props.view}
          handleClose={this.props.handleClose}
        />
      );
    }
  }
}

export default withTranslation()(withRouter(sideform));
