import React, { useContext } from "react";
import { GlobalContext } from "../../../mycontext";
import "./ResetButton.css";
import Male_img from "../../../assets/svg/profile-male.svg";
import Female_img from "../../../assets/svg/Female.svg";
import { FiEdit } from "react-icons/fi";
import { isFunction } from "lodash";
import { useTranslation } from "react-i18next";
const ResetButton = (props) => {
  const context = useContext(GlobalContext);
  const { t, i18n } = useTranslation();
  const resetForm = () => {
    if (context.horoscopeCountry === null || context.horoscopeCountry === "") {
      context.setCountry(context.requestParamsHoroscope.country, "Horoscope");
    }
    if (
      context.horoscopeCityState === null ||
      context.horoscopeCityState === ""
    )
      context.setCityAndState(
        `${context.requestParamsHoroscope.name},${context.requestParamsHoroscope.state}`,
        "Horoscope"
      );
    context.handleHoroscopeForm(true);
  };
  const userIcon = (type) => {
    return (
      <div className="profile">
        <img src={type === "M" ? Male_img : Female_img}></img>
      </div>
    );
  };
  return (
    <>
      {Object.keys(context.horoscopeData).length > 0 ? (
        <div className={props.parent === "cview" ? "dob-cview " : "dob"}>
          {context.horoscopeData.basic_details.gender === "Male" ||
          context.horoscopeData.basic_details.gender === "पुरुष"
            ? userIcon("M")
            : userIcon("F")}
          <div className="">
            <h4>{`${context.horoscopeData.native_name}`}</h4>
            <p className="my-0">
              <b>{t("DOB")}:</b>{" "}
              {`${context.horoscopeData.date_of_birth} - ${context.horoscopeData.time_of_birth}`}
            </p>
            <p className="my-0">
              <b>{t("Place")}:</b> {context.horoscopeData["Place of birth"]}{" "}
              <a href="#" class="badge badge-primary" onClick={() => resetForm()} style={{fontSize:"12px"}}>Edit / New</a>
              {/* <b className="resetLink" onClick={() => resetForm()}>
                <FiEdit className="ico-edit" />
              </b> */}
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
    // <button
    //   type="button"
    //   className="btn btn-outline-secondary"
    //   onClick={() => context.handleHoroscopeForm(true)}
    //   style={{
    //     marginTop: "5px",
    //     float: "right",
    //     marginRight: "30px",
    //   }}
    // >
    //   <i className="mdi mdi-refresh"></i> Reset
    // </button>
  );
};

export default ResetButton;
