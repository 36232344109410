import axios from "axios";

const baseURL = "https://apiv2.omparashar.com/user/";

//check user exist
export async function checkIfUserExist(user_id) {
  try {
    let res = await axios({
      url: baseURL + "checkuser/" + user_id,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
    // console.log(res);

    return res;
    // Don't forget to return something
    //return res;
  } catch (err) {
    alert("error catched");
    console.error(err);
  }
}

export async function registerUser(accessToken, userName, preferredLanguage) {
  try {
    let body = {
      auth_token: accessToken,
      username: userName,
      lang: preferredLanguage,
    };
    // console.log(body);
    let res = await axios({
      url: baseURL + "registeruser/",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    });
    // console.log(res);
    if (res.status === 200) {
      if (res.data.message === "user created") {
        return true;
      }
    }
    // Don't forget to return something
    //return res;
  } catch (err) {
    console.error(err);
  }
}

export async function changeLanguageAPI(userId, preferredLanguage) {
  try {
    let body = {
      userid: userId,
      lang: preferredLanguage,
    };
    // console.log(body);
    let res = await axios({
      url: baseURL + "changelang/",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    });
    // console.log(res);
    if (res.status === 200) {
      if (res.data.message === "language changed") {
        return true;
      }
    }
    // Don't forget to return something
    //return res;
  } catch (err) {
    console.error(err);
  }
}

export async function downloadReportPDF(reportString) {
  try {
    let body = {
      report_type: reportString,
    };

    let res = await axios({
      url: baseURL + "downloadPDF/",
      method: "post",
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    });

    if (res.status === 201) {
      return res;
    }
    // Don't forget to return something
    //return res;
  } catch (err) {
    console.error(err);
  }
}
