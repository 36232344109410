import React, { Component } from "react";
import Table from "react-bootstrap/Table";
import "./DivisionalChart.css";
import { withTranslation } from "react-i18next";

const divValMapper = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
  4: 7,
  5: 9,
  6: 10,
  7: 12,
  8: 16,
  9: 20,
  10: 24,
  11: 27,
  12: 30,
  13: 40,
  14: 45,
  15: 60,
};
function chart_name(val) {
  let str;
  if (val === 1) {
    str = "Lagna Chart (D1)";
  } else if (val === 2) {
    str = "Hora Wealth (D2 Chart)";
  } else if (val === 3) {
    str = "Drekkana-Siblings (D3 Chart)";
  } else if (val === 4) {
    str = "Chaturthamsha-Luck (D4 Chart)";
  } else if (val === 7) {
    str = "Saptamamsha-Children (D7 Chart)";
  } else if (val === 9) {
    str = "Navamsha-Spouse (D9 Chart)";
  } else if (val === 10) {
    str = "Dashamamsha-Profession (D10 Chart)";
  } else if (val === 12) {
    str = "Dwadashmamsha-Parents (D12 Chart)";
  } else if (val === 16) {
    str = "Shodashamsha-Vehicles (D16 Chart)";
  } else if (val === 20) {
    str = "Vimshamsha-Religious Inclinations(D20 Chart)";
  } else if (val === 24) {
    str = "Chaturvimshamsha-Education (D24 Chart)";
  } else if (val === 27) {
    str = "Saptavimshamsha-Strength (D27 Chart)";
  } else if (val === 30) {
    str = "Trimshamsha-Misfortune (D30 Chart)";
  } else if (val === 40) {
    str = "Khavedamsha-Auspicious Results(D40 Chart)";
  } else if (val === 45) {
    str = "Akshvedamsha-Well Being (D45 Chart)";
  } else {
    str = "Shastiamsha-Well Being (D60 Chart)";
  }
  return str;
}

function extract_image_link_charts(val) {
  let imglink = "/assets/div-icons/";
  if (val === 20) {
    return imglink + "D" + val + ".png";
  } else {
    return imglink + "D" + val + ".svg";
  }
}

function zodiac_name(val) {
  let str;
  if (val === 1) {
    str = "Aries";
  } else if (val === 2) {
    str = "Taurus";
  } else if (val === 3) {
    str = "Gemini";
  } else if (val === 4) {
    str = "Cancer";
  } else if (val === 5) {
    str = "Leo";
  } else if (val === 6) {
    str = "Virgo";
  } else if (val === 7) {
    str = "Libra";
  } else if (val === 9) {
    str = "Sagittarius";
  } else if (val === 8) {
    str = "Scorpio";
  } else if (val === 10) {
    str = "Capricorn";
  } else if (val === 11) {
    str = "Aquarius";
  } else {
    str = "Aquarius";
  }
  return str.substring(0, 3);
}
function extract_image_link_zodiac(val) {
  let str;
  if (val === 1) {
    str = "Aries";
  } else if (val === 2) {
    str = "Taurus";
  } else if (val === 3) {
    str = "Gemini";
  } else if (val === 4) {
    str = "Cancer";
  } else if (val === 5) {
    str = "Leo";
  } else if (val === 6) {
    str = "Virgo";
  } else if (val === 7) {
    str = "Libra";
  } else if (val === 9) {
    str = "Sagittarius";
  } else if (val === 8) {
    str = "Scorpio";
  } else if (val === 10) {
    str = "Capricorn";
  } else if (val === 11) {
    str = "Aquarius";
  } else {
    str = "Aquarius";
  }
  let imglink = "/assets/Zodiac/";
  return imglink + str + ".svg";
}
class DivisionalTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderChoice: "rashiNumber",
    };
  }
  renderElement = (value) => {
    this.setState({ renderChoice: value });
  };

  render() {
    const { data, tab, ausData } = this.props;
    const { t, i18n } = this.props;
    return (
      <div>
        <center className="row">
          <h2
            className={
              tab === "1"
                ? "title text-right col-md-7 col-12 pt-2 pl-5"
                : "title text-right col-md-8 col-12 pt-2 pl-5"
            }
          >
            {tab === "1"
              ? t("Rashi for Planets in Divisional Charts")
              : tab === "2"
              ? t("House for Planets in Divisional Charts")
              : t("Auspicious, InAuspicious Placing")}
          </h2>
          {tab === "1" ? (
            <div className="col-md-3 col-12 pt-4">
              <div>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontFamily: "Lucida Bright",
                  }}
                >
                  <input
                    type="radio"
                    name="optrashi"
                    value="rashiNumber"
                    checked={this.state.renderChoice === "rashiNumber"}
                    onClick={() => this.renderElement("rashiNumber")}
                    readOnly
                  />
                  {""}
                  {t("Rashi Number")}
                </label>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontFamily: "Lucida Bright",
                  }}
                >
                  <input
                    type="radio"
                    name="optrashi"
                    value="rashiName"
                    style={{ marginLeft: "20px" }}
                    onClick={() => this.renderElement("rashiName")}
                    readOnly
                  />
                  {""}
                  {t("Rashi Name")}
                </label>
              </div>
            </div>
          ) : (
            <> </>
          )}
        </center>
        {tab === "1" ? (
          this.state.renderChoice === "rashiName" ? (
            <Table responsive="xs" className="content-table-div rashiNameTable">
              <thead>
                <tr>
                  <th>{t("Div Chart Num")}</th>
                  <th>{t("Asc")}</th>
                  <th>{t("Sun")}</th>
                  <th>{t("Moon")}</th>
                  <th>{t("Mars")}</th>
                  <th>{t("Merc")}</th>
                  <th>{t("Jupt")}</th>
                  <th>{t("Venu")}</th>
                  <th>{t("Satn")}</th>
                  <th>{t("Rahu")}</th>
                  <th>{t("Ketu")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map(function (val, i) {
                  return (
                    <tr key={val + i}>
                      <td className="text-left">
                        {tab === "1" ? (
                          val["num"] === 1 || val["num"] === "1" ? (
                            <span>
                              &nbsp; &nbsp;&nbsp;&nbsp;
                              {t(chart_name(val["num"]))}
                            </span>
                          ) : val["num"] ? (
                            <>
                              <img
                                className="image-center"
                                alt="Div"
                                height="20px"
                                width="20px"
                                src={extract_image_link_charts(val["num"])}
                              ></img>
                              {""}
                              <span> {t(chart_name(val["num"]))}</span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          val["num"]
                        )}
                      </td>
                      <td>
                        {tab === "1" ? (
                          val["Asc"] ? (
                            <>
                              <img
                                className="zodiacImages"
                                alt=""
                                src={extract_image_link_zodiac(val["Asc"])}
                              ></img>
                              {""}
                              &nbsp;
                              <span> {t(zodiac_name(val["Asc"]))}</span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          val["Asc"]
                        )}
                      </td>
                      <td>
                        {tab === "1" ? (
                          val["Sun"] ? (
                            <>
                              <img
                                className="zodiacImages"
                                alt=""
                                src={extract_image_link_zodiac(val["Sun"])}
                              ></img>{" "}
                              &nbsp;
                              <span> {t(zodiac_name(val["Sun"]))}</span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          val["Asc"]
                        )}
                      </td>
                      <td>
                        {tab === "1" ? (
                          val["Moon"] ? (
                            <>
                              <img
                                className="zodiacImages"
                                alt=""
                                src={extract_image_link_zodiac(val["Moon"])}
                              ></img>{" "}
                              &nbsp;
                              <span> {t(zodiac_name(val["Moon"]))}</span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          val["Asc"]
                        )}
                      </td>
                      <td>
                        {tab === "1" ? (
                          val["Mars"] ? (
                            <>
                              <img
                                className="zodiacImages"
                                alt=""
                                src={extract_image_link_zodiac(val["Mars"])}
                              ></img>{" "}
                              &nbsp;
                              <span> {t(zodiac_name(val["Mars"]))}</span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          val["Asc"]
                        )}
                      </td>
                      <td>
                        {tab === "1" ? (
                          val["Merc"] ? (
                            <>
                              <img
                                className="zodiacImages"
                                alt=""
                                src={extract_image_link_zodiac(val["Merc"])}
                              ></img>{" "}
                              &nbsp;
                              <span> {t(zodiac_name(val["Merc"]))}</span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          val["Merc"]
                        )}
                      </td>
                      <td>
                        {tab === "1" ? (
                          val["Jupt"] ? (
                            <>
                              <img
                                className="zodiacImages"
                                alt=""
                                src={extract_image_link_zodiac(val["Jupt"])}
                              ></img>{" "}
                              &nbsp;
                              <span> {t(zodiac_name(val["Jupt"]))}</span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          val["Jupt"]
                        )}
                      </td>
                      <td>
                        {tab === "1" ? (
                          val["Venu"] ? (
                            <>
                              <img
                                className="zodiacImages"
                                alt=""
                                src={extract_image_link_zodiac(val["Venu"])}
                              ></img>{" "}
                              &nbsp;
                              <span> {t(zodiac_name(val["Venu"]))}</span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          val["Venu"]
                        )}
                      </td>
                      <td>
                        {tab === "1" ? (
                          val["Satn"] ? (
                            <>
                              <img
                                className="zodiacImages"
                                alt=""
                                src={extract_image_link_zodiac(val["Satn"])}
                              ></img>{" "}
                              &nbsp;
                              <span> {t(zodiac_name(val["Satn"]))}</span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          val["Satn"]
                        )}
                      </td>
                      <td>
                        {tab === "1" ? (
                          val["Rahu"] ? (
                            <>
                              <img
                                className="zodiacImages"
                                alt=""
                                src={extract_image_link_zodiac(val["Rahu"])}
                              ></img>{" "}
                              &nbsp;
                              <span> {t(zodiac_name(val["Rahu"]))}</span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          val["Rahu"]
                        )}
                      </td>
                      <td>
                        {tab === "1" ? (
                          val["Ketu"] ? (
                            <>
                              <img
                                className="zodiacImages"
                                alt=""
                                src={extract_image_link_zodiac(val["Ketu"])}
                              ></img>{" "}
                              &nbsp;
                              <span> {t(zodiac_name(val["Ketu"]))}</span>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          val["Ketu"]
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <Table responsive="xs" className="content-table-div">
              <thead>
                <tr>
                  <th>{t("Div Chart Num")}</th>
                  <th>{t("Asc")}</th>
                  <th>{t("Sun")}</th>
                  <th>{t("Moon")}</th>
                  <th>{t("Mars")}</th>
                  <th>{t("Merc")}</th>
                  <th>{t("Jupt")}</th>
                  <th>{t("Venu")}</th>
                  <th>{t("Satn")}</th>
                  <th>{t("Rahu")}</th>
                  <th>{t("Ketu")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map(function (val, i) {
                  return (
                    <tr key={val + i}>
                      <td>{val["num"]}</td>
                      <td>{val["Asc"]}</td>
                      <td>{val["Sun"]}</td>
                      <td>{val["Moon"]}</td>
                      <td>{val["Mars"]}</td>
                      <td>{val["Merc"]}</td>
                      <td>{val["Jupt"]}</td>
                      <td>{val["Venu"]}</td>
                      <td>{val["Satn"]}</td>
                      <td>{val["Rahu"]}</td>
                      <td>{val["Ketu"]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )
        ) : tab === "2" ? (
          <Table responsive="xs" className="content-table-div">
            <thead>
              <tr>
                <th>{t("Div Chart Num")}</th>
                <th>{t("Asc")}</th>
                <th>{t("Sun")}</th>
                <th>{t("Moon")}</th>
                <th>{t("Mars")}</th>
                <th>{t("Merc")}</th>
                <th>{t("Jupt")}</th>
                <th>{t("Venu")}</th>
                <th>{t("Satn")}</th>
                <th>{t("Rahu")}</th>
                <th>{t("Ketu")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map(function (val, i) {
                return (
                  <tr key={val + i}>
                    <td>
                      {tab === "1" ? (
                        val["num"] ? (
                          <span> {t(chart_name(val["num"]))}</span>
                        ) : (
                          ""
                        )
                      ) : (
                        val["num"]
                      )}
                    </td>
                    <td>
                      {tab === "1" ? (
                        val["Asc"] ? (
                          <>
                            <img
                              className="zodiacImages"
                              alt=""
                              src={extract_image_link_zodiac(val["Asc"])}
                            ></img>{" "}
                            &nbsp; &nbsp;
                            <span> {t(zodiac_name(val["Asc"]))}</span>
                          </>
                        ) : (
                          ""
                        )
                      ) : (
                        val["Asc"]
                      )}
                    </td>
                    <td>{val["Sun"]}</td>
                    <td>{val["Moon"]}</td>
                    <td>{val["Mars"]}</td>
                    <td>{val["Merc"]}</td>
                    <td>{val["Jupt"]}</td>
                    <td>{val["Venu"]}</td>
                    <td>{val["Satn"]}</td>
                    <td>{val["Rahu"]}</td>
                    <td>{val["Ketu"]}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <Table responsive="xs" className="content-table-div">
            <thead>
              <tr>
                <th>{t("Div Chart")}</th>
                <th>{t("Sun")}</th>
                <th>{t("Moon")}</th>
                <th>{t("Mars")}</th>
                <th>{t("Merc")}</th>
                <th>{t("Jupt")}</th>
                <th>{t("Venu")}</th>
                <th>{t("Satn")}</th>
              </tr>
            </thead>
            <tbody>
              {ausData.slice(0, 16).map(function (val, i) {
                return (
                  <tr key={val + i}>
                    <td style={{ textAlign: "left" }}>
                      {i === 0 ? (
                        <span style={{ marginLeft: "35px" }}>
                          {" "}
                          {t(chart_name(divValMapper[i]))}
                        </span>
                      ) : (
                        <>
                          <img
                            className="image-center"
                            alt="Div"
                            height="20px"
                            width="20px"
                            src={extract_image_link_charts(divValMapper[i])}
                          ></img>

                          <span> {t(chart_name(divValMapper[i]))}</span>
                        </>
                      )}
                    </td>
                    {val.map(function (innerval, j) {
                      return (
                        <td>
                          {innerval === "I" ? (
                            <span class="dot-red"></span>
                          ) : (
                            <span class="dot-green"></span>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
              <tr className="bold-row">
                <td style={{ textAlign: "center" }}>{t("Auspicious Count")}</td>
                <td>{ausData[16]["Auspicious Count"]["Sun"]}</td>
                <td>{ausData[16]["Auspicious Count"]["Moon"]}</td>
                <td>{ausData[16]["Auspicious Count"]["Mars"]}</td>
                <td>{ausData[16]["Auspicious Count"]["Mercury"]}</td>
                <td>{ausData[16]["Auspicious Count"]["Jupiter"]}</td>
                <td>{ausData[16]["Auspicious Count"]["Venus"]}</td>
                <td>{ausData[16]["Auspicious Count"]["Saturn"]}</td>
              </tr>
              <tr className="bold-row">
                <td style={{ textAlign: "center" }}>
                  {t("InAuspicious Count")}
                </td>
                <td>{ausData[17]["Inauspicious Count"]["Sun"]}</td>
                <td>{ausData[17]["Inauspicious Count"]["Moon"]}</td>
                <td>{ausData[17]["Inauspicious Count"]["Mars"]}</td>
                <td>{ausData[17]["Inauspicious Count"]["Mercury"]}</td>
                <td>{ausData[17]["Inauspicious Count"]["Jupiter"]}</td>
                <td>{ausData[17]["Inauspicious Count"]["Venus"]}</td>
                <td>{ausData[17]["Inauspicious Count"]["Saturn"]}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}

export default withTranslation()(DivisionalTable);
