function leapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}
export const validateDate = async (gettext_dob) => {

    let parts_of_DOB = gettext_dob.split(".");

    let year = parts_of_DOB[2];

    let month = parts_of_DOB[1];

    month = parseInt(month);
    let day = parts_of_DOB[0];


    const regex_four_digit = /^\d{4}$/g;
    let dayMonths31 = [1, 3, 5, 7, 8, 10, 12];
    console.log(year,month,day,'gg');
    if (year === null || month === null || day === null || day === '__' || month === '__' || year === '____' || !year.match(regex_four_digit)) {

        alert("Please Check Date");
        return 0;
    } else if (
        (day === 31 && dayMonths31.indexOf(month) === -1) ||
        (month === 2 && leapYear(year) && day > 29) ||
        (month === 2 && !leapYear(year) && day > 28)
    ) {
        alert("Please Check Month and Date Combination");
        return 0;
    }
    return 1;
};

// function timeFormatter(name, time) {
//     var ampm = time.slice(time.length - 2, time.length);
//     var SplitTime = time.slice(0, time.length - 2).split(":");
//     var h = parseInt(SplitTime[0]);
//     var min = parseInt(SplitTime[1]);
//     var sec = parseInt(SplitTime[2].trim());
//     if (ampm === "AM" && h === 12) {
//         h = 0;
//     } else if (ampm === "PM" && h === 12) {
//         h = 12;
//     } else if (ampm === "PM") {
//         h = 12 + h;
//     }
//     if (name === "hour") {
//         return h.toString();
//     }
//     if (name === "min") {
//         return min.toString();
//     }
//     if (name === "sec") {
//         return sec.toString();
//     }
// }