import React from "react";
import { useTranslation } from "react-i18next";

const ChandraBalamHTML = (props) => {
  const { t, i18n } = useTranslation();
  if (typeof props.chandrabalam !== "undefined")
    if (props.chandrabalam.length < 30) {
      return (
        <>
          <table
            className="tablesaw table mb-0 tablesaw-stack"
            id="tablesaw-802"
            style={{ border: "none", borderTop: "none" }}
          >
            <tbody>
              <tr className="table_head_tr">
                <th scope="col" colSpan="5" className="sectionheader">
                  <span>{t("Chandra Balam")}</span>
                </th>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[0]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Aries")}</span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[12].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[12]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[6]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Libra")}</span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[17].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[17]}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[1]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Taurus")}</span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[13].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[13]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[7]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Scorpio")}</span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[18].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[18]}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[2]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Gemini")}</span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[14].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[14]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[8]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Sagittarius")}</span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[19].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[19]}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[3]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Cancer")}</span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[15].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[15]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[9]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Capricorn")}</span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[20].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[20]}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[4]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Leo")}</span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[15].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[15]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[10]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Aquarius")}</span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[21].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[21]}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[5]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Virgo")}</span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[16].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[16]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[11]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Pisces")}</span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[22].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[22]}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    } else {
      return (
        <>
          <table
            className="tablesaw table mb-0 tablesaw-stack"
            id="tablesaw-802"
            style={{ border: "none", borderTop: "none" }}
          >
            <tbody>
              <tr className="table_head_tr">
                <th scope="col" colSpan="5" className="sectionheader">
                  <span>{t("Chandra Balam")}</span>
                </th>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[0]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Aries")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[12].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[12]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[25].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[25]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[6]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Libra")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[17].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[17]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[30].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[30]}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[1]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Taurus")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[13].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[13]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[26].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[26]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[7]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Scorpio")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[18].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[18]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[31].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[31]}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[2]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Gemini")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[14].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[14]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[27].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[27]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[8]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Sagittarius")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[19].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[19]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[32].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[32]}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[3]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Cancer")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[15].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[15]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[28].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[28]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[9]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Capricorn")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[20].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[20]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[33].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[33]}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[4]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Leo")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[16].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[16]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[29].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[29]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[10]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Aquarius")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[21].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[21]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[34].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[34]}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="td1">
                  <img
                    src={props.chandrabalam[5]}
                    alt="Rashi"
                    className="RashiIconChandra"
                  />
                  <span className="tablelabel">{t("Virgo")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td2">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[17].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[17]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[30].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[30]}
                  </span>
                </td>
                <td className="td3">
                  <img
                    src={props.chandrabalam[11]}
                    alt="Rashi"
                    className="RashiIcon2"
                  />
                  <span className="tablelabel">{t("Pisces")}</span>
                  <br></br>
                  <span className="tablelabel l2">
                    {props.chandrabalam[24]}
                  </span>
                </td>
                <td className="td4">
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[22].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[22]}
                  </span>
                  <br></br>
                  <span
                    className={
                      "tablevalue " +
                      props.chandrabalam[35].split(" ")[0] +
                      "chandra"
                    }
                  >
                    {props.chandrabalam[35]}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
};

export default ChandraBalamHTML;
