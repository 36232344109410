import React, { Component } from "react";
import Plotly from "plotly.js";
import moment from "moment";
import { GlobalContext } from "../../../mycontext";
import "../../TransitionComponents/Traces/plotly.css";
import { withTranslation } from "react-i18next";

class BarGraph extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      view_type: "ranked",
    };
  }

  componentDidMount() {
    this.apidate_format_ranked(this.props.horoscopeData, this.props.barValue);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.horoscopeData !== prevProps.horoscopeData ||
      this.props.barValue !== prevProps.barValue ||
      this.props.t !== prevProps.t
    ) {
      this.apidate_format_ranked(this.props.horoscopeData, this.props.barValue);
    }
  }

  apidate_format_ranked = (horoscopeData, value, t) => {
    let gd1 = document.getElementById(this.props.id);
    this.view_name = "ranked";
    let planetNames = [];
    let values = [];
    let color = [];

    if (value === 28) {
      color = [
        "#c6e4ca",
        "#9fd2a7",
        "#76c282",
        "#51b15e",
        "#3d8949",
        "#2e6136",
        "#1c3a20",
      ];
      let datamodified = JSON.parse(JSON.stringify(horoscopeData.graha_bala));
      datamodified.total_shad_bala.sort((a, b) =>
        a.unit_value > b.unit_value ? 1 : b.unit_value > a.unit_value ? -1 : 0
      );
      for (let i in datamodified.total_shad_bala) {
        planetNames.push(
          this.props.t(datamodified.total_shad_bala[i].planet_name)
        );
        values.push(datamodified.total_shad_bala[i].unit_value);
      }
    } else {
      color = [
        "#d6edd9",
        "#bae1c1",
        "#a1d2aa",
        "#84c88f",
        "#6bbc77",
        "#51b15e",
        "#449751",
        "#367a41",
        "#2b5e33",
        "#1c4525",
        "#132915",
        "#070e07",
      ];
      let datamodified = JSON.parse(JSON.stringify(horoscopeData.bhava_bala));
      datamodified.total_bhava_bala.sort((a, b) =>
        a.unit_value > b.unit_value ? 1 : b.unit_value > a.unit_value ? -1 : 0
      );
      for (let i in datamodified.total_bhava_bala) {
        planetNames.push(datamodified.total_bhava_bala[i].bhava_number);
        values.push(datamodified.total_bhava_bala[i].unit_value);
      }
    }
    // console.log(planetNames);
    let plotData = [
      {
        x: planetNames,
        y: values,
        type: "bar",
        text: planetNames,
        textposition: "auto",
        marker: {
          color: color,
        },
      },
    ];

    let layout = {
      width: "388",
      height: "400",
      margin: {
        l: 30,
        r: 10,
        b: 50,
        t: 10,
      },
      xaxis: {
        type: "category",
        tickfont: {
          family: "Arial",
          size: 12,
          color: "rgb(82, 82, 82)",
        },
      },
    };
    Plotly.newPlot(gd1, plotData, layout);
  };

  toggleClass = () => {
    this.state.currentClass === "col-lg-10 col-md-12"
      ? this.setState({ currentClass: "fullscreen" })
      : this.setState({ currentClass: "col-lg-10 col-md-12" });
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        {/* <button onClick={this.apidate_format}>click me</button> */}

        <center>
          <div id={this.props.id}></div>
        </center>
      </>
    );
  }
}

export default withTranslation()(BarGraph);
