import React, { useState, useEffect } from "react";
import "react-bootstrap";
import "./DashboardCard.css";
import { GlobalContext } from "../../mycontext";
import Sideform from "../SideComponents/sideform";
import { Link, withRouter,useLocation } from "react-router-dom";
// import loadjs from "loadjs";
import TransitionSideBar from "./DashboardSideBarTypes/TransitionSideBar";
import HoroscopeSideBar from "./DashboardSideBarTypes/HoroscopeSideBar.js";
import CelebrityManagementSideBar from "./DashboardSideBarTypes/CelebrityManagementSidebar";
// import { appendScript } from "../utils/appendScript";
// import { removeScript } from "../utils/removeScript";
import { FiAirplay } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const DashboardSideBar = (props) => {
  const context = React.useContext(GlobalContext);
  // const [show, setShow] = useState(false); // flag to control side form show
  let [localview, setLocalview] = useState(context.currentView); // view for different sideforms internal like snapshot.
  let [localmode, setLocalmode] = useState("TransitionView"); // mode for different side forms like transition, horoscope.
  const { t, i18n } = useTranslation();
  const location = useLocation();
  // const handleClose = () => {
  //   setShow(!show);
  // };

  const AreSnapshotDetailsFilled = () => {
    if (
      Object.keys(context.requestParams).length === 0 &&
      context.requestParams.constructor === Object
    ) {
      return false;
    } else {
      return true;
    }
  };
  const AreConjunctionDetailsFilled = () => {
    if (
      (Object.keys(context.requestParams).length === 0 &&
        context.requestParams.constructor === Object) ||
      context.planetConjunctionStart === null ||
      context.planetConjunctionEnd === null || context.selectedConjunctionArray.length<=1
    ) {
      return false;
    } else {
      return true;
    }
  };
  const AreTransitionDetailsFilled = () => {
    if (
      (Object.keys(context.requestParams).length === 0 &&
        context.requestParams.constructor === Object) ||
      context.startDate === null ||
      context.endDate === null
    ) {
      return false;
    } else {
      return true;
    }
  };

  // const handlePlainView = (selectedName) => {
  //   context.change_View(selectedName);
  // };

  const handleTransitionView = (selectedName) => {
    if (selectedName === "snapshotView" || selectedName === "kaalPurushView") {
      setLocalmode("Snapshot");
      setLocalview(selectedName);
      if (AreSnapshotDetailsFilled()) {
        props.history.push(`/dashboard/${selectedName}`);
      } else {
        context.handleSideFormTransition(true);
      }
    }
    else if (selectedName === "planetaryConjunctions") {
      setLocalmode("PlanetaryConjunctionMode");
      setLocalview(selectedName);
      if (AreConjunctionDetailsFilled()) {
        props.history.push(`/dashboard/${selectedName}`);
      } else {
        context.handleSideFormTransition(true);
      }
    }
     else {
      setLocalmode("TransitionView");
      setLocalview(selectedName);
      // if(selectedName==="planetaryConjunctions"){
      //   if (AreConjunctionDetailsFilled()) {
      //     props.history.push(`/dashboard/${selectedName}`);
      //   } else {
      //     context.handleSideFormTransition(true);
      //   }
      // }else{
      if (AreTransitionDetailsFilled()) {
        props.history.push(`/dashboard/${selectedName}`);
      } else {
        context.handleSideFormTransition(true);
      // }
    }}
  };

  const handleHoroscopeView = (selectedName) => {
    const urlParams = new URLSearchParams(location.search);
  const cbValue = urlParams.get('cb');
  let queryStringValue = '';
  if(cbValue){
    queryStringValue = `?cb=${cbValue}`;
  }

    if (Object.keys(context.horoscopeData).length === 0 && queryStringValue === '') {
      context.handleHoroscopeForm(true);
      setLocalmode("Horoscope");
      setLocalview(selectedName);
    } else {
      if(queryStringValue === ''){
        props.history.push(`/horoscope/${selectedName}`);
      }else{
        props.history.push(`/horoscope/${selectedName}${queryStringValue}`);
      }
     
    }
  };
  const handleCelebrityManagementView = (selectedName) => {
    console.log("handleCelebrityManagementView....",selectedName);
    props.history.push(`/CelebrityManagement/${selectedName}`);
    // if (Object.keys(context.CelebrityManagementData).length === 0) {
    //   context.handleCelebrityManagementForm(true);
    //   setLocalmode("CelebrityManagement");
    //   setLocalview(selectedName);
    // } else {
    //   props.history.push(`/CelebrityManagement/${selectedName}`);
    // }
  };
  return (
    <>
      {/*<!-- ========== Left Sidebar Start ========== --> */}
      <Sideform
        show={context.showSideFormTransition}
        mode={localmode}
        view={localview}
        handleClose={
          localmode === "Horoscope"
            ? context.handleHoroscopeForm
            : context.handleSideFormTransition
        }
        reset={false}
        history={props.history}
      />
      <div className="left-side-menu">
        <div className="h-100" data-simplebar>
          <div id="sidebar-menu">
            <ul id="side-menu">
              <li onClick={() => props.history.push("/")}>
                <Link to="">
                  <a
                    href=""
                    data-toggle="collapse"
                    style={{ color: "#6e768e" }}
                  >
                    {/* <i data-feather="airplay"></i> */}
                    <FiAirplay />
                    <span>{t("Home")}</span>
                  </a>
                </Link>
              </li>
              {props.history.location.pathname === "/panchang" ? (
                <></>
              ) : props.history.location.pathname.includes("/dashboard") ? (
                <TransitionSideBar
                  handleView={handleTransitionView}
                  history={props.history}
                />
              )  : (
                <HoroscopeSideBar
                  handleView={handleHoroscopeView}
                  history={props.history}
                />
                )}
            </ul>
          </div>
          {/* <div className="sidebar_botom ">
            <div class="custom-control custom-switch mb-1">
              <input
                type="radio"
                class="custom-control-input"
                name="color-scheme-mode"
                value="light"
                id="light-mode-check"
                checked={context.light}
                onClick={() => loadLight()}
              />
              <label class="custom-control-label" htmlFor="light-mode-check">
                Light Mode
              </label>
            </div>

            <div class="custom-control custom-switch mb-1">
              <input
                type="radio"
                class="custom-control-input"
                name="color-scheme-mode"
                value="dark"
                id="dark-mode-check"
                checked={!context.light}
                onClick={() => loadDark()}
              />
              <label class="custom-control-label" htmlFor="dark-mode-check">
                Dark Mode
              </label>
            </div>
          </div> */}

          <div className="clearfix"></div>
        </div>
      </div>
    </>
  );
};

export default withRouter(DashboardSideBar);
