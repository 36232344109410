import {IMaskInput} from "react-imask";
import React from "react";

const TimeInput =(props)=>{
    const {hour,
    minute,second,onHourChange,
    onMinuteChnage,onSecondChange,isDisabled}=props
    const _style = { width: "20%", fontSize: "0.9rem" }
    const disabled_style= { width: "20%", fontSize: "0.9rem" ,background: "#dddddd"}

    return(<div className="mb-1" style={{ display: "flex" }} >
                            <IMaskInput
                                style={isDisabled?disabled_style:_style}
                                mask={Number}
                                min={0}
                                max={23}
                                value={String(hour)
                                }
                                // onAccept={(value, mask) =>{setHour1(value);console.log(value,'hour1')}}
                                onAccept={(value, mask) =>{onHourChange(value);console.log(value,'hour1')}}
                                placeholder="hh"
                                className="form-control"
                                disabled={isDisabled}
                            ></IMaskInput>

                            <span
                                style={{
                                  "font-size": "0.9rem",
                                  padding: "6px",
                                  "font-weight": "600",
                                }}
                            >
                      :
                    </span>

                            <IMaskInput
                                // style={{ width: "20%", fontSize: "0.9rem" }}
                                style={isDisabled?disabled_style:_style}
                                mask={Number}
                                min={0}
                                max={59}
                                value={String(minute)
                                }
                                // onAccept={(value, mask) => {setMinute1(value);console.log(value,'min1')}}
                                onAccept={(value, mask) => {onMinuteChnage(value);console.log(value,'min1')}}
                                placeholder="mm"
                                className="form-control"
                                disabled={isDisabled}
                            ></IMaskInput>
                            <span
                                style={{
                                  "font-size": "0.9rem",
                                  padding: "6px",
                                  "font-weight": "600",
                                }}
                            >
                      :
                    </span>
                            <IMaskInput
                                // style={{ width: "20%", fontSize: "0.9rem" }}
                                style={isDisabled?disabled_style:_style}
                                mask={Number}
                                min={0}
                                max={59}
                                value={
                                  String(second)
                                }
                                // onAccept={(value, mask) => {setSecond1(value);console.log(value,'sec1');}}
                                onAccept={(value, mask) => {onSecondChange(value);console.log(value,'sec1');}}
                                placeholder="ss"
                                className="form-control"
                                disabled={isDisabled}
                            ></IMaskInput>
                          </div>);
}
export default TimeInput;
