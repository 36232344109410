import React from "react";
import { GlobalContext } from "../../mycontext";
import "../SideComponents/sidetable.css";
import { useTranslation } from "react-i18next";

const Sidetable = (props) => {
  const { t, i18n } = useTranslation();
  const context = React.useContext(GlobalContext);
  var date = context.apidataState && context.apidataState.date;
  var planet_data_degree = [];
  planet_data_degree = context.apidataState && context.apidataState.planets;
  // console.log("super-" + planet_data_degree);
  return (
    <>
      <div style={{ position: "absolute", width: "25%" }}>
        <div className="sub-heading" style={{ color: "#fff" }}>
          {" "}
          {t("Date")} :{" "}
          <span className="badge-pill date_sidetable"> {date} </span> <br></br>{" "}
          {localStorage.getItem("UserDefaultLanguage") === "English"
            ? t("At") + "12:00 AM @" + context.requestParams.name
            : "12:00 AM" +
              " " +
              t("At") +
              " " +
              "@" +
              context.requestParams.name}
        </div>
        <table className="table-sm table-bordered" style={{ color: "white" }}>
          <thead>
            <tr>
              <th scope="col">{t("Planet")}</th>
              <th scope="col">{t("Degrees")}</th>
            </tr>
          </thead>

          {planet_data_degree && planet_data_degree.length > 0
            ? planet_data_degree.map((item) => {
                return (
                  <>
                    <tr key={item.name}>
                      <td>{t(item.name)}</td>
                      <td>
                        {item.deg}&#176;{" "}
                        <b>
                          {item.motion === "D" || item.motion === "मार्गी"
                            ? ""
                            : t("R")}
                        </b>
                      </td>
                    </tr>
                  </>
                );
              })
            : "."}
        </table>
      </div>
    </>
  );
};

export default Sidetable;
