import axios from "axios";
import Table from "react-bootstrap/Table";

export default class Api {
  atlas_api = axios.create({
    baseURL: "https://atlas.omparashar.com/atlas/",
  });

  fetchUserHoroscope = (
    name,gender,day,month,year,hour,min,sec,lat,long,offset,city,state,country,reflong,timezone,token
  ) => {
    let URL = "https://apiv2.omparashar.com/horoscope/horoscope/aggregate";
    //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
    let params ="?nativename=" + name + "&gender=" + gender + "&year=" + year + "&month=" + month + "&day=" + day + "&hour=" + hour + "&minute=" + min + "&second=" + sec + "&lat=" + lat + "&long=" + long + "&offset=" + offset + "&city=" + city + "&state=" + state + "&country=" + country + "&reflong=" + reflong + "&timezone=" + timezone + "&token=" + token;
    // console.log(URL + params);

    return axios({
      url: URL + params,
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response);
          return response;
        } else {
        }
      })
      .catch((error) => {
        // // console.log(error);
      });
    // this.setState({open: false});
  };

  fetchSuggestionsCountry = async (userInput) => {
    try {
      var result = await axios.get(
        `https://apiv2.omparashar.com/core/atlas/fetchcountry?name=${userInput}`
      );
    } catch {
      // console.log("API issue");
    }

    return result;
  };

  fetchSuggestionsCityAndState = async (userInput, country) => {
    try {
      var result = await axios.get(
        `https://apiv2.omparashar.com/core/atlas/fetchcity?name=${userInput}&countryname=${country}`
      );
    } catch {
      // console.log("API issue");
    }

    return result;
  };

  fetchLocationDetails = async (id) => {
    //   const URL = `https://atlas.omparashar.com/atlas/fetchcity?cityname=${city}&countryname=${country}`;
    //   var config = {
    //     method: "get",
    //     url: URL,
    //     headers: {},
    //   };
    //   return axios(config)
    //     .then((response) => {
    //       if (response.status === 200) {
    //         return response;
    //       }
    //     })
    //     .catch(function (error) {
    //       // console.log("Result" + error);
    //       this.setState({ IsLoading: false });
    //     });
    // };
    try {
      var result = await axios.get(
        `https://apiv2.omparashar.com/core/atlas/fulldetailscity?cityid=${id}`
      );
    } catch {
      // console.log("API issue");
    }
    return result;
  };
  fetchChalit = (
    name, gender, day, month, year, hour, min, sec, lat, long, offset, city, state, country, reflong, timezone, token
  ) => {
    let URL =
      "https://apiv2.omparashar.com/imageservice/graphs/chalitchartgraph";
    //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
    let params = "?nativename=" + name + "&gender=" + gender + "&year=" + year + "&month=" + month + "&day=" + day + "&hour=" + hour + "&minute=" + min + "&second=" + sec + "&lat=" + lat + "&long=" + long + "&offset=" + offset + "&city=" + city + "&state=" + state + "&country=" + country + "&reflong=" + reflong + "&timezone=" + timezone + "&token=" +
      token;
    // console.log(URL + params);

    return axios({
      url: URL + params,
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    // this.setState({open: false});
  };

  fetchSudarshan = (
    name,
    gender,
    day,
    month,
    year,
    hour,
    min,
    sec,
    lat,
    long,
    offset,
    city,
    state,
    country,
    reflong,
    timezone,
    token
  ) => {
    let URL =
      "https://apiv2.omparashar.com/imageservice/graphs/sudarshanchakra";
    //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
    let params =
      "?nativename=" +
      name +
      "&gender=" +
      gender +
      "&year=" +
      year +
      "&month=" +
      month +
      "&day=" +
      day +
      "&hour=" +
      hour +
      "&minute=" +
      min +
      "&second=" +
      sec +
      "&lat=" +
      lat +
      "&long=" +
      long +
      "&offset=" +
      offset +
      "&city=" +
      city +
      "&state=" +
      state +
      "&country=" +
      country +
      "&reflong=" +
      reflong +
      "&timezone=" +
      timezone +
      "&token=" +
      token;
    // console.log(URL + params);

    return axios({
      url: URL + params,
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response);
          return response;
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    // this.setState({open: false});
  };

  fetchGalactic = (
    name,
    gender,
    day,
    month,
    year,
    hour,
    min,
    sec,
    lat,
    long,
    offset,
    city,
    state,
    country,
    reflong,
    timezone,
    token
  ) => {
    let URL =
      "https://apiv2.omparashar.com/imageservice/graphs/planetpositionsgraph";
    //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
    let params =
      "?nativename=" +
      name +
      "&gender=" +
      gender +
      "&year=" +
      year +
      "&month=" +
      month +
      "&day=" +
      day +
      "&hour=" +
      hour +
      "&minute=" +
      min +
      "&second=" +
      sec +
      "&lat=" +
      lat +
      "&long=" +
      long +
      "&offset=" +
      offset +
      "&city=" +
      city +
      "&state=" +
      state +
      "&country=" +
      country +
      "&reflong=" +
      reflong +
      "&timezone=" +
      timezone +
      "&token=" +
      token;
    // console.log(URL + params);

    return axios({
      url: URL + params,
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    // this.setState({open: false});
  };
  fetchPushkarNavamsha = (
    name,
    gender,
    day,
    month,
    year,
    hour,
    min,
    sec,
    lat,
    long,
    offset,
    city,
    state,
    country,
    reflong,
    timezone,
    token
  ) => {
    let URL =
      "https://apiv2.omparashar.com/imageservice/graphs/pushkarnavamsha";
    //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
    let params =
      "?nativename=" +
      name +
      "&gender=" +
      gender +
      "&year=" +
      year +
      "&month=" +
      month +
      "&day=" +
      day +
      "&hour=" +
      hour +
      "&minute=" +
      min +
      "&second=" +
      sec +
      "&lat=" +
      lat +
      "&long=" +
      long +
      "&offset=" +
      offset +
      "&city=" +
      city +
      "&state=" +
      state +
      "&country=" +
      country +
      "&reflong=" +
      reflong +
      "&timezone=" +
      timezone +
      "&token=" +
      token;
    // console.log(URL + params);

    return axios({
      url: URL + params,
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    // this.setState({open: false});
  };
  fetchHoroTransit = (
    day,
    month,
    year,
    hour,
    min,
    sec,
    lat,
    long,
    offset,
    city,
    state,
    country,
    reflong,
    timezone,
    option,
    token
  ) => {
    let URL = "https://apiv2.omparashar.com/core/transit/natalchart";
    //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
    let params =
      "?year=" +
      year +
      "&month=" +
      month +
      "&day=" +
      day +
      "&hour=" +
      hour +
      "&minute=" +
      min +
      "&second=" +
      sec +
      "&lat=" +
      lat +
      "&long=" +
      long +
      "&offset=" +
      offset +
      "&city=" +
      city +
      "&state=" +
      state +
      "&country=" +
      country +
      "&reflong=" +
      reflong +
      "&timezone=" +
      timezone +
      "&ascendantnum=" +
      option +
      "&token=" +
      token;
    // console.log(URL + params);
    return axios({
      url: URL + params,
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        } else {
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    // this.setState({open: false});
  };
  fetchAnalyzeHoroscopeAPI = async (
    name,
    gender,
    day,
    month,
    year,
    hour,
    min,
    sec,
    lat,
    long,
    offset,
    city,
    state,
    country,
    reflong,
    timezone,
    token
  ) => {
    let URL =
      "https://apiv2.omparashar.com/horoscope/analytics/analyzeHoroscope";
    //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
    let params =
      "?nativename=" +
      name +
      "&gender=" +
      gender +
      "&year=" +
      year +
      "&month=" +
      month +
      "&day=" +
      day +
      "&hour=" +
      hour +
      "&minute=" +
      min +
      "&second=" +
      sec +
      "&lat=" +
      lat +
      "&long=" +
      long +
      "&offset=" +
      offset +
      "&city=" +
      city +
      "&state=" +
      state +
      "&country=" +
      country +
      "&reflong=" +
      reflong +
      "&timezone=" +
      timezone +
      "&token=" +
      token;
    console.log(URL + params);

    return await axios({
      url: URL + params,
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          return response;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  callReportAPI = (
    name,
    gender,
    day,
    month,
    year,
    hour,
    min,
    sec,
    lat,
    long,
    offset,
    city,
    state,
    country,
    reflong,
    timezone,
    token,
    reportType
  ) => {
    let URL;
    if (reportType === "Short Report") {
      URL =
        "https://apiv2.omparashar.com/imageservice/reports/createshortreport";
    } else {
      URL = "https://apiv2.omparashar.com/imageservice/reports/createreport";
    }
    //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
    let params =
      "?nativename=" +
      name +
      "&gender=" +
      gender +
      "&year=" +
      year +
      "&month=" +
      month +
      "&day=" +
      day +
      "&hour=" +
      hour +
      "&minute=" +
      min +
      "&second=" +
      sec +
      "&lat=" +
      lat +
      "&long=" +
      long +
      "&offset=" +
      offset +
      "&city=" +
      city +
      "&state=" +
      state +
      "&country=" +
      country +
      "&reflong=" +
      reflong +
      "&timezone=" +
      timezone +
      "&token=" +
      token;
    // console.log(URL + params);
    return axios({
      url: URL + params,
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response);
          return response;
        } else {
          return "API Issue";
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    // this.setState({open: false});
  };

  callVedicYogaReportAPI = (language) => {
    let URL;

    URL = "https://apiv2.omparashar.com/imageservice/reports/yogaDBreport";

    let params = "?language=" + language;
    // console.log(URL + params);
    return axios({
      url: URL + params,
      method: "GET",
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response);
          return response;
        } else {
          return "API Issue";
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    // this.setState({open: false});
  };

  call_planetary_conjunction_API=async(
    start_year,
    end_year,
    planet_combunation,
    lat,
      long,
      offset,
      city,
      state,
      country,
      reflong,
      timezone
  )=>{
  let url_string = "https://apiv2.omparashar.com/core/transit/conjunctionreport/" + planet_combunation;
          //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";
          let params =
              "?start_year=" +
              start_year +
              "&end_year=" +
              end_year +
              "&lat=" +
              lat +
              "&long=" +
              long +
              "&offset=" +
              offset +
              "&city=" +
              city +
              "&state=" +
              state +
              "&country=" +
             country +
              "&reflong=" +
              reflong +
              "&timezone=" +
              timezone
          // +
          // "&token=" +
          // localStorage.getItem("omp_token");
          const url = url_string + params;
          // console.log(url);
  
          const result = await axios(url);
          if(result.status==200){
            return result.data
          }else{
            console.error(result);
          }
  };
}


