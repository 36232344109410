import React, { Component } from "react";
import "./NatalCharts.css";
import { Tabs, Tab } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";
import { GlobalContext } from "../../../mycontext";
import Sideform from "../../SideComponents/sideform";
import NatalChart from "./NatalChartsD1";
import NatalChartMoon from "./NatalChartMoon";
import ResetButton from "../ResetButton/ResetButton";
import Api from "../../../API/Api";
import { withTranslation } from "react-i18next";
import NatalChartKundali from "./NatalChartKundali";
import Login from "../../GoogleLogin/login";
import {getQueryParam} from '../../utils/utility';

const timeFunction = (time) => {
  let splitTimeAMPM = time.split(" ");
  // console.log(splitTimeAMPM);
  let splitTime = splitTimeAMPM[0].split(":");
  // console.log(splitTime);
  let h = splitTime[0];
  let m = splitTime[1];
  let s = splitTime[2];
  if (splitTimeAMPM[1] === "AM" && parseInt(h) === 12) {
    h = "00";
  } else if (splitTimeAMPM[1] === "PM" && parseInt(h) === 12) {
    h = "12";
  } else if (splitTimeAMPM[1] === "PM" && parseInt(h) !== 12) {
    h = (parseInt(h) + 12).toString();
  }
  return [h, m, s];
};
const styles = (theme) => ({
  divisionalChartDiv: {
    position: "relative",
    background: "#fff",
    minHeight: "600px",
  },
  natalChartdiv: {
    marginLeft: "auto",
    marginRight: "auto",
  },
});

class NatalCharts extends Component {
  static contextType = GlobalContext;
 
  constructor(props) {
    super(props);
    this.state = {
      value: "0",
      tab: "0",
      chalitGraph: "",
      sudarshanGraph: "",
      galacticGraph: "",
      horoscopeData:JSON.parse(window.localStorage.getItem("horoscopeData")) || null,
      requestParamsHoroscope:JSON.parse(window.localStorage.getItem("requestParamsHoroscope")) || null,

      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false

    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let googleAuthObj = JSON.parse(
      window.localStorage.getItem("googleAuthObj")
    );
    if (googleAuthObj !== null && this.context.googleAuthObj === null) {
      this.context.setGoogleAuthObj(googleAuthObj);
    }

    let queryString = getQueryParam('cb');
    if(queryString){
     // alert(queryString);
      this.setState({cb_view:true});
      this.setState({cb_horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
      this.setState({cb_requestParamsHoroscope:JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`))});
    }
    else{
      if (this.state.requestParamsHoroscope !== null && 
        Object.keys(this.context.requestParamsHoroscope).length === 0
      ) {
        this.context.setRequestParamsHoroscope(this.state.requestParamsHoroscope);
      }
      if (this.state.horoscopeData === null) {
        this.context.handleHoroscopeForm(true, "open");
      } else {
        this.context.setHoroscopeData(this.state.horoscopeData);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.horoscopeData !== prevProps.horoscopeData) {
      this.setState({ horoscopeData: this.props.horoscopeData });

      if (this.state.tab === "3") {
       // alert("called 101")

       (this.state.cb_view)? this.fetchSudarshan(this.state.cb_requestParamsHoroscope,this.state.cb_horoscopeData) :
        this.fetchSudarshan(this.context.requestParamsHoroscope,this.props.horoscopeData);

      } else if (this.state.tab === "4") {

        (this.state.cb_view)?this.fetchChalit(this.state.cb_requestParamsHoroscope,this.state.cb_horoscopeData) :
        this.fetchChalit(this.context.requestParamsHoroscope,this.props.horoscopeData);

      } else if (this.state.tab === "5") {

        (this.state.cb_view)? this.fetchGalactic(this.state.cb_requestParamsHoroscope,this.state.cb_horoscopeData) : 
        this.fetchGalactic(this.context.requestParamsHoroscope,this.props.horoscopeData);

      }
    }
  }

  fetchChalit(params, data) {
    let API = new Api();
    let splitDate = data.date_of_birth.split("-");
    let splitTime = timeFunction(data.time_of_birth);
    API.fetchChalit(
      data.native_name,
      data.basic_details.gender,
      splitDate[0],
      splitDate[1],
      splitDate[2],
      splitTime[0],
      splitTime[1],
      splitTime[2].slice(0, 2),
      params.latitude,
      params.longitude,
      params.offset,
      params.name,
      params.state,
      params.country,
      params.reference_longitude_for_timezone,
      params.timezone,
      localStorage.getItem("omp_token")
    )
      .then((result) => {
        // console.log(result.data);
        if (result.data.message === "Token expired") {
          this.context.handleShow(`/horoscope/natalcharts`);
          this.fetchChalit(
            this.state.requestParamsHoroscope,
            this.state.horoscopeData
          );
        } else {
          var chalit_img = result.data.data;
          chalit_img.replace(/[\r\n]+/gm, "");
          // console.log(chalit_img);
          this.setState({ chalitGraph: chalit_img });
        }
      })
      .catch((e) => {
        // console.log(e);
      });
  }

  fetchSudarshan(params, data) {
    let API = new Api();

    let splitDate = data.date_of_birth.split("-");
    let splitTime = timeFunction(data.time_of_birth);
    // console.log(splitTime);
    API.fetchSudarshan(
      data.native_name,
      data.basic_details.gender,
      splitDate[0],
      splitDate[1],
      splitDate[2],
      splitTime[0],
      splitTime[1],
      splitTime[2],
      params.latitude,
      params.longitude,
      params.offset,
      params.name,
      params.state,
      params.country,
      params.reference_longitude_for_timezone,
      params.timezone,
      localStorage.getItem("omp_token")
    )
      .then((result) => {
        // console.log(result.data);
        if (result.data.message === "Token expired") {
          this.context.handleShow(`/horoscope/natalcharts`);
          (this.state.cb_view) ?  this.fetchSudarshan(this.state.cb_requestParamsHoroscope,this.state.cb_horoscopeData) :
          this.fetchSudarshan(this.state.requestParamsHoroscope,this.state.horoscopeData);
        } else {
          var sudharshan_img = result.data.data;
          sudharshan_img.replace(/[\r\n]+/gm, "");
          // console.log(sudharshan_img);
          this.setState({ sudarshanGraph: sudharshan_img });
        }
      })
      .catch((e) => {
        // console.log(e);
      });
  }

  fetchGalactic(params, data) {
    let API = new Api();

    let splitDate = data.date_of_birth.split("-");
    let splitTime = timeFunction(data.time_of_birth);
    API.fetchGalactic(
      data.native_name,
      data.basic_details.gender,
      splitDate[0],
      splitDate[1],
      splitDate[2],
      splitTime[0],
      splitTime[1],
      splitTime[2].slice(0, 2),
      params.latitude,
      params.longitude,
      params.offset,
      params.name,
      params.state,
      params.country,
      params.reference_longitude_for_timezone,
      params.timezone,
      localStorage.getItem("omp_token")
    )
      .then((result) => {
        if (result.data.message === "Token expired") {
          this.context.handleShow(`/horoscope/natalcharts`);
          this.fetchGalactic(
            this.state.requestParamsHoroscope,
            this.state.horoscopeData
          );
        } else {
          var galactic_img = result.data.data;
          galactic_img.replace(/[\r\n]+/gm, "");
          //console.log(galactic_img);
          this.setState({ chalitGraph: "" });
          this.setState({ sudarshanGraph: "" });

          this.setState({ galacticGraph: galactic_img });
        }
      })
      .catch((e) => {
        // console.log(e);
      });
  }

  get_svg = (svg_tags) => {
    return <span dangerouslySetInnerHTML={{ __html: svg_tags }} />;
  };

  handleChange(k) {
    if (k === "3") {
      (this.state.cb_view)?  this.fetchSudarshan(this.state.cb_requestParamsHoroscope,this.state.cb_horoscopeData) : 
      this.fetchSudarshan(this.state.requestParamsHoroscope,this.state.horoscopeData);
    } else if (k === "4") {

      this.setState({ sudarshanGraph: "", galacticGraph: "" });

      (this.state.cb_view)? this.fetchChalit(this.state.cb_requestParamsHoroscope,this.state.cb_horoscopeData) : 
      this.fetchChalit(this.state.requestParamsHoroscope,this.state.horoscopeData);

    } else if (k === "5") {
      this.setState({ sudarshanGraph: "", chalitGraph: "" });

      (this.state.cb_view)?  this.fetchGalactic(this.state.cb_requestParamsHoroscope,this.state.cb_horoscopeData) : 
      this.fetchGalactic(this.state.requestParamsHoroscope,this.state.horoscopeData);
    }
    this.setState({ tab: k });
  }
  render() {
    const { classes } = this.props;
    const { t, i18n } = this.props;
    if ( this.state.cb_view || 
      (Object.keys(this.context.horoscopeData).length > 0 &&
      typeof this.context.horoscopeData !== "undefined")
    ) {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"natalcharts"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
          <div className="natalChart-wrapper">
            <div className="card">
              <div className="card-body" style={{ padding: "0px 0" }}>
                <ResetButton />
                <div className="panel with-nav-tabs panel-warning">
                  <div className="panel-heading">
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={this.state.tab}
                      onSelect={(k) => this.handleChange(k)}
                    >
                      <Tab
                        className={classes.tab}
                        eventKey={0}
                        title={t("With Moon")}
                      >
                        <div className="row" style={{ marginTop: "25px" }}>
                          {this.state.horoscopeData !== null ? (
                            <>
                              <NatalChart
                                t={t}
                                Chartname={t("Natal Chart")}
                                data={(this.state.cb_view)? this.state.cb_horoscopeData.divisional_charts[0].list_of_houses :
                                  this.state.horoscopeData.divisional_charts[0].list_of_houses
                                }
                                className="svg1"
                                id="container1"
                                hoverData={ (this.state.cb_view) ? this.state.cb_horoscopeData.natal_chart.all_planets : 
                                  this.state.horoscopeData.natal_chart.all_planets
                                }
                                name="natalchart"
                                Asc={t("Asc")}
                              />
                              <NatalChartMoon
                                t={t}
                                Chartname={t("Moon Chart")}
                                data={ (this.state.cb_view) ?  this.state.cb_horoscopeData.moon_chart.list_of_houses : 
                                  this.state.horoscopeData.moon_chart.list_of_houses
                                }
                                className="svg2"
                                id="container2"
                                w="500"
                                h="630"
                                xview="300"
                                yview="150"
                                Asc={t("Asc")}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Tab>
                      <Tab
                        className={classes.tab}
                        eventKey={1}
                        title={t("Swansh Kundali")}
                      >
                        <div className="row" style={{ marginTop: "25px" }}>
                          {this.state.horoscopeData !== null ? (
                            <>
                              <NatalChart
                                t={t}
                                Chartname={t("Natal Chart")}
                                data={ (this.state.cb_view) ?  this.state.cb_horoscopeData.divisional_charts[0].list_of_houses :
                                  this.state.horoscopeData.divisional_charts[0].list_of_houses
                                }
                                className="svg3"
                                id="container3"
                                hoverData={ (this.state.cb_view) ?  this.state.cb_horoscopeData.natal_chart.all_planets :
                                  this.state.horoscopeData.natal_chart.all_planets
                                }
                                name="natalchart"
                                Asc={t("Asc")}
                              />
                              <NatalChartKundali
                                t={t}
                                Chartname={t("Swansh Kundali")}
                                data={ (this.state.cb_view) ? this.state.cb_horoscopeData.swansh_kundali.list_of_houses :
                                  this.state.horoscopeData.swansh_kundali.list_of_houses
                                }
                                className="svg4"
                                id="container4"
                                Asc={t("Asc")}
                                w="500"
                                h="630"
                                xview="300"
                                yview="150"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Tab>
                      <Tab
                        className={classes.tab}
                        eventKey={2}
                        title={t("Karkansh Kundali")}
                      >
                        <div className="row" style={{ marginTop: "25px" }}>
                          {this.state.horoscopeData !== null ? (
                            <>
                              <NatalChart
                                t={t}
                                Chartname={t("Natal Chart")}
                                data={ (this.state.cb_view) ?  this.state.cb_horoscopeData.divisional_charts[0].list_of_houses :
                                  this.state.horoscopeData.divisional_charts[0].list_of_houses
                                }
                                className="svg5"
                                id="container5"
                                hoverData={ (this.state.cb_view) ?  this.state.cb_horoscopeData.natal_chart.all_planets :
                                  this.state.horoscopeData.natal_chart.all_planets
                                }
                                name="natalchart"
                                Asc={t("Asc")}
                              />
                              <NatalChartKundali
                                t={t}
                                Chartname={t("Karkansh Kundali")}
                                data={ (this.state.cb_view) ? this.state.cb_horoscopeData.karkansh_kundali.list_of_houses :
                                  this.state.horoscopeData.karkansh_kundali.list_of_houses
                                }
                                className="svg6"
                                id="container6"
                                Asc={t("Asc")}
                                w="500"
                                h="630"
                                xview="300"
                                yview="150"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Tab>
                      <Tab
                        className={classes.tab}
                        eventKey={3}
                        title={t("Sudarshan")}
                      >
                        <center className="row">
                          <h2 className="title text-right col-md-7 col-12 pt-2 pl-5">
                            {t("Sudarshan Chakra")}
                          </h2>
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-6 col-md-12">
                                <div id="sudharshan_div" class="svg_img">
                                  {this.get_svg(this.state.sudarshanGraph)}
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-12">
                                <div className="zoom_container">
                                  <div
                                    id="sudharshan_rotate"
                                    className="rotate"
                                  >
                                    {this.get_svg(this.state.sudarshanGraph)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </center>
                      </Tab>
                      <Tab
                        className={classes.tab}
                        eventKey={4}
                        title={t("Chalit")}
                      >
                        <center className="row">
                          <h2 className="title text-right col-md-7 col-12 pt-2 pl-5">
                            {t("Chalit Chakra")}
                          </h2>
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-6 col-md-12">
                                <div id="chalit_div" class="svg_img">
                                  {this.get_svg(this.state.chalitGraph)}
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-12">
                                <div className="zoom_container">
                                  <div id="chalit_rotate" className="rotate">
                                    {this.get_svg(this.state.chalitGraph)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </center>
                      </Tab>
                      <Tab
                        className={classes.tab}
                        eventKey={5}
                        title={t("Galactic View")}
                      >
                        <center className="row">
                          <h2 className="title text-right col-md-7 col-12 pt-2 pl-5">
                            {t("Galactic View")}
                          </h2>
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-6 col-md-12">
                                <div id="galatic_div" class="svg_img">
                                  {this.get_svg(this.state.galacticGraph)}
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-12">
                                <div className="zoom_container">
                                  <div id="galactic_rotate" className="rotate">
                                    {this.get_svg(this.state.galacticGraph)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </center>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Login
            page={this.context.currentPage}
            show={this.context.loginModalShow}
            handleClose={this.context.closeLoginModal}
          /> */}
        </>
      );
    } else {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"natalcharts"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
        </>
      );
    }
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(NatalCharts)
);
