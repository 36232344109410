import React, { useEffect, useState } from "react";
import "./CelebrityManagement.css";
import moment from "moment";
import loadjs from "loadjs";
import Select from "react-select/async";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router";
import DashboardTopBar from "../DashBoardComponents/DashboardTopBar";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { getAtlasDetails, getGender, verifyData, getRatings, getTags, createData, updateData, deleteHoroscope } from "../../API/CelebrityManagement_API";
import CelebrityManagementSidebar from "../DashBoardComponents/DashboardSideBarTypes/CelebrityManagementSidebar";
import axios from "axios";

import {IMaskInput} from "react-imask";
import {value} from "lodash/seq";
import {validateDate} from "./validators";
import TimeInput from "./Autosuggests/TimeInput";
import AutocompleteCityAndState from "./Autosuggests/AutocompleteCityAndState";
import EventAutouggest from "./Autosuggests/EventAutoSuggest";
import TagAutosuggest from "./Autosuggests/TagAutoSuggest";
import AutoCompleteCountry from "./Autosuggests/AutoCompleteCountry";
import {getAllData} from "../../API/CelebrityManagement_API";
import CloseButton from 'react-bootstrap/CloseButton'
import {Toast} from "react-bootstrap";

const CreateHoroscope = (props) => {

  let history = useHistory();
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState(false);
  const [source, setSource] = useState(null);
  // const [userName, setUserName] = useState(null);
  const [firstName, setFirstname] = useState(null);
  const [lastName, setLastname] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [second, setSecond] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [reflong, setReflong] = useState(null);
  const [offset, setOffset] = useState(null);
  const [timezone, setTimezone] = useState(null);
  //alternate data fields
  const [alternateDataExists, setAlternateDataExists] = useState(false);
  const [country1, setCountry1] = useState(null);
  const [state1, setState1] = useState(null);
  const [city1, setCity1] = useState(null);
  // const [cityOfBirth1, setCityOfBirth1] = useState(null);
  // const [dateOfBirth1, setDateOfBirth1] = useState(null);
  // const [timeOfBirth1, setTimeOfBirth1] = useState(null);
  const [day1, setDay1] = useState(null);
  const [month1, setMonth1] = useState(null);
  const [year1, setYear1] = useState(null);
  const [hour1, setHour1] = useState(null);
  const [minute1, setMinute1] = useState(null);
  const [second1, setSecond1] = useState(null);
  const [latitude1, setLatitude1] = useState(null);
  const [longitude1, setLongitude1] = useState(null);
  const [reflong1, setReflong1] = useState(null);
  const [offset1, setOffset1] = useState(null);
  const [timezone1, setTimezone1] = useState(null);
  //other details
  const [options, setOptions] = useState([]);
  const [selectedGender, setSelectedGender] = useState(null);
  const [ratings, setRatings] = useState([]);
  const [selectedRating, setSelectedRating] = useState(null);
  const [biography, setBiography] = useState('');
  const [tags, setTags] = useState(null);
  const [events, setEvents] = useState([]);
  const [wikipediaLink, setWikipediaLink] = useState(null);
  const [tagReferences, setTagReferences] = useState(null);
  const [tagId, settagId] = useState(null);
  const [tagName, settagName] = useState('');
  const [taginputvalue, settaginputvalue] = useState('');
  const [tagselectedvalue, settagselectedvalue] = useState('');
  const [tagOptions, setTagOptions] = useState([]);
  // const [selectedtagOptions, setSelectedtagOptions] = useState();

  const [photoName, setPhotoName] = useState(null);
  const [photofile, setPhotofile] = useState(null);
  const [oid, setOid] = useState(null);
  const [collectionName, setCollectionName] = useState('');
  const [completionFlag, setCompletionFlag] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState();

  const [formValues, setFormValues] = useState([{ event_name: '', event_date: '', event_description: '' }])
  // const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  // const [startDate, setStartDate] = useState(new Date());
  // Autocomplete state
  const [countries, setCountries] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  // const [countrySuggestions, setCountrySuggestions] = useState([]);
  // const [text, setText] = useState([]);

  // const [cities, setCities] = useState([]);
  // const [citysuggestions, setCitySuggestions] = useState([]);
  // const [citysuggestions1, setCitySuggestions1] = useState([]);
  // const [cityID, setCityID] = useState([]);
  // const [cityID1, setCityID1] = useState([]);

  const [verification_status, setVerificationStatus] = useState(props.location.state.completionFlag==="True");
  const [cityState,setCityState] = useState(null);
  const [cityState1,setCityState1] = useState(null);
  const [category,setCategory] = useState(localStorage.getItem("category"));// === "1"?"1":"2")
  // const [isDataCreatedOrUpdated,setIsDataCreatedOrUpdated] = useState(false)

  // Function triggered on selection
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  useEffect(() => {
    loadjs("assets/js/vendor.min.js", () => { });
    //get gender

    (async () => {
      const res = await getGender();
      setOptions(res);
    })();
    //get ratings
    (async () => {
      const res = await getRatings();
      setRatings(res);
    })();
    //
    // const loadCountry = async () => {
    //   const itemponse = await axios.get('https://apiv2.omparashar.com/core/atlas/fetchcountry?name');
    //   setCountries(itemponse.data);
    // }
    // loadCountry();
    setOid(props.location.state.oid);
    setCollectionName(props.location.state.collectionName);
    setCompletionFlag(props.location.state.completionFlag);
    if(props.location.state.emptyData === undefined){
    handleHorscopeDetail(props.location.state.res);
    }
    // console.log(collectionName,typeof collectionName,'ccc',category,typeof category,props.location.state.collectionName);
    if (props.location.state.collectionName === "1" || props.location.state.collectionName === "2") {
      setActive(true);
    }
    else {
      setActive(false);
    }// handleHorscopeDetail();
  }, [oid]);

  // useEffect(()=>{
  //   //make a api call o get new data and set the details by calling handleHoroscopeDetails
  //    },[isDataCreatedOrUpdated]);

  const onChangePicture = e => {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      setPhotofile(files[i]);
      setPhotoName(files[i].name);
    }
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleHorscopeDetail = (item) => {

    setCollectionName(localStorage.getItem(collectionName));
    setCompletionFlag(localStorage.getItem(completionFlag));
    let dob = item["primary_birth_details"].day + "-" + item["primary_birth_details"].month + "-" + item["primary_birth_details"].year;
    let time = item["primary_birth_details"].hour + ":" + item["primary_birth_details"].minute + ":" + item["primary_birth_details"].second;

    setSource(item.source_url);
    setFirstname(item.first_name);
    setLastname(item.last_name);
    setCountry(item["primary_birth_details"].country);
    setCity(item["primary_birth_details"].city);
    // setDateOfBirth(dob);
    // setTimeOfBirth(time);
    setFirstname(item.first_name);
    setLastname(item.last_name);
    // setGender(item.gender);
    setSelectedGender(item.gender);
    // setPhotoName(item.photo_name);
    setYear(item["primary_birth_details"].year);
    setMonth(item["primary_birth_details"].month);
    setDay(item["primary_birth_details"].day);
    setHour(item["primary_birth_details"].hour);
    setMinute(item["primary_birth_details"].minute);
    setSecond(item["primary_birth_details"].second);
    setLatitude(item["primary_birth_details"].lat);
    setLongitude(item["primary_birth_details"].long);
    setOffset(item["primary_birth_details"].offset);
    setCity(item["primary_birth_details"].city);
    setState(item["primary_birth_details"].state);
    setCountry(item["primary_birth_details"].country);
    setReflong(item["primary_birth_details"].reflong);
    setTimezone(item["primary_birth_details"].timezone);
    setCityState(item["primary_birth_details"].city+" ,"+item["primary_birth_details"].state)
    //alternate birth data

    setAlternateDataExists(item["alternate_birth_details"] !== null);
    if (item["alternate_birth_details"] != null) {
      let dob1 = item["alternate_birth_details"].day + "-" + item["alternate_birth_details"].month + "-" + item["alternate_birth_details"].year;
      let time1 = item["alternate_birth_details"].hour + ":" + item["alternate_birth_details"].minute + ":" + item["alternate_birth_details"].second;

      setCountry1(item["alternate_birth_details"].country);
      // setCityOfBirth1(item["alternate_birth_details"].city);
      // setDateOfBirth1(dob);
      // setTimeOfBirth1(time);
      setYear1(item["alternate_birth_details"].year);
      setMonth1(item["alternate_birth_details"].month);
      setDay1(item["alternate_birth_details"].day);
      setHour1(item["alternate_birth_details"].hour);
      setMinute1(item["alternate_birth_details"].minute);
      setSecond1(item["alternate_birth_details"].second);
      setLatitude1(item["alternate_birth_details"].lat);
      setLongitude1(item["alternate_birth_details"].long);
      setOffset1(item["alternate_birth_details"].offset);
      setCity1(item["alternate_birth_details"].city);
      setState1(item["alternate_birth_details"].state);
      setCountry1(item["alternate_birth_details"].country);
      setReflong1(item["alternate_birth_details"].reflong);
      setTimezone1(item["alternate_birth_details"].timezone);
      setCityState1(item["alternate_birth_details"].city+" ,"+item["alternate_birth_details"].state)
    }
    //other details          
    setSelectedRating(item.rating);
    if (item["completion_flag"] !== "false") {
      if (item["events"] !== null) {
        setFormValues(item["events"]);
      }
      const arr = [];
      if (item["tags"] !== null && item["completion_name"] !== 0) {
        (item["tags"].map((item, i) => {
          arr.push({ ["id"]: item, ["tag_name"]: item });
        }));
      }
      // setTagOptions(JSON.stringify(arr),);
      setTagOptions(JSON.stringify([]),);

      settagselectedvalue(arr)
      //////////////////////////////////////
      // console.log("array3", arr);
      // setTags(arr.map((item, i) => {
      //   return (
      //     <label className="drp-label">{item["tag_name"]}</label>
      //   )
      //
      // }));
      // setTags(arr);
    }

    setBiography(item.biography);
    setWikipediaLink(item.wikipedia_link);
    setPhotoName(item.photo_name?(item.photo_name.trim() === ""?null:item.photo_name):null);
    // if (photoName === " "){
    //   console.log(photoName,'phhh');
    //   setPhotoName(null);
    // }
    setTagReferences(item.tag_references);
    // })();


  };

  const handleGenderChange = (value) => {
    setSelectedGender(value);
  }

  const handleRatingChange = (value) => {
    setSelectedRating(value);
  }
  const GetTags = () => {
    console.log('in get tag');
    return getTags(tagName).then((item) => {
      console.log(item);
      return item.data;
    });
  }

  const handleTagChange = (e) => {
    let arr = [];
    // console.log(e,'in handle tag change');
    if(e === null){
      settagId(null);
      settagselectedvalue([]);
    }
    else {
      (e.map((item, i) => {
        arr.push({tag_name: item["tag_name"], id: item["id"]});
        return (
            <label className="drp-label">{item["tag_name"]}</label>
        )
      }));
      // let arr2 = JSON.parse(tagOptions);
      // console.log(e, 'e in tag')
      settagId(e.id);
      settagselectedvalue(e);
      // settagselectedvalue(arr);
    }
  }

  const deleteTagHandler = (i)=>{
    let arr = [...tagselectedvalue];
    arr.splice(i,1);
    settagselectedvalue(arr);
  }

  const addtag = ()=>{
    let arr = [];
    let newarr = [...tagselectedvalue];
    // console.log(newarr,'newarr',tags);
        newarr.push({id:tags,tag_name:tags});
    // console.log(newarr,'newarr affter push');
    //   (newarr.map((item, i) => {
    //     arr.push({tag_name: item["tag_name"], id: item["id"]});
    //     return (
    //         <label className="drp-label">{item["tag_name"]}</label>
    //     )
    //   }));
      // console.log(newarr, 'newarr in tag');
      // settagId(newarr.id);
      settagselectedvalue(newarr);
      setTags("");
  }
  const VerifyData = () => {
    console.log('in verifu');
    if(verification_status){
      setVerificationStatus(false);
      return ;
    }

    if(!firstName|| !lastName){
      alert("please enter valid first and last names");
      return;
    }
    else if(firstName.trim() === "" || lastName.trim() === ""){
      alert("please enter valid first and last names");
      return;
    }
    else{
      setFirstname(firstName.trim());
      setLastname(lastName.trim());
    }

    validateDate(day + '.' + month + '.' + year)

    let primary_birth_data = {
      year,
      month,
      day,
      hour,
      minute,
      second,
      lat: latitude,
      long: longitude,
      offset,
      city,
      state,
      country,
      reflong,
      timezone,
    }
    // console.log(alternateDataExists);
    if(alternateDataExists){
      validateDate(day1+'.'+month1+'.'+year1);
      // console.log(alternateDataExists,'hey');
      let alternate_birth_data = {
        year: year1,
        month: month1,
        day: day1,
        hour: hour1,
        minute: minute1,
        second: second1,
        lat: latitude1,
        long: longitude1,
        offset: offset1,
        city: city1,
        state: state1,
        country: country1,
        reflong: reflong1,
        timezone: timezone1,
      }
      const item = verifyData(firstName, lastName, selectedGender, primary_birth_data,alternate_birth_data).then((item) => {
        if (!item) {
          // alert("Please select correct country and city");
          alert("Data couldn't be verified. Please check entered details!");
        }
        else {
          alert("Verified data successfully");
          setVerificationStatus(true);
        }
      });
    }
    else{
    const item = verifyData(firstName, lastName, selectedGender, primary_birth_data,null).then((item) => {
      if (!item) {
        // alert("Please select correct country and city");
        alert("Data couldn't be verified. Please check entered details!");
      }
      else {
        alert("Verified data successfully");
        setVerificationStatus(true);
      }
    });
    }
  }

  const CreateData = () => {
    if(verification_status === false){
      alert("Please Verify Birth Details first!");
      return ;
    }
    let primary_birth_data = {
      year,
      month,
      day,
      hour,
      minute,
      second,
      lat: latitude,
      long: longitude,
      offset,
      city,
      state,
      country,
      reflong,
      timezone,
    }
    let alternate_birth_data = {
      year: year1,
      month: month1,
      day: day1,
      hour: hour1,
      minute: minute1,
      second: second1,
      lat: latitude1,
      long: longitude1,
      offset: offset1,
      city: city1,
      state: state1,
      country: country1,
      reflong: reflong1,
      timezone: timezone1,
    }
    let newTags = extractTags();
    let data = {
      workspace_name: "Admin",
      record_url: source,
      rating: selectedRating,
      first_name: firstName,
      last_name: lastName,
      gender: selectedGender,
      primary_birth_data: primary_birth_data,
      alternate_birth_data: alternateDataExists === false ? null : alternate_birth_data,
      // life_events: events.length > 0 ? events : formValues,
      life_events: formValues,
      biography: biography,
      tag_references: tagReferences,
      tags: newTags,
      // tags: JSON.parse(tagOptions),
      wikipedia_link: wikipediaLink,
      // category: localStorage.getItem("category"),
      category: category,
    }

    createData(data, photofile, photoName).then((item) => {
      if (item[0] === true) {
        alert("Data created successfully");
        const _ = async ()=>{
          let d = await getAllData(item[1]["Primary_horoscope_id"],category);
          // handleHorscopeDetail(d);
          history.replace({
            pathname: "/celebrityManagement/createHoroscope",
            state: { res: d, oid: item[1]["Primary_horoscope_id"], collectionName: category, completionFlag : "True" }
          })
          localStorage.setItem("category",category);
          window.location.reload();
        }
        _()
      }
      else {
        // alert("Error");
        alert(item);
      }
    });

  }
  const extractTags = () => {
    // let newTags = ([...JSON.parse(tagOptions), ...tagselectedvalue]);
    let newTags = ([...tagselectedvalue]);
    let objArray = [];
    for (var i = 0; i < newTags.length; i++) {
      objArray.push(newTags[i]["tag_name"]);
    }
    return objArray;
  }
  const UpdateData = () => {
    if(verification_status === false){
      alert("Please Verify Birth Details first!");
      return ;
    }

    let primary_birth_data = {
      year,
      month,
      day,
      hour,
      minute,
      second,
      lat: latitude,
      long: longitude,
      offset,
      city,
      state,
      country,
      reflong,
      timezone,
    }
    let alternate_birth_data = {
      year: year1,
      month: month1,
      day: day1,
      hour: hour1,
      minute: minute1,
      second: second1,
      lat: latitude1,
      long: longitude1,
      offset: offset1,
      city: city1,
      state: state1,
      country: country1,
      reflong: reflong1,
      timezone: timezone1,
    }
    let newTags = extractTags();
    let alldata = {
      oid: oid,
      record_url: source,
      rating: selectedRating,
      first_name: firstName,
      last_name: lastName,
      gender: selectedGender,
      primary_birth_data: primary_birth_data,
      alternate_birth_data: alternateDataExists === false ? null : alternate_birth_data,
      life_events: events.length > 0 ? events : formValues,
      biography: biography,
      tag_references: tagReferences,
      tags: newTags,
      // tags: JSON.parse(tagOptions),
      wikipedia_link: wikipediaLink,
      // category: localStorage.getItem("category")
      category: category,

    }
    // let photoNames = "Romy_Haag_1_1_1948_5_45_0.jpg";
    updateData(alldata, photofile, photoName).then((item) => {
      if (item[0] === true) {
        var o = "";
        console.log(item[1],'detail');
        if(item[1]["primary_record_oid"]){
          o = item[1]["primary_record_oid"];
        }
        else{
          o = item[1]
        }
        alert("Data updated successfully");
        const _ = async ()=>{
          let d = await getAllData(o,category);
          // handleHorscopeDetail(d);
          history.replace({
            pathname: "/celebrityManagement/createHoroscope",
            state: { res: d, oid: o, collectionName: category, completionFlag : "True" }
          })
          window.location.reload();
        }
        _()
      }
      else {
        // alert("Error");
        alert(item);
      }
    });
  }
  const DeleteHoroscope = () => {
    const item = deleteHoroscope(oid, category).then((item) => {
      if (item[0] === true) {
        alert("Entry deleted successfully");
        history.goBack();
      }
      else {
        alert(item);
      }
    });
  }

  let handleEventChange = (i, e) => {
    let newFormValues = [...formValues];
    // let edate = newFormValues[i].event_date;
      newFormValues[i][e.target.name] = e.target.value;
    // console.log(e.target.name,'name')
    // newFormValues[i]["event_name"] = e;
    setFormValues(newFormValues);
  }

  let handleEventDateChange = (i,e)=>{
    let newFormValues = [...formValues];
      var v = e.target.value.split('-');
      var new_dt = v[2]+"-"+v[1]+'-'+v[0];
      newFormValues[i]["event_date"] = new_dt;
    setFormValues(newFormValues);
  }
  let handleEventNameChange = (i, name) => {
    let newFormValues = [...formValues];
    console.log('in event name',newFormValues)
    // let edate = newFormValues[i].event_date;
      newFormValues[i]["event_name"] = name;
    setFormValues(newFormValues);
  }

  let EventdeletionHandler = (i) =>{
    var eventName = formValues[i]["event_name"];
    var c = window.confirm("Do You want to delete Event- "+eventName);
    if(c){
      let newFormValues = [...formValues];
      newFormValues.splice(i,1);
      setFormValues(newFormValues);
      alert("Event Deleted Successfully");
    }
      else{
        // do nothing
    }

  }
  let addFormFields = () => {
    setFormValues([...formValues, { event_name: "", event_date: "", event_description: "" }])
  }

  let handleEventSubmit = (e) => {
    e.preventDefault();
    // const arr3 = [...events, ...formValues];
    const arr3 = [...formValues];
    // console.log('arr3 in event submit',arr3,events,formValues)

    // let itemult = moment("29-10-2022", 'DD-MM-YYYY', true).isValid();

    // setEvents(arr3);
    var j;
    for(j=0;j<arr3.length;j++){
      let _name = arr3[j].event_name;
      let _date = arr3[j].event_date;
      let _description = arr3[j].event_description;
      if(!_name || !_date || !_description){
        alert("Please save events with their name, date and description");
        return;
      }
    }
    setFormValues(arr3);
    // var i;
    // for (i = 0; i < arr3.length; i++) {
    //   console.log(arr3[i].event_date,'date');
    //   arr3[i].event_date = changedateformat(arr3[i].event_date);
    //   console.log(arr3[i].event_date,'date2');
    // }

    console.log("After change", arr3);
    alert("Events Added successfully");
  }

  const handleCelebrityManagementView = (selectedName) => {
    console.log("handleCelebrityManagementView....", selectedName);
    props.history.push(`/CelebrityManagement/${selectedName}`);
  };
  //Atlas Country Autosuggestion
  // const onChangeHandler = (text) => {
  //   let matches = []
  //   if (text.length > 0) {
  //     console.log(text);
  //     // text = text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  //     matches = countries.filter(data => {
  //       // console.log(text,'text');
  //       // const regex = new RegExp(`${text}`, "gi");
  //       const regex = new RegExp(`${text}`, "gi");
  //       return data.match(regex);
  //     })
  //   }
  //   setSuggestions(matches);
  //   setCountry(text);
  // }

  // const onSuggestHandler = (text) => {
  //   setCountry(text);
  //   setSuggestions([]);
  //   countries.map((item, i) => {
  //     if (text === item) {
  //       setCountry(item);
  //     }
  //   });
  // }
  // const onCountryChangeHandler = (text) => {
  //   let matches = []
  //   if (text.length > 0) {
  //     matches = countries.filter(data => {
  //       const regex = new RegExp(`${text}`, "gi");
  //       return data.match(regex);
  //     })
  //   }
  //   setCountrySuggestions(matches);
  //   setCountry1(text);
  // }
  // const onCountrySuggestHandler = (text) => {
  //   setCountry1(text);
  //   setCountrySuggestions([]);
  //   countries.map((item, i) => {
  //     if (text === item) {
  //       setCountry1(item);
  //     }
  //   });
  // }
  //Atlas City Autosuggestion
  // const onCityChangeHandler = (text) => {
  //   const loadCities = async () => {
  //     const response = await axios.get('https://apiv2.omparashar.com/core/atlas/fetchcity?name=' + text + "&countryname=" + country);
  //
  //     setCities(response.data.Patterns_matched);
  //     let reqData = response.data.Patterns_matched;
  //     let cityMap = {};
  //     for (let i in response.data.Patterns_matched) {
  //       cityMap[reqData[i][0]] = reqData[i][1];
  //     }
  //     cityMap = response.data.Patterns_matched;
  //
  //     setCitySuggestions(cityMap);
  //     setCityOfBirth(text);
  //   }
  //   loadCities();
  // }
  //
  // const onCitySuggestHandler = (text) => {
  //   console.log("text suggest", text);
  //   setCityOfBirth(text);
  //   setCitySuggestions([]);
  //   cities.map((item, i) => {
  //     if (text === item[0]) {
  //       setCityOfBirth(item[0]);
  //       setCityID(item[1]);
  //       if (item[1] != null) {
  //         try {
  //           getAtlasDetails(item[1]
  //           ).then((res) => {
  //             setState(res.state);
  //             setReflong(res.reference_longitude_for_timezone);
  //             setLatitude(res.latitude);
  //             setLongitude(res.longitude);
  //             setOffset(res.offset);
  //             setTimezone(res.timezone);
  //           });
  //         } catch (err) {
  //           console.log("Error");
  //         }
  //       }
  //     }
  //   });
  // }

  // alternate city autosearch
  // const onCityChangeHandler1 = (text) => {
  //   setCityOfBirth1("");
  //   const loadCities = async () => {
  //     const response = await axios.get('https://apiv2.omparashar.com/core/atlas/fetchcity?name=' + text + "&countryname=" + country1);
  //     setCities(response.data.Patterns_matched);
  //     let reqData = response.data.Patterns_matched;
  //     let cityMap = {};
  //     for (let i in response.data.Patterns_matched) {
  //       cityMap[reqData[i][0]] = reqData[i][1];
  //     }
  //     cityMap = response.data.Patterns_matched;
  //     setCitySuggestions1(cityMap);
  //     setCityOfBirth1(text);
  //   }
  //   loadCities();
  // }

  // const onCitySuggestHandler1 = (text) => {
  //   setCityOfBirth1(text);
  //   // setCitySuggestions1([]);
  //   cities.map((item, i) => {
  //     if (text === item[0]) {
  //       setCityOfBirth1(item[0]);
  //       setCityID1(item[1]);
  //       if (item[1] != null) {
  //         try {
  //           getAtlasDetails(item[1]
  //           ).then((res) => {
  //             setState1(res.state);
  //             setReflong1(res.reference_longitude_for_timezone);
  //             setLatitude1(res.latitude);
  //             setLongitude1(res.longitude);
  //             setOffset1(res.offset);
  //             setTimezone1(res.timezone);
  //           });
  //         } catch (err) {
  //           console.log("Error");
  //         }
  //       }
  //     }
  //   });
  // }

  const handleDateChange=(day,month,year)=>{
    // Handler for date fields in birth details form
      let d =String(day)
      if(d.length !== 2){
        d = '0'+d;
      }
      let m = String(month)
      if(m.length !== 2){
        m = '0'+m;
      }
      return d+'.'+m+'.'+String(year);
  }
  const setPrimaryLocationAttributes = (data)=>{
    // country,state,city,lat,long,reflong,offset,timezone
    // console.log(data,data.name,data.state,'home')
    if(data == "reset"){
      // If country is changed set all location attributes to null except country
      setCity(null);
      setState(null);
      setLatitude(null);
      setLongitude(null);
      setReflong(null);
      setOffset(null);
      setTimezone(null);
      setCityState(null);
    }
    else {
      setCountry(data.country);
      setCity(data.name);
      setState(data.state);
      setLatitude(data.latitude);
      setLongitude(data.longitude);
      setReflong(data.reference_longitude_for_timezone);
      setOffset(data.offset);
      setTimezone(data.timezone);
      setCityState(data.name + " ," + data.state);
    }

  }
  const setAlternateLocationAttributes = (data)=>{
    // country,state,city,lat,long,reflong,offset,timezone
    // console.log(data,data.name,data.state,'home')
    if(data == "reset"){
      // If country is changed set all location attributes to null except country
      // setCountry(data.country);
      setCity1(null);
      setState1(null);
      setLatitude1(null);
      setLongitude1(null);
      setReflong1(null);
      setOffset1(null);
      setTimezone1(null);
      setCityState1(null);
    }
    else {
      setCountry1(data.country);
      setCity1(data.name);
      setState1(data.state);
      setLatitude1(data.latitude);
      setLongitude1(data.longitude);
      setReflong1(data.reference_longitude_for_timezone);
      setOffset1(data.offset);
      setTimezone1(data.timezone);
      setCityState1(data.name + " ," + data.state);
    }

  }
  const handleCategoryChange=(e)=>{
    setCategory(e.target.value);
    if (e.target.value === "2"){
    setSelectedGender("N/A");
    }
    console.log("category",e.target.value);
  }


  return (
    <>
      <DashboardTopBar />
      <CelebrityManagementSidebar handleView={handleCelebrityManagementView} history={props.history} />
      {/* <DashboardSideBar /> */}

      <div className="eventWrapper col-lg-12">
        <div className="card" style={{ width: "calc(100% - 240px)" }}>
          <div className="card-body">
            <div className="categoryWrapper">
              <div className="row mb-4" style={{ "marginTop": "-40px" }}>
                <div className="col-md-10 col-12 pt-3" style={{ "text-align": "center" }}>
                  <h2>{t("Celebrity DataBase Management")} </h2>
                </div>
                <div className="col-md-2 col-12">
                  { <div className="select-container pt-4">
                    <label>Category:</label>
                    <select onChange={(e)=>handleCategoryChange(e)} value={category} disabled={active === true}>
                      <option value={1} key={1}>Human</option>
                      <option value={2} key={2}>Mundane</option>
                    </select>
                  </div> }
                </div>
              </div>
              {/* Primary Details */}
              <div className="primary-birth-details">
                <div className="row mb-4">
                  <div className="col-md-6 col-12">
                    <label> Source URL:
                    <input className="form-control" name="source" placeholder="Source URL"
                      value={source} onChange={(e) => setSource(e.target.value)}></input>
                    </label>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3 col-12">
                    <label>First Name:</label>
                    <input className="form-control" type="text" name="lastname" placeholder="First Name"
                      value={firstName} onChange={(e) => setFirstname(e.target.value)}
                     disabled={verification_status} style={{ background:(verification_status?"#dddddd":"white")} }></input>
                  </div>
                  <div className="col-md-3 col-12">
                    <label>Last Name:</label>
                    <input className="form-control" type="text" name="firstname" placeholder="Last Name"
                      value={lastName} onChange={(e) => setLastname(e.target.value)}
                           disabled={verification_status}
                           style={{ background:(verification_status?"#dddddd":"white")}}></input>
                  </div>
                  <div className="col-md-3 col-12">
                    <label>Country:</label>
                    {/*<div className="autocompleteWrapper">*/}
                    {/*  <input type="text" className="col-md-12 autocomplete"*/}
                    {/*    onChange={(e) => {onChangeHandler(e.target.value);}}*/}
                    {/*    value={country}*/}
                    {/*    placeholder="Enter Country"*/}
                    {/*         disabled={verification_status}*/}
                    {/*  />*/}
                    {/*  <ul className="suggestionWrapper" style={{ "z-index": "99" }}>*/}
                    {/*    {suggestions && suggestions.map((suggestion, i) =>*/}
                    {/*      <li className="suggestion col-md-12 justify-content-md-center"*/}
                    {/*        onClick={() => onSuggestHandler(suggestion)}>{suggestion}</li>*/}
                    {/*    )}*/}
                    {/*  </ul>*/}
                    {/*</div>*/}
                    <AutoCompleteCountry
                        autoComplete="off"
                    city = {country}
                    setCountry = {(country)=>setCountry(country)}
                    isDisabled={verification_status}
                    border_color = {(state === null || offset === null || reflong === null)?"red":""}
                    />
                  </div>
                  <div className="col-md-3 col-12">
                    <label>City and State:</label>
                    <AutocompleteCityAndState
                        autoComplete="off"
                        city={
                            cityState
                        }
                        country={country}
                        setLocation = {setPrimaryLocationAttributes}
                        isDisabled={verification_status}
                        border_color = {(state === null || offset === null || reflong === null)?"red":""}
                    />
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-4 col-12">
                    <label>Date of Birth</label>
                    <IMaskInput
                        id="textDOB"
                        style={{fontSize: "0.9rem", "letter-spacing": "5px"}}
                        mask={Date}
                        pattern={"d.m`.`Y"}
                        lazy={false}
                        min={new Date(1900, 0, 1)}
                        max={new Date(2100, 0, 1)}
                        onAccept={(value, mask) => {
                          // console.log(typeof value, 'hii');
                          let text = value.split('.');
                          setDay(text[0]);
                          setMonth(text[1]);
                          setYear(text[2]);
                        }}
                        placeholder="DD.MM.YYYY"
                        className="form-control"
                        value={
                      handleDateChange(day,month,year)
                        }
                        disabled={verification_status}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <label>Time of Birth</label>
                    <TimeInput
                        hour={hour}
                        minute={minute}
                        second = {second}
                        onHourChange={hour=>setHour(hour)}
                        onMinuteChnage={minute=>setMinute(minute)}
                        onSecondChange={second=>setSecond(second)}
                        isDisabled={verification_status}/>
                  </div>
                  <div className="col-md-4 col-12">
                    <div className="select-container">
                      <label>Gender:</label>
                      { <select disabled={category==="2"?true:verification_status} style={verification_status?{background: "#dddddd"}:null} value={category==="2"?"N/A":selectedGender} onChange={(e) => handleGenderChange(e.target.value)} placeholder="Select Gender">
                          <option value=""> Select Gender</option>
                          {options.map((item) => (
                            <option value={item}>
                              {item}
                            </option>
                          ))}
                        </select> }
                    </div>
                  </div>
                </div>
              </div>
              {/* Alternate Details */}
              {alternateDataExists?
                (
                  <div className="alternate-birth-details">
                    <fieldset className="form-group border p-3">
                      <legend className="w-auto px-2">Alternate Birth Details </legend>
                      <div className="row mb-4">
                        <div className="col-md-3 col-12">
                          <label>Country:</label>
                          {/*<div className="autocompleteWrapper">*/}
                          {/*  <input type="text" className="col-md-12 autocomplete"*/}
                          {/*    onChange={e => onCountryChangeHandler(e.target.value)}*/}
                          {/*    value={country1}*/}
                          {/*    placeholder="Enter Country"*/}
                          {/*         disabled={verification_status}*/}
                          {/*  />*/}
                          {/*  <ul className="suggestionWrapper" style={{ "z-index": "99" }}>*/}
                          {/*    {countrySuggestions && countrySuggestions.map((suggestion, i) =>*/}
                          {/*      <li className="suggestion col-md-12 justify-content-md-center"*/}
                          {/*        onClick={() => onCountrySuggestHandler(suggestion)}>{suggestion}</li>*/}
                          {/*    )}*/}
                          {/*  </ul>*/}
                          {/*</div>*/}
                          <AutoCompleteCountry
                              autoComplete="off"
                              city = {country1}
                              setCountry = {(country1)=>setCountry1(country1)}
                              isDisabled={verification_status}
                              border_color = {(state1 === null || offset1 === null || reflong1 === null)?"red":""}
                          />
                        </div>
                        <div className="col-md-3 col-12">
                          <label>City And State:</label>
                          <AutocompleteCityAndState
                              autoComplete="off"
                              city={
                                cityState1
                              }
                              country={country1}
                              setLocation = {setAlternateLocationAttributes}
                              isDisabled={verification_status}
                              border_color = {(state1 === null || offset1 === null || reflong1 === null)?"red":""}
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-4 col-12">
                         <label>Date Of Birth:</label>
                          <IMaskInput
                              id="textDOB"
                              style={{fontSize: "0.9rem", "letter-spacing": "5px"}}
                              mask={Date}
                              pattern={"d.m`.`Y"}
                              lazy={false}
                              min={new Date(1900, 0, 1)}
                              max={new Date(2100, 0, 1)}
                              onAccept={(value, mask) => {
                                // console.log(typeof value, 'hii');
                                let text = value.split('.');
                                setDay1(text[0]);
                                setMonth1(text[1]);
                                setYear1(text[2]);
                              }}
                              placeholder="DD.MM.YYYY"
                              className="form-control"
                               value={handleDateChange(day1,month1,year1)
                               }
                              disabled={verification_status}
                          />
                        </div>
                        <div className="col-md-4 col-12">
                          <label>Time of Birth:</label>
                          <TimeInput
                          hour={hour1}
                          minute={minute1}
                          second = {second1}
                          onHourChange={hour1=>setHour1(hour1)}
                          onMinuteChnage={minute1=>setMinute1(minute1)}
                          onSecondChange={second1=>setSecond1(second1)}
                          isDisabled={verification_status}
                          />
                        </div>
                        {/*<div className="col-md-4 col-12">
                          <div className="select-container">
                            <select value={selectedGender} onChange={(e) => handleGenderChange(e.target.value)} placeholder="Select Gender">
                              <option value=""> Select Gender</option>
                              {options.map((item) => (
                                <option value={item}>
                                  {item}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>*/}
                      </div>
                    </fieldset>
                    <button type="button" className="btn btn-theme btn-lg ml-0" style={{ "width": "auto" }} onClick={()=>{setAlternateDataExists(false);setVerificationStatus(false)}}>
                      {t("Remove Alternate Data")}
                    </button>
                  </div>
                ) : <button type="button" className="btn btn-theme btn-lg ml-0" style={{ "width": "auto" }} onClick={()=>{setAlternateDataExists(true);setVerificationStatus(false)}}>
                    {/*{t(alternateDataExists?null:"Add Alternate Data")}*/}
                    {t("Add Alternate Data")}
                  </button>}
              <center>
                <hr />
              </center>

              <div className="row">
                <div className="col-md-3 col-12">
                  <div className="select-container">
                    {<label>Rating: <select value={selectedRating} onChange={(e) => handleRatingChange(e.target.value)} placeholder="Select Ratings">
                        <option value=""> Select Rating</option>
                        {ratings.map((item) => (
                          <option value={item}>
                            {item}
                          </option>
                        ))}
                      </select> </label>}
                  </div>
                </div>
                <div className="col-md-2 offset-md-7 col-12">
                  <button type="button" className="btn btn-theme btn-lg ml-0" style={{ "width": "auto" }} onClick={VerifyData}>
                    {t(verification_status?"Edit Birth Details":"Verify")}
                  </button>
                </div>
              </div>
              <center>
                <hr />
              </center>
              {verification_status ? (
                <div className="categories">
                  {/* Life Events */}
                  {/*{localStorage.getItem("category") !== 2 && collectionName !== "0" || collectionName !== "2" ? (*/}
                  {(category === "1")? (
                      <div className="life-events mt-2">
                        <fieldset className="form-group border p-3">
                          <legend className="w-auto px-2">Life Events</legend>
                          <form onSubmit={handleEventSubmit}>
                            {formValues.map((item, index) => (
                                <div key={index}>
                                  <div className="row mb-4">
                                    <div className="col-md-4 col-12">
                                      {/*<input className="form-control" type="text" name="event_name" placeholder="Event Name"*/}
                                      {/*       value={item.event_name} onChange={(e) => handleEventChange(index, e)} />*/}
                                     <label>Event Name:</label>
                                      <EventAutouggest event_name = {item.event_name} setLocation={handleEventNameChange}
                                      index = {index}/>
                                      {/*{console.log(index,'index',item,item.event_name)}*/}
                                    </div>
                                    <div className="col-md-4 col-12">
                                      <label>Event Date:</label>
                                      <input className="form-control" type="date" name="event_date" placeholder="Event Date"
                                             value={(moment(item.event_date,'DD-MM-YYYY').format('YYYY-MM-DD'))}
                                             // onChange={(e) => handleEventChange(index, e)} />
                                             onChange={(e) => handleEventDateChange(index, e)} />
                                        {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                                    </div>
                                    <div className="col-md-4 col-12">
                                      <CloseButton aria-label={"Delete Event"} onClick={()=>EventdeletionHandler(index)}/>
                                    </div>
                                  </div>
                                  <div className="row mb-4">
                                    <div className="col-md-12 col-12">
                                      <label>Event Description:</label>
                              <textarea className="form-control" type="text" name="event_description" placeholder="Event Description"
                                        value={item.event_description} onChange={(e) => handleEventChange(index, e)} />

                                      </div>
                                  </div>
                                  <hr/>
                                </div>
                            ))}
                            <div className="row button-section">
                              <div className="col-12">
                                <div className="float-right">
                                  <button type="button" className="btn btn-theme btn-lg mr-2" style={{ "width": "auto" }} onClick={() => addFormFields()}>Create New Event</button>
                                  <button type="submit" className="btn btn-theme btn-lg submit" style={{ "width": "auto" }} >Save Event</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </fieldset>
                      </div>
                  ) : ""}
                  <div className="row">
                    <div className="col-md-12 col-12 mb-2">
                      <label>Biography:</label>
                      {/*<input className="form-control" type="text" name="biography" placeholder="Biography......." id = "biography"*/}
                      {/*  value={biography?biography:""} onChange={(e)=>setBiography(e.target.value)}></input>*/}
                      <textarea className="form-control" type="text" name="biography" placeholder="Biography......." id = "biography"
                             value={biography?biography:""} onChange={(e)=>setBiography(e.target.value)}></textarea>

                      </div>
                  </div>
                  <fieldset className="form-group border p-3 mt-2">
                    <legend className="w-auto px-2">Tags</legend>
                    <div className="col-md-12 col-12">
                      <label>Tag References:</label>
                      {/*<input className="form-control" type="text" name="tagReferences" placeholder="Tag References......."*/}
                      {/*  value={tagReferences}></input>*/}
                      <textarea className="form-control" type="text" name="tagReferences" placeholder="Tag References......."
                                value={tagReferences}></textarea>

                    </div>
                    <div className="row mb-4">
                      <div className="col-md-12 offset-md-0 col-12">
                        <div className="select-container mt-3">
                          <label>Selected Tags:</label>
                            <div className="row border px-2 py-2">
                            {[...tagselectedvalue].map((item,index)=>
                                (
                                    // console.log(item)
                                    // <Toast onClose={() => deleteTagHandler(index)} style={{width: "fit-content"}}>
                                    //   <Toast.Header>
                                    //     <strong className="me-auto">{item.tag_name}</strong>
                                    //   </Toast.Header>
                                    // </Toast>

                                    <div className="col-md-auto border  border-primary px-1 py-1 rounded mx-1 mt-1"
                                         // style={{display:"contents",border:"solid black"}}
                                    >
                                      {/*<span>*/}
                                       <span className="mr-1"> {item.tag_name} </span>
                                      <CloseButton onClick={()=>deleteTagHandler(index)}></CloseButton>
                                      {/*<button onClick={()=>deleteTagHandler(index)}> X </button>*/}
                                      {/*</span>*/}
                                    </div>

                                ))}
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-auto col-12">
                        <label>Tag Name:</label>
                      </div>
                      <div className="col-md-auto col-12">
                        <TagAutosuggest tag_name = {tags} setLocation={setTags}/>
                      </div>
                      <div className="col-md-auto col-12">
                        { <button type="button" className="btn btn-theme btn-lg" style={{ width: "auto" }}
                                  onClick={addtag}>
                            {t("+ Add Tag")}
                          </button> }
                      </div>
                    </div>
                  </fieldset>
                </div>
              ) : ""}
              <div className="btns-grp">
                <center>
                  <div className="row">
                    <div className="col-md-4 col-12">
                      {/*<div className="d-flex">*/}
                        <label>Wikipedia Link:</label>
                        <input className="form-control" type="text" name="wikipedia" placeholder="Wikipedia Link"
                          value={wikipediaLink} onChange={(e)=>setWikipediaLink(e.target.value)}></input>
                        {wikipediaLink !== null ?
                            (<a className="form-control btn btn-theme btn-lg" href={wikipediaLink} placeholder="Wikipedia Link" target={"_blank"}>GO</a>)
                            : ""}
                      {/*</div>*/}
                    </div>
                    <div className="col-md-4 col-12">
                      {imgData !== null ? (<img src={imgData} className="img-photo" alt="" />) :
                        <img src={"https://omparashar-dev.s3.ap-south-1.amazonaws.com/images/" + photoName} className="img-photo" alt="" />}
                      {/* {imgData !== <img src={imgData} className="img-photo" alt="" /> ? (<img src={imgData} className="img-photo" alt="" />) : ""} */}
                      {/* <img src={require('https://omparashar-dev.s3.ap-south-1.amazonaws.com/images/' + {imgData})} /> */}
                    </div>
                    <div className="col-md-4 col-12">
                      <input id="profilePic" type="file" onChange={onChangePicture} style={{ "float": "right" }} />
                      <button onClick={()=>{setPhotoName(null);setPhotofile(null);}}>Remove photo</button>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="offset-md-8 col-md-4 col-12">
                      <div className="float-right">
                        {active == true ? (<div className="float-right">
                          <button type="button" className="btn btn-update btn-lg mr-2" onClick={UpdateData} style={{ width: "auto" }}>
                            {t("Update")}
                          </button>
                          <button type="button" className="btn btn-delete btn-lg" onClick={DeleteHoroscope} style={{ width: "auto" }}>
                            {t("Delete")}
                          </button>
                        </div>)
                          : (<button type="button" className="btn btn-theme btn-lg mr-2" onClick={CreateData} style={{ "width": "auto" }}>
                            {t("Create Horoscope")}
                          </button>)}
                      </div>
                    </div>

                  </div>
                </center>
              </div>
            </div>
          </div >
        </div >
      </div >
    </>
  );
};

export default withRouter(CreateHoroscope);

