export const nakshatraNameToNumMap = {
    "Ashwini": 1, "Bharni": 2, "Krittika": 3,
    "Rohini": 4, "Mrigshira": 5, "Ardra": 6,
    "Punarvasu": 7, "Pushya": 8, "Ashlesha": 9,
    "Magha": 10, "Purva Phalguni": 11, "Uttara Phalguni": 12,
    "Hasta": 13, "Chitra": 14, "Swati": 15,
    "Visakha": 16, "Anuradha": 17, "Jyestha": 18,
    "Moola": 19, "Purva Ashadha": 20, "Uttara Ashadha": 21,
    "Shravana": 22, "Dhanishta": 23, "Shatbhisha": 24,
    "Purva Bhadrapada": 25, "Uttara Bhadrapada": 26, "Revati": 27
};

export const rashiNumberToNameMap={1:"Aries",2:"Taurus",3:"Gemini",4:"Cancer",5:"Leo",6:"Virgo",7:"Libra",
    8:"Scorpio",9:"Sagittarius",10:"Capricorn",11:"Aquarius",12:"Pisces"
}

export const houseLordNamesArray=['Lord of 1st house','Lord of 2nd house',
'Lord of 3rd house','Lord of 4th house',
'Lord of 5th house','Lord of 6th house',
'Lord of 7th house','Lord of 8th house',
'Lord of 9th house','Lord of 10th house',
'Lord of 11th house','Lord of 12th house']

const createPlanetCodesJSON=()=>{
    var planetCodesJSON = {
        "Ascendant": 0,
        "Sun": 1,
        "Moon": 2,
        "Mars": 3,
        "Mercury": 4,
        "Jupiter": 5,
        "Venus": 6,
        "Saturn": 7,
        "Rahu": 8,
        "Ketu": 9
    }
    houseLordNamesArray.forEach(item=>{
        var i = houseLordNamesArray.indexOf(item);
        if(i<10){
            var code = '72760'+(i+1).toString();
        }
        else{
            var code = '7276'+(i+1).toString();
        }
        planetCodesJSON[item]=parseInt(code);
    })
    return planetCodesJSON;
}

// export const planetCodes = {
//     "Ascendant": 0,
//     "Sun": 1,
//     "Moon": 2,
//     "Mars": 3,
//     "Mercury": 4,
//     "Jupiter": 5,
//     "Venus": 6,
//     "Saturn": 7,
//     "Rahu": 8,
//     "Ketu": 9
// }

export const planetCodes = createPlanetCodesJSON();


export const planetToAspectMap =
    {
        "Sun": [7], "Moon": [7], "Mars": [4, 8, 7],
        "Mercury": [7], "Jupiter": [5, 7, 9], "Venus": [7],
        "Saturn": [3, 7, 10], "Rahu": [5, 7, 9], "Ketu": [5, 7, 9]
    }

export const motionToNumMap = {"Direct": 0, "Retrograde": 1}

export const calculateRashiFromNakNum = (nakNum) => {
    // each nakshtra is 13.33 degrees and each rashi is 30 degrees
    var nakStartDegree = (nakNum - 1) * 13.33;
    var nakEndDegree = (nakNum) * 13.33;
    var rashiNum1 = Math.floor(nakStartDegree / 30) + 1;
    var rashiNum2 = Math.floor(nakEndDegree / 30) + 1;
    if (rashiNum1 === rashiNum2) {
        return [rashiNum1];
    }
    return [rashiNum1, rashiNum2];
}

// console.log(calculateRashiFromNakNum(23));

export const rashiToNakNameMap = {
    1: ["Ashwini", "Bharni", "Krittika"],
    2: ["Krittika", "Rohini", "Mrigshira"],
    3: ["Mrigshira", "Ardra", "Punarvasu"],
    4: ["Punarvasu", "Pushya", "Ashlesha"],
    5: ["Magha", "Purva Phalguni", "Uttara Phalguni"],
    6: ["Uttara Phalguni", "Hasta", "Chitra"],
    7: ["Chitra", "Swati", "Visakha"],
    8: ["Visakha", "Anuradha", "Jyestha"],
    9: ["Moola", "Purva Ashadha", "Uttara Ashadha"],
    10: ["Uttara Ashadha", "Shravana", "Dhanishta"],
    11: ["Dhanishta", "Shatbhisha", "Purva Bhadrapada"],
    12: ["Purva Bhadrapada", "Uttara Bhadrapada", "Revati"]
}

export const calculateNakshtrasInRashis = (rashis) => {
    console.log(rashis,'in calc nak');
    var t = Array();
    // for (var i in rashis) {
    //     console.log(i,rashiToNakNameMap[i])
    //     t= t.concat(rashiToNakNameMap[i]);
    // }
    rashis.forEach((i)=>{t= t.concat(rashiToNakNameMap[i]);})
    console.log(t,[...new Set(t)],'lli')
    return [...new Set(t)];

}

export const getPlanetNameFromPlanetCode=(planet_code)=>{
    for(var p_name in planetCodes){
        if(parseInt(planet_code)===planetCodes[p_name]){
            return p_name;
        }
    }
}

// console.log(calculateNakshtrasInRashis([1]))