import React, { Component } from "react";
import "./ShadBala.css";
import Table from "react-bootstrap/Table";
import { withTranslation } from "react-i18next";
class CollapseTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  render() {
    const { t, i18n } = this.props;
    const { data } = this.props;
    // console.log(data);
    return (
      <>
        <div className="shad">
          <Table className="content_table_shad" responsive="xs">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>{t("Sun")}</th>
                <th>{t("Moon")}</th>
                <th>{t("Mars")}</th>
                <th>{t("Mercury")}</th>
                <th>{t("Jupiter")}</th>
                <th>{t("Venus")}</th>
                <th>{t("Saturn")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map(function (val, i) {
                return (
                  <tr
                    className={val["Column1"].includes("Total") ? "bold" : ""}
                  >
                    <td className="left-align-shad"> {t(val["Column1"])}</td>
                    <td className="left-align-shad">{t(val["Column2"])}</td>
                    <td className="right-align-shad">{t(val["Sun"])}</td>
                    <td className="right-align-shad">{t(val["Moon"])}</td>
                    <td className="right-align-shad">{t(val["Mars"])}</td>
                    <td className="right-align-shad">{t(val["Mercury"])}</td>
                    <td className="right-align-shad">{t(val["Jupiter"])}</td>
                    <td className="right-align-shad">{t(val["Venus"])}</td>
                    <td className="right-align-shad">{t(val["Saturn"])}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default withTranslation()(CollapseTable);
