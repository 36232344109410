import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "../Testview/testview.css";

class Testview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div class="col-lg-12">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              src="https://www.solarsystemscope.com/iframe"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Testview);
