import i18n from 'i18next';
import { I18nContext, initReactI18next } from 'react-i18next';

import translateEN from './locale/en/translateEN.json';
import translateHI from './locale/hi/translateHI.json';

const resources = {
    en:{
        translation : translateEN
    },
    hi:{
        translation : translateHI 
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'en',
    keySeparator:false,
    
    interpolatiom:{
        escapeValue: false
    }
});

export default i18n;