import React, { Component } from "react";
import Plotly from "plotly.js";
import { GlobalContext } from "../../../mycontext";
import {getQueryParam} from '../../utils/utility';

function unpack(rows, key) {
  return rows.map(function (row) {
    return row[key];
  });
}

const PlanetNames = {
  Ju: "Jupiter",
  Mo: "Moon",
  Ve: "Venus",
  Ma: "Mars",
  Su: "Sun",
  Me: "Mercury",
  Ra: "Rahu",
  Ke: "Ketu",
  Sa: "Saturn",
};

const MonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Datify = (date) => {
  let arr = date.split("-");
  return arr[0] + " " + MonthNames[arr[1] - 1] + ", " + arr[2];
};

class SunBurstMahaDasha extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      view_type: "ordered",
      horoscopeData:
      JSON.parse(window.localStorage.getItem("horoscopeData")) || null,

      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let queryString = getQueryParam('cb');
    if(queryString){
      this.setState({horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
    }
    else{
      this.setState({horoscopeData: JSON.parse(window.localStorage.getItem("horoscopeData"))});
    }

    if (Object.keys(this.state.horoscopeData).length === 0) {
      this.props.history.push("/horoscope/");
    } else {
      this.apidata_format();
    }
  }

  //   componentWillReceiveProps(newProps) {
  //     if (
  //       this.props.end !== newProps.end ||
  //       this.props.start !== newProps.start ||
  //       this.props.city !== newProps.city
  //     )
  //       this.apidate_format_ordered();
  //   }

  apidata_format = () => {
    let gd1 = document.getElementById("myDiv_SunBurst");
    let dict = [];

   
    let mahadashas =
      this.state.horoscopeData.vimsottari_dasha_days_only.mahadashalist;
    for (let i of mahadashas) {
      let newDict1 = {};
      newDict1["ids"] = "Maha-" + i.lord;
      newDict1["labels"] = i.lord;
      newDict1["parents"] = "";
      newDict1["time"] =
        "Maha Dasha " +
        "<br>" +
        Datify(i.start_date) +
        " to " +
        Datify(i.end_date);
      dict.push(newDict1);
    }
    var countArdha = 0;
    var countP = 0;
    for (let i of mahadashas) {
      let ardhaDasha = i.sub_list;
      for (let j of ardhaDasha) {
        let newDict2 = {};
        newDict2["ids"] = "Anthar-" + countArdha + j.lord;
        newDict2["labels"] = j.lord;
        newDict2["parents"] = "Maha-" + i.lord;
        newDict2["time"] =
          "Anthar Dasha " +
          "<br>" +
          Datify(j.start_date) +
          " to " +
          Datify(j.end_date);
        dict.push(newDict2);
        let pradhamaDasha = j.sub_list;
        for (let k of pradhamaDasha) {
          let newDict3 = {};
          newDict3["ids"] = "Pradhama-" + countP + k.lord;
          newDict3["labels"] = k.lord;
          newDict3["parents"] = "Anthar-" + countArdha + j.lord;
          newDict3["time"] =
            "Pradhama Dasha " +
            "<br>" +
            Datify(k.start_date) +
            " to " +
            Datify(k.end_date);
          dict.push(newDict3);
        }
        countP = countP + 1;
        countArdha = countArdha + 1;
      }
    }
    let ids = unpack(dict, "ids");
    let labels = unpack(dict, "labels");
    let parents = unpack(dict, "parents");
    let time = unpack(dict, "time");
    var data = [
      {
        type: "sunburst",
        maxdepth: 3,
        ids: ids,
        labels: labels,
        parents: parents,
        hovertext: time,
        textposition: "inside",
        insidetextorientation: "radial",
      },
    ];

    var layout = {
      margin: { l: 0, r: 0, b: 0, t: 0 },
      width: 600,
      height: 600,
      sunburstcolorway: [
        "#636efa",
        "#EF553B",
        "#00cc96",
        "#ab63fa",
        "#19d3f3",
        "#e763fa",
        "#FECB52",
        "#FFA15A",
        "#FF6692",
        "#B6E880",
      ],
      extendsunburstcolorway: true,
    };

    Plotly.react(gd1, data, layout);
  };

  render() {
    return (
      <>
        {/* <button onClick={this.apidate_format}>click me</button> */}
        <div className="col-lg-14" style={{ overflow: "hidden" }}>
          <div id="MahaDasha">
            <div className="card">
              <div className="card-body" style={{ padding: "10px" }}>
                <center>
                  <h3 style={{ color: "midnightblue" }}>
                    SunBurst Chart for MahaDashas
                  </h3>
                  <div id="myDiv_SunBurst"></div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SunBurstMahaDasha;
