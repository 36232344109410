import React from "react";

import "react-bootstrap";

const TermsConditions = () => {
  function scrollFunction() {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      // document.getElementById("navbar").style.padding = "30px 10px";
      // document.getElementById("logo").style.fontSize = "25px";
    } else {
      //document.getElementById("navbar").style.padding = "80px 10px";
      //document.getElementById("logo").style.fontSize = "35px";
      //alert("2");
    }
  }
  window.onscroll = function () {
    scrollFunction();
  };

  return (
    <>
      <div id="home" className="home">
        <nav
          className="navbar navbar-expand-lg navbar-expand-xl navbar-dark"
          id="mainNav"
        >
          <div
            className="container"
            style={{ marginLeft: "10%", marginRight: "10%" }}
          >
            <a className="navbar-brand js-scroll-trigger" href="/">
              <img src={require("../assets/img/logo.png")} alt="" />
            </a>
           
          </div>
        </nav>
        {/* <!-- Masthead--> */}

        <section className="page-section" id="services">
          <div class="container">
            <div class="row">
              <div id="primary" class="content-area col-md-8 mx-auto">
                <div id="content" class="site-content" role="main" style={{marginTop:"-30px"}}>
                  <article
                    id="post-108"
                    class="post-108 page type-page status-publish hentry"
                  >
                    <header class="entry-header" style={{height:"20%"}}>
                      <h1 class="entry-title">Terms & Conditions of Usage</h1>
                    </header>

                    <div class="entry-content">
                   
<ol>
    <li><strong>GENERAL DESCRIPTION:</strong></li>
    <li>The Website is an internet-based portal having its existence on World Wide Web, Applications and other electronic mediums and provides astrological content, reports, data, telephone, video and email consultations (hereinafter referred to as &ldquo;Content&rdquo;). The Website is offering &ldquo;Free Services&rdquo; and &ldquo;Paid Services&rdquo; (Collectively referred to as &ldquo;Services&rdquo;).&nbsp;</li>
    <li>Free Services are easily accessible without becoming a member however for accessing the personalised astrological services and/or receive additional Content and get access to Paid Services, you are required to register as a member on the portal.&nbsp;</li>
    <li>By registering for Paid Services, a member agrees to:</li>
    <li>To provide current, complete, and accurate information about himself as prompted to do so by the Website.</li>
    <li>To maintain and update the above information as required and submitted by you with the view to maintain the accuracy of the information being current and complete.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>THIS IS A CONTRACT:</strong></li>
</ol>
<p>Angee Bridging India Private Limited<strong>&nbsp;allows you to access its website&nbsp;</strong>Omparashar<strong>&nbsp;and all the content in them on certain terms and conditions specified below. By accessing or subscribing to any part of these sites, you are explicitly agreeing to the terms and conditions below.&nbsp;</strong></p>
<p><br/></p>
<ol>
    <li><strong>TERMS AND CONDITIONS OF USAGE:</strong></li>
</ol>
<p><strong>These terms and conditions of Use (hereinafter referred as &ldquo;Terms of Usage&rdquo;) describe and govern the User&rsquo;s use of the content and services offered by Angee Bridging India Private Limited through&nbsp;</strong>www.omparashar.com<strong>&nbsp;(hereinafter referred as &ldquo;We&rdquo; &ldquo;omparashar&rdquo; &ldquo;us&rdquo; &ldquo;our&rdquo; &ldquo;omparashar application&rdquo; &ldquo;Website&rdquo;).</strong></p>
<ol>
    <li><strong>UPDATION:</strong></li>
</ol>
<p><strong>The Website may update/amend/modify these Terms of Usage from time to time. The User is responsible to check the Terms of Usage periodically to remain in compliance with these terms.</strong></p>
<p><strong>REGISTRATION AND ELIGIBILITY:</strong></p>
<ol>
    <li>The User of the Website must be a person who can form legally binding contracts under Indian Contract Act, 1872. A minor under the age of eighteen (18) in most jurisdiction, are not permitted to avail the services provided on the Website without a legal guardian in accordance with the applicable laws. The Website would not be held responsible for any misuse that may occur by virtue of any person including a minor using the services provided through the Website.</li>
    <li>For the User to avail the services, the User will be directed to Register as a Member on the Website whereby You (User) agree to provide update, current and accurate information while filling up the sign-in form. All information that you fill and provide to the Website and all updates thereto are referred to in these Terms of Usage as &ldquo;Registration Data&rdquo;.</li>
    <li>An account could be created by you through the Website ID (Your Phone Number) and password (OTP) or other log - in ID and password which can include a facebook, gmail or any other valid email ID. The User while creating an account hereby represents and warrants that all the information provided by the User is current, accurate and complete and that the User will maintain the accuracy and keep the information updated from time to time. Use of another User&rsquo;s account information for availing the services is expressly prohibited. If in case it is found that the information so supplied on the Website is inaccurate, incomplete, untrue and not current, the Website has the right to suspend or terminate the User&rsquo;s account and restrict/refuse the use of the Website by such User in future.</li>
    <li>The right to use this Website is personal to the User and is not transferable to any other person or entity. The User would be responsible for protecting the confidentiality of User&rsquo;s passwords and other information required for the purposes of registration. The User would be fully responsible for all the activities that occur under the User&rsquo;s account with the Website. The Website cannot and will not be liable for any loss or damage arising from the User&rsquo;s failure to maintain secrecy and confidentiality. The User shall notify the Website immediately if they become aware of any unauthorised use of their Account(s) or breach of any security. The User must log out from its account at the end of the session.</li>
    <li>The User while availing any service shall be informed whether the service so rendered is personal to the Website or is available from a Third party. The Website shall have no control or monitoring on the information disseminated to any third party via the Website.</li>
    <li>The User agrees, understands and confirms that his/ her personal data including without limitation to details relating to debit card/ credit card transmitted over the Internet may be susceptible to misuse, hacking, theft and/ or fraud and that the Website or the Payment Service Provider(s) have no control over such matters.</li>
    <li>The Website does not permit the use of the Services by any User under the following conditions: -</li>
    <li>If the User is a resident of any jurisdiction that may prohibit the use of the Services rendered by the Website.</li>
    <li>If the User is a resident of any State/Country that prohibits by way of law, regulation, treaty or administrative act for entering into trade relations or/and</li>
    <li>Due to any religious practices.</li>
    <li>If the User has created multiple accounts using various mobile numbers. The User may not have more than one active account with the Website.<br/><br/><strong>WEBSITE CONTENT:</strong></li>
    <li>The Website and any individual websites which may be available through external hyperlinks with the Website are private property.</li>
    <li>All interaction on this Website inclusive of the guidance and advice received directly from the Licensed Provider must comply with these Terms of Usage.</li>
    <li>The User shall not post or transmit through this Website any material which violates or infringes in any way upon the rights of others, or any material which is unlawful, abusive, defamatory, invasive of privacy, vulgar, obscene, profane or otherwise objectionable, which encourages conduct that would constitute a criminal offence, give rise to civil liability or otherwise violate any law.</li>
    <li>The Website shall have a right to suspend or terminate access by such User or terminate the User&rsquo;s registration and such User shall not gain access to the Website.</li>
    <li>The Website reserves the right to terminate the access or to change or discontinue any aspect or feature of the Website including, but not limited to, content, graphics, deals, offers, settings, etc.</li>
    <li>Any information other the guidance and advice, received directly from the Third-Party Service Provider, the educational, graphics, research sources and other incidental information on the Site, the content, should not be considered as medical advice.</li>
    <li>The Website does not take guarantee regarding the medical advice, if provided, by the third-party service provider inclusive of registered astrologers with the site. The User should always talk to an appropriately qualified health care professional for diagnosis and treatment including information regarding which medications or treatment may be appropriate for the User. None of the Content represents or warrants that any particular medication or treatment is safe, appropriate, or effective for you. Omparashar does not endorse any specific tests, medications, products or procedures.</li>
    <li>The Website does not take guarantee of any untoward incident that may happen with the User after seeking the Service. The Website or the Service Provider providing the advice is not liable and does not guarantee any results as expected by the User and accessing the Website in such scenario is purely at the risk of the User.</li>
    <li>By using the Site, Application or Services, User hereby agrees that any legal remedy or liability that you seek to obtain for actions or omissions of other Members inclusive of the service provider registered with the Website or other third parties linked with the Website, shall be limited to claim against such particular party who may have caused any harm. You agree not to attempt to impose liability on or seek any legal remedy from the Website with respect to such actions or omissions.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>FEATURE &ldquo;MAKE CALL WITH ASTROLOGER&rdquo;:</strong></li>
</ol>
<p><strong>The Website is providing certain service which is available through the medium of telecommunication with the Astrologer listed and enrolled with the Website. By agreeing to the present Terms of Usage, you are also giving your unconditional consent to the Website to arrange a call with you on your mobile number even though your number is on DND service provided by your mobile service provider.</strong></p>
<ol>
    <li><strong>MEMBER ELIGIBILITY:</strong></li>
</ol>
<p><strong>The Services are available only to individuals who can form legally binding contracts under the applicable law. Without limiting the foregoing, the Services are not permitted to be used by minors (which means, in most jurisdictions, those under the age of 18) or temporarily or indefinitely suspended members.</strong></p>
<ol>
    <li><strong>MEMBER REGISTRATION DATA:</strong></li>
</ol>
<p><strong>Upon registration, including your provision of Registration Data, you will receive a unique identification (ID) and a password. You shall be entirely responsible for maintaining the confidentiality of your USER ID and password. You shall be responsible for all uses of your account, whether or not actually or expressly authorised by you. You may change your password by following instructions on the website. You agree that your account, ID, and password may not be transferred or sold to another party. You shall also agree to immediately notify Omparashar team of any unauthorised use of your account or any other breach of security known to you.</strong></p>
<ol>
    <li><strong>OUR ACCESS TO YOUR ACCOUNT:</strong></li>
</ol>
<p><strong>In order to ensure that Omparshar is able to provide high-quality services, respond to customer needs, and comply with laws, you shall hereby consent to omparahar employees and agents being able to access your account and records on a case-by-case basis to investigate complaints or other allegations or suspected abuse.</strong></p>
<ol>
    <li><strong>PAID SERVICES:</strong></li>
</ol>
<p><strong>1. General Products and Services (not including subscriptions) of omparashar operates several paid services. By subscribing to receive any of these services, as well as the above terms and conditions, you are also agreeing to the following terms:</strong></p>
<ol>
    <li>You must provide us with complete and accurate payment information.&nbsp;</li>
    <li>You can pay by either credit or debit card through your bank&apos;s internet payment gateway.&nbsp;</li>
    <li>By submitting credit or debit card payment details to omparshar you warrant that you are entitled to purchase the subscription services using those payment details.&nbsp;</li>
    <li>In the case of unauthorised payments</li>
    <li>omparshar</li>
    <li>&nbsp;reserves the right to suspend or terminate your access to the online services.&nbsp;</li>
    <li>If we do not receive payment authorisation or any authorisation is subsequently cancelled, we may immediately terminate or suspend your access to any paid-for services.</li>
    <li>omparshar will try to process your purchase promptly but does not guarantee that the paid services will be available to you by any specified time.&nbsp;</li>
    <li>A contract with you for receipt of the paid services will come into effect when Omparashar. sends you an email message confirming your subscription details.</li>
    <li>Omparashar. will charge you in Indian rupees (INR), and any payment in other any other currency will be calculated at the relevant rates of exchange.&nbsp;</li>
    <li>You may also have to pay any applicable local taxes. Omparashar. will not entertain any dispute about the rate at which your currency is converted into Indian rupees since these transactions are determined through the agreements our payment gateway partners have with banks and credit card companies.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>DELIVERY OF SERVICES:</strong></li>
    <li>Online reports and soft-copies of reports that are auto processed will be delivered as an attachment in pdf format to the email id provided by the purchaser within a maximum of 2 (two) working days.</li>
    <li>Custom reports from astrology consultants will be delivered as an attachment in pdf or MS Word format to the email id provided by the purchaser within a maximum of 14 (fourteen) working days.</li>
    <li>Hardcopies (printed copies) of reports will be mailed via India Post to the address provided by the purchaser within a maximum of 14 working days. Delivery outside of India is currently not available.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>USER CONSENT:</strong></li>
    <li>By accessing the Website and using it, you (&ldquo;Member&rdquo;, &ldquo;You&rdquo;, &ldquo;Your&rdquo;) indicate that you understand the terms and unconditionally &amp; expressly consent to the Terms of Usage of this Website. If you do not agree with the Terms of Usage, please do not click on the &ldquo;I AGREE&rdquo; button.&nbsp;</li>
    <li>The User is advised to read the Terms of Usage carefully before using or registering on the Website or accessing any material, information or services through the Website. Your use and continued usage of the Website (irrespective of the amendments made from time to time) shall signify your acceptance of the terms of usage and your agreement to be legally bound by the same.</li>
    <li>Omparashar is not an accredited program or institution. Information on this Web Site or related content or service is provided for entertainment purposes only and is not experimentally proven. In no way should content on this Web Site or related content or service be considered as conforming with any standards or qualifications prescribed by any entity or organisation.</li>
    <li>Astrology is an evolving body of knowledge, with experiences and original research being contributed by astrologers from around the world. Any data, interpretation, prediction or information in any form received through Omparashar should not be treated as substitute for advice or treatment you would normally receive from medical professionals, financial advisors, legal consultants and other such licensed services.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>PROPRIETARY RIGHTS TO THE CONSENT:</strong></li>
    <li>The User acknowledges that the Content, including but not limited to text, software, music, sound, photographs, video, graphics or other material contained in sponsor advertisements or distributed via email, commercially produced information presented to Member by the Website, its suppliers, and/or advertisers, is protected by copyrights, trademarks, service marks, patents and/or other proprietary rights and laws.&nbsp;</li>
    <li>All content - textual, still pictures, graphs, voice or video - made available on Omparashar sites (including content available on our email newsletters and SMS services) (hereinafter referred to as the &quot;content&quot;) belongs to Omparashar or its content partners or licensors.&nbsp;</li>
    <li>Omparashar or its partners/licensors own all intellectual property rights (including copyright and database rights) in the content. You may retrieve and view content on a computer screen, PDA or mobile telephone, print individual pages on paper (but not photocopy them) and store such pages in electronic form on disk or on your mobile telephone (but not on any server or other storage device connected to a network) for your personal, non-commercial use. You may also email articles to a maximum of five individuals using our &apos;email this&apos; service.</li>
    <li>You may not use our sites for any unlawful purpose. Except as indicated above, you may not reproduce, publish, broadcast, transmit, modify, adapt, create derivative works of, store, archive or in any way commercially exploit any of the content. Without limitation, you may not do any of the following without prior written permission from Omparashar (and neither may you allow a third party to do any of the same:</li>
    <li>redistribute or resell any of the content, including our headlines</li>
    <li>create a database in electronic or structured manual form by systematically downloading and storing all or any of the content; or</li>
    <li>deep link to, frame, spider, harvest or scrape the content or otherwise access the content for similar purposes; or</li>
    <li>use any machine, electronic, web-based or similar device to read or extract the content by automated techniques.</li>
    <li>Reprint and republication rights</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>USER BEHAVIOUR AND RESTRICTIONS:</strong></li>
    <li>Your right to use our Services is personal to you. You represent that you are an individual, and that You are not a corporation or other business entity. You agree not to resell or make any commercial use of the Services without the express written consent of Omparashar You are also solely responsible for the content of your transmissions through our website. Your use of the Services is subject to all applicable local, state, national and international laws and regulations.</li>
    <li>You are responsible for all use of the Omparashar sites made by you or anyone else using your ID and for preventing unauthorised use of your ID. If you believe there has been any breach of security such as the disclosure, theft or unauthorised use of your ID or any payment information, you must notify Omparashar immediately by e-mailing info@mparashar.com We recommend that you do not select an obvious user password (such as your name) and that you change it regularly.</li>
    <li>If you provide Omparashar with an email address that will result in any emails or SMS messages from Omparashar being sent to you via a computer network or telephone operated or owned by a third party (e.g. your employer or your institution) then you warrant that you are entitled to receive those messages. You also agree that Omparashar may refrain from sending messages to you without notifying you, even if you have subscribed to receive them, if we receive a request from a third party to stop sending emails or SMS messages to you.</li>
    <li><strong>USER OBLIGATION:</strong></li>
    <li>The User (inclusive of the astrologer and the Member Customer) under an obligation not to violate the privacy policy, terms and conditions and any other terms as defined on the Website. The User represents that he is an individual and not a corporation or other legal business entity. The rights to use the Website&rsquo;s services is personal to the User. The User shall while using the Website and engaged in any form of communication on any of the forums inclusive of the products listed on the Website shall not violate the terms and conditions which are inclusive of:-</li>
    <li>The User shall not Post, publish or transmit any messages that is false, misleading, defamatory, harmful, threatening, abusive, harassing, defamatory, invades another&apos;s privacy, offensive, promotes racism, hatred or harm against any individual or group or religion or caste, infringes another&apos;s rights including any intellectual property rights or copyright or trademark, violates or encourages any conduct that would violate any applicable law or regulation or would give rise to civil liability.</li>
    <li>The User shall not upload or post or otherwise make available any content that User do not have a right to make available, under any law or under contractual or fiduciary relationships.</li>
    <li>The User shall not upload or post or otherwise make available any content that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party. The User may, however, post excerpts of copyrighted material so long as they adhere to Fair Use guidelines.</li>
    <li>The User shall not collect screen names and email addresses of members who are registered on the Website for purposes of advertisement, solicitation or spam.</li>
    <li>The User shall not send unsolicited email, junk mail, spam, or chain letters, or promotions or advertisements for products or services.</li>
    <li>The User shall not upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website or another&rsquo;s computer.</li>
    <li>The User shall not engage in any activity that interferes with or disrupts access to the Website</li>
    <li>The User shall not attempt to gain unauthorised access to any portion or feature of the Website, any other systems or networks connected to the Website, to any of the services offered on or through the Website, by hacking, password mining or any other illegitimate means.</li>
    <li>The User shall not violate any applicable laws or regulations for the time being in force within or outside India. The use and continuous use of the Website is subject to but not limited to using the services for personal use.</li>
    <li>The User shall not resell or make any commercial use of the Services without the express written consent from the Website.</li>
    <li>The User shall not violate these Terms of Usage including but not limited to any applicable Additional terms of the Website contained herein or elsewhere.</li>
    <li>The User shall not Reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Website.</li>
    <li>The User by becoming a Registered member of the Website agrees to the following situations, which list is not exhaustive and may include services incidental to the below mentioned: -</li>
    <li>The user agrees to receive communication through the app/website through email/SMS or any other communication medium including though WhatsApp Business Messages in regards to the usage of the app/website.</li>
    <li>The User agrees not to transmit via the Website any unlawful, harassing, libellous, abusive, threatening, harmful, vulgar, obscene or otherwise objectionable material of any kind or nature.</li>
    <li>The User not to transmit any material that encourages conduct that could constitute a criminal offence, give rise to civil liability, or otherwise violate any applicable local, state, national or international law or regulation. Attempts to gain unauthorised access to other computer systems are prohibited.</li>
    <li>The User shall not interfere with any other members&apos; use or enjoyment of the Website or Services.</li>
    <li>The User is under an obligation to report any misuse or abuse of the Site. If you notice any abuse or misuse of the Site or anything which is in violation of this Agreement, you shall forthwith report such violation to Website by writing to Customer Care. On receipt of such complaint, Website may investigate such complaint and if necessary, may terminate the membership of the Member responsible for such violation abuse or misuse without any refund of the subscription fee.</li>
    <li>Any false complaint made by a Member shall make such Member liable for termination of his / her membership without any refund of the subscription fee.</li>
    <li>The Website reserves the right to withdraw its services to any customer who is found to be unreasonable or abusive during their conversation with the Service Provider inclusive of astrologer regardless of any reason.</li>
    <li>While the Website shall take all steps to resolve any situation that is in violation of the above obligations arises, however if the situation is not controllable, the Website reserves its right to send a written warning henceforth. Such violations, if repeated by the User, shall lead to a total ban for transacting on the platform by such User. If any balance is present in the wallet of the User, the same shall be refunded subject to the other charges that may be applicable for such violations.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>USER ACCOUNT ACCESS:</strong></li>
    <li>The Website shall have access to the account and the information created by the User for ensuring and maintaining the high-quality services provided by the Website and for addressing the need of the customer in the most effective manner.&nbsp;</li>
    <li>User hereby consents for the unconditional access of the account by the Website, its employees, agents and other appointed person in such regard. For the purpose of addressing the complaints (if any received) and any suspected abuse reported, the Website shall investigate on case-to-case basis from the records available. The User is directed to read the terms provided in the Privacy Policy as regards such records.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>BANK ACCOUNT INFORMATION:</strong></li>
    <li>The User is under an obligation to provide his banking information as and when required. For that purpose, the obligation of the User are:-</li>
    <li>The User agrees that the debit/credit card details provided by him/ her for use of the aforesaid Service(s) must be correct and accurate and that the User shall not use a debit/ credit card, that is not lawfully owned by him/ her or the use of which is not authorised by the lawful owner thereof. The User further agrees and undertakes to provide correct and valid debit/credit card details.</li>
    <li>The User may pay the fees required, to the Website by using a debit/credit card or through online banking account. The User warrants, agrees and confirms that when he/ she initiates a payment transaction and/or issues an online payment instruction and provides his/ her card / bank details:</li>
    <li>The User is fully and lawfully entitled to use such credit / debit card, bank account for such transactions;</li>
    <li>The User is responsible to ensure that the card/ bank account details provided by him/ her are accurate;</li>
    <li>The User is responsible to ensure sufficient credit is available on the nominated card/ bank account at the time of making the payment to permit the payment of the dues payable or the bill(s) selected by the User inclusive of the applicable Fee.</li>
    <li>The User further agrees that if any part of these Terms of Usage are determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth herein, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these Terms of Usage shall continue in effect.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>TECHNICAL REQUIREMENTS AND SITE SECURITY:</strong></li>
</ol>
<p><strong>The Omparashar Sites are best viewed using most versions of popular web browsers. Any older browser versions may work but users could have a limited browsing experience. The Omparashar site may not work well with Netscape versions. Payments to Omparashar online are facilitated through third party payment gateways. Any payments made through&nbsp;</strong>www.omparashar.com<strong>&nbsp;must meet the terms and conditions indicated by these payment gateways.</strong></p>
<p><br/></p>
<p><br/></p>
<ol>
    <li><strong>DISCLAIMER:</strong></li>
    <li>The site omparashar.com of Omparashar, contains facts, figures, opinions, views, statements, recommendations, references, of the editors of omparashar.com, advertisers, third party information providers, organisations, and professionals. Omparashar does not represent or endorse the accuracy, completeness, reliability of any advice, opinion, statement, or any other information displayed, uploaded, distributed, linked through the web site. The user acknowledges that any reliance upon any such opinion, advice, statement, or information shall be at the user&apos;s risk.</li>
    <li>The information and data contained on &hellip;&hellip;&hellip;&hellip; website is to be treated purely for your entertainment purposes only. Any prediction or other message that you receive is not a substitute for advice, programs, or treatment that you would normally receive from a licensed professional such as a lawyer, doctor, psychiatrist, or financial advisor.</li>
    <li>Accordingly, &hellip;&hellip;&hellip;&hellip;..provides no guarantees, implied warranties, or assurances of any kind, and will not be responsible for any interpretation made or use by the recipient of the information and data mentioned above.</li>
    <li>Moreover, &hellip;&hellip;&hellip;&hellip;&hellip;&hellip;..is not a registered firm. It is a product of&hellip;&hellip;&hellip;&hellip;. All the transaction and gathered data is / will be accessed by &hellip;&hellip;&hellip;..</li>
    <li>You ought to understand and agree that all the Services by omparashar....are provided on the User&rsquo;s demand. Besides, omparashar....assumes no responsibility for availability (or lack thereof), deletions or failures to store any member communications or personalization settings. in particular, but not by way of limitation, omparashar.... may delete e-mail or other accounts or the information therein if the account is inactive for more than ninety (90) days the use, application and understanding of all the predictions and explanations is on the User&rsquo;s discretion, and the User shall be solely responsible for any eventuality, if any arises.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>WARRANTY:</strong></li>
    <li>Omparashar or any of the parties providing information to the system, makes no warranty or representation whatsoever regarding the web site or any content or products provided through or in connection with the web site. Omparashar disclaims any and all warranties, expressed or implied, including any warranty as to the availability, accuracy, completeness or content of information, products or services which are part of the web site omparashar.com.</li>
    <li>Omparashar also disclaims any or all warranties or fitness for a particular purpose, or merchantability or against infringement. In no event will Omparashar, its empolyees, agents, or contractors, be liable to you for any damages or losses, including without limitation indirect, consequential, special, incidental or punitive damages resulting from or caused by Omparashar, its , even if contents advise of the possibilities of such damages.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>AMENDMENTS AND ALTERATIONS:</strong></li>
    <li>These terms and conditions were updated as on &hellip;/&hellip;/&hellip;.., and &hellip;&hellip;&hellip;&hellip;&hellip; reserves all rights to change, modify or alter these terms and conditions at any point of time &nbsp;with or without notifying existing users and subscribers. If you do not agree to any new terms and conditions you should not use www.omparashar.com</li>
    <li>Paid users will be refunded the unexpired portions of their subscriptions on a pro rata basis if they send us an email within 72 hours of changes made in the terms and conditions.</li>
    <li>The references in these terms and conditions to &quot;Omparashar&quot; (or &quot;we&quot;, &quot;us&quot; or &quot;our&quot;) refer to the &nbsp;company &hellip;&hellip;&hellip;&hellip;.. or any associate/subsidiary concern that may be operating the website www.omparashar.com and its associate sites.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>PRIVACY POLICY AND REGISTRATION:</strong></li>
    <li>All information received by you from your use of the Omparashar sites will be used by Omparashar in accordance with our Privacy Policy.</li>
    <li>On registration, you must provide Omparashar with accurate, complete registration information and it is your responsibility to update and maintain changes to that information on the applicable Omparashar site registration pages. This is particularly important if you subscribe to any paid services or newsletters of Omparashar.</li>
    <li>Each registration is for a single user only. On registration, you will choose a user name and password (ID). Omparashar does not permit any other person sharing your user name and password or access through a single name and password being made available to multiple users on a network. Omparashar may cancel or suspend your access to the Omparashar sites if you do this without further obligation to you.</li>
    <li>Privacy of our customers and subsidiaries is very important to us, and thus is our Privacy Policy. This Privacy Policy may be updated from time to time, and, the changes shall be incorporated into these Terms of Service for reference.</li>
    <li>We may amend our Privacy Policy from time to time pursuant to the terms for amending this Terms of Service.</li>
    <li>The User hereby consents, expresses and agrees that the User has read and fully understand the Privacy Policy of the Website. The User further consents that the terms and contents of such Privacy policy is acceptable to the User inclusive of any update/alteration/change made and duly displayed on the Website.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>COMPLIANCE WITH THE LAWS:</strong></li>
    <li>You agree:</li>
    <li>To comply with all Indian laws regarding the transmission of technical data exported from India through our Services</li>
    <li>Not to use the Website or any of its Services for illegal purposes</li>
    <li>Not to interfere or disrupt networks connected to omparashar....</li>
    <li>To comply with all applicable regulations, policies and procedures of networks connected to the omparashar....website; and</li>
    <li>To comply with all applicable domestic and international laws, statutes, ordinances and regulations regarding your use of the Services.</li>
    <li>omparashar.... makes use of the Internet to send and receive certain messages.&nbsp;</li>
</ol>
<p><strong>Therefore, your conduct is subject to Internet regulations, policies, and procedures.&nbsp;</strong></p>
<ol>
    <li>You will not use omparashar....for chain letters, junk mail, spamming, or any use of distribution lists to any person who has not given specific permission to be included in such a process.&nbsp;</li>
    <li>You agree not to transmit through omparashar....any unlawful, harassing, libellous, abusive, threatening, harmful, vulgar, obscene or otherwise objectionable material of any kind or nature.&nbsp;</li>
    <li>You further agree not to transmit any material that encourages conduct that could constitute a criminal offence, give rise to civil liability, or otherwise violate any applicable local, state, national or international law or regulation.&nbsp;</li>
    <li>Attempts to gain unauthorized access to other computer systems/ servers are strictly prohibited. You shall not interfere with any other omparashar....members use or enjoyment of the Website or Services.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>BREACH AND TERMINATION:</strong></li>
    <li>The Website may, in whole or in part, without informing the User, modify, discontinue, change or alter the services ordered or the Account of the User registered with the Website. The Website may or may not issue notice or provide any reason for such action taken by the Website.</li>
    <li>Violation of any conditions mentioned in this Terms of Usage shall lead to immediate cancellation of the Registration of the User, if registered with the Website. The Website reserves right to terminate and initiate action immediately, if:</li>
    <li>The Website is not able to verify and authenticate the Registration data or any other relevant information provided by the User.</li>
    <li>The Website believes that the actions of the User may cause legal liability for the Website, other Users or any service provider linked with the Website.</li>
    <li>The Website believes that the User has provided the Website with false and misleading Registration Data or there is interference with the other Users or the administration of the services, or have violated the privacy policy as listed by the Website.</li>
    <li>For the Service Provider inclusive of the Astrologer, You understand and agree that your relationship with the Website is limited to being a member and You act exclusively on your own behalf and for your own benefit. The Website may terminate and de-activate the Profile of such service provider for any violation of the present terms of usage and the Service Terms and Conditions agreed upon between the parties while registration of the data by such Service Provider.</li>
    <li>omparashar.... may modify or discontinue (in whole or in part/s) any of its Services or your membership account, with or without notice, for any reason without any liability to you, any other user, or any third party.&nbsp;</li>
    <li>Without limiting the foregoing, we also reserve the right to terminate your account &nbsp; &nbsp; &nbsp; &nbsp;immediately&nbsp;</li>
</ol>
<p><strong>(a) if We are unable to verify or authenticate Your Registration Data or other information provided by you,&nbsp;</strong></p>
<p><strong>(b) if We believe that your actions may cause legal liability for you, &hellip;omparashar., or all or some of our other users, or&nbsp;</strong></p>
<p><strong>(c) if We believe you have &ndash; provided us with false or misleading Registration Data or other information, have interfered with other users or the administration of the Services, or have violated these Terms of Service or our&nbsp;</strong><strong>Privacy Policy.</strong></p>
<ol>
    <li><strong>COPYRIGHT AND TRADEMARKS:</strong></li>
    <li>Requests to republish or redistribute content should be addressed to Omparashar&apos;s syndication service team at info@omparahar.com. Links to Omparashar websites.</li>
    <li>If you would like to link to any of the Omparashar sites, please read and comply with the following guidelines and all applicable laws. A site or service that links to a Omparashar site:</li>
    <li>may display a Omparashar logo</li>
    <li>may not distort or alter the size or appearance of the logo;</li>
    <li>may link to the homepage of a Omparashar site or any other site Omparashar has given prior &nbsp;written permission;</li>
    <li>must not in any way imply that Omparashar is endorsing it or its products or services;</li>
    <li>must not misrepresent its relationship with Omparashar or present false information about Omparashar;</li>
    <li>must not be a site or service that infringes any intellectual property or other right of any &nbsp; person or that otherwise does not comply with all relevant laws and regulations;</li>
    <li>*must not be a site or service that contains content that could be construed as distasteful or offensive to public morality and ethics.</li>
</ol>
<p><strong>Omparashar expressly reserves the right to require that any link in breach of these terms and conditions be removed and to take whatever other action it deems appropriate.</strong></p>
<ol>
    <li><strong>CHOICE OF LAW AND JURISDICTION:</strong></li>
    <li>These terms and conditions shall be governed by, and construed in accordance with laws of the Government of India.&nbsp;</li>
    <li>Users of Omparashar websites also explicitly agree that only courts in India shall have exclusive jurisdiction to settle any dispute which may arise out of, under, or in connection with these terms and conditions, and for those purposes irrevocably submit all disputes to the jurisdiction of Indian courts.</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>ALTERNATE DISPUTE RESOLUTION:</strong></li>
    <li>Any dispute, claim or controversy arising out of or relating to this Terms of Usage including the determination of the scope or applicability of this Terms of Usage to arbitrate, or your use of the Application or information to which it gives access, shall be determined by arbitration in India, before a sole arbitrator mutually appointed by Members and Website. Arbitration shall be conducted in accordance with the Arbitration and Conciliation Act, 1996. The seat of such arbitration shall be New Delhi. All proceedings of such arbitration, including, without limitation, any awards, shall be in the English language. The award shall be final and binding on the parties to the dispute. If the parties want to settle the dispute amicably instead of approaching the Court of Law.</li>
    <li>Notwithstanding the foregoing, either party has the right to seek any interim or preliminary relief from a court of competent jurisdiction in New Delhi in order to protect the rights of such party pending the completion of any arbitration hereunder, and both parties agree to submit to the exclusive jurisdiction of the courts of India and venue in New Delhi for any such proceeding. If either party files an action contrary to this provision, the other party may recover attorneys&apos; fees and costs up to One Lakh Rupees INR.</li>
    <li>These Terms of Usage shall be governed by and construed in accordance with the laws of India without giving effect to any choice of law and principles that would require the application of the laws of a different state. If for any reason a court of competent jurisdiction finds any provision or portion of these Terms of Usage or Privacy Policy to be unenforceable or invalid, such provision shall be changed and interpreted so as to best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law, and the remainder of the Terms of Usage or Privacy Policy, as applicable, will continue in full force and effect.&nbsp;</li>
    <li>Headings are for reference purposes only and in no way define, limit, construe, or describe the scope or extent of such section. Any waiver of any provision of the Terms of Usage shall be effective only if in writing and signed by omparashar. These Terms of Usage constitute the entire agreement between the parties with respect to the subject matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.</li>
    <li>These Terms of Usage and your use of the Services will be interpreted in accordance with the laws of India excluding its rules on conflicts of laws.&nbsp;</li>
</ol>
<p><br/></p>
<ol>
    <li><strong>LIMITATION OF LIABILITY:</strong></li>
    <li>The User expressly understands and agree that, to the maximum extent permitted by applicable law, the Website does not provide warranties for the service. Astrological counselling provided through the Website is based on cumulative or individual knowledge, experience and interpretations of astrologers and as such, it may vary from one astrologer to another.</li>
    <li>The Website is offering services through a diverse panel of Astrologers duly verified by the Website and such Service Provider (Astrologer) may from time to time make recommendations of using mantras, jantras, gemstones or other astrological remedies to be used by User. Such recommendations are being made in good faith by the astrologers and the Website and its subsidiaries, affiliates, officers, employees, agents, partners, and licensors make no warranty that:</li>
    <li>the service will meet your requirements</li>
    <li>the service will be uninterrupted, timely, secure or error - free</li>
    <li>the results that may be obtained from the use of the service will be accurate or reliable</li>
    <li>the quality of any products, services, information or other material purchased or obtained by you through the service will meet your expectations and</li>
    <li>any errors in the software will be corrected. You are required to make full disclosure about the emotional, mental and physical state of the person seeking advice from the panel of astrologers of Website so that the astrologers make an informed judgment about giving advice.</li>
    <li>The Website, services and other materials are provided by the Website on an &quot;as is&quot; basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of title, non-infringement, merchantability or fitness for a particular purpose.&nbsp;</li>
    <li>To the maximum extent permitted by applicable law, the Website will have no liability related to User content arising under intellectual property rights, libel, privacy, publicity, obscenity or other laws. The Website also disclaims all liability with respect to the misuse, loss, modification or unavailability of any User content.</li>
    <li>The Website will not be liable for any loss that the User may incur as a consequence of unauthorised use of their account or account information in connection with the Website or any services or materials, either with or without the User&rsquo;s knowledge.&nbsp;</li>
    <li>The Website has endeavoured to ensure that all the information on the Website is correct, but the Website neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information, product or service. The Website shall not be responsible for the delay or inability to use the Website or related functionalities, the provision of or failure to provide functionalities, or for any information, software, products, functionalities and related graphics obtained through the Website, or otherwise arising out of the use of the Website, whether based on contract, tort, negligence, strict liability or otherwise.&nbsp;</li>
    <li>Further, the Website shall not be held responsible for non-availability of the Website during periodic maintenance operations or any unplanned suspension of access to the Website that may occur due to technical reasons or for any reason beyond the Website&apos;s control.</li>
    <li>The User understands and agrees that any material or data downloaded or otherwise obtained through the Website is done entirely at their own discretion and risk and they will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material or data. The Website is not responsible for any typographical error leading to an invalid coupon. The Website accepts no liability for any errors or omissions, with respect to any information provided to you whether on behalf of itself or third parties.</li>
    <li>The services may consist of the following, without limitation: Astrological content, Reports, Tarot readings, fortunes, numerology, predictions, live telephone consultations, email consultations or products sold throughomparashar Shop. omparasharcharges for the chat/call service offered on this platform on per minute basis and holds no responsibility or liability about the reality or reliability of the astrological effects on the human physiology, by the gems, any other products or services represented and sold on the website. No advice or information, whether oral or written, obtained by you shall create any warranty.</li>
    <li>The advisors/consultants/astrologers are also members of the site and not employees of the Website or the company. However, the Website verifies the degrees, qualifications, credentials, and background of the advisors/consultants/astrologers but does not refer, endorse, recommend, verify, evaluate or guarantee any advice, information or other services provided by the advisors/consultants/astrologers or by the company, nor does it warrant the validity, accuracy, completeness, safety, legality, quality, or applicability of the content, anything said or written by, or any advice provided by the advisors/consultants/astrologers.</li>
    <li>Notwithstanding anything to the contrary contained herein, creates liability to you for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to the Website, for the service during the term of membership.</li>
    <li>In no event will omparashar.... or its suppliers be liable for any indirect, incidental, special, consequential or punitive damages of any kind, including but not limited to damages for loss of profits and the cost of procurement of substitute goods and services (collectively, indirect damages) arising out of or in connection with our website, the services, our privacy policy, or these terms of service (however arising, including negligence), even if omparashar....had been advised of the possibility of such damages. without limiting the foregoing, omparashar.... will not be liable for indirect damages arising out of or in connection with</li>
</ol>
<p><strong>You hereby acknowledge that any use of this website or related content or service is at your sole discretion. Also by proceeding to access the Web Site or related content or service , you will be assuming all the risks associated with the use of the web site, including risk of your computer, software or data being damaged by any virus that might be transmitted or activated via this site or your access to it.</strong></p>
<ol>
    <li><strong>NOTICES:</strong></li>
</ol>
<p><strong>Except as otherwise stated in these Terms of Service, all notices to a party shall be in writing and shall be made either via email or snail mail. Notice shall be deemed given 24 hours after an email is sent, or 3 days after deposit in the snail mail, to Member at the address provided by Member in the Registration Data and to the Website at the address set forth below:</strong></p>
<p><strong>Angee Bridging India Private Limited.India&rdquo;</strong></p>
<ol>
    <li><strong>THIRD PARTY SITES AND SERVICES:</strong></li>
    <li>The Omparashar sites may contain links to other Internet websites or online and mobile services provided by independent third parties (&quot;Third party sites&quot;), either directly or through frames.&nbsp;</li>
    <li>Third party sites may be co-branded with Omparashar and so include Omparashar&apos;s trade mark. Omparashar is not responsible for the availability or content of Third party sites and will not be a party to, or in any way responsible for, any transaction concerning goods or services available from such Third party sites.&nbsp;</li>
    <li>If you purchase products or services from a Third party site your contract for such products or services will be with the third party and not with Omparashar. Our privacy policy does not apply to Third party sites.</li>
    <li>The Omparashar sites contain advertising and sponsorship. Advertisers and sponsors are responsible for ensuring that material submitted for inclusion on the Omparashar sites comply with international and national law. Omparashar will not be responsible for any error or inaccuracy in advertising or sponsorship material.</li>
    <li><strong>ASSIGNMENT &amp; LICENSING:</strong></li>
</ol>
<p><strong>You may not assign, sub-license or otherwise transfer any of your rights under these terms and conditions. If any provision of these terms and conditions is found to be invalid by any Indian court having competent jurisdiction, the invalidity of that provision will not affect the validity of the remaining provisions of these terms and conditions, which shall remain in full force and effect.</strong></p>
<ol>
    <li><strong>WAIVER</strong></li>
</ol>
<p><strong>Failure by either party to exercise any right or remedy under these terms and conditions does not constitute a waiver of that right or remedy. Headings in these terms and conditions are for convenience only and will have no legal meaning or effect.</strong></p>
<p><br/></p>
<ol>
    <li><strong>SUBSCRIPTION AND COSTS:</strong></li>
</ol>
<p><strong>I Omparashar does not provide refunds for reports that have been generated. In case of errors on the part of Omparashar, a corrected report will be sent to the purchaser on receipt of the compliant by the purchaser to the Omparashar support team via telephone or email.</strong></p>
<p><strong>In the rare instance, of the purchaser not receiving the reports in the maximum time specified in the &apos;Delivery of Services&apos; section above, a refund will be provided to the purchaser within 2 (two) working days on receipt of the compliant by the purchaser to the Omparashar support team via telephone or email.</strong></p>
<p><br/></p>
<ol>
    <li><strong>COSTS</strong></li>
</ol>
<p><strong>The refunded amount will be the full cost (100% of the price) of the specific report that was not received by the purchaser. Omparashar will not be liable for any additional costs.</strong></p>
<ol>
    <li><strong>SUBSCRIPTION SERVICES</strong></li>
</ol>
<p><strong>You agree to pay any subscription fees at the rates in effect when the charges are incurred.</strong></p>
<p><strong>Certain areas of the Omparashar sites are only accessible to users who pay to subscribe. There are two levels of subscription - free content for registered users, which mostly contains content up to 72 hours old; and paid subscription, which allows you complete access to relevant content, including all content in our archives, in the section of choice or the entire website, or through newsletters.</strong></p>
<ol>
    <li><strong>CANCELLING OR CHANGING YOUR ONLINE SUBSCRIPTION:&nbsp;</strong></li>
</ol>
<p><strong>When you subscribe we may give you a free trial. You may cancel your subscription at any time during any trial period by informing the webmaster though an email. (webmaster@omparasharsoftware.com) and by clicking on the &quot;UNSUBSCRIBE&quot; link. Omparashar will refund you the unexpired portion of the subscription on a pro rata basis. This means that Omparashar will refund you or credit your account with any amounts you have paid us in advance that relate to any remaining period of your subscription. Any cancellation will be effective only once Omparashar receives notice of it.</strong></p>
<p><br/></p>
<ol>
    <li><strong>DELIVERY, CANCELLATION AND REFUND:</strong></li>
    <li>No refund shall be processed on the order of any reports under any circumstances if the order has reached the &ldquo;processing&rdquo; (Assigned to an Astrologer) stage. The risk and liability of placing order in a haste and careless manner totally lies with the User and the Website is not responsible for any refund once the processing stage has started.</li>
    <li>No refund shall be processed once the Order has been placed and executed. However, if the User intends to cancel a successfully placed order before execution, the User is required to contact the customer care team within 1 (one) hour of making the payment, thereafter it is totally at the discretion of the Website whether to issue refund.</li>
    <li>Any technical delay or glitch reported in the Website during the processing of the request which includes generating reports by the service provider i.e. Astrologer shall not be eligible for claiming refund. The User agrees that the timelines are approximate and all essentials steps would be taken to adhere to the timelines as displayed.</li>
    <li>No refund shall be processed for the reason that in-correct information or data has been provided by Yourself. The User agrees to be careful while providing any information to the Website and must re-check the information filled before clicking on &ldquo;Submit&rdquo;. The User can request for change in the in-correct information or data entered provided, the request for such change has been made with the customer care within 1 (one hour) of execution of the service rendered by the service provider.</li>
    <li>No refund shall be processed for return of any damaged product. The User undertakes and agrees that by ordering any product as displayed on the Website, the Registered User shall be fully responsible for any damaged caused to the product, post its delivery. For orders made via &ldquo;Cash on Delivery&rdquo; method of payment, the User shall be charged for the cost of the product as displayed by the Website and the shipping/custom/courier charges as applicable, if the product is returned.</li>
</ol>
<p><br/></p>
<ol>
    <li>Refund on pro-rata basis may be considered for any delay in the activation of the subscription services and any damage that may be caused to the product while in transit shall be dealt by the Website and its agencies.</li>
    <li>You agree that the display picture for the products listed for purchase by the User are for reference purpose only and the Website will try to deliver the product ordered in an as-is condition as displayed on the Website. The User is advised to exercise discretion in such case and no refund shall be issued on such grounds.</li>
    <li>The services offered and the products sold are strictly not meant to replace any philosophical, emotional or medical treatment. The Website holds no responsibility or liability about the reality or reliability of the astrological effects on the human physiology, by the gems represented and sold on the Website. The placing of order for buying such products or taking the services is solely on the discretion and will of the User and the Website does not have any responsibility upon the products sold. The User is advised to exercise discretion in such case and no refund shall be issued on such grounds.</li>
    <li>No refund shall be processed for providing a wrong contact number for the purpose of availing the &ldquo;Call with Astrologer&rdquo; feature. The User once opted for this feature is advised to keep the Contact Number in full coverage area and must answer the call when received. No refund shall be processed for any call which gets connected.</li>
    <li>The refunds, if any, shall be processed after deduction of the transaction charges levied by the Bank and/or the Payment Gateway, to &amp; fro cost of the shipping and/or courier charges (With regard to purchase of a product listed on the Website), customs duty (if levied) and/or any other charges that may have been incurred by the Website during processing and/or delivering the service, as applicable.</li>
</ol>
<p><br/></p>
<ol>
    <li>In case the Website or Payment gateway&rsquo;s webpage, that is linked to the Website, is experiencing any server related issues like &lsquo;slow down&rsquo; or &lsquo;failure&rsquo; or &lsquo;session timeout&rsquo;, the User shall, before initiating the second payment, check whether his/her Bank Account has been debited or not and accordingly resort to one of the following options:</li>
    <li>In case the Bank Account appears to be debited, ensure that you do not make the payment twice and immediately thereafter contact the Website via customer care to confirm payment.</li>
    <li>In case the Bank Account is not debited, the User may initiate a fresh transaction to make payment.</li>
    <li>However, refund for multiple payment, if any, even after the above precaution against the same order shall be refunded in full without deduction of the transaction charges as mentioned above. The Website shall only retain the cost of one single order as intended to be placed by the User.</li>
    <li>If there are orders that the Website is unable to accept and must cancel, the Website at its sole discretion, reserves the right to refuse or cancel any order for any reason whatsoever. Some situations may result in the order being cancelled and include, without limitation, non-availability of the service, inaccuracy, error in pricing information or other problems as identified. If the User&rsquo;s order is cancelled after charges being paid against the said service, the said amount paid for booking shall be refunded.</li>
    <li>By requesting for refund, user is agreeing to provide omparashar&rsquo;s quality audit team permission to access the chat/call recording of the consultation for which refund has been requested, in order to determine whether the case is eligible for refund or not.</li>
</ol>
<p><br/></p>
<ol>
    <li>omparashar quality audit team, at best effort basis, provide partial/full refunds to the users in their omparashar wallet wherever consultant&rsquo;s quality parameters are not satisfied. It can take upto 72 hours to analyse and refund the amount back to omparashar wallet</li>
</ol>
<p><strong>Note: All refunds will be credited to user&rsquo;s omparashar wallet.</strong></p>
<ol>
    <li>Refunds will only be considered in the following cases:</li>
    <li>Network issue due to which chat/call was affected in between or there was a weak signal, background noise, inaudible consultant etc during the video/normal call sessions</li>
    <li>Consultant is unable to respond fluently in the language mentioned in their profile</li>
    <li>Consultant is taking inordinately long enough to respond back to the user</li>
    <li>Consultant has responded back in irrelevant or inappropriate response to the query asked by the user</li>
</ol>
<p><strong>Please Note: No refund will be given in case of lack of accuracy of any consultation. omparashar takes no responsibility for factual accuracy on any consultations.</strong></p>
<ol>
    <li><strong>COMMUNICATION:</strong></li>
    <li>You hereby expressly allow &hellip;&hellip;&hellip; and/or its authorized personnel to communicate to you through any telecom resources in the registered number provided by you to explain, explicate and clarify the various services provided by omparashar and to assist, aid or support you in availing the said services of omparashar.</li>
    <li>If at any time, you wish to discontinue receiving the communications (including, but not limited to emails, sms and phone calls) from omparashar, you may by email to support@omparashar.com indicate the same to omparashar and/or its authorised personnel regarding such discontinuance.</li>
</ol>
<p><br/></p>
<ol>
    <li>You represent that you or the mobile number submitted by you is not registered with the Do Not Disturb / National Customer Preference Register and/or you have already changed such registration to allow omparashar to contact you on your mobile number.</li>
    <li>Further and in any event, you do hereby unconditionally agree and undertake that this invitation and solicitation shall supersede any preferences set by you with or registration done with the Do Not Disturb (&quot;DND Register&quot;)/ National Customer Preference Register (&quot;NCPR&quot;).</li>
    <li>Without prejudice to the aforesaid and in any event, by expressly inviting the services from omparashar, you also unconditionally agree that your rights under the Telecom Commercial Communications Customer Preference Regulations, 2010 or any subsequent amendments thereto or under NCPR, are kept in abeyance or remain extinguished till you expressly communicate for discontinuation of relationship.</li>
    <li>You also unconditionally agree to indemnify omparashar against all losses, damages, penalties, costs or consequences whether direct or indirect, that may arise out of any breach or violation of the aforesaid representation, commitment and undertaking.</li>
    <li>When you visit omparashar.com or send e-mails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by e-mail or by posting notices on this site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</li>
    <li><strong>FORCE MAJURE:</strong></li>
</ol>
<p><strong>Notwithstanding anything to the contrary contained herein, the Rights Agent shall not be liable for any delays or failures in performance resulting from acts beyond its reasonable control including, without limitation, acts of God, terrorist acts, shortage of supply, breakdowns or malfunctions, interruptions or malfunction of computer facilities, or loss of data due to power failures or mechanical difficulties with information storage or retrieval systems, labour difficulties, war, or civil unrest.</strong></p>
<ol>
    <li><strong>MISCELLANEOUS:</strong></li>
</ol>
<p><strong>From time to time&nbsp;</strong>www.omparashar.com<strong>may run competitions, prize schemes, promotions and surveys on all its websites. These may be subject to additional terms and conditions that will be specified at the time they are run.</strong></p>
<ol>
    <li><strong>OTHER INFORMATION:</strong></li>
</ol>
<p><strong>Without limiting the above, Omparashar is not liable for matters beyond its reasonable control. Omparashar does not control telephones, third party communications networks (including your Internet Service Provider) or the Internet or the acts of third parties.</strong></p>
<p style={{textAlign:"center"}}><strong>*****************</strong></p>

                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default TermsConditions;
