import React from "react";
import { useTranslation } from "react-i18next";
const SamvatsaraHTML = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <table
        className="tablesaw table mb-0 tablesaw-stack"
        id="tablesaw-802"
        style={{ border: "none", borderTop: "none" }}
      >
        <tbody>
          <tr className="table_head_tr" style={{}}>
            <th scope="col" colSpan="5" className="sectionheader">
              {t("Samvatsara")}
            </th>
          </tr>
        </tbody>
      </table>
      <table
        className="tablesaw table mb-0 tablesaw-stack"
        id="tablesaw-802 example-collapse-text"
        style={{ border: "none", borderTop: "none" }}
      >
        <tbody id="example-collapse-text">
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Shaka Samvatsara")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.samvatsara}</span>
            </td>
            <td className="td3">
              <span className="tablelabel"></span>
            </td>
            <td className="td4">
              <span className="tablevalue"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default SamvatsaraHTML;
