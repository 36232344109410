import React from "react";
import "./dash-panchang.css";
import { useTranslation } from "react-i18next";
import ImageMapper from "./ImageMapper";

const RashiHTML = (props) => {
  const { t, i18n } = useTranslation();
  function extract_image_link(name) {
    let imglink = "../../assets/Zodiac/";
    return imglink + ImageMapper[name] + ".svg";
  }
  function extract_image_link_Nakshatra(name) {
    let imglink = "../../assets/Nakshatra/";
    console.log(name);
    return imglink + ImageMapper[name] + ".svg";
  }
  return (
    <>
      <table
        className="tablesaw table mb-0 tablesaw-stack"
        id="tablesaw-802"
        style={{ border: "none", borderTop: "none" }}
      >
        <tbody>
          <tr className="table_head_tr" style={{}}>
            <th scope="col" colSpan="5" className="sectionheader">
              {t("Rashi")}
            </th>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel"> {t("Sunsign")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">
                {
                  <img
                    className="rashiImages"
                    alt={props.sunsign}
                    src={extract_image_link(props.sunsign)}
                  ></img>
                }
                {props.sunsign}
              </span>
            </td>
            <td className="td3">
              <span className="tablelabel"> {t("MoonSign")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">
                {
                  <img
                    className="rashiImages"
                    alt={props.moonsign}
                    src={extract_image_link(props.moonsign)}
                  ></img>
                }
                {props.moonsign}
              </span>
            </td>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel"> {t("Surya Nakshtra")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">
                {
                  <img
                    className="rashiImages"
                    alt={props.suryanakshtra}
                    src={extract_image_link_Nakshatra(props.suryanakshtra)}
                  ></img>
                }
                {props.suryanakshtra}
              </span>
            </td>
            <td className="td3">
              <span className="tablelabel"> {t("Ascendant")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">
                {
                  <img
                    src="../../assets/Zodiac/Libra.svg"
                    alt="Sunrise"
                    className="rashiImages"
                  />
                }
                {props.ascendantsun}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default RashiHTML;
