import React, { Component } from "react";
import * as d3 from "d3";
import { GlobalContext } from "../../../mycontext";
import { withTranslation } from "react-i18next";
import {getQueryParam} from '../../utils/utility';
const circlesMapper = {
  circleFirst: ["#circle_id0", "Sun"],
  circleSecond: ["#circle_id1", "Moon"],
  circleThird: ["#circle_id2", "Mars"],
  circleFourth: ["#circle_id3", "Mercury"],
  circleFifth: ["#circle_id4", "Jupiter"],
  circleSixth: ["#circle_id5", "Venus"],
  circleSeven: ["#circle_id6", "Saturn"],
};

const planetIdMapper = {
  Sun: "#circle_id0",
  Moon: "#circle_id1",
  Mars: "#circle_id2",
  Mercury: "#circle_id3",
  Jupiter: "#circle_id4",
  Venus: "#circle_id5",
  Saturn: "#circle_id6",
  रवि: "#circle_id0",
  चन्द्र: "#circle_id1",
  मंगल: "#circle_id2",
  बुध: "#circle_id3",
  गुरु: "#circle_id4",
  शुक्र: "#circle_id5",
  शनि: "#circle_id6",
};

const ColorMapper = {
  Friend: "#32cd32",
  Enemy: "#ff5252",
  Bitter: "#cc0000",
  Intimate: "#008000",
  Neutral: "#808080",
  मित्र: "#32cd32",
  शत्रु: "#ff5252",
  अधिशत्रु: "#cc0000",
  अधिमित्र: "#008000",
  सम: "#808080",
};

const Planets = [
  "Sun",
  "Moon",
  "Mars",
  "Mercury",
  "Jupiter",
  "Venus",
  "Saturn",
  "रवि",
  "चन्द्",
  "मंगल",
  "बुध",
  "गुरु",
  "शुक",
  "शनि",
];

const relationMap = {
  1: "permanent_relations",
  2: "temporary_relations",
  3: "five_fold_relations",
};
class PlanetD3Reation extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.d3_RelationGraph = React.createRef();
    this.state = {
      horoscopeData:
        JSON.parse(window.localStorage.getItem("horoscopeData")) || null,
      bye: "hi",
    };
    this.draw_outer_circle = this.draw_outer_circle.bind(this);
  }

  componentDidMount() {
    this.draw_outer_circle();

  }
  componentWillMount() {
    let queryString = getQueryParam('cb');
    if(queryString){
      this.setState({horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
    }

  }
  componentDidUpdate(prevProps) {
    if (prevProps.horoscopeData !== this.props.horoscopeData) {
      d3.select(".fivefold").remove();
      this.setState({ horoscopeData: this.props.horoscopeData });
      this.draw_outer_circle();
    } else if (prevProps.value !== this.props.value) {
      d3.select(".fivefold").remove();
      this.draw_outer_circle();
    }
  }

  draw_outer_circle() {
    var xview = 100;
    var yview = 50;
    var planets = this.props.planets;
    var width = this.props.width,
      height = this.props.height,
      radius = Math.min(width, height);
    var spacetime = d3
      .select(this.d3_RelationGraph.current)
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("class", "ccc_class_1")
      .attr("viewBox", xview + " " + yview + " " + width + " " + height + "");

    var jsonCircles = [
      {
        x_axis: 30,
        y_axis: 30,
        radius: this.props.radius,
        color: "white",
        class: "circleFirst",
      },
      {
        x_axis: 70,
        y_axis: 70,
        radius: this.props.radius,
        color: "#92C47C",
        class: "circleSecond",
      },
      {
        x_axis: 110,
        y_axis: 100,
        radius: this.props.radius,
        color: "#666666",
        class: "circleThird",
      },
      {
        x_axis: 130,
        y_axis: 80,
        radius: this.props.radius,
        color: "#FF0100",
        class: "circleFourth",
      },
      {
        x_axis: 230,
        y_axis: 70,
        radius: this.props.radius,
        color: "#37761D",
        class: "circleFifth",
      },
      {
        x_axis: 330,
        y_axis: 50,
        radius: this.props.radius,
        color: "#92C47C",
        class: "circleSixth",
      },
      {
        x_axis: 330,
        y_axis: 50,
        radius: this.props.radius,
        color: "#CC0200",
        class: "circleSeven",
      },
    ];

    var radii = {
      sun: radius / 6,
      earthOrbit: radius / 2.5,
      earth: radius / 15,
      moonOrbit: radius / 16,
      moon: radius / 96,
    };

    var svg22 = spacetime
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("class", "fivefold")
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    var orbit = svg22
      .append("g")
      .attr("id", "outerC")
      .append("circle")
      .attr("class", "earthOrbit")
      .attr("r", radii.earthOrbit)
      .style("fill", "none")
      .style("stroke", "#bababa")
      .style("stroke-width", "10");

    function getCirclePoints(points, radius, center) {
      var circlePositions = [];
      var slice = (2 * Math.PI) / points;
      for (var i = 0; i < points; i++) {
        var angle = slice * i + 180.65;
        //console.log("angle = " + angle);
        var newX = center.X + radius * Math.cos(angle);
        var newY = center.Y + radius * Math.sin(angle);

        circlePositions.push({
          x_axis: newX,
          y_axis: newY,
        });
      }
      return circlePositions;
    }

    var circlePositions = getCirclePoints(7, radii.earthOrbit, {
      X: 0,
      Y: 0,
    });

    for (var i = 0; i < jsonCircles.length; i++) {
      jsonCircles[i].x_axis = circlePositions[i].x_axis;
      jsonCircles[i].y_axis = circlePositions[i].y_axis;
    }

    let click = (circle) => {
      svg22.selectAll("line").remove();

      let planetName = this.props.t(circlesMapper[circle.class][1]);

      let reqData =
        this.state.horoscopeData.planetary_relationship[
          relationMap[this.props.value]
        ];
      let finalArray = [];
      let smallArray = [];
      for (let i in reqData) {
        if (reqData[i].main_planet_name === planetName) {
          for (let j in reqData[i].relation_with_others) {
            smallArray = [];
            if (reqData[i].relation_with_others[j].planet_name === planetName)
              continue;
            else
              smallArray.push(
                reqData[i].relation_with_others[j].relationship_value
              );
            smallArray.push(reqData[i].relation_with_others[j].planet_name);
            finalArray.push(smallArray);
          }
        }
      }

      d3.select("#groupOfCircles")
        .selectAll("circle")
        .filter((d) => d.class !== circle.class)
        .each((d, i) => {
          //this calculTION ISTO SHORTEN line connecting planets
          var r2 = radii.earthOrbit / 4.15;
          var dx = d.x_axis - circle.x_axis;
          var dy = d.y_axis - circle.y_axis;
          var mag = Math.hypot(dx, dy);

          var x1 = circle.x_axis + (r2 * dx) / mag;
          var y1 = circle.y_axis + (r2 * dy) / mag;

          var x2 = d.x_axis - (r2 * dx) / mag;
          var y2 = d.y_axis - (r2 * dy) / mag;

          // console.log(x1 +"--"+ y1 +"-"+ x2 + y2);

          svg22
            .append("line")
            .style("stroke", getcolour(circle.class, i, finalArray[i]))
            .style("stroke-width", 5)
            // .attr("x1", circle.x_axis)
            // .attr("y1", function (d,i) {
            //     console.log(circle.class)
            //     var yaxis = circle.y_axis;
            //     if(circle.class == "circleFirst"){
            //         yaxis = circle.y_axis +5;
            //     }
            //     return yaxis; })
            // .attr("x2", d.x_axis)
            // .attr("y2", d.y_axis)
            .attr("x1", x1)
            .attr("y1", y1)
            .attr("x2", x2)
            .attr("y2", y2)
            .attr("class", circle.class + i);
        });
    };
    //get color lines
    let getcolour = (planet_name_count, relationCount, color) => {
      // check which planet is clicked and what its relation with other planets with line number
      // clock-wise planet placed
      // planet clicked is SUN

      //change SUN colour
      for (let i of Planets) {
        if (i !== this.props.t(circlesMapper[planet_name_count][1])) {
          let node = "#" + i;

          d3.select(node).style("fill", "white");
        }
      }
      let node = "#" + circlesMapper[planet_name_count][1];
      d3.select(circlesMapper[planet_name_count][0]).style("fill", "white");
      d3.select(node).style("fill", "black");
      //d3.select('#circle_id1').style("fill", "red");
      if (relationCount == "0") {
        // console.log(color[1], planetIdMapper[color[1]], ColorMapper[color[0]]);
        d3.select(planetIdMapper[color[1]]).style(
          "fill",
          ColorMapper[color[0]]
        );
        return ColorMapper[color[0]];
      }
      if (relationCount == "1") {
        // console.log(color[1], planetIdMapper[color[1]], ColorMapper[color[0]]);
        d3.select(planetIdMapper[color[1]]).style(
          "fill",
          ColorMapper[color[0]]
        );
        return ColorMapper[color[0]];
      }
      if (relationCount == "2") {
        // console.log(color[1], planetIdMapper[color[1]], ColorMapper[color[0]]);
        d3.select(planetIdMapper[color[1]]).style(
          "fill",
          ColorMapper[color[0]]
        );
        return ColorMapper[color[0]];
      }
      if (relationCount == "3") {
        // console.log(color[1], planetIdMapper[color[1]], ColorMapper[color[0]]);
        d3.select(planetIdMapper[color[1]]).style(
          "fill",
          ColorMapper[color[0]]
        );
        return ColorMapper[color[0]];
      }
      if (relationCount == "4") {
        // console.log(color[1], planetIdMapper[color[1]], ColorMapper[color[0]]);
        d3.select(planetIdMapper[color[1]]).style(
          "fill",
          ColorMapper[color[0]]
        );
        return ColorMapper[color[0]];
      }
      if (relationCount == "5") {
        // console.log(color[1], planetIdMapper[color[1]], ColorMapper[color[0]]);
        d3.select(planetIdMapper[color[1]]).style(
          "fill",
          ColorMapper[color[0]]
        );
        return ColorMapper[color[0]];
      }
    };

    var earthLayer = svg22
      .append("g")
      .classed("earthLayer", true)
      .attr("id", "groupOfCircles");

    var circles = earthLayer
      .selectAll("circle")
      .data(jsonCircles)
      .enter()
      .append("circle")
      .style("stroke", "#bababa")
      .style("z-index", "100")
      .style("stroke-width", "6")
      .style("cursor", "pointer");

    var circleAttributes = circles
      .attr("id", function (d, i) {
        return "circle_id" + i;
      })
      .attr("cx", function (d) {
        return d.x_axis;
      })
      .attr("cy", function (d) {
        return d.y_axis;
      })
      .attr("r", function (d) {
        return d.radius;
      })
      .attr("class", function (d) {
        return d.class;
      })
      .style("fill", function (d) {
        return d.color;
      })

      .on("click", click);

    //text
    var textLayer = svg22.append("g").classed("textLayer", true);
    var texts = textLayer
      .selectAll("text")
      .data(circlePositions)
      .enter()
      .append("text")
      .style("fill", function (d, i) {
        if (i === 0) {
          return "black";
        } else {
          return "white";
        }
      })
      .attr("x", function (d) {
        return d.x_axis;
      })
      .attr("dx", -radii.earth / 4)
      .attr("y", function (d) {
        return d.y_axis;
      })
      .attr("id", function (d, i) {
        return Object.keys(planetIdMapper)[i];
      })
      .text(function (d, i) {
        if (i === 0) return planets[0];
        else if (i === 1) return planets[1];
        else if (i === 2) return planets[2];
        else if (i === 3) return planets[3];
        else if (i === 4) return planets[4];
        else if (i === 5) return planets[5];
        else if (i === 6) return planets[6];
      });
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <span className="spancolor">{t("Friend")}</span>
        <span
          className="color-box"
          style={{ backgroundColor: "#32cd32" }}
        ></span>
        <span className="spancolor">{t("Intimate")}</span>
        <span
          className="color-box"
          style={{ backgroundColor: "#008000" }}
        ></span>
        <span className="spancolor">{t("Neutral")}</span>
        <span
          className="color-box"
          style={{ backgroundColor: "#808080" }}
        ></span>
        <span className="spancolor">{t("Bitter")}</span>
        <span
          className="color-box"
          style={{ backgroundColor: "#cc0000" }}
        ></span>
        <span className="spancolor">{t("Enemy")}</span>
        <span
          className="color-box"
          style={{ backgroundColor: "#ff5252" }}
        ></span>
        <div ref={this.d3_RelationGraph}>{/* <p>qwerty</p> */}</div>
      </>
    );
  }
}

export default withTranslation()(PlanetD3Reation);
