import React, {useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import DashboardTopBar from "../DashBoardComponents/DashboardTopBar";
import {useTranslation} from "react-i18next";
import {getGender} from "../../API/CelebrityManagement_API";
import CelebrityUserSidebar from "../DashBoardComponents/DashboardSideBarTypes/CelebrityUserSideBar";
// import DisplayMatches from "./displayMatches";
// import displayResults from "./QueryBuilder/displayResults";
// import AutoCompleteCountry from "../CelebrityManagement/Autosuggests/AutoCompleteCountry";
import DisplayResults from "./QueryBuilder/displayResults";
import "./basicsearch.css";
// import Results from "./results";

const QueryPage = (props) => {
    const {t, i18n} = useTranslation();
    const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const [inputCategory, setinputCategory] = useState(localStorage.getItem("category_user")||"1");
    const [page_number, setPageNumber] = useState(1)
    const [query, setQuery] = useState(JSON.parse(localStorage.getItem("basicQuery"))||{})
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("basicQueryFilters"))||{gender:undefined, country: undefined, from_year: undefined,to_year:undefined})
    //will store selected filters till "apply filters" button is clicked
    const [tempfilters, setTempFilters] = useState(JSON.parse(localStorage.getItem("basicQueryFilters"))||{gender:undefined, country: undefined, from_year: undefined,to_year:undefined})
    const [nativeName, setNativeName] = useState(query.native_name||"")
    const [selectedAlphabet, setSelectedAlphabet] = useState(query.alphabet||"Select Alphabet")
    const [allGenders, setAllGenders] = useState([])
    const [showFilters,setShowFilters] = useState(!!parseInt(localStorage.getItem("basicMatchesCount")))
    const [resultsDiv,setResultsDiv]=useState("")
    const yearArray = [];

    for (let x = 1900; x <= 2399; x++) {
        yearArray.push(x)
    }
    // useEffect(()=>{
    //     // console.log('filter changed',filters)
    //     localStorage.setItem("basicQueryFilters",JSON.stringify(temp));
    // },[filters])

    const handleCategorychange = (value) => {
        setinputCategory(value);
        localStorage.setItem("category_user", value);

    }
    useEffect(() => {
        const c = async () => {
            var data = await getGender();
            setAllGenders(data);
            // console.log(allGenders)
        }
        c();
    }, [])
    useEffect(() => {
    }, [page_number])
    useEffect(() => {
    }, [query])

    const renderResults=(_query)=>{
        return(<>
        <DisplayResults
        query={_query}
        setCategory={setinputCategory}
        setLoading={e=>{}}
        basicSearchFlag={true}
        />
        </>)
    }

    const resetFilterData = () => {
        var temp = {
            gender: undefined,
            country: undefined,
            from_year: undefined,
            to_year:undefined
        }
        setFilters(temp);
        localStorage.setItem("basicQueryFilters",JSON.stringify(temp));
        // console.log(filters,temp);
    }

    const applyTempFilter=(_key,value)=>{
        var temp = {};
        for (var key in filters) {
            if (filters.hasOwnProperty(key)) {
                var val = filters[key];
                if (key === _key) {
                    temp[key] = value;
                    if(_key==="placeholder"){
                        temp[key] = undefined;
                    }
                }
                else{
                    temp[key]= val
                }
            }
        }
        // console.log("temp",temp);
        setTempFilters(temp);
        // localStorage.setItem("basicQueryFilters",JSON.stringify(temp));
    }

    const applyFilters=()=>{
        var temp = {...tempfilters};
        setFilters(temp);
        localStorage.setItem("basicQueryFilters",JSON.stringify(temp));
    }

    const setQueryAndSearch = async (flag,item='') => {
        console.log("Flag",flag);
        console.log("item",item);
        if (inputCategory === "Select Category") {
            alert("Please Select Category");
            return;
        }
        var tempQuery = {category: inputCategory, page_number: page_number}
        if (nativeName == "" && item == "") {
            alert("Enter name or select alphabet");
            return;
        }
        if ( flag ==="name" && nativeName !== "") {
            tempQuery.native_name = nativeName
        } else if ( flag ==="name" && nativeName === ""){
            alert("Please enter a valid name.");
            return;
        }

        if (flag==="alphabet")
        {
         tempQuery.alphabet = item
        }else if (flag==="alphabet" && item ===""){
            alert("Please select alphabet.");
            return;
        }

        // for (var key in filters) {
        //     if (filters.hasOwnProperty(key)) {
        //         var val = filters[key];
        //         if (val !== undefined) {
        //             tempQuery[key] = val;
        //         }
        //     }
        // }
        setQuery(tempQuery);
        var r = renderResults(tempQuery)
        setResultsDiv(r);
        // localStorage.setItem("basicQuery",JSON.stringify(tempQuery));
        // console.log("Final Query", query, tempQuery);
    }

    return (
        <>
            <DashboardTopBar/>
            <CelebrityUserSidebar/>
            <div className="content-page">
                <div className="content">
                    <div className="col">
                        <div className="card">
                            <div className="card-body pb-0">
                                <div className="container">
   
    <div className="col-lg-12">
    <div className="card">
     <div className="card-body pt-0 pb-0">
        <center>
         <div className="col-md-12 pt-3">
         <div className="form-group">
             <div className="row">
                <div className="col-md-2">
    <label className="">{t("Select Category")}</label>
                </div>
                <div className="col-md-4 row">
        <div className="col-md-6">
            <div className="form-check">
                <input className="form-check-input" type="radio" id="categoryHuman" value="1" checked={inputCategory === "1"} onChange={() => handleCategorychange("1")}/>
                <label className="form-check-label" htmlFor="categoryHuman">
                    {t("Human")}
                </label>
            </div>
        </div>
        <div className="col-md-6">
            <div className="form-check">
                <input className="form-check-input" type="radio" id="categoryMundane" value="2" checked={inputCategory === "2"} onChange={() => handleCategorychange("2")}/>
                <label className="form-check-label" htmlFor="categoryMundane">
                    {t("Mundane")}
                </label>
            </div>
        </div>
                </div>
            </div>
        </div>
            <div className="form-group row">
                <div className="col-md-6">
                    <input className="form-control" style={{ color: "black", border: "1px solid black" }} placeholder="Enter Name..." value={nativeName} onChange={(e) => setNativeName(e.target.value)} />
                </div>
                <div className="col-md-4">
                    <button type="button" className="btn btn-theme" onClick={() => setQueryAndSearch("name")}>
                        {t("Search By Name")}
                    </button>
                </div>
            </div>
            <hr/>
            <div className="form-group row">
                <div className="col-md-10">
                <div className="form-group row alphabet-buttons"> 
                 <div className="col-md-12"> 
                    {alphabets.map((item) => (
                    <button key={item} className="btn btn-sm alphabet-button" 
                        onClick={() => setQueryAndSearch("alphabet", item)}>
                        {item}
                    </button>
                    ))}
                    </div>
                </div>


                </div>
               
            </div>
        </div>
        </center>
      </div>
     </div>
    </div>
                                </div>
                            </div>
                            {resultsDiv}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default withRouter(QueryPage);