import React, { Component } from "react";
import Plotly from "plotly.js";
import { GlobalContext } from "../../../mycontext";
import "../../TransitionComponents/Traces/plotly.css";
import { withTranslation } from "react-i18next";

class VimshopakGraph extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      view_type: "ordered",
    };
  }

  componentDidMount() {
    this.apidate_format_ranked(this.context.horoscopeData);
  }

  componentDidUpdate(prevProps) {
    if (this.props.horoscopeData !== prevProps.horoscopeData) {
      this.apidate_format_ranked(this.props.horoscopeData);
    }
  }

  apidate_format_ranked = (horoscopeData) => {
    let valMapper = {
      0: "Shad Varga",
      1: "Sapt Varga",
      2: "Dash Varga",
      3: "Shodash Varga",
    };

    for (let i = 0; i < 4; i++) {
      let divName = "myDiv_NAKS";
      let gd1 = "";
      let planetNames = [];
      let values = [];
      let str = divName + i;
      // console.log(str);
      gd1 = document.getElementById(str);
      // console.log(gd1);
      let datamodified = JSON.parse(
        JSON.stringify(horoscopeData.vimshopak_bala)
      );
      var items = Object.keys(datamodified[valMapper[i]]).map(function (key) {
        return [key, datamodified[valMapper[i]][key]];
      });

      items.sort(function (first, second) {
        return first[1] - second[1];
      });
      for (let j in items) {
        planetNames.push(this.props.t(items[j][0]));
        values.push(items[j][1]);
      }
      let plotData = [
        {
          x: planetNames,
          y: values,
          type: "bar",
          text: planetNames,
          textposition: "auto",
          marker: {
            color: [
              "#d6edd9",
              "#bae1c1",
              "#a1d2aa",
              "#84c88f",
              "#6bbc77",
              "#51b15e",
              "#449751",
              "#367a41",
              "#2b5e33",
              "#1c4525",
              "#132915",
              "#070e07",
            ],
          },
        },
      ];

      let title = this.props.t(valMapper[i]);
      let layout = {
        width: 300,
        height: 350,
        title: title,
        titlefont: "8px",
        margin: {
          l: 25,
          r: 25,
          b: 30,
          t: 50,
          pad: 3,
        },
        xaxis: {
          title: this.props.t("Planets"),
          type: "category",
          showticklabels: false,
        },
      };
      var config = { responsive: true };
      Plotly.newPlot(gd1, plotData, layout, config);
    }
  };

  toggleClass = () => {
    this.state.currentClass === "col-lg-10 col-md-12"
      ? this.setState({ currentClass: "fullscreen" })
      : this.setState({ currentClass: "col-lg-10 col-md-12" });
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-sm-3">
            <div id="myDiv_NAKS0"></div>
          </div>
          <div className="col-sm-3">
            <div id="myDiv_NAKS1"></div>
          </div>
          <div className="col-sm-3">
            <div id="myDiv_NAKS2"></div>
          </div>
          <div className="col-sm-3">
            <div id="myDiv_NAKS3"></div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(VimshopakGraph);
