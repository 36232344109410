import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { GlobalProvider } from "./mycontext";
// import HomePage from "./layout/HomePage";
import Home from "./layout/Home";
import TransitionDashboard from "./components/DashBoardComponents/DashboardTypes/TransitionDashboard";
import Dash_Panchang from "./components/PanchangComponents/Dash-Panchang";
import history from "./history";
import HoroscopeDashboard from "./components/DashBoardComponents/DashboardTypes/HoroscopeDashboard";
import EventManagementDashboard from "./components/DashBoardComponents/DashboardTypes/CelebrityManagementDashboard"
import VedicYoga from "./components/VedicYoga/VedicYoga";
import TermsConditions from "./components/termsconditions";
import PrivacyPolicy from "./components/privacypolicy";
import EventManagement from "./components/DashBoardComponents/DashboardTypes/CelebrityManagementDashboard";
import CelebrityEvent from "./components/CelebrityManagement/CelebrityEvent";
import CreateHoroscope from "./components/CelebrityManagement/CreateHoroscope";
import Events from "./components/CelebrityManagement/Events";
import Tags from "./components/CelebrityManagement/Tags";
import SearchByAlphabet from "./components/CelebrityManagement/SearchByAlphabet";
import QueryPage from "./components/CelebrityUserComponents/basicSearch";
import ShowHoroscope from "./components/CelebrityUserComponents/showHoroscope";
// import QueryBuilder1 from "./components/CelebrityUserComponents/queryBuilder";
import QueryBuilder from "./components/CelebrityUserComponents/QueryBuilder/main";
import Instructions from "./components/CelebrityUserComponents/QueryBuilder/instructions";
import DisplayResults from "./components/CelebrityUserComponents/QueryBuilder/displayResults";
function App() {
  return (
    <>
      <GlobalProvider history={history}>
        <div id="wrapper">
          <Router>
            <Switch>
            <Route exact path="/" render={(props) => <Home />} />
            <Route path="/dashboard/" render={(props) => <TransitionDashboard />}/>
            <Route path="/panchang" render={(props) => <Dash_Panchang />} />
            <Route path="/horoscope" render={(props) => <HoroscopeDashboard />}/>
            <Route path="/vedicyoga" render={() => <VedicYoga />}/> 
            <Route exact path="/celebrityManagement" render={() => <CelebrityEvent />}/> 
            <Route path="/celebrityManagement/events" render={() => <Events />}/>
            <Route path="/celebrityManagement/tags" render={() => <Tags />}/> 
            <Route path="/celebrityManagement/createHoroscope" render={() => <CreateHoroscope />}/>  
            <Route path="/celebrityManagement/alphabeticalHoroscopes" render={() => <SearchByAlphabet />}/>
            <Route path="/celebrity/basicsearch" render={() => <QueryPage />}/>
            <Route path="/celebrity/show" render={() => <ShowHoroscope />}/>
            <Route path="/celebrity/querybuilder/instructions" render={() => <Instructions/>}/>
            <Route path="/celebrity/querybuilder/results" render={() => <DisplayResults/>}/>
            <Route path="/celebrity/querybuilder" render={() => <QueryBuilder />}/>
            <Route path="/celebrity/show" render={() => <ShowHoroscope />}/>
            <Route path="/privacy-policy" render={() => <PrivacyPolicy />}/>
            <Route path="/terms-and-conditions" render={() => <TermsConditions />}/>
            </Switch>
          </Router>
        </div>
      </GlobalProvider>
    </>
  );
} 

export default App;
