import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../mycontext";
import { useTranslation } from "react-i18next";
import {QueryPage} from "../../CelebrityUserComponents/basicSearch";

import {BiAnalyse, BiHome, BiSearch, BiSearchAlt} from "react-icons/bi";

const CelebrityUserSidebar = (props) => {
    const { t, i18n } = useTranslation();
    let [loading, setLoading] = useState(false);

    return (
        <>
            <div className="left-side-menu">
                <div className="h-100" data-simplebar>
                    <div id="sidebar-menu">
                        <ul id="side-menu">
                            <li className="menu-title mt-2">{t("Workspace")}</li>
                            <li>
                                <a href="/celebrity/basicsearch" ><BiSearch/>
                                    <span>{t("Basic Search")}</span>
                                </a>
                            </li>
                            <li>
                                <a href="/celebrity/querybuilder" ><BiAnalyse/>
                                    <span>{t("Advance Search")}</span>
                                </a>
                            </li>

                            {/*<li>*/}
                            {/*    <a href="/CelebrityManagement/events">*/}
                            {/*        <FiSun />*/}
                            {/*        <span>{t("Events")}</span>{" "}*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                        </ul>
                    </div></div></div>
        </>
    );
};

export default CelebrityUserSidebar;
