import { nakshatraNameToNumMap, motionToNumMap, calculateNakshtrasInRashis, planetCodes, calculateRashiFromNakNum, houseLordNamesArray } from "../astralConstants";
// import { calculateHouseToRashiMap } from "./utilityFunctions";
import { InRashi, InNakshatra, InHouse, hasMotion, ConjunctWith, AspectsPlanet, AspectsHouse } from "./operators";
import { D1 } from "./charts";

var motionsArray = Object.keys(motionToNumMap);
var directArray = ["Direct"];
var retrogradeArray = ["Retrograde"];
const calculateHouseToRashiMap = (asc, temp) => {
    //works only for single ascendant
    var temp = temp;
    if (temp === undefined) {
        temp = {};
    }
    if (temp.hasOwnProperty(1)) {
        temp[1] = temp[1].push(asc);
    }
    else {
        temp[1] = [asc];
    }
    for (var i = 1; i < 12; i++) {
        var r = []
        var rashi = (asc + i) % 12;
        if (rashi === 0) {
            rashi = 12;
        }
        if (temp.hasOwnProperty(i + 1)) {
            temp[i + 1] = temp[i + 1].push(rashi);
        }
        else {
            temp[i + 1] = [rashi];
        }
    }
    return temp;
}

// var RashiValueArray = 

export class Ascendant {
    constructor(variable_object, operators_names, chart_name) {
        /*this.rashis = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
         this.nak_names = [...Object.keys(nakshatraNameToNumMap)]*/
        this.chartName = chart_name;
        this.operators = [];
        this.selectedOperators = [];
        this.selected = false;
        if (operators_names.includes(InRashi.name)) {
            if (this.chartName === "D2") {
                this.rashis = new InRashi([4, 5], this.onrashiSelect);
            }
            else {
                this.rashis = new InRashi([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], this.onrashiSelect,this.onRashiDeSelect);
            }
            this.operators.push(this.rashis)
        }
        if (operators_names.includes(InNakshatra.name)) {
            this.nakshatras = new InNakshatra([...Object.keys(nakshatraNameToNumMap)], this.onNakshatraSelect,this.onNakshatraDeSelect);
            this.operators.push(this.nakshatras);
        }


        this.variable_object = variable_object;
    }

    setHosueToRashiMapForAscRashi = (rashis) => {
        var v = calculateHouseToRashiMap(rashis);
        return v;
    }

    setPlanetSelection =()=>{
        if (this.selectedOperators.length === this.operators.length) {
            this.selected = true;
        }else{
            this.selected=false;
        }
    }
    onrashiSelect = (selectedrashis, selectedFromDropdown) => {
        this.rashis.values = selectedrashis;
        if (selectedrashis.length === 1) {
            //TODO: add code  for map update with multiple possible rashis
            if (selectedFromDropdown) {
                this.rashis.selected = true;
                //not pushing object since we only need to compare length,so save memory
                this.selectedOperators.push(InRashi.name);
                this.setPlanetSelection();
                if (this.chartName === "D1" && !this.nakshatras.selected) {
                    var newNaks = calculateNakshtrasInRashis(selectedrashis);
                    this.nakshatras.values = newNaks;
                }
            }
            var t = {};
            this.variable_object.setHouseToRashiMap(this.setHosueToRashiMapForAscRashi(selectedrashis[0], t));
        }
    }

    onRashiDeSelect =(deselectedFromDropdown,value)=>{
        this.rashis.deselectValue(value);
        this.selectedOperators = this.selectedOperators.filter(function(item){
            return item!==InRashi.name
        })
        if(deselectedFromDropdown && this.chartName==="D1"){
            if(!this.nakshatras.selected){
                //Nakshatra not selected but options were set according to selected rashi
                this.onNakshatraDeSelect(false,this.nakshatras.values[0]);
            }else{
                //If nak is selected, rashi options should be according to selected nakshatra
                var nak_num = nakshatraNameToNumMap[this.nakshatras.values[0]];
            this.rashis.values = calculateRashiFromNakNum(nak_num)
            }
        }
        this.setPlanetSelection();
    }
    onNakshatraSelect = (selectedNaks) => {
        //selectedNaks will be an array with nakshtra name
        //call constraint
        var nak_num = nakshatraNameToNumMap[selectedNaks[0]];
        var rashis_ = calculateRashiFromNakNum(nak_num);
        if (!(this.rashis.values.length === 1 && this.rashis.selected === true)) {
            //rashi not already selected
            this.onrashiSelect(rashis_, false)
        } else {
            var map = {}
            rashis_.forEach(r => {
                map = this.setHosueToRashiMapForAscRashi(r, map);
            });
            this.variable_object.setHouseToRashiMap(map);
        }

        this.nakshatras.selected = true;

        //not pushing object since we only need to compare length,so save memory
        this.selectedOperators.push(InNakshatra.name);
        this.setPlanetSelection();
    }

    onNakshatraDeSelect = (deselectedFromDropdown,value)=>{
        this.nakshatras.deselectValue(value);
        this.selectedOperators = this.selectedOperators.filter(function(item){
            return item!==InNakshatra.name
        })
        if(deselectedFromDropdown){
            if(!this.rashis.selected){
                //rashi options were set according to selected nakshatra, reset them
            this.onRashiDeSelect(false,this.rashis.values[0]);
        }
        else{
            //if rashi is selected, then set current nakshatra options according to the selected rashi
            var newNaks = calculateNakshtrasInRashis(this.rashis.values);
                this.nakshatras.values = newNaks;
        }
        }
        this.setPlanetSelection();
    }
}


export class Planet {
    constructor(planetName, aspects, motions, variables_object, operators_names, chart_name) {
        this.chartName = chart_name;
        // this.operators=this.getOperators();
        this.operators = [];
        this.selectedOperators = [];
        this.selected = false;
        this.planetName = planetName;
        this.planetCode = planetCodes[this.planetName];
        // console.log('planet code',this.planetName,this.planetCode);
        if (operators_names.includes(InHouse.name)) {
            var houseNumArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            if (this.chartName === "D2") {
                //Since ascendant is only 4 or 5 and all planets are also in one of these two rashis
                houseNumArray = [1, 2, 12];
            }
            this.houses = new InHouse(houseNumArray, this.onHouseSelect,this.onHouseDeSelect);
            this.operators.push(this.houses)
        }
        if (operators_names.includes(InRashi.name)) {
            var rashiNumArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            if (this.chartName === "D2") {
                //In D2 any planet will only be in rashis 4 or 5
                rashiNumArray = [4, 5];
            }
            this.rashis = new InRashi(rashiNumArray, this.onRashiSelect,this.onRashiDeSelect);
            this.operators.push(this.rashis)
        }
        if (operators_names.includes(InNakshatra.name)) {
            this.nakshatras = new InNakshatra([...Object.keys(nakshatraNameToNumMap)], this.onNakshatraSelect,this.onNakshatraDeSelect);
            this.operators.push(this.nakshatras)
        }
        if (operators_names.includes(hasMotion.name)) {
            //0 is Direct 1 is Retrograde
            this.motion = new hasMotion(motions, this.onMotionSelect,this.onMotionDeSelect);
            this.operators.push(this.motion)
        }
        if (operators_names.includes(ConjunctWith.name)) {
            this.conjunctWith = new ConjunctWith(this.getConjucntionArray(new.target.name), this.onConjunctionSelect,this.onConjunctionDeSelect);
            this.operators.push(this.conjunctWith)
        }
        if (operators_names.includes(AspectsPlanet.name)) {
            // Since Rahu ketu always aspect each other they are not in option for one another
            this.aspectsPlanets = new AspectsPlanet(this.getConjucntionArray(new.target.name), this.onApectedPlanetSelect,this.onAspectsPlanetDeselect);
            this.operators.push(this.aspectsPlanets)
        }
        if (operators_names.includes(AspectsHouse.name)) {
            this.aspectsHouses = new AspectsHouse([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], this.onApectedHouseSelect,this.onAspectedHouseDeselct);
            this.aspects = aspects;
            this.operators.push(this.aspectsHouses)
        }

        this.variable_object = variables_object;
        this.selected = false;
        // console.log([InHouse,AspectsHouse].includes(InHouse),'array match operator')


    }

    getOperators = () => {
        if (["Sun", "Moon", "Rahu", "Ketu"].includes(this.planetName)) {
            return [this.houses, this.rashis, this.nakshatras, this.conjunctWith,
            this.aspectsPlanets, this.aspectsHouses];
        }
        return [this.houses, this.rashis, this.nakshatras, this.motion, this.conjunctWith,
        this.aspectsPlanets, this.aspectsHouses];
    }

    getConjucntionArray = (val) => {
        var arr = ['Sun', 'Moon', 'Mars', 'Mercury', 'Jupiter', 'Venus', 'Saturn', 'Rahu', 'Ketu'];
        arr.push(...houseLordNamesArray);
        arr = arr.filter(function (item) {
            return item !== val
        });
        if (val === 'Rahu') {
            arr = arr.filter(function (item) {
                return item !== 'Ketu'
            });
        }
        if (val === 'Ketu') {
            arr = arr.filter(function (item) {
                return item !== 'Rahu'
            });
        }

        return arr;
    }

    setPlanetSelection = ()=>{
        if (this.selectedOperators.length === this.operators.length) {
            this.selected = true;
        }
        else{
            this.selected=false;
        }
    }
    removeValueFromSelectedOperators=(operator_name)=>{
        this.selectedOperators = this.selectedOperators.filter(function(item){
            return item!==operator_name
        })
    }
    onHouseSelect = (currentsession, selectedHouseNum, otherPlanestsObjList, ascObject, selectedFromDropdown) => {
        //Options vs selected values in both exhaustive and non exhaustive?
        this.houses.values = selectedHouseNum;
        //selected will be passed true only when this function is called from dropdown selection
        if (selectedHouseNum.length === 1 && selectedFromDropdown) {
            this.houses.selected = true;
            //not pushing object since we only need to compare length,so save memory
            this.selectedOperators.push(InHouse.name);
            this.setPlanetSelection();
        } else {
            currentsession.push(this.constructor.name + '_' + this.houses.alias);
        }
        // this.houses.generalConstraint.houseSelected(currentsession, this, otherPlanestsObjList, ascObject, selectedHouseNum, this.variable_object.getHouseToRashiMap())
    }
    onHouseDeSelect = (deselectedFromDropdown,value)=>{
        this.houses.deselectValue(value);
        this.removeValueFromSelectedOperators(InHouse.name);
        this.setPlanetSelection();
    }
    onRashiSelect = (currentsession, selectedRashiNum, otherPlanestsObjList, ascObject, selectedFromDropdown) => {
        this.rashis.values = selectedRashiNum;
        //selected will be passed true only when this function is called from dropdown selection
        if (selectedRashiNum.length === 1 && selectedFromDropdown) {
            this.rashis.selected = true;
            //not pushing object since we only need to compare length,so save memory
            this.selectedOperators.push(InRashi.name);
            if (this.selectedOperators.length === this.operators.length) {
                this.selected = true;
            }
            if (this.chartName === "D1" && !this.nakshatras.selected) {
                var newNaks = calculateNakshtrasInRashis([selectedRashiNum]);
                this.nakshatras.values = newNaks;
            }
        } else {
            currentsession.push(this.constructor.name + '_' + this.rashis.alias);
        }
    }

    onRashiDeSelect=(deselectedFromDropdown,value)=>{
        this.rashis.deselectValue(value);
        this.removeValueFromSelectedOperators(InRashi.name)
       
        if(deselectedFromDropdown && this.chartName==="D1"){
            if(!this.nakshatras.selected){
                //Nakshatra not selected but options were set according to selected rashi
                this.onNakshatraDeSelect(false,this.nakshatras.values[0]);
            }else{
                //If nak is selected, rashi options should be according to selected nakshatra
                var nak_num = nakshatraNameToNumMap[this.nakshatras.values[0]];
            this.rashis.values = calculateRashiFromNakNum(nak_num)
            }
        }
        this.setPlanetSelection();
    }

    onNakshatraSelect = (currentsession, selectedNakshatraName, otherPlanestsObjList, ascObject, selectedFromDropdown) => {
        this.nakshatras.values = selectedNakshatraName;
        //selected will be passed true only when this function is called from dropdown selection
        if (selectedNakshatraName.length === 1 && selectedFromDropdown) {
            this.nakshatras.selected = true;
            //not pushing object since we only need to compare length,so save memory
            this.selectedOperators.push(InNakshatra.name);
            if (this.selectedOperators.length === this.operators.length) {
                this.selected = true;
            }
            if(!this.rashis.selected){
                var nak_num = nakshatraNameToNumMap[selectedNakshatraName];
                this.rashis.values = calculateRashiFromNakNum(nak_num)
            }
        } else {
            currentsession.push(this.constructor.name + '_' + this.nakshatras.alias);
        }
    }

    onNakshatraDeSelect=(deselectedFromDropdown,value)=>{
        this.nakshatras.deselectValue(value);
        this.removeValueFromSelectedOperators(InNakshatra.name);
        if(deselectedFromDropdown){
            if(!this.rashis.selected){
                //rashi options were set according to selected nakshatra, reset them
            this.onRashiDeSelect(false,this.rashis.values[0]);
        }
        else{
            //if rashi is selected, then set current nakshatra options according to the selected rashi
            var newNaks = calculateNakshtrasInRashis(this.rashis.values[0]);
                this.nakshatras.values = newNaks;
        }
        }
        this.setPlanetSelection();
    }

    onConjunctionSelect = (currentsession, selectedPlanet, otherPlanestsObjList, ascObject, selectedFromDropdown) => {
        this.conjunctWith.updateselectedValues(selectedPlanet[0]);
        if (this.conjunctWith.values.length === 0) {
            this.conjunctWith.selected = true;
            //not pushing object since we only need to compare length,so save memory
            this.selectedOperators.push(ConjunctWith.name);
            if (this.selectedOperators.length === this.operators.length) {
                this.selected = true;
            }
        }
    }

    onConjunctionDeSelect = (deselectedFromDropdown,value)=>{
        this.conjunctWith.deselectValue(value)
        this.removeValueFromSelectedOperators(ConjunctWith.name);
        this.setPlanetSelection();
    }

    onApectedHouseSelect = (currentsession, selectedHouseNum, otherPlanestsObjList, ascObject, selectedFromDropdown) => {
        this.aspectsHouses.updateSelectedValues(selectedHouseNum[0]);
        // var old=this.aspectsHouses.selectedValues
        // if(old.includes(selectedHouseNum[0])){
        //     //ideally should always be false
        //     return;
        // }
        // old.push(selectedHouseNum[0]);
        if (this.aspectsHouses.selectedValues.length === this.aspects.length ||
            this.aspectsHouses.selectedValues.length == 3) {
            //old.length===3 - this is done for lord aspect as the liat will have all 12 houses
            // but a planet with maximum asoect will also have only 3 aspects 
            //all possible house aspects are selected
            this.aspectsHouses.selected = true;
            //not pushing object since we only need to compare length,so save memory
            this.selectedOperators.push(AspectsHouse.name);
            if (this.selectedOperators.length === this.operators.length) {
                this.selected = true;
            }
        }

    }

    onAspectedHouseDeselct=(deselectedFromDropdown,value)=>{
        this.aspectsHouses.deselectValue(value);
        this.removeValueFromSelectedOperators(AspectsHouse.name);
        this.setPlanetSelection();
    }

    onApectedPlanetSelect = (currentsession, selectedPlanetName, otherPlanestsObjList, ascObject, selectedFromDropdown) => {
        this.aspectsPlanets.updateSelectedValues(selectedPlanetName[0])
        if (this.aspectsPlanets.values.length === 0) {
            this.aspectsPlanets.selected = true;
            //not pushing object since we only need to compare length,so save memory
            this.selectedOperators.push(AspectsPlanet.name);
            if (this.selectedOperators.length === this.operators.length) {
                this.selected = true;
            }
        }
    }

    onAspectsPlanetDeselect=(deselectedFromDropdown,value)=>{
        this.aspectsPlanets.deselectValue(value);
        this.removeValueFromSelectedOperators(AspectsPlanet.name);
        this.setPlanetSelection();
    }
    onMotionSelect = (selectedMotion) => {
        this.motion.values = selectedMotion;
        this.motion.selected = true;
        //not pushing object since we only need to compare length,so save memory
        this.selectedOperators.push(hasMotion.name);
        if (this.selectedOperators.length === this.operators.length) {
            this.selected = true;
        }

    }

    onMotionDeSelect=(value)=>{
        this.motion.deselectValue(value);
        this.removeValueFromSelectedOperators(hasMotion.name);
        this.setPlanetSelection();
    }
}
export class Sun extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Sun', [7], directArray, variables_object, operators_names, chart_name);
        // delete this.motion
    }
}

export class Moon extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Moon', [7], directArray, variables_object, operators_names, chart_name);
        // delete this.motion
    }
}

export class Mars extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Mars', [4, 7, 8], motionsArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
    }
}

export class Mercury extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Mercury', [7], motionsArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
    }
}

export class Jupiter extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Jupiter', [5, 7, 9], motionsArray, variables_object, operators_names, chart_name);
        //If jupiters one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
    }
}

export class Venus extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Venus', [7], motionsArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
    }
}

export class Saturn extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Saturn', [3, 7, 10], motionsArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
    }
}

export class Rahu extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Rahu', [5, 7, 9], retrogradeArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
        // delete this.motion
    }
}

export class Ketu extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Ketu', [5, 7, 9], retrogradeArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
        // delete this.motion
    }
}

////////////////////////////////////////////////////////////////Lords
export class House1Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 1st house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
        // delete this.motion
    }
}

export class House2Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 2nd house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
        // delete this.motion
    }
}

export class House3Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 3rd house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
        // delete this.motion
    }
}

export class House4Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 4th house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
        // delete this.motion
    }
}

export class House5Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 5th house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
        // delete this.motion
    }
}

export class House6Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 6th house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
        // delete this.motion
    }
}

export class House7Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 7th house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
        //If planet one aspect house is selected, calculate others assung selected can be 5,7,or 9 if no other 
        // param is available to decide which aspect selected house is
        // delete this.motion
    }
}

export class House8Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 8th house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
    }
}

export class House9Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 9th house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
    }
}

export class House10Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 10th house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
    }
}

export class House11Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 11th house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
    }
}

export class House12Lord extends Planet {
    constructor(variables_object, operators_names, chart_name) {
        super('Lord of 12th house', [3, 4, 5, 7, 9, 8, 10], motionsArray, variables_object, operators_names, chart_name);
    }
}



