/* eslint-disable no-useless-concat */

import * as d3 from "d3";
import React, { Component } from "react";
import { GlobalContext } from "../../../mycontext";
import eventDrops from "event-drops";
import "./JourneyStyles.css";
import Select from "react-select";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import jsPDF from "jspdf";
import "jspdf-autotable";
import axios from "axios";
import Sideform from "../../SideComponents/sideform";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ImageMapper from "../ImageMapper.json";
import Login from "../../GoogleLogin/login";
var _ = require("lodash");

function extract_image_link(name) {
  let imglink = "/assets/Planets/";
  return imglink + ImageMapper[name] + ".png";
}
const setTimeFormat = (timestring) => {
  timestring = timestring.split(":");
  var hours = timestring[0];
  var minutes = timestring[1];
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  // hours = hours < 10 ? hours.substring(1): hours;
  return hours + ":" + minutes + " " + ampm;
};
const planets = [
  { value: "Sun", label: "Sun" },
  { value: "Moon", label: "Moon" },
  { value: "Mars", label: "Mars" },
  { value: "Mercury", label: "Mercury" },
  { value: "Jupiter", label: "Jupiter" },
  { value: "Venus", label: "Venus" },
  { value: "Saturn", label: "Saturn" },
  { value: "Rahu", label: "Rahu" },
  { value: "Ketu", label: "Ketu" },
];
const planetnums = [
  "Sun",
  "Moon",
  "Mercury",
  "Venus",
  "Mars",
  "Jupiter",
  "Saturn",
  "Uranus",
  "Neptune",
  "Pluto",
  "Rahu",
];

const filterOptions = [
  { value: "Direction Event", label: "Direction Event" },
  { value: "Rashi Event", label: "Rashi Event" },
  { value: "Nakshtra Event", label: "Nakshatra Event" },
  { value: "Pada Event", label: "Pada Event" },
  { value: "Combustion Event", label: "Combustion Event" },
];
class Journey extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      selectedOption: { value: "Jupiter", label: "Jupiter" },
      multiValue: [],
      repositories: "",
      currentClass: "col-lg-10 col-md-12",
      loading: true,
      show: false,
      pdfSelectedEvents: [],
      requestParams: {},
      startDate: null,
      endDate: null,
      showResetForm:false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleMultiChange = this.handleMultiChange.bind(this);
    this.exportJourneyPDF = this.exportJourneyPDF.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePDFEvents = this.handlePDFEvents.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
  }
  componentWillMount() {
    let requestParams = JSON.parse(
      window.localStorage.getItem("requestParams")
    );
    if (requestParams !== null) {
      this.setState({ requestParams: requestParams });
      this.context.setRequestParams(requestParams);
    }
    let start = JSON.parse(window.localStorage.getItem("startDate"));
    if (start !== null) {
      this.setState({ startDate: start });
      this.context.setStartOrEndDate("start", start);
    }
    let end = JSON.parse(window.localStorage.getItem("endDate"));
    if (end !== null) {
      this.setState({ startDate: start });
      this.context.setStartOrEndDate("end", end);
    }
    if (requestParams === null || start === null || end === null) {
      this.props.history.push("/dashboard");
    } else {
      this.fetchData(requestParams, start, end);
    }
  }
  componentDidUpdate(newProps) {
    if (
      this.props.city !== newProps.city ||
      this.props.start !== newProps.start ||
      this.props.end !== newProps.end ||
      this.props.selectedLanguage !== newProps.selectedLanguage ||
      this.props.tokenExpiredCount !== newProps.tokenExpiredCount
    ) {
      this.setState({ loading: true });
      this.setState(
        { selectedOption: { value: "Jupiter", label: "Jupiter" } },
        () =>
          this.fetchData(
            this.context.requestParams,
            this.context.startDate,
            this.context.endDate
          )
      );
    }
  }

  fetchData(requestParams, start, end) {
    let start_Date = "";
    let end_Date = "";
    let from_year = "",
      from_month = "",
      from_day = "";
    let to_year = "",
      to_month = "",
      to_day = "";
    let lat = "",
      long = "";
    let offset = "";
    let pnum = 0;
    if (
      this.state.selectedOption.value !== "Ketu" &&
      this.state.selectedOption.value !== "केतु"
    ) {
      pnum = planetnums.indexOf(ImageMapper[this.state.selectedOption.value]);
    } else {
      pnum = 100;
    }
    start_Date = start;
    end_Date = end;
    from_year = moment(start_Date).format("YYYY");
    from_month = moment(start_Date).format("MM");
    from_day = moment(start_Date).format("DD");

    to_year = moment(end_Date).format("YYYY");
    to_month = moment(end_Date).format("MM");
    to_day = moment(end_Date).format("DD");

    lat = requestParams.latitude;
    long = requestParams.longitude;
    offset = requestParams.offset;

    let url_string =
      "https://apiv2.omparashar.com/core/transit/journey/overdaterange";
    //var params = "?from_year=2020&from_month=1&from_day=1&to_year=2020&to_month=6&to_day=30&lat=29.47&long=77.69&offset=19800&p_nums=3&p_nums=4";

    let params =
      "?from_year=" +
      from_year +
      "&from_month=" +
      from_month +
      "&from_day=" +
      from_day +
      "&to_year=" +
      to_year +
      "&to_month=" +
      to_month +
      "&to_day=" +
      to_day +
      "&p_num=" +
      pnum +
      "&lat=" +
      lat +
      "&long=" +
      long +
      "&offset=" +
      offset +
      "&city=" +
      requestParams.name +
      "&state=" +
      requestParams.state +
      "&country=" +
      requestParams.country +
      "&reflong=" +
      requestParams.reference_longitude_for_timezone +
      "&timezone=" +
      requestParams.timezone +
      "&token=" +
      localStorage.getItem("omp_token");
    // console.log(url_string + params);
    var config = {
      method: "get",
      url: url_string + params,
      headers: {},
    };

    // always use arrow function otherwise this. will not work
    axios(config)
      .then((response) => {
        //console.log(response);
        if (response.data.message === "Token expired") {
          this.context.handleShow(`/dashboard/journeyView`);
        } else {
          this.setState(
            (state) => {
              return {
                repositories: response.data,
                loading: false,
              };
            },
            () => this.setOptions()
          );
        }
      })
      .catch(function (error) {
        // console.log("Result" + error);
      });
  }

  setOptions() {
    let array = [];
    this.state.repositories.transits.forEach(function (arrayItem) {
      if (arrayItem !== null) {
        array.push({
          label: arrayItem.event_type,
          value: arrayItem.event_type,
        });
      }
    });
    this.setState(
      (state) => {
        return {
          multiValue: array,
          loading: false,
        };
      },
      () => this.createChart()
    );
  }

  createChart() {
    const addZero = (i) => {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    };
    const humanizeDate = (event_datetime) => {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const dayNames = ["Sun.", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return `
       ${this.props.t(dayNames[event_datetime.getDay()])}, ${this.props.t(
        monthNames[event_datetime.getMonth()]
      )} ${event_datetime.getDate()} ${event_datetime.getFullYear()}
        ${addZero(event_datetime.getHours())}:${addZero(
        event_datetime.getMinutes()
      )}:${addZero(event_datetime.getSeconds())}
    `;
    };

    const numberCommitsContainer = document.getElementById("numberCommits");
    const updateCommitsInformation = (chart) => {
      const filteredData = chart
        .filteredData()
        .reduce((total, repo) => total.concat(repo.data), []);
      numberCommitsContainer.textContent = filteredData.length;
      // zoomStart.textContent = humanizeDate(chart.scale().domain()[0]);
      // zoomEnd.textContent = humanizeDate(chart.scale().domain()[1])
    };
    const tooltip = d3
      .select("body")
      .append("div")
      .classed("tooltip", true)
      .style("opacity", 0)
      .style("pointer-events", "auto");

      const inLocale={
        "dateTime": "%x, %X",
        "date": "%-m/%-d/%Y",
        "time": "%-I:%M:%S %p",
        "periods": ["AM", "PM"],
        "days": [this.props.t("Sunday"), this.props.t("Monday"), this.props.t("Tuesday"), this.props.t("Wednesday"), this.props.t("Thursday"), this.props.t("Friday"), this.props.t("Saturday")],
        "shortDays": [this.props.t("Sun."),this.props.t("Mon") , this.props.t("Tue"), this.props.t("Wed"),this.props.t("Thu") ,this.props.t("Fri") , this.props.t("Sat")],
        "months": [this.props.t("January"), this.props.t("February"), this.props.t("March"), this.props.t("April"), this.props.t("May"), this.props.t("June"), this.props.t("July"), this.props.t("August"), this.props.t("September"), this.props.t("October"), this.props.t("November"), this.props.t("December")],
        "shortMonths": [this.props.t("Jan"), this.props.t("Feb"), this.props.t("Mar"), this.props.t("Apr"), this.props.t("May"),this.props.t("Jun"), this.props.t("Jul"), this.props.t("Aug"), this.props.t("Sep"), this.props.t("Oct"), this.props.t("Nov"), this.props.t("Dec")]
      }

    const chart = eventDrops({
      d3,
      locale: inLocale,
      zoom: {
        onZoomEnd: () => updateCommitsInformation(chart),
      },
      bound:{
        format:d3.timeFormat('%d-%m-%Y')
      },
      range: {
        start: new Date(this.context.startDate),
        end: new Date(this.context.endDate),
      },
      drop: {
        date: (d) => new Date(d.event_datetime),
        onMouseOver: (milestones) => {
          tooltip
            .transition()
            .duration(200)
            .style("opacity", 1)
            .style("pointer-events", "auto");

          tooltip
            .html(
              `
                    <div class="commit">
                   
                    <div class="content">
                        <h3 class="message">${milestones.desc}</h3>
                        <p>
                           
                            ${this.props.t(
                              "on"
                            )} <span class="date">${humanizeDate(
                new Date(milestones.event_datetime)
              )}</span>
                           
                        </p>
                    </div>
                `
            )
            .style("left", `${d3.event.pageX - 30}px`)
            .style("top", `${d3.event.pageY + 20}px`);
        },
        onMouseOut: () => {
          tooltip
            .transition()
            .duration(500)
            .style("opacity", 0)
            .style("pointer-events", "none");
        },
      },
    });
    let repositoriesData = {};
    if (this.state.multiValue === null) {
      repositoriesData = this.state.repositories.transits
        .filter(
          (f) => !filterOptions.some((person) => person.value === f.event_type)
        )
        .map((repository) => ({
          name: repository.event_type,
          data: repository.milestones,
        }));
    } else {
      repositoriesData = this.state.repositories.transits
        .filter((f) =>
          this.state.multiValue.some((person) => person.value === f.event_type)
        )
        .map((repository) => ({
          name: repository.event_type,
          data: repository.milestones,
        }));
    }
    //chart = d3.zoom().on("zoom", zoomed);
    d3.select("#events").data([repositoriesData]).call(chart);
    updateCommitsInformation(chart);
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption, loading: true }, () =>
      this.fetchData(
        this.context.requestParams,
        this.context.startDate,
        this.context.endDate
      )
    );
  };

  handleMultiChange(option) {
    this.setState(
      (state) => {
        return {
          multiValue: option,
        };
      },
      () => this.createChart()
    );
  }

  toggleClass = () => {
    this.state.currentClass === "col-lg-12"
      ? this.setState({ currentClass: "fullscreen" })
      : this.setState({ currentClass: "col-lg-12" });
  };

  exportJourneyPDF() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handlePDFEvents(option) {
    this.setState((state) => {
      return {
        pdfSelectedEvents: option,
      };
    });
  }

  generatePDF(t) {
    this.setState({ show: false });
    const pdfData = this.state.repositories.transits
      .filter((f) =>
        this.state.pdfSelectedEvents.some(
          (person) => person.value === f.event_type
        )
      )
      .map((repository) => ({
        event_type: repository.event_type,
        data: repository.milestones,
      }));
    let pdfArray = [];
    pdfData.forEach(function (element) {
      element.data.forEach(function (milestone) {
        let singleEle = {};
        singleEle.event_type = element.event_type;
        singleEle.desc = milestone.desc.split(" ").slice(2).join(" ");
        let datetimes = milestone.event_datetime.split(" ");
        singleEle.date =
          datetimes[0] +
          " " +
          datetimes[1] +
          " " +
          datetimes[2] +
          " " +
          datetimes[3];
        singleEle.time = setTimeFormat(datetimes[4].substring(0, 5));
        pdfArray.push(singleEle);
      });
    });
    pdfArray.sort(function (a, b) {
      var dateA = new Date(a.date),
        dateB = new Date(b.date);
      return dateA - dateB;
    });

    const grouping = _.groupBy(pdfArray, (element) => element.date);
    const sections = _.map(grouping, (items, date) => ({
      date: date,
      alerts: items,
    }));
   // console.log(sections, pdfArray);
    const unit = "pt";
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, [800, 600], true);
    var img = new Image();
    img.src = "../logo_OP.png";
    //     const myFont =  // load the *.ttf font file as binary string
    // // add the font to jsPDF
    // doc.addFileToVFS("MyFont.ttf", myFont);
    // doc.addFont("MyFont.ttf", "MyFont", "normal");
    // doc.setFont("MyFont");
    doc.addImage(img, "png", 10, 20, 100, 30, "", "FAST");
    const title =
      t(this.state.selectedOption.value) + t("'s") + " " + t("Journey");
    // console.log(t(this.state.selectedOption.value));
    // doc.setFont("Roboto", "bold");
    doc.setFontSize(20);
    doc.setTextColor(25, 25, 112);
    doc.setFont("Roboto", "normal");
    doc.text(title, 232, 80);
    doc.setFontSize(15);
    doc.setTextColor(80, 80, 80);
    let headers = [];
    headers.push("Date");
    pdfData.forEach(function (event) {
      headers.push(event.event_type.split(" ")[0]);
    });
    let actualheaders = [headers];

    const tabledata = [];

    pdfArray.forEach(function (row) {
      let newrow = [];
      let time = row.time;
      newrow.push(row.date);
      let index = headers.indexOf(row.event_type.split(" ")[0]);
      for (var i = 1; i < headers.length; i++) {
        if (i !== index) {
          newrow.push("");
        } else {
          newrow.push(row.desc + " " + "@" + " " + time);
        }
      }
     // console.log("newROW",newrow);
      tabledata.push(newrow);
    });
   // console.log("tableDATA",tabledata);

    const dummytabledata = [];

    sections.forEach(function (row) {
      let newrow = new Array(headers.length);
      newrow.fill("");
      newrow[0] = row.date;
      let end = 0;

      for (var i = 0; i < row.alerts.length; i++) {
        end = headers.indexOf(row.alerts[i].event_type.split(" ")[0]);
        newrow[end] +=
          row.alerts[i].desc + " " + "@" + " " + row.alerts[i].time;
      }
      dummytabledata.push(newrow);
    });

    let content = {
      startY: 150,
      theme: "grid",
      head: actualheaders,
      body: dummytabledata,
      headStyles: {
        fontSize: 11,
        halign: "center",
      },
      bodyStyles: {
        fontSize: 10,
        halign: "center",
      },
      rowPageBreak: "avoid",
    };
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let start = this.context.startDate.split("/");

    let sday = start[2];
    let smonthName = monthNames[parseInt(start[1]) - 1];

    let syear = start[0];
    let end = this.context.endDate.split("/");

    let eday = end[2];
    let emonthName = monthNames[parseInt(end[1]) - 1];

    let eyear = end[0];

    let tabletitle =
      "From" +
      " " +
      sday +
      "," +
      " " +
      smonthName +
      " " +
      syear +
      " " +
      "To" +
      " " +
      eday +
      "," +
      " " +
      emonthName +
      " " +
      eyear;
    // console.log(content);
    doc.text(tabletitle, 200, 130);
    doc.autoTable(content);
    doc.setFontSize(10);
    doc.setTextColor(255, 0, 0);
    let str = "Powered By OmParashar";
    doc.text(str, 450, doc.internal.pageSize.getHeight() - 30);
    doc.save("Omparashar_journey.pdf");
  }

  generateCircularPDF() {
    const unit = "pt";
    const orientation = "portrait"; // portrait or landscape
    const doc = new jsPDF(orientation, unit, [800, 600], true);
    var img = new Image();
    img.src = "../logo_OP.png";
    doc.addImage(img, "png", 10, 20, 100, 30, "", "FAST");
    const title = "Brief journey";
    doc.setFont("Roboto", "bold");
    doc.setFontSize(20);
    doc.setTextColor(25, 25, 112);
    doc.setFont("Roboto", "normal");
    doc.text(title, 232, 80);
    doc.setFontSize(15);
    doc.setTextColor(80, 80, 80);
  }

  resetForm() {
    if (Object.keys(this.context.requestParams.length !== 0)) {
      if (
        this.context.transitCountry === null ||
        this.context.transitCountry === ""
      ) {
        this.context.setCountry(this.context.requestParams.country, "Transit");
      }
      if (
        this.context.transitCityState === null ||
        this.context.transitCityState === ""
      )
        this.context.setCityAndState(
          `${this.context.requestParams.name},${this.context.requestParams.state}`,
          "Transit"
        );
    }
    // this.context.handleSideFormTransition(true);
    this.setState({showResetForm:true});
  }

  convertToLanguageMultiple = (obj, t) => {
    if (obj !== null) {
      let obj2 = JSON.parse(JSON.stringify(obj));
      obj2.map((i, value) => {
        i["label"] = t(i["label"]);
        i["value"] = t(i["value"]);
        return i;
      });

      return obj2;
    }
  };
  convertToLanguageSingle = (obj, t) => {
    if (obj !== null) {
      let obj2 = JSON.parse(JSON.stringify(obj));
      obj2["label"] = t(obj2["label"]);
      obj2["value"] = t(obj2["value"]);
      return obj2;
    }
  };

  render() {
    const { t, i18n } = this.props;
    const { selectedOption } = this.state;
    return (
      <>
        <Sideform
          // show={this.context.showSideFormTransition}
          mode="TransitionView"
          view="journeyView"
          // handleClose={this.context.handleSideFormTransition}
          show={this.state.showResetForm}
          handleClose={i=>this.setState({showResetForm:false})}
          reset={true}
          history={this.props.history}
        />
        <div className="col-lg-14">
          <div id="d3graph">
            <div className="card">
              <div className="card-body" style={{ padding: "10px" }}>
                <div className="row" style={{ paddingBotton: "30px" }}>
                  <div className="col-lg-12">
                    <form
                      className="form-inline"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="form-group mx-md-3">
                        <label className="mr-2">{t("Location")}:</label>
                        <label className="mr-2" style={{ color: "#343a40" }}>
                          {this.context.requestParams.name}
                        </label>
                      </div>
                      <div className="mx-md-3">
                        <span className="mr-2">{t("Start Date")}:</span>
                        <label className="mr-2">{this.context.startDate}</label>
                      </div>
                      <div className="mx-md-3">
                        <span className="mr-2">{t("End Date")}:</span>
                        <label className="mr-2">{this.context.endDate}</label>
                      </div>

                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => this.resetForm()}
                        style={{
                          marginLeft: "40px",
                        }}
                      >
                        <i className="mdi mdi-refresh"></i>
                        {t("Reset")}
                      </button>
                    </form>
                  </div>
                </div>
                <hr></hr>
                <center>
                  <label>
                    {t("Select Events")}:
                    <Select
                      value={this.convertToLanguageMultiple(
                        this.state.multiValue,
                        t
                      )}
                      options={this.convertToLanguageMultiple(filterOptions, t)}
                      onChange={this.handleMultiChange}
                      isMulti
                    />
                  </label>
                  <label
                    className="basic-single-label"
                    style={{ width: "140px" }}
                  >
                    {t("Select a Planet")}:
                    <Select
                      value={this.convertToLanguageSingle(selectedOption, t)}
                      onChange={this.handleChange}
                      options={this.convertToLanguageMultiple(planets, t)}
                      style={{ width: "250px" }}
                    />
                  </label>
                  <button
                    type="submit"
                    className="btn btn-success ml-5"
                    onClick={this.exportJourneyPDF}
                  >
                    {t("Generate PDF")}
                  </button>
                  <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    centered
                  >
                    <Modal.Header
                      closeButton
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Modal.Title
                        style={{
                          color: "rgb(3, 66, 141)",
                          fontWeight: "bold",
                        }}
                      >
                        {t(selectedOption.value.toString())}
                        {t("'s")} {t("Planet Journey")}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <center>
                        <label>
                          {t("Select Events to be generated in PDF")}:
                          <Select
                            value={this.convertToLanguageSingle(
                              this.state.pdfSelectedEvents,
                              t
                            )}
                            options={this.convertToLanguageMultiple(
                              filterOptions,
                              t
                            )}
                            onChange={this.handlePDFEvents}
                            isMulti
                          />
                        </label>
                      </center>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={(x) => this.generatePDF(t)}
                      >
                        {t("Done")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </center>
                <hr style={{ marginBottom: "2px" }} />
                <div className="row">
                  <div className="col-lg-12">
                    <center>
                      <h2>
                        <img
                          className="ArdhaTitleImages"
                          alt={selectedOption.value}
                          src={extract_image_link(selectedOption.value)}
                        ></img>
                        {t(selectedOption.value)}
                        {t("'s")} {t("Transit Events Timeline")}
                      </h2>
                    </center>
                    <br></br>
                    {this.state.loading ? (
                      <div>
                        <div className="loader"></div>
                      </div>
                    ) : localStorage.getItem("UserDefaultLanguage") ===
                      "English" ? (
                      <div>
                        <div></div>
                        <div id="events" ref={this.myRef}></div>
                        <p className="infos">
                          <span id="numberCommits"></span>{" "}
                          {t("Transit Events ")}
                          <span className="light">
                            {t("found between")}
                          </span>{" "}
                          <br />
                          {moment(this.context.startDate).format(
                            "Do MMMM YYYY"
                          )}{" "}
                          <span className="light">{t("and")}</span>{" "}
                          {moment(this.context.endDate).format("Do MMMM YYYY")}
                          {/* <span id="zoomStart"></span> <span class="light">and</span> <span id="zoomEnd"></span> */}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div></div>
                        <div id="events" ref={this.myRef}></div>
                        <p className="infos">
                          {moment(this.context.startDate).format("DD")}{" "}
                          {t(moment(this.context.startDate).format("MMMM"))}{" "}
                          {moment(this.context.startDate).format("YYYY")}
                          {" "}
                          <span className="light">{t("and")}</span>{" "}
                          {/* {moment(this.context.endDate).format("DD MMMM YYYY")} */}
                          {moment(this.context.endDate).format("DD")}{" "}
                          {t(moment(this.context.endDate).format("MMMM"))}{" "}
                          {moment(this.context.endDate).format("YYYY")}
                          <br />
                          <span id="numberCommits"></span> {t("Transit Events")}
                          <span className="light">
                            {t("found between")}
                          </span>{" "}
                          {/* <span id="zoomStart"></span> <span class="light">and</span> <span id="zoomEnd"></span> */}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <Ploty_1 /> */}
          </div>
        </div>
        {/* <Login
          page={this.context.currentPage}
          show={this.context.loginModalShow}
          handleClose={this.context.closeLoginModal}
        /> */}
      </>
    );
  }
}

export default withTranslation()(withRouter(Journey));
