import React from "react";
import { useTranslation } from "react-i18next";
const PanchangHTML = (props) => {
  const { t, i18n } = useTranslation();
  // console.log(props.masa);
  return (
    <>
      <table
        className="tablesaw table mb-0 tablesaw-stack"
        id="tablesaw-802"
        style={{ border: "none", borderTop: "none" }}
      >
        <tbody>
          <tr className="table_head_tr" style={{}}>
            <th scope="col" colSpan="5" className="sectionheader">
              {t("Panchang")}
            </th>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Tithi")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.tithicurrent}</span>
              <br></br>
              <span className="tablevalue">{props.tithinext}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Karan")}</span>{" "}
            </td>
            <td className="td4">
              <span className="tablevalue">{props.karancurrent}</span>
              <br></br>
              <span className="tablevalue">{props.karannext}</span>
              <br></br>
              <span className="tablevalue">{props.karan_next_next}</span>
            </td>
          </tr>

          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Yoga")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.yogacurrent}</span>
              <br /> <span className="tablevalue">{props.yoganext}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Weekday")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">{props.weekday}</span>
            </td>
          </tr>

          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Nakshtra")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.nakshtra}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Amanta Masa")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">{props.masa.amanta.name}</span>
            </td>
          </tr>

          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Purnimanta Masa")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.masa.purnimanta.name}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Samvata")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">{props.samvata}</span>
            </td>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Shaka")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.shaka}</span>
            </td>
            <td className="td3">
              <span className="tablelabel"></span>
            </td>
            <td className="td4">
              <span className="tablevalue"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default PanchangHTML;
