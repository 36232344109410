import React, { Component } from "react";
import * as d3 from "d3";

import { GlobalContext } from "../../../mycontext";
import "./Natal.css";

class NatalChart extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    
    this.d3_Chart_ref = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    this.draw_chart_layout();
    this.rashi_placement();
    this.planet_positions();
  }

  componentDidUpdate() {
    d3.select(`.${this.props.className}`).remove();
    this.draw_chart_layout();
    this.rashi_placement();
    this.planet_positions();
  }

  rashi_placement() {
    var container2 = d3.select(`#${this.props.id}`);
    container2
      .selectAll(".rashiText")
      .data(this.props.data)
      .enter()
      .append("text")
      .attr("class", "rashiClass")
      .attr("transform", function (d, i) {
        var house1_x = 540;
        var house1_y = 400;
        if (i === 0) {
          return "translate(" + house1_x + "," + house1_y + ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 100) + "," + (house1_y - 100) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 120) + "," + (house1_y - 80) + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 20) + "," + (house1_y + 18) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 120) + "," + (house1_y + 120) + ")";
        } else if (i === 5) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 140) + ")";
        } else if (i === 6) {
          return "translate(" + house1_x + "," + (house1_y + 40) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 140) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 120) + "," + (house1_y + 120) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 20) + "," + (house1_y + 18) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 120) + "," + (house1_y - 80) + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 100) + "," + (house1_y - 100) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .text(function (d, i) {
        return d.rashi_num;
      });
  }

  planet_positions() {
    var planet_container = d3.select(`#${this.props.id}`);
    var house1_x = 540;
    var house1_y = 320;

    planet_container
      .append("text")
      .text("")
      .attr("dy", house1_y)
      .attr("dx", house1_x - 10)
      .attr("font-size", 20)
      .attr("font-family", "monospace")
      .attr("fill", "red");
    // Define the div for the tooltip
    var div = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);

    // planet_container
    //   .selectAll(".planetText1")
    //   .data(this.props.data)
    //   .enter()
    //   .append("circle")
    //   .attr("circle", function (d, i) {
    //     return d.list;
    //   })
    //   .style("fill", "#fff!important")
    //   .attr("r", 23)
    //   .attr("cx", 40)
    //   .attr("cy", 40)
    //   .attr(
    //     "class",
    //     this.props.parent === "cview"
    //       ? "planetClass1Cview"
    //       : this.props.relation === "Bhinnashtak"
    //         ? "planetClass1Bhin"
    //         : "planetClass1Astak1"
    //   )
    //   .attr("transform", function (d, i) {
    //     if (i === 0) {
    //       return "translate(" + (house1_x - 40) + "," + (house1_y - 96) + ")";
    //     } else if (i === 1) {
    //       return "translate(" + (house1_x - 140) + "," + (house1_y - 116) + ")";
    //     } else if (i === 2) {
    //       return "translate(" + (house1_x - 199) + "," + (house1_y - 45) + ")";
    //     } else if (i === 3) {
    //       return "translate(" + (house1_x - 140) + "," + (house1_y + 55) + ")";
    //     } else if (i === 4) {
    //       return "translate(" + (house1_x - 199) + "," + (house1_y + 155) + ")";
    //     }
    //     else if (i === 5) {
    //       return "translate(" + (house1_x - 135) + "," + (house1_y + 220) + ")";
    //     } else if (i === 6) {
    //       return "translate(" + (house1_x - 40) + "," + (house1_y + 155) + ")";
    //     } else if (i === 7) {
    //       return "translate(" + (house1_x + 60) + "," + (house1_y + 220) + ")";
    //     } else if (i === 8) {
    //       return "translate(" + (house1_x + 120) + "," + (house1_y + 155) + ")";
    //     } else if (i === 9) {
    //       return "translate(" + (house1_x + 60) + "," + (house1_y + 55) + ")";
    //     } else if (i === 10) {
    //       return "translate(" + (house1_x + 125) + "," + (house1_y - 45) + ")";
    //     } else if (i === 11) {
    //       return "translate(" + (house1_x + 60) + "," + (house1_y - 116) + ")";
    //     }
    //     else {

    //     }
    //   })
      // .attr("stroke", function (d, i) {
      //   if (d.list < 30) {
      //     return "rgb(255,136,136)";
      //   } else if (d.list >= 30) {
      //     return "rgb(0,200,0)";
      //   }
      //   else {
      //     return "rgb(0,80,0)";
      //   }
      // });

    planet_container
      .selectAll(".planetText1")
      .data(this.props.data)
      .enter()
      .append("text")

      .attr(
        "class",
        this.props.parent === "cview"
          ? "planetClass1Cview"
          : this.props.relation === "Bhinnashtak"
            ? "planetClass1Bhin"
            : "planetClass1Astak"
      )
      .attr("transform", function (d, i) {
        if (i === 0) {
          return "translate(" + house1_x + "," + (house1_y - 50) + ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 100) + "," + (house1_y - 70) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 160) + "," + house1_y + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 100) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 160) + "," + (house1_y + 200) + ")";
        } else if (i === 5) {
          return "translate(" + (house1_x - 95) + "," + (house1_y + 265) + ")";
        } else if (i === 6) {
          return "translate(" + house1_x + "," + (house1_y + 200) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 265) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 160) + "," + (house1_y + 200) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 100) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 165) + "," + house1_y + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 100) + "," + (house1_y - 70) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .text(function (d, i) {
        return d.list;
      })
      .attr("style", function (d, i) {
        if (d.list < 30) {
          return "fill:rgb(255,136,136)";
        } else if (d.list >= 30) {
          return "fill:rgb(0,200,0)";
        }
        else {
          return "fill:rgb(0,80,0)";
        }
      });



    // second planet placement
    planet_container
      .selectAll(".planetText2")
      .data(this.props.data)
      .enter()
      .append("text")
      .attr("class", "planetClass2")
      .attr("transform", function (d, i) {
        if (i === 0) {
          return "translate(" + (house1_x - 50) + "," + house1_y + ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 120) + "," + (house1_y - 50) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 180) + "," + (house1_y - 40) + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 80) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 180) + "," + (house1_y + 160) + ")";
        } else if (i === 5) {
          return "translate(" + (house1_x - 95) + "," + (house1_y + 265) + ")";
        } else if (i === 6) {
          return "translate(" + house1_x + "," + (house1_y + 200) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 265) + ")";
        }  else if (i === 8) {
          return "translate(" + (house1_x + 180) + "," + (house1_y + 220) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 80) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 180) + "," + (house1_y - 30) + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 100) + "," + (house1_y - 90) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .text(function (d, i) { })
      .attr("style", function (d, i) {
        if (d.list < 30) {
          return "fill:rgb(255,136,136), stroke:red";
        } else if (d.list >= 30) {
          return "fill:rgb(0,200,0)";
        }
        else {
          return "fill:rgb(0,80,0)";
        }
      });

    // Third Planet
    planet_container
      .selectAll(".planetText3")
      .data(this.props.data)
      .enter()
      .append("text")
      .attr("class", "planetClass3")
      .attr("transform", function (d, i) {
        if (i === 0) {
          return "translate(" + (house1_x + 50) + "," + house1_y + ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 80) + "," + (house1_y - 90) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 180) + "," + (house1_y + 20) + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 140) + "," + (house1_y + 100) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 180) + "," + (house1_y + 220) + ")";
        }  else if (i === 5) {
          return "translate(" + (house1_x - 95) + "," + (house1_y + 265) + ")";
        } else if (i === 6) {
          return "translate(" + house1_x + "," + (house1_y + 200) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 265) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 180) + "," + (house1_y + 180) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 140) + "," + (house1_y + 100) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 180) + "," + (house1_y + 20) + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 100) + "," + (house1_y - 50) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .text(function (d, i) { })
      .attr("style", function (d, i) {
        if (d.list < 30) {
          return "fill:rgb(255,136,136), stroke:red";
        } else if (d.list >= 30) {
          return "fill:rgb(0,200,0)";
        }
        else {
          return "fill:rgb(0,80,0)";
        }
      });

    // fourth Planet
    
    planet_container
      .selectAll(".planetText4")
      .data(this.props.data)
      .enter()
      .append("text")
      .attr("class", "planetClass4")
      .attr("transform", function (d, i) {
        if (i === 0) {
          return "translate(" + house1_x + "," + (house1_y + 50) + ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 140) + "," + (house1_y - 90) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 180) + "," + (house1_y + 40) + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 100) + "," + (house1_y + 160) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 180) + "," + (house1_y + 240) + ")";
        }  else if (i === 5) {
          return "translate(" + (house1_x - 95) + "," + (house1_y + 265) + ")";
        } else if (i === 6) {
          return "translate(" + house1_x + "," + (house1_y + 200) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 265) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 180) + "," + (house1_y + 150) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 100) + "," + (house1_y + 140) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 180) + "," + (house1_y + 40) + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 140) + "," + (house1_y - 70) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .text(function (d, i) { })
      .attr("style", function (d, i) {
        if (d.list < 30) {
          return "fill:rgb(255,136,136), stroke:red";
        } else if (d.list >= 30) {
          return "fill:rgb(0,200,0)";
        }
        else {
          return "fill:rgb(0,80,0)";
        }
      });

    // fiveth Planet

    planet_container
      .selectAll(".planetText5")
      .data(this.props.data)
      .enter()
      .append("text")
      .attr("class", "planetClass5")
      .attr("transform", function (d, i) {
        if (i === 0) {
          return "translate(" + house1_x + "," + (house1_y - 30) + ")";
        } else if (i === 1) {
          return "translate(" + (house1_x - 60) + "," + (house1_y - 70) + ")";
        } else if (i === 2) {
          return "translate(" + (house1_x - 140) + "," + house1_y + ")";
        } else if (i === 3) {
          return "translate(" + (house1_x - 50) + "," + (house1_y + 100) + ")";
        } else if (i === 4) {
          return "translate(" + (house1_x - 180) + "," + (house1_y + 180) + ")";
        } else if (i === 5) {
          return "translate(" + (house1_x - 60) + "," + (house1_y + 280) + ")";
        } else if (i === 6) {
          return "translate(" + (house1_x - 50) + "," + (house1_y + 200) + ")";
        } else if (i === 7) {
          return "translate(" + (house1_x + 60) + "," + (house1_y + 270) + ")";
        } else if (i === 8) {
          return "translate(" + (house1_x + 180) + "," + (house1_y + 240) + ")";
        } else if (i === 9) {
          return "translate(" + (house1_x + 50) + "," + (house1_y + 100) + ")";
        } else if (i === 10) {
          return "translate(" + (house1_x + 150) + "," + house1_y + ")";
        } else if (i === 11) {
          return "translate(" + (house1_x + 60) + "," + (house1_y - 70) + ")";
        } else {
          return "translate(530,500)";
        }
      })
      .attr("text-anchor", "middle")
      // .attr("xlink:href", function (d, i) {
      //     return "#SunSign_Arc_" + i;
      // })
      .text(function (d, i) { })
      .attr("style", function (d, i) {
        if (d.list < 30) {
          return "fill:rgb(255,136,136), stroke:red";
        } else if (d.list >= 30) {
          return "fill:rgb(0,200,0)";
        }
        else {
          return "fill:rgb(0,80,0)";
        }
      });
  }

  draw_chart_layout() {
    var json = [
      {
        chart: "D1",
        Asc: 11,
        rashi: {
          11: ["Su", "Ma", "Sa"],
          12: ["Me"],
          1: ["Mo"],
          2: ["Ra", "Ju"],
          3: [],
          6: [],
          7: [],
          8: [],
          9: [],
          10: ["Ke"],
          11: [],
          12: ["Ve"],
        },
      },
    ];
    var w, h, x, y, xview, yview;
    if (this.props.parent === "cview") {
      w = this.props.w;
      h = this.props.h;
      x = 1080 / 2;
      y = 630 / 2;
      xview = this.props.xview;
      yview = this.props.yview;
    } else {
      w = 900 / 2;
      h = this.props.h;
      x = 1080 / 2;
      y = 630 / 2;
      xview = 330;
      yview = 180;
    }
    var svg = d3
      .select(this.d3_Chart_ref.current)
      .append("svg")
      // .attr("width", w)
      // .attr("height", h)
      .attr("preserveAspectRatio", "xMinYMin meet")
      .attr("class", this.props.className)
      .attr("viewBox", xview + " " + yview + " " + w + " " + h + "");

    var container = svg.append("g").attr("id", this.props.id);

    var rectangle = container
      .append("rect")
      .style("stroke", "#8f8f8f")
      .style("stroke-width", "2px")
      .style("fill", "none")
      .attr("x", x - 200)
      .attr("y", y - 100)
      .attr("width", 400)
      .attr("height", 400);

    var rectangle2 = container
      .append("rect")
      .style("stroke", "#8f8f8f")
      .style("stroke-width", "2px")
      .style("fill", "none")
      .attr("x", x - 5)
      .attr("y", y - 545)
      .attr("width", 281)
      .attr("height", 281)
      .attr("transform", "rotate(45)");

    var line1 = container
      .append("line")
      .attr("x1", x + 200)
      .attr("x2", x - 200)
      .attr("y1", y + 300)
      .attr("y2", y - 100)
      .style("stroke", "#8f8f8f")
      .style("stroke-width", "2px");

    var line2 = container
      .append("line")
      .attr("x1", x + 200)
      .attr("x2", x - 200)
      .attr("y1", y - 100)
      .attr("y2", y + 300)
      .style("stroke", "#8f8f8f")
      .style("stroke-width", "2px");

    var house1_x = 540;
    var house1_y = 400;
    // planet group
    // container
    //   .append("text")
    //   .text("Su")
    //   .attr("dy", house1_y)
    //   .attr("dx", house1_x)
    //   .attr("font-size", 15)
    //   .attr("font-family", "monospace")
    //   .attr("fill", "white");

    var text = svg.append("g").attr("id", "text_");

    //Append the month names to each slice
    // container
    //   .selectAll(".monthT")
    //   .data(data)
    //   .enter()
    //   .append("text")
    //   .attr("class", "monT")
    //   .attr("x", house1_x) //Move the text from the start angle of the arc
    //   .attr("y", house1_y) //Move the text down
    //   .attr("text-anchor", "middle")
    //   // .attr("xlink:href", function (d, i) {
    //   //     return "#SunSign_Arc_" + i;
    //   // })
    //   .text(function (d, i) {
    //     return d.planets;
    //   });
  }

  render() {
    return (
      <>
        <div
          // className="col-lg-6"
          style={{ marginLeft: "auto", marginRight: this.props.rightMargin }}
        >
          <div ref={this.d3_Chart_ref}></div>
        </div>
      </>
    );
  }
}

export default NatalChart;
