import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import RelationTable from "./RelationTable";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { GlobalContext } from "../../../mycontext";
import Sideform from "../../SideComponents/sideform";
import ResetButton from "../ResetButton/ResetButton";
import "./relation.css";
import PlanetD3Reation from "./Planet-d3-realtion";
import { withTranslation } from "react-i18next";
import {getQueryParam} from '../../utils/utility';

const ValueMapper = {
  1: "permanent_relations",
  2: "temporary_relations",
  3: "five_fold_relations",
};

const HeaderMapper = {
  1: "Permanent Relation",
  2: "Temporary Relation",
  3: "Five-Fold Relation",
};

const PlanetMapper = {
  Su: 0,
  Mo: 1,
  Ma: 2,
  Me: 3,
  Ju: 4,
  Ve: 5,
  Sa: 6,
};

const ColorMapper = {
  Friend: "rgba(90,135,34,0.8)",
  मित्र: "rgba(90,135,34,0.8)",
  Enemy: "rgba(238,101,123,0.9)",
  शत्रु: "rgba(238,101,123,0.9)",
  Bitter: "rgba(137,48,146,0.6)",
  अधिशत्रु: "rgba(137,48,146,0.6)",
  Intimate: "rgba(51,70,245,0.6)",
  अधिमित्र: "rgba(51,70,245,0.6)",
  Neutral: "rgba(62,150,157,0.6)",
  सम: "rgba(62,150,157,0.6)",
  "--": "rgba(0,0,0,0.6)",
};

const deduceColor = (value) => {
  if (value === "Friend" || value === "मित्र") {
    return "#32cd32";
  } else if (value === "Neutral" || value === "सम") {
    return "#808080";
  } else if (value === "Enemy" || value === "शत्रु") {
    return "#ff5252";
  } else if (value === "Intimate" || value === "अधिमित्र") {
    return "#008000";
  } else if (value === "Bitter" || value === "अधिशत्रु") {
    return "#cc0000";
  } else {
    return "#696969";
  }
};
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  rootCard: {
    maxWidth: 300,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "5%",
  },
  CardContent: {
    backgroundColor: "rgb(90,135,34)",
    color: "#fff",
  },
  PlanetaryRelationDiv: {
    position: "relative",
    background: "#fff",
    minHeight: "625px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    fontFamily: "Lucida Bright",
  },
  searchDiv: {
    textAlign: "center",
    fontFamily: "Lucida Bright",
  },
  resultdiv: {
    fontSize: "1.5em",
    fontWeight: "bold",
    textAlign: "center",
    minHeight: "300px",
    fontFamily: "Lucida Bright",
  },
  tab: {
    color: "grey",
  },
  label: {
    backgroundColor: "white",
    fontFamily: "Lucida Bright",
  },
  select: {
    fontFamily: "Lucida Bright",
  },
});

class PlanetRelations extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      value: 1,
      showTable: true,
      relation: "permanent_relations",
      firstPlanet1: "Su",
      secondPlanet1: "Su",
      firstPlanet2: "Su",
      secondPlanet2: "Su",
      firstPlanet3: "Su",
      secondPlanet3: "Su",
      result1: "--",
      result2: "--",
      result3: "--",
      resultColor: "rgb(90,135,34)",
      renderedChoice: "table",
      horoscopeData:
      JSON.parse(window.localStorage.getItem("horoscopeData")) || null,

      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false
    };
    this.FilterData = this.FilterData.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let googleAuthObj = JSON.parse(
      window.localStorage.getItem("googleAuthObj")
    );
    if (googleAuthObj !== null && this.context.googleAuthObj === null) {
      this.context.setGoogleAuthObj(googleAuthObj);
    }
    let queryString = getQueryParam('cb');
    if(queryString){
      // alert(queryString);
       this.setState({cb_view:true});
       this.setState({cb_horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
       this.setState({cb_requestParamsHoroscope:JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`))});
 
       this.setState({horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
       this.setState({requestParamsHoroscope:JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`))});
       this.handleChange(1);
     }
     else{

    let requestParams = JSON.parse(
      window.localStorage.getItem("requestParamsHoroscope")
    );
    if (
      requestParams !== null &&
      Object.keys(this.context.requestParams).length === 0
    ) {
      this.context.setRequestParamsHoroscope(requestParams);
    }
    let horoscopeData = JSON.parse(
      window.localStorage.getItem("horoscopeData")
    );

    if (horoscopeData === null) {
      this.context.handleHoroscopeForm(true, "open");
    } else {
      this.context.setHoroscopeData(horoscopeData);
      this.FilterData(this.state.relation, horoscopeData);
    }
  }
  }

  componentDidUpdate(prevProps) {
    if (this.props.horoscopeData !== prevProps.horoscopeData) {
      this.FilterData(this.state.relation, this.state.horoscopeData);
    }
  }

  FilterData(param, horoscopeData) {
    let planetData = horoscopeData.planetary_relationship;

    let requiredData = planetData[param];

    let mainjson = [];
    for (let i of requiredData) {
      let json = {};
      json["Main Planet"] = i.main_planet_name;
      for (let j of i.relation_with_others) {
        json[j.planet_name] = j.relationship_value;
      }
      mainjson.push(json);
    }

    this.setState((state) => {
      return {
        tableData: mainjson,
      };
    });
  }

  FilterSearchData(relation, planet1, planet2) {
    let planetData = this.state.horoscopeData.planetary_relationship;
    let requiredData = planetData[relation];
    let reqObj = requiredData[PlanetMapper[planet1]];
    let output =
      reqObj.relation_with_others[PlanetMapper[planet2]].relationship_value;
    if (relation === "permanent_relations") {
      this.setState({ result1: output, resultColor: ColorMapper[output] });
    } else if (relation === "temporary_relations") {
      this.setState({ result2: output, resultColor: ColorMapper[output] });
    } else {
      this.setState({ result3: output, resultColor: ColorMapper[output] });
    }
  }

  handleChange = (newValue) => {
    if (newValue === "4") {
      this.setState({ showTable: false, value: newValue });
    } else {
      this.setState(
        { showTable: true, value: newValue, relation: ValueMapper[newValue] },
        () => this.FilterData(ValueMapper[newValue], this.state.horoscopeData)
      );
    }
  };

  handleFirstPlanetChange = (event, relation) => {
    if (relation === "permanent_relations") {
      this.setState({ firstPlanet1: event.target.value }, () =>
        this.FilterSearchData(
          relation,
          event.target.value,
          this.state.secondPlanet1
        )
      );
    } else if (relation === "temporary_relations") {
      this.setState({ firstPlanet2: event.target.value }, () =>
        this.FilterSearchData(
          relation,
          event.target.value,
          this.state.secondPlanet2
        )
      );
    } else {
      this.setState({ firstPlanet3: event.target.value }, () =>
        this.FilterSearchData(
          relation,
          event.target.value,
          this.state.secondPlanet3
        )
      );
    }
  };

  handleSecondPlanetChange = (event, relation) => {
    if (relation === "permanent_relations") {
      this.setState({ secondPlanet1: event.target.value }, () =>
        this.FilterSearchData(
          relation,
          this.state.firstPlanet1,
          event.target.value
        )
      );
    } else if (relation === "temporary_relations") {
      this.setState({ secondPlanet2: event.target.value }, () =>
        this.FilterSearchData(
          relation,
          this.state.firstPlanet2,
          event.target.value
        )
      );
    } else {
      this.setState({ secondPlanet3: event.target.value }, () =>
        this.FilterSearchData(
          relation,
          this.state.firstPlanet3,
          event.target.value
        )
      );
    }
  };

  renderElement = (choice) => {
    this.setState({ renderedChoice: choice });
  };

  render() {
    const { classes } = this.props;
    const { tableData } = this.state;
    const { resultColor } = this.state;
    const { t, i18n } = this.props;
    if (
      Object.keys(this.state.horoscopeData).length > 0 &&
      typeof this.state.horoscopeData !== "undefined"
    ) {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"planetaryRelations"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
          <div className={[classes.PlanetaryRelationDiv, "px-1"].join(" ")}>
            <ResetButton />
            <div className="panel with-nav-tabs panel-warning">
              <div className="panel-heading">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={this.state.value}
                  onSelect={(k) => this.handleChange(k)}
                >
                  <Tab
                    className={classes.tab}
                    eventKey={1}
                    title={t("Permanent Relationship")}
                  ></Tab>
                  <Tab
                    className={classes.tab}
                    eventKey={2}
                    title={t("Temporary Relationship")}
                  ></Tab>
                  <Tab
                    className={classes.tab}
                    eventKey={3}
                    title={t("Five-Fold Relationship")}
                  ></Tab>
                  <Tab
                    className={classes.tab}
                    eventKey={4}
                    title={t("Search Relations")}
                  ></Tab>
                </Tabs>
              </div>
              <div className="planet-relations-main">
                {this.state.value !== "4" ? (
                  <>
                    <center>
                      <span className="main-title mr-2">
                        {t(`${HeaderMapper[this.state.value]} between Planets`)}
                      </span>

                      <label
                        style={{
                          fontSize: "15px",
                          fontWeight: "normal",
                          fontFamily: "Lucida Bright",
                        }}
                      >
                        <input
                          type="radio"
                          name="optradio"
                          value="table"
                          checked={this.state.renderedChoice === "table"}
                          onClick={() => this.renderElement("table")}
                          readOnly
                        />{" "}
                        {t("Tabular View")}
                      </label>
                      <label
                        style={{
                          fontSize: "15px",
                          fontWeight: "normal",
                          fontFamily: "Lucida Bright",
                        }}
                      >
                        <input
                          type="radio"
                          name="optradio"
                          value="chart"
                          style={{ marginLeft: "20px" }}
                          checked={this.state.renderedChoice === "graph"}
                          onClick={() => this.renderElement("graph")}
                          readOnly
                        />{" "}
                        {t("Round Robin View")}
                      </label>
                    </center>
                  </>
                ) : (
                  <h2 className="planet_relations_header_last">
                    {t("Search Planetary Relations")}
                  </h2>
                )}
                {this.state.showTable &&
                this.state.renderedChoice === "table" ? (
                  <div className="planet-relations">
                    <RelationTable data={tableData} />
                  </div>
                ) : this.state.showTable &&
                  this.state.renderedChoice === "graph" ? (
                  <div>
                    <PlanetD3Reation
                      height={550}
                      width={1000}
                      radius={50}
                      horoscopeData={this.props.horoscopeData}
                      value={this.state.value}
                      t={this.props.t}
                      planets={[
                        t("SU"),
                        t("MO"),
                        t("MA"),
                        t("ME"),
                        t("JU"),
                        t("VE"),
                        t("SA"),
                      ]}
                      language={localStorage.getItem("User Defined Language")}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      top: "250px",
                    }}
                  >
                    <div className={classes.searchDiv}>
                      <span
                        className="badge"
                        style={{
                          backgroundColor: "#00adadff",
                          padding: "20px",
                          fontSize: "1.2em",
                          width: "270px",
                          color: "white",
                          fontFamily: "Lucida Bright",
                        }}
                      >
                        {t("Permanent Relationship")}
                      </span>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          className={classes.label}
                        >
                          {t("First Planet")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.firstPlanet1}
                          onChange={(e) =>
                            this.handleFirstPlanetChange(
                              e,
                              "permanent_relations"
                            )
                          }
                          className={classes.select}
                          label="First Planet"
                        >
                          <MenuItem value={"Su"}>{t("Sun")}</MenuItem>
                          <MenuItem value={"Mo"}>{t("Moon")}</MenuItem>
                          <MenuItem value={"Ma"}>{t("Mars")}</MenuItem>
                          <MenuItem value={"Me"}>{t("Mercury")}</MenuItem>
                          <MenuItem value={"Ju"}>{t("Jupiter")}</MenuItem>
                          <MenuItem value={"Ve"}>{t("Venus")}</MenuItem>
                          <MenuItem value={"Sa"}>{t("Saturn")}</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          className={classes.label}
                        >
                          {t("Second Planet")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.secondPlanet1}
                          className={classes.select}
                          onChange={(e) =>
                            this.handleSecondPlanetChange(
                              e,
                              "permanent_relations"
                            )
                          }
                          label="Relation"
                        >
                          <MenuItem value={"Su"}>{t("Sun")}</MenuItem>
                          <MenuItem value={"Mo"}>{t("Moon")}</MenuItem>
                          <MenuItem value={"Ma"}>{t("Mars")}</MenuItem>
                          <MenuItem value={"Me"}>{t("Mercury")}</MenuItem>
                          <MenuItem value={"Ju"}>{t("Jupiter")}</MenuItem>
                          <MenuItem value={"Ve"}>{t("Venus")}</MenuItem>
                          <MenuItem value={"Sa"}>{t("Saturn")}</MenuItem>
                        </Select>
                      </FormControl>
                      <span
                        className="badge"
                        style={{
                          backgroundColor: deduceColor(this.state.result1),
                          padding: "20px",
                          fontSize: "18px",
                          minWidth: "200px",
                          marginLeft: "10px",
                          marginTop: "5px",
                          color: "white",
                          fontFamily: "Lucida Bright",
                        }}
                      >
                        {t(this.state.result1)}
                      </span>
                    </div>
                    <div className={classes.searchDiv}>
                      <span
                        className="badge"
                        style={{
                          backgroundColor: "#00adadff",
                          padding: "20px",
                          fontSize: "1.2em",
                          minWidth: "275px",
                          color: "white",
                          fontFamily: "Lucida Bright",
                        }}
                      >
                        {t("Temporary Relationship")}
                      </span>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          className={classes.label}
                        >
                          {t("First Planet")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.firstPlanet2}
                          onChange={(e) =>
                            this.handleFirstPlanetChange(
                              e,
                              "temporary_relations"
                            )
                          }
                          label="First Planet"
                          className={classes.select}
                        >
                          <MenuItem value={"Su"}>{t("Sun")}</MenuItem>
                          <MenuItem value={"Mo"}>{t("Moon")}</MenuItem>
                          <MenuItem value={"Ma"}>{t("Mars")}</MenuItem>
                          <MenuItem value={"Me"}>{t("Mercury")}</MenuItem>
                          <MenuItem value={"Ju"}>{t("Jupiter")}</MenuItem>
                          <MenuItem value={"Ve"}>{t("Venus")}</MenuItem>
                          <MenuItem value={"Sa"}>{t("Saturn")}</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          className={classes.label}
                        >
                          {t(" Second Planet")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          className={classes.select}
                          value={this.state.secondPlanet2}
                          onChange={(e) =>
                            this.handleSecondPlanetChange(
                              e,
                              "temporary_relations"
                            )
                          }
                          label="Relation"
                        >
                          <MenuItem value={"Su"}>{t("Sun")}</MenuItem>
                          <MenuItem value={"Mo"}>{t("Moon")}</MenuItem>
                          <MenuItem value={"Ma"}>{t("Mars")}</MenuItem>
                          <MenuItem value={"Me"}>{t("Mercury")}</MenuItem>
                          <MenuItem value={"Ju"}>{t("Jupiter")}</MenuItem>
                          <MenuItem value={"Ve"}>{t("Venus")}</MenuItem>
                          <MenuItem value={"Sa"}>{t("Saturn")}</MenuItem>
                        </Select>
                      </FormControl>
                      <span
                        className="badge"
                        style={{
                          backgroundColor: deduceColor(this.state.result2),
                          padding: "20px",
                          fontSize: "18px",
                          minWidth: "200px",
                          marginLeft: "10px",
                          marginTop: "5px",
                          color: "white",
                          fontFamily: "Lucida Bright",
                        }}
                      >
                        {t(this.state.result2)}
                      </span>
                    </div>
                    <div className={classes.searchDiv}>
                      <span
                        className="badge"
                        style={{
                          backgroundColor: "#00adadff",
                          padding: "20px",
                          fontSize: "1.2em",
                          minWidth: "275px",
                          color: "white",
                          fontFamily: "Lucida Bright",
                        }}
                      >
                        {t("Five-Fold Relationship")}
                      </span>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          className={classes.label}
                        >
                          {t("First Planet")}
                        </InputLabel>
                      
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.firstPlanet3}
                          onChange={(e) =>
                            this.handleFirstPlanetChange(
                              e,
                              "five_fold_relations"
                            )
                          }
                          label="First Planet"
                          className={classes.select}
                        >
                          <MenuItem value={"Su"}>{t("Sun")}</MenuItem>
                          <MenuItem value={"Mo"}>{t("Moon")}</MenuItem>
                          <MenuItem value={"Ma"}>{t("Mars")}</MenuItem>
                          <MenuItem value={"Me"}>{t("Mercury")}</MenuItem>
                          <MenuItem value={"Ju"}>{t("Jupiter")}</MenuItem>
                          <MenuItem value={"Ve"}>{t("Venus")}</MenuItem>
                          <MenuItem value={"Sa"}>{t("Saturn")}</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          className={classes.label}
                        >
                          {t("Second Planet")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={this.state.secondPlanet3}
                          onChange={(e) =>
                            this.handleSecondPlanetChange(
                              e,
                              "five_fold_relations"
                            )
                          }
                          label="Relation"
                          className={classes.select}
                        >
                          <MenuItem value={"Su"}>{t("Sun")}</MenuItem>
                          <MenuItem value={"Mo"}>{t("Moon")}</MenuItem>
                          <MenuItem value={"Ma"}>{t("Mars")}</MenuItem>
                          <MenuItem value={"Me"}>{t("Mercury")}</MenuItem>
                          <MenuItem value={"Ju"}>{t("Jupiter")}</MenuItem>
                          <MenuItem value={"Ve"}>{t("Venus")}</MenuItem>
                          <MenuItem value={"Sa"}>{t("Saturn")}</MenuItem>
                        </Select>
                      </FormControl>
                      <span
                        className="badge"
                        style={{
                          backgroundColor: deduceColor(this.state.result3),
                          padding: "20px",
                          fontSize: "18px",
                          minWidth: "200px",
                          marginLeft: "10px",
                          marginTop: "5px",
                          color: "white",
                          fontFamily: "Lucida Bright",
                        }}
                      >
                        {t(this.state.result3)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
export default withTranslation()(
  withStyles(styles, { withTheme: true })(PlanetRelations)
);
