import React, { Component } from "react";
import Select from "react-select";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import DatePicker from "react-date-picker";
import Button from "@material-ui/core/Button";
import "../Horoscope/MahaDasha.css";
import "./MahaDasha.css";
import Sideform from "../../SideComponents/sideform";
import ResetButton from "../ResetButton/ResetButton";
import { GlobalContext } from "../../../mycontext";
import { FiCalendar } from "react-icons/fi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import ImageMapper from "../ImageMapper.json";
import {getQueryParam} from '../../utils/utility';

const MonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

let filterOptions = [
  { value: "Vimsottari Dasha", label: "Vimsottari Dasha" },
  { value: "Yogini Dasha", label: "Yogini Dasha" },
];

const ordinalDate = (d) => {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const styles = (theme) => ({
  root: {
    "& > *": {},
  },
  demo: {
    // backgroundColor: theme.palette.background.paper,
    maxWidth: "100%",
    cursor: "pointer",
    padding: "0 0rem",
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  highlighted: {
    backgroundColor: "rgb(226,230,223)",
    color: "#fff",
  },
  list: {
    cursor: "pointer",
  },
});

function extract_image_link(name) {
  let imglink = "/assets/Planets/";
  return imglink + ImageMapper[name] + ".png";
}
let req_format = "";
let today = new Date();
var today_format =
  today.getDate() +
  "-" +
  (today.getMonth() >= 10
    ? today.getMonth() + 1
    : "0" + (today.getMonth() + 1)) +
  "-" +
  today.getFullYear();

var Mapper = {
  "Vimsottari Dasha": "vimsottari_dasha_days_only",
  "Yogini Dasha": "yogini_dasha",
  "विंशोत्तरी दशा": "vimsottari_dasha_days_only",
  "योगिनी दशा": "yogini_dasha",
};

const toDate = (dateString) => {
  var dateParts = dateString.split("-");

  // month is 0-based, that's why we need dataParts[1] - 1
  var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return dateObject;
};

const computeCurrentDashaDate = (data) => {
  let requiredData = data.vimsottari_dasha_days_only.mahadashalist;
  let today = new Date();
  let notToday = data.date_of_birth;
  if (
    today >= toDate(requiredData[0].start_date) &&
    today <= toDate(requiredData[requiredData.length - 1].start_date)
  ) {
    return moment(today).format("DD-MM-YYYY");
  } else {
    return notToday;
  }
};

class MahaDasha extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      // ... other state properties ...
      selectedLanguage: { value: 'English' }, // Default value
    };
    this.state = {
      mahaDashaData: [],
      currentMahaDasha: [],
      ArdhaDasha: [],
      PradhamaDasha: [],
      currentMaha: 0,
      currentPradhama: 0,
      dashaType: { value: "Vimsottari Dasha", label: "Vimsottari Dasha" },
      currentDate: null,
      isOpen: false,
      currentArdha: null,
      horoscopeData:JSON.parse(window.localStorage.getItem("horoscopeData")) || null,

      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false
    };
    this.FilterData = this.FilterData.bind(this);
  }

  componentDidMount() {
   // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // let googleAuthObj = JSON.parse(window.localStorage.getItem("googleAuthObj"));
    // if (googleAuthObj !== null && this.context.googleAuthObj === null) {this.context.setGoogleAuthObj(googleAuthObj);}

    if (localStorage.getItem("UserDefaultLanguage") !== "English" && localStorage.getItem("UserDefaultLanguage") !== "Hindi") {
      if (this.state.selectedLanguage && this.state.selectedLanguage.value) {
        localStorage.setItem("UserDefaultLanguage", this.state.selectedLanguage.value);
      } else {
        localStorage.setItem("UserDefaultLanguage", "English"); // Default to English if selectedLanguage is not set
      }
    }
    

    let queryString = getQueryParam('cb');
    let horoscopeData = JSON.parse(window.localStorage.getItem("horoscopeData"));
    let requestParams = JSON.parse(window.localStorage.getItem("requestParamsHoroscope"));
   
    if(queryString){
      horoscopeData = JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`));
      requestParams = JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`));
    }
    {
   

    if (requestParams !== null &&Object.keys(this.context.requestParamsHoroscope).length === 0 && !queryString) {
      this.context.setRequestParamsHoroscope(requestParams);
    }

    if (horoscopeData === null) {
      this.context.handleHoroscopeForm(true, "open");
    } else {
      this.setState({
        currentDate: computeCurrentDashaDate(horoscopeData),
        filterOptions: this.convertToLanguageMultiple(filterOptions),
        dashaType: this.convertToLanguageSingle(this.state.dashaType),
      });
     
      if(!queryString){
        this.context.setHoroscopeData(horoscopeData);
      }
      this.FilterData(this.state.dashaType.value, horoscopeData);
    }
    }
  }

  componentDidUpdate(prevProps) {
    if ( this.props.horoscopeData !== prevProps.horoscopeData ||  this.props.t !== prevProps.t) {
      this.setState(
        {
          currentDate: computeCurrentDashaDate(this.state.horoscopeData),
          filterOptions: this.convertToLanguageMultiple(filterOptions),
          dashaType: this.convertToLanguageSingle(this.state.dashaType),
        },
        () =>
          this.FilterData(this.state.dashaType.value, this.state.horoscopeData)
      );
    }
  }
  

  Datify = (date) => {
    if (date !== null || date !== "") {
      date = date.split(" ")[0];
      if (date === "00-00-0000") {
        return "";
      }

      let arr = date.split("-");
      let day;
      if (arr[0] < 10) {
        day = arr[0].replace("0", "");
      } else {
        day = arr[0];
      }

      return localStorage.getItem("UserDefaultLanguage") === "English"
        ? day +
            ordinalDate(day) +
            " " +
            this.props.t(MonthNames[arr[1] - 1]) +
            ", " +
            arr[2]
        : day + " " + this.props.t(MonthNames[arr[1] - 1]) + ", " + arr[2];
    }
  };

  checkDate(lord, current, start, end) {
    if (
      moment(current, "DD-MM-YYYY") >= moment(start, "DD-MM-YYYY") &&
      moment(current, "DD-MM-YYYY") <= moment(end, "DD-MM-YYYY")
    ) {
      return true;
    } else {
      return false;
    }
  }

  FilterData(dashaType, horoscopeData) {
    var required = Mapper[dashaType];
    var mahadashaData = horoscopeData[required].mahadashalist;
    let state_currentDate = computeCurrentDashaDate(horoscopeData);
    //  console.log("state------",this.state.currentDate);
    //  console.log("state_currentDate------",state_currentDate);
    for (let iter in mahadashaData) {
      let current = mahadashaData[iter];
      console.log("Current FilterData -->",current);

      if (
        moment(state_currentDate, "DD-MM-YYYY") >=
          moment(current.start_date.split(" ")[0], "DD-MM-YYYY") &&
        moment(state_currentDate, "DD-MM-YYYY") <=
          moment(current.end_date.split(" ")[0], "DD-MM-YYYY")
      ) {
        this.setState(
          (state) => {
            return {
              currentMahaDasha: current,
              currentMaha: iter,
              ArdhaDasha: current.sub_list,
            };
          },
          () => this.FilterPradhamaDasha(null)
        );
        break;
      } else {
        // console.log("DATE NOT GREATER");
      }
    }
  }

  nextMaha(dashaType) {
    var required = Mapper[dashaType.value];
    let requiredData =this.state.horoscopeData[required].mahadashalist[this.state.currentMaha];
    console.log("requiredData nextMaha",requiredData);
    this.setState(
      (state) => {
        return {
          currentMahaDasha: requiredData,
          ArdhaDasha: requiredData.sub_list,
          currentDate: requiredData.start_date,
        };
      },
      () => this.FilterPradhamaDasha(0)
    );
  }

  previousMaha(dashaType) {
    var required = Mapper[dashaType.value];
    let requiredData =this.state.horoscopeData[required].mahadashalist[this.state.currentMaha];
    console.log("requiredData previousMaha",requiredData);
    this.setState(
      (state) => {
        return {
          currentMahaDasha: requiredData,
          ArdhaDasha: requiredData.sub_list,
          currentDate: requiredData.start_date,
        };
      },
      () => this.FilterPradhamaDasha(0, this.state.horoscopeData)
    );
  }
  handleHighlight = (start_date, end_date, lord, index) => {
    //check mahaDasha on currentDate
    //today > from and today < to =  return highlight

    if (
      moment(this.state.currentDate, "DD-MM-YYYY") >
        moment(start_date, "DD-MM-YYYY") &&
      moment(this.state.currentDate, "DD-MM-YYYY") <
        moment(end_date, "DD-MM-YYYY")
    ) {
      return "highlighted";
    }
  };
  FilterPradhamaDasha(index) {
    if (index === null) {
      for (let iter in this.state.ArdhaDasha) {
        let current = this.state.ArdhaDasha[iter];
        if (
          moment(this.state.currentDate, "DD-MM-YYYY") >=
            moment(current.start_date.split(" "), "DD-MM-YYYY") &&
          moment(this.state.currentDate, "DD-MM-YYYY") <=
            moment(current.end_date.split(" "), "DD-MM-YYYY")
        ) {
          this.setState((state) => {
            return {
              currentArdha: iter,
              PradhamaStartDate: this.Datify(current.start_date),
              PradhamaEndDate: this.Datify(current.end_date),
              PradhamaDasha: current.sub_list,
            };
          });
        }
      }
    } else {
      this.setState({
        PradhamaDasha: this.state.ArdhaDasha[index].sub_list,
        currentArdha: index,
        PradhamaStartDate: this.Datify(this.state.ArdhaDasha[index].start_date),
        PradhamaEndDate: this.Datify(this.state.ArdhaDasha[index].end_date),
      });
    }
  }

  primaryArdhaText(text, sdate, edate) {
    return (
      <div className="d-flex">
        <span className="ArdhaPlanet text-left">{text + " "}</span>
        <span className="ArdhaDates">{this.Datify(sdate)}</span>
        <span className="ArdhaDates">{this.Datify(edate)}</span>
      </div>
    );
  }

  dateChange = (date) => {
    this.setState({ currentDate: moment(date).format("DD-MM-YYYY") }, () =>
      this.FilterData(this.state.dashaType.value, this.state.horoscopeData)
    );

    this.setState({ isOpen: !this.state.isOpen });
  };

  moveToNextMahaDasha = (dashaType) => {
    // document.getElementById("asOndate").setAttribute("style", "display:none");
    // document.getElementById("Lords").setAttribute("style", "display:none");

    this.setState({ currentMaha: Number(this.state.currentMaha) + 1 }, () =>
      this.nextMaha(dashaType)
    );
  };

  moveToPreviousMahaDasha = (dashaType) => {
    this.setState({ currentMaha: Number(this.state.currentMaha) - 1 }, () =>
      this.previousMaha(dashaType)
    );
  };

  handleDashaTypeChange = (e) => {
    if (e.value === "Yogini Dasha" || e.value === "योगिनी दशा") {
      let requiredData = this.state.horoscopeData.yogini_dasha.mahadashalist;

      if (
        moment(this.state.currentDate, "DD-MM-YYYY") >=
          moment(requiredData[0].start_date.split(" ")[0], "DD-MM-YYYY") &&
        moment(this.state.currentDate, "DD-MM-YYYY") <=
          moment(
            requiredData[requiredData.length - 1].start_date.split(" ")[0],
            "DD-MM-YYYY"
          )
      ) {
        this.setState(
          {
            dashaType: e,
          },
          () =>
            this.FilterData(
              this.state.dashaType.value,
              this.state.horoscopeData
            )
        );
      } else {
        this.setState(
          {
            dashaType: e,
            currentDate: this.state.horoscopeData.date_of_birth,
          },
          () =>
            this.FilterData(
              this.state.dashaType.value,
              this.state.horoscopeData
            )
        );
      }
    } else {
      let requiredData =
        this.state.horoscopeData.vimsottari_dasha_days_only.mahadashalist;

      if (
        moment(this.state.currentDate, "DD-MM-YYYY") >=
          moment(requiredData[0].start_date, "DD-MM-YYYY") &&
        moment(this.state.currentDate, "DD-MM-YYYY") <=
          moment(requiredData[requiredData.length - 1].start_date, "DD-MM-YYYY")
      ) {
        this.setState(
          {
            dashaType: e,
          },
          () =>
            this.FilterData(
              this.state.dashaType.value,
              this.state.horoscopeData
            )
        );
      } else {
        this.setState(
          {
            dashaType: e,
            currentDate: this.state.horoscopeData.date_of_birth,
          },
          () =>
            this.FilterData(
              this.state.dashaType.value,
              this.state.horoscopeData
            )
        );
      }
    }
  };
  handleChange = (e) => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleClick = (e) => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  convertToLanguageMultiple = (obj) => {
    return obj.map((item) => {
      return {
        ...item,
        label: this.props.t(item.label),
      };
    });
  };
  
  convertToLanguageSingle = (obj) => {
   
    const newObj = { ...obj }; 

    // Check if "omp_token" in localStorage is "English"
    if (localStorage.getItem("omp_token") === "English") {
        newObj["label"] = obj["value"];
    } else {
        newObj["label"] = this.props.t(obj["label"]);
    }

    return newObj;
};
  

  // convertToLanguageMultiple = (obj, t) => {
  //   if (obj !== null) {
  //     let obj2 = JSON.parse(JSON.stringify(obj));
  //     obj2.map((i, value) => {
  //       i["label"] = t(i["label"]);
  //       i["value"] = t(i["value"]);
  //       return i;
  //     });

  //     return obj2;
  //   }
  // };
  // convertToLanguageSingle = (obj, t) => {
  //   let obj2 = JSON.parse(JSON.stringify(obj));
  //   obj2["label"] = t(obj2["label"]);
  //   obj2["value"] = t(obj2["value"]);
  //   return obj2;
  // };

  render() {
    const { classes } = this.props;
    const { t, i18next } = this.props;
    console.log("horoscopeData",this.state.horoscopeData);
    console.log("currentMahaDasha render",this.state.currentMahaDasha);
    if (
      Object.keys(this.state.horoscopeData).length > 0 &&
      Object.keys(this.state.currentMahaDasha).length > 0
    ) {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"mahaDasha"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
          <div
            className="col-lg-12"
            style={{ overflow: "hidden", padding: "0px" }}
          >
            <div id="MahaDasha">
              <div className="card">
                <div className="card-body" style={{ padding: "0px 0px 0" }}>
                  {/* Desktop Header */}
                  <div className="HeaderDiv">
                    <div className="row">
                      <div className="col-lg-3 col-sm-3 col-12">
                        <label
                          style={{ float: "left" }}
                          className="basic-single-label"
                        >
                          <span className="dasha-name">
                            {" "}
                            {t("Dasha System")}:{" "}
                          </span>
                          <Select
                            className="dasha-system"
                            value={this.convertToLanguageSingle(
                              this.state.dashaType
                            )}
                            onChange={this.handleDashaTypeChange}
                            options={this.convertToLanguageMultiple(
                              filterOptions
                            )}
                            width={400}
                          />
                        </label>
                      </div>
                      <div className="col-lg-6 col-sm-4 col-12 pt-2">
                        <span className="dasha-name" id="asOndate">
                          {t("As on")}:&nbsp;&nbsp;
                          {this.Datify(this.state.currentDate, t)}
                        </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                          className="example-custom-input"
                          onClick={this.handleClick}
                        >
                          <img
                            src={require("../../../assets/img/icons/calendar.png")}
                            alt="calendar"
                            style={{ width: "24px" }}
                          />
                        </button>
                        {this.state.isOpen && (
                          <DatePicker
                            onChange={this.dateChange}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            inline
                            onClickOutside={this.handleClick}
                            selected={toDate(this.state.currentDate)}
                            minDate={toDate(
                              this.state.horoscopeData.date_of_birth
                            )}
                            maxDate={
                              this.state.dashaType.value ===
                                "Vimsottari Dasha" ||
                              this.state.dashaType.value === "विंशोत्तरी दशा"
                                ? toDate(
                                    this.state.horoscopeData
                                      .vimsottari_dasha_days_only.mahadashalist[
                                      this.state.horoscopeData
                                        .vimsottari_dasha_days_only
                                        .mahadashalist.length - 1
                                    ].start_date
                                  )
                                : toDate(
                                    this.state.horoscopeData.yogini_dasha.mahadashalist[
                                      this.state.horoscopeData.yogini_dasha
                                        .mahadashalist.length - 1
                                    ].start_date.split(" ")[0]
                                  )
                            }
                          />
                        )}
                      </div>
                      <div className="col-lg-3 col-sm-5 col-1">
                        <ResetButton />
                      </div>
                    </div>
                    <div className={classes.root}>
                      <div className="row mt-n2">
                        <div className="col-lg-4 col-4">
                          <Button
                            className="btn float-left btn-prev"
                            variant="contained"
                            onClick={(p) =>
                              this.moveToPreviousMahaDasha(this.state.dashaType)
                            }
                            disabled={
                              this.state.currentMaha <= 0 ? true : false
                            }
                          >
                            <img
                              src={require("../../../assets/img/prev-icon.png")}
                              alt="Prev Dasha"
                            />{" "}
                            &nbsp; <span>{t("Prev Maha Dasha")}</span>
                          </Button>
                        </div>

                        <div className="col-lg-4 col-4 relative">
                          <span className="mahadashaOf">
                            {" "}
                            {t("Maha dasha of")}
                          </span>{" "}
                          &nbsp;
                          <span className="MahaDashaName">
                            {this.state.dashaType.value === "Yogini Dasha" ||
                            this.state.dashaType.value === "योगिनी दशा" ? (
                              <></>
                            ) : (
                              <img
                                className="ArdhaTitleImages"
                                alt={this.state.currentMahaDasha.lord}
                                src={extract_image_link(
                                  this.state.currentMahaDasha.lord
                                )}
                              ></img>
                            )}
                            <span className="planet-name">
                              {this.state.currentMahaDasha.lord}
                            </span>
                          </span>
                        </div>
                        <div className="col-lg-4 col-4">
                          <Button
                            className="btn float-left btn-next"
                            onClick={(p) =>
                              this.moveToNextMahaDasha(this.state.dashaType)
                            }
                            disabled={
                              (this.state.currentMaha >= 8 &&
                                this.state.dashaType.value ===
                                  "Vimsottari Dasha") ||
                              this.state.dashaType.value === "विंशोत्तरी दशा"
                                ? true
                                : this.state.currentMaha >= 23 &&
                                  (this.state.dashaType.value ===
                                    "Yogini Dasha" ||
                                    this.state.dashaType.value === "योगिनी दशा")
                                ? true
                                : false
                            }
                          >
                            <span>{t("Next Maha Dasha")}</span> &nbsp;{" "}
                            <img
                              src={require("../../../assets/img/next-icon.png")}
                              alt="Next Dasha"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="LordNames" id="Lords">
                      {" "}
                      <span style={{marginRight:"5px"}}>{t("Current Dasha")}</span>
                      <span style={{ color: "#ff6767ff" }}>
                        {this.state.currentMahaDasha.lord}{" "}
                      </span>{" "}
                      &nbsp; - &nbsp;
                      {this.state.ArdhaDasha.map((ardha, index) => (
                        <div key={ardha + index}>
                          {String(this.state.currentArdha) === String(index) ? (
                            <span>{ardha.lord}</span>
                          ) : null}
                        </div>
                      ))}{" "}
                      &nbsp; - &nbsp;
                      {this.state.PradhamaDasha.map((pradhama, index) => (
                        <div key={pradhama + index}>
                          {moment(this.state.currentDate, "DD-MM-YYYY") >=
                            moment(pradhama.start_date, "DD-MM-YYYY") &&
                          moment(this.state.currentDate, "DD-MM-YYYY") <=
                            moment(pradhama.end_date, "DD-MM-YYYY") ===
                            true ? (
                            <span>{pradhama.lord}</span>
                          ) : null}
                        </div>
                      ))}
                    </div>
                    <div className="MahaDashaDates">
                      <b>{t("From")}:</b>
                      <span>
                        {" "}
                        {this.Datify(this.state.currentMahaDasha.start_date)}
                      </span>{" "}
                      &nbsp; &nbsp;
                      <b>{t("To")}:</b>{" "}
                      <span>
                        {this.Datify(this.state.currentMahaDasha.end_date)}
                      </span>
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col-lg-6 col-12">
                      <div className="ArdhaDashaDiv mb-3">
                        <div className="ArdhaDashaHeader">
                          <div className="title">{t("Antra Dasha")}</div>{" "}
                          <div className="ArdhaDashaDates pt-1">
                            <b>{t("From")}: </b>
                            <span>
                              {" "}
                              {this.Datify(
                                this.state.currentMahaDasha.start_date
                              )}
                              &nbsp; &nbsp;
                            </span>
                            <b>{t("To")} </b>{" "}
                            <span>
                              {" "}
                              {this.Datify(
                                this.state.currentMahaDasha.end_date
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="subheader d-flex">
                          <span>
                            {this.state.dashaType.value === "Yogini Dasha" ||
                            this.state.dashaType.value === "योगिनी दशा"
                              ? t("Lord Yogini")
                              : t("Lord Vimsottari")}
                          </span>
                          <span>{t("From")}</span>
                          <span>{t("To")}</span>
                        </div>
                        <List  className={classes.demo} dense={true}>
                          {this.state.ArdhaDasha.map((ardha, index) => (
                            <>
                              <ListItem
                                className={
                                  String(this.state.currentArdha) ===
                                  String(index)
                                    ? classes.highlighted
                                    : null
                                }
                                // className={this.state.currentArdha === index ? classes.highlighted : null}
                                primary={this.primaryArdhaText(
                                  ardha.lord,
                                  ardha.start_date,
                                  ardha.end_date,
                                  localStorage.setItem("ardhaLord", ardha.lord)
                                )}
                                onClick={() => this.FilterPradhamaDasha(index)}
                              >
                                {this.state.dashaType.value ===
                                  "Yogini Dasha" ||
                                this.state.dashaType.value === "योगिनी दशा" ? (
                                  <></>
                                ) : (
                                  <>
                                    <ListItemAvatar>
                                      <img
                                        className="ArdhaImages"
                                        alt={ardha.lord}
                                        src={extract_image_link(ardha.lord)}
                                      ></img>
                                    </ListItemAvatar>
                                  </>
                                )}

                                <ListItemText
                                className="py-1"
                                  primary={this.primaryArdhaText(
                                    ardha.lord,
                                    ardha.start_date,
                                    ardha.end_date
                                  )}
                                />
                              </ListItem>
                              <Divider />
                            </>
                          ))}
                        </List>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="PradhamaDashaDiv">
                        <div className="PradhamaDashaHeader">
                          <div className="title">{t("Pratyantar Dasha")}</div>{" "}
                          <div className="PradhamaDashaDates">
                            <b>{t("From")}: </b>
                            <span>{this.state.PradhamaStartDate} </span>
                            <b>{t("To")}: </b>{" "}
                            <span> {this.state.PradhamaEndDate}</span>
                          </div>
                        </div>
                        <div className="subheader d-flex">
                          <span>
                            {" "}
                            {this.state.dashaType.value === "Yogini Dasha" ||
                            this.state.dashaType.value === "योगिनी दशा"
                              ? t("Lord Yogini")
                              : t("Lord Vimsottari")}
                          </span>
                          <span>{t("From")}</span>
                          <span>{t("To")}</span>
                        </div>
                        {this.state.PradhamaDasha.length > 0 ? (
                          <List className={classes.demo} dense={true}>
                            {this.state.PradhamaDasha.map((pradhama, index) => (
                              <>
                                <ListItem
                                  className={
                                    this.checkDate(
                                      pradhama.lord,
                                      this.state.currentDate,
                                      pradhama.start_date,
                                      pradhama.end_date
                                    )
                                      ? classes.highlighted
                                      : null
                                  }
                                >
                                  {this.state.dashaType.value ===
                                    "Yogini Dasha" ||
                                  this.state.dashaType.value ===
                                    "योगिनी दशा" ? (
                                    <></>
                                  ) : (
                                    <ListItemAvatar>
                                      <img
                                        className="ArdhaImages"
                                        alt={pradhama.lord}
                                        src={extract_image_link(pradhama.lord)}
                                      ></img>
                                    </ListItemAvatar>
                                  )}
                                  <ListItemText
                                  className="mt-1"
                                    primary={this.primaryArdhaText(
                                      pradhama.lord,
                                      pradhama.start_date,
                                      pradhama.end_date
                                    )}
                                    secondary={null}
                                  />
                                </ListItem>
                                <Divider />
                              </>
                            ))}
                          </List>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Sideform
            show={this.context.horoscopeForm}
            mode={"Horoscope"}
            view={"mahaDasha"}
            handleClose={this.context.handleHoroscopeForm}
            reset={false}
            history={this.props.history}
          />
          <div>Loading ......</div>
        </>
      );
    }
  }
}

export default withTranslation()(
  withStyles(styles, { withTheme: true })(MahaDasha)
);
