import React, { useEffect, useRef } from "react";
import "react-bootstrap";
import DashboardTopBar from "../DashboardTopBar";
import DashboardSideBar from "../DashboardSideBar";
import DashboardFooter from "../DashboardFooter";
import { GlobalContext } from "../../../mycontext";
import { Route, withRouter } from "react-router-dom";
import loadjs from "loadjs";
import i18n from "../../../i18n";
import Events from "../../CelebrityManagement/Events";
import Tags from "../../CelebrityManagement/Tags";
import CreateHoroscope from "../../CelebrityManagement/CreateHoroscope";

const CelebrityManagementDashboard = (props) => {
  const context = React.useContext(GlobalContext);
  if (context.currentView === "Dashboard") {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  useEffect(() => {
    loadjs("assets/js/app.js", () => { });
    loadjs("assets/js/vendor.min.js", () => {});
    let language = localStorage.getItem("UserDefaultLanguage");
    if (language !== null) {
      if (language === "Hindi") {
        i18n.changeLanguage("hi");
      } else {
        i18n.changeLanguage("en");
      }
    }
  }, []);

  return (
    <>
      <DashboardTopBar />
      <DashboardSideBar />

      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <Route              
              path="/celebrityManagement/Tags"
              render={() => (
                <Tags
                  history={props.history}
                  //eventManagementData={context.eventManagementData}
                />
              )}
            />           
            <Route              
              path="/celebrityManagement/Events"
              render={() => (
                <Events
                  history={props.history}
                 // eventManagementData={context.eventManagementData}
                />
              )}
            />
             <Route              
              path="/celebrityManagement/createHoroscope"
              render={() => (
                <CreateHoroscope
                  history={props.history}
                 // eventManagementData={context.eventManagementData}
                />
              )}
            />
          </div>
        </div>
        {/* <DashboardFooter /> */}
      </div>
    </>
  );
};
export default withRouter(CelebrityManagementDashboard);
