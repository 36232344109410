import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ImageMapper from "./ImageMapper";

const AscendantTableHTML = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(()=>{
    if(props){
      console.log(props);
    }
  },[]);

  return (
    <>
    
      <table
        className="tablesaw table mb-0 tablesaw-stack"
        id="tablesaw-802"
        style={{ border: "none", borderTop: "none" }}
      >
        <tbody>
          <tr className="table_head_tr" style={{}}>
            <th scope="col" colSpan="5" className="sectionheader">
              {t("Ascendant Table")}
            </th>
          </tr>
        </tbody>
      </table>
      <table
        className="tablesaw table mb-0 tablesaw-stack"
        id="tablesaw-802 example-collapse-text"
        style={{ border: "none", borderTop: "none" }}
      >
        <tbody id="example-collapse-text">
          <tr>
            <td className="td1">
              <div className="d-lg-flex justify-content-between">
              <img src={props.asc[0][3]} alt="Rashi" className="RashiIcon" />
              <span className="tablelabel">{props.asc[0][0]}</span>
              </div>
            </td>
            <td className="td2">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[0][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[0][2] + " " + t("upto")}
                </span>
              )}
            </td>
            <td className="td3">
             <div className="d-lg-flex justify-content-between">
             <img src={props.asc[7][3]} alt="Rashi" className="RashiIcon2" />
              <span className="tablelabel">{props.asc[7][0]} </span>
             </div>
            </td>
            <td className="td">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[7][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[7][2] + " " + t("upto")}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td className="td1">
              <div className="d-lg-flex justify-content-between">
              <img src={props.asc[1][3]} alt="Rashi" className="RashiIcon" />
              <span className="tablelabel">{props.asc[1][0]} </span>
              </div>
            </td>
            <td className="td2">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[1][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[1][2] + " " + t("upto")}
                </span>
              )}
            </td>
            <td className="td3">
              <div className="d-lg-flex justify-content-between">
              <img src={props.asc[8][3]} alt="Rashi" className="RashiIcon2" />
              <span className="tablelabel">{props.asc[8][0]}</span>
              </div>
            </td>
            <td className="td4">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[8][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[8][2] + " " + t("upto")}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td className="td1">
            <div className="d-lg-flex justify-content-between">
            <img src={props.asc[2][3]} alt="Rashi" className="RashiIcon" />
              <span className="tablelabel">{props.asc[2][0]} </span>
            </div>
             </td>
            <td className="td2">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[2][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[2][2] + " " + t("upto")}
                </span>
              )}
            </td>
            <td className="td3">
            <div className="d-lg-flex justify-content-between">
              <img src={props.asc[9][3]} alt="Rashi" className="RashiIcon2" />
              <span className="tablelabel">{props.asc[9][0]} </span>
            </div>
            </td>
            <td className="td4">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[9][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[9][2] + " " + t("upto")}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td className="td1">
            <div className="d-lg-flex justify-content-between">
               <img src={props.asc[3][3]} alt="Rashi" className="RashiIcon" />
              <span className="tablelabel">{props.asc[3][0]} </span>
            </div>
            </td>
            <td className="td2">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[3][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[3][2] + " " + t("upto")}
                </span>
              )}
            </td>
            <td className="td3">
            <div className="d-lg-flex justify-content-between">
              <img src={props.asc[10][3]} alt="Rashi" className="RashiIcon2" />
              <span className="tablelabel">{props.asc[10][0]} </span>
            </div>
            </td>
            <td className="td4">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[10][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[10][2] + " " + t("upto")}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td className="td1">
              <div className="d-lg-flex justify-content-between">
              <img src={props.asc[4][3]} alt="Rashi" className="RashiIcon" />
              <span className="tablelabel">{props.asc[4][0]} </span>
              </div>
            </td>
            <td className="td2">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[4][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[4][2] + " " + t("upto")}
                </span>
              )}
            </td>
            <td className="td3">
              <div className="d-lg-flex justify-content-between">
              <img src={props.asc[11][3]} alt="Rashi" className="RashiIcon2" />
              <span className="tablelabel">{props.asc[11][0]} </span>
              </div>
            </td>
            <td className="td4">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[11][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[11][2] + " " + t("upto")}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td className="td1">
              <div className="d-lg-flex justify-content-between">
              <img src={props.asc[5][3]} alt="Rashi" className="RashiIcon" />
              <span className="tablelabel">{props.asc[5][0]} </span>
              </div>
            </td>
            <td className="td2">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[5][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[5][2] + " " + t("upto")}
                </span>
              )}
            </td>
            <td className="td3">
            <div className="d-lg-flex justify-content-between">
              <img src={props.asc[12][3]} alt="Rashi" className="RashiIcon2" />
              <span className="tablelabel">{props.asc[12][0]} </span>
            </div>
            </td>
            <td className="td4">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[12][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[12][2] + " " + t("upto")}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td className="td1">
              <div className="d-lg-flex justify-content-between">
              <img src={props.asc[6][3]} alt="Rashi" className="RashiIcon" />
              <span className="tablelabel">{props.asc[6][0]} </span>
              </div>
            </td>
            <td className="td2">
              {props.language === "English" ? (
                <span className="tablevalue">{"upto " + props.asc[6][2]}</span>
              ) : (
                <span className="tablevalue">
                  {props.asc[6][2] + " " + t("upto")}
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default AscendantTableHTML;
