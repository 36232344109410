import React, { useEffect, useRef,useState } from "react";
import "react-bootstrap";
import DashboardTopBar from "../DashboardTopBar";
import DashboardTopBarCelebrity from "../DashboardTopBarCelebrity";
import DashboardSideBar from "../DashboardSideBar";
import DashboardFooter from "../DashboardFooter";
import { GlobalContext } from "../../../mycontext";
import { Route, withRouter } from "react-router-dom";
import Chakra from "../../HoroscopeComponents/Chakras/Chakra";
import PlanetRelations from "../../HoroscopeComponents/PlanetRelationships/PlanetRelations";
import MahaDasha from "../../HoroscopeComponents/Horoscope/MahaDasha";
import AgeMahaDashaPlot from "../../HoroscopeComponents/AgeMahaDashaPlot/AgeMahaDashaPlot";
import AstakVarga from "../../HoroscopeComponents/AstakVarga/AstakVarga";
import BhavBala from "../../HoroscopeComponents/BhavBala/BhavBala";
import ShadBala from "../../HoroscopeComponents/ShadBala/ShadBala";
import SunBurstMahaDasha from "../../HoroscopeComponents/SunBurstCharts/SunBurstMahaDasha";
import DivisionalChart from "../../HoroscopeComponents/DivisionalCharts/DivisionalChart";
import InitHoroscopeDashboard from "../InitDashboards/InitHoroscopeDashboard";
import PlanetPositions from "../../HoroscopeComponents/PlanetPositions/PlanetPositions";
import PlanetRelationships from "../../HoroscopeComponents/PlanetRelationships/PlanetRelations";
import CView from "../../HoroscopeComponents/CView/CView";
import NatalCharts from "../../HoroscopeComponents/NatalCharts/NatalCharts";
import loadjs from "loadjs";
import NatalChart from "../../HoroscopeComponents/DivisionalCharts/Natal_chart";
import PlanetD3Reation from "../../HoroscopeComponents/PlanetRelationships/Planet-d3-realtion";
import YogaAnalizer from "../../HoroscopeComponents/YogaAnalizer/YogaAnalizer";
import i18n from "../../../i18n";
import {getQueryParam} from '../../utils/utility';

const HoroscopeDashboard = (props) => {
  const context = React.useContext(GlobalContext);
  const [isCelebrity, setIsCelebrity] = useState(false);
  if (context.currentView === "Dashboard") {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  useEffect(() => {
    let queryString = getQueryParam('cb');
    if(queryString){
      setIsCelebrity(true);
    }
    // loadjs("assets/js/app.js", () => { });
    loadjs("assets/js/vendor.min.js", () => {});
    let language = localStorage.getItem("UserDefaultLanguage");
    if (language !== null) {
      if (language === "Hindi") {
        i18n.changeLanguage("hi");
      } else {
        i18n.changeLanguage("en");
      }
    }
  }, []);

  return (
    <>
      
      {(isCelebrity)?<DashboardTopBarCelebrity />:<DashboardTopBar/>}
      <DashboardSideBar />

      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <Route
              exact
              path="/horoscope/basicdetails"
              render={() => (
                <Chakra  history={props.history} horoscopeData={context.horoscopeData}/>
              )}
            />
            <Route
              exact
              path={`/horoscope/cview`}
              render={() => (
                <CView history={props.history} horoscopeData={context.horoscopeData}/>
              )}
            />
            <Route
              exact
              path="/horoscope/natalcharts"
              render={() => (
                <NatalCharts history={props.history} horoscopeData={context.horoscopeData}/>
              )}
            />
            <Route
              exact
              path="/horoscope/planetaryRelations"
              render={() => (
                <PlanetRelationships
                  history={props.history}
                  horoscopeData={context.horoscopeData}
                />
              )}
            />
            <Route
              exact
              path="/horoscope/ageDashaPlot"
              render={(props) => <AgeMahaDashaPlot history={props.history} />}
            />
            <Route
              exact
              path="/horoscope/mahaDasha"
              render={() => (
                <MahaDasha
                  history={props.history}
                  horoscopeData={context.horoscopeData}
                />
              )}
            />
            <Route
              exact
              path="/horoscope/astakVarga"
              render={() => (
                <AstakVarga
                  history={props.history}
                  horoscopeData={context.horoscopeData}
                />
              )}
            />
            <Route
              exact
              path="/horoscope/bhavBala"
              render={() => (
                <BhavBala
                  history={props.history}
                  horoscopeData={context.horoscopeData}
                />
              )}
            />
            <Route
              exact
              path="/horoscope/shadBala"
              render={() => (
                <ShadBala
                  history={props.history}
                  horoscopeData={context.horoscopeData}
                />
              )}
            />
            <Route
              exact
              path="/horoscope/sunBurst"
              render={() => <SunBurstMahaDasha history={props.history} />}
            />
            <Route
              exact
              path="/horoscope/divisionalCharts"
              render={() => (
                <DivisionalChart
                  history={props.history}
                  horoscopeData={context.horoscopeData}
                />
              )}
            />
            <Route
              exact
              path="/horoscope/planetPositions"
              render={() => (
                <PlanetPositions
                  history={props.history}
                  horoscopeData={context.horoscopeData}
                />
              )}
            />
            <Route
              exact
              path="/horoscope/d3rel"
              render={() => (
                <PlanetD3Reation
                  history={props.history}
                  horoscopeData={context.horoscopeData}
                />
              )}
            />
           <Route
              exact
              path="/horoscope/yogaAnalizer"
              render={() => <YogaAnalizer                
                history={props.history}                
                horoscopeData={context.horoscopeData} />}
            />
          </div>
        </div>
        {/* <DashboardFooter /> */}
      </div>
    </>
  );
};
export default withRouter(HoroscopeDashboard);
