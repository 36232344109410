import React from "react";
import { useTranslation } from "react-i18next";
const TaraBalamHTML = (props) => {
  const { t, i18n } = useTranslation();
  if (typeof props.generictarabalam !== "undefined") {
    if (typeof props.generictarabalam.Next_Quality_Mappings !== "undefined") {
      var verygood =
        props.generictarabalam.Quality_Mappings[1].For_Janma_Nakshtra;
      var good = props.generictarabalam.Quality_Mappings[0].For_Janma_Nakshtra;
      var notgood =
        props.generictarabalam.Quality_Mappings[2].For_Janma_Nakshtra;
      var bad = props.generictarabalam.Quality_Mappings[3].For_Janma_Nakshtra;
      var verybad =
        props.generictarabalam.Quality_Mappings[4].For_Janma_Nakshtra;
      var verygoodnext =
        props.generictarabalam.Next_Quality_Mappings[1].For_Janma_Nakshtra;
      var goodnext =
        props.generictarabalam.Next_Quality_Mappings[0].For_Janma_Nakshtra;
      var notgoodnext =
        props.generictarabalam.Next_Quality_Mappings[2].For_Janma_Nakshtra;
      var badnext =
        props.generictarabalam.Next_Quality_Mappings[3].For_Janma_Nakshtra;
      var verybadnext =
        props.generictarabalam.Next_Quality_Mappings[4].For_Janma_Nakshtra;
      var date = new Date(props.generictarabalam.Upto);
      var fromtime =
        date.getHours() +
        ":" +
        (date.getMinutes() < 10 ? "0" : "") +
        date.getMinutes();
      return (
        <>
          <table
            className="tablesaw table mb-0 tablesaw-stack"
            id="tablesaw-802"
            style={{ border: "none", borderTop: "none" }}
          >
            <tbody>
              <tr className="table_head_tr">
                <th scope="col" colSpan="5" className="sectionheader">
                  <span>{t("Tara Balam")}</span>
                </th>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2 td2tbalam1">
                  <span className="tablevalue tbalamheading1">
                    {props.language === "English" ? (
                      <b>
                        {t("From")} 12:00 AM {t("to")}{" "}
                        {props.setTimeFormatNaks(fromtime)}
                      </b>
                    ) : (
                      <b>
                        12:00 AM {t("From")} {props.setTimeFormatNaks(fromtime)}{" "}
                        {t("to")}{" "}
                      </b>
                    )}
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4 td4tbalam2">
                  <span className="tablevalue tbalamheading2">
                    {props.language === "English" ? (
                      <b>
                        {t("From")} {props.setTimeFormatNaks(fromtime)}{" "}
                        {t("to Rest of the Day")}
                      </b>
                    ) : (
                      <b>
                        {props.setTimeFormatNaks(fromtime)} {t("From")}{" "}
                        {t("to Rest of the Day")}
                      </b>
                    )}
                  </span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel VeryGood"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Very Good")} </b>
                    </i>
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel VeryGood"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Very Good")} </b>
                    </i>
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">{verygood.join(", ")}</span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue">{verygoodnext.join(", ")}</span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel Good"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Good")} </b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel Good"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Good")} </b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">{good.join(", ")}</span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue">{goodnext.join(", ")}</span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel NotGood"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Not Good")} </b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel NotGood"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Not Good")} </b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">{notgood.join(", ")}</span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue">{notgoodnext.join(", ")}</span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel Bad"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Bad")}</b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel Bad"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Bad")}</b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">{bad.join(", ")}</span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue">{badnext.join(", ")}</span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel VeryBad"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Totally Bad")} </b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel VeryBad"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Totally Bad")} </b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">{verybad.join(", ")}</span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue">{verybadnext.join(", ")}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    } else {
      verygood = props.generictarabalam.Quality_Mappings[1].For_Janma_Nakshtra;
      good = props.generictarabalam.Quality_Mappings[0].For_Janma_Nakshtra;
      notgood = props.generictarabalam.Quality_Mappings[2].For_Janma_Nakshtra;
      bad = props.generictarabalam.Quality_Mappings[3].For_Janma_Nakshtra;
      verybad = props.generictarabalam.Quality_Mappings[4].For_Janma_Nakshtra;
      return (
        <>
          <table
            className="tablesaw table mb-0 tablesaw-stack"
            id="tablesaw-802"
            style={{ border: "none", borderTop: "none" }}
          >
            <tbody>
              <tr className="table_head_tr">
                <th scope="col" colSpan="5" className="sectionheader">
                  <span>{t("Tara Balam")}</span>
                </th>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2 td2tbalam1">
                  <span className="tablevalue tbalamheading1">
                    <b>
                      {t("From")}12:00 AM {t("to Rest of the Day")}
                    </b>
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4 td4tbalam2">
                  <span className="tablevalue"></span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel VeryGood"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Very Good")} </b>
                    </i>
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue"></span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">{verygood.join(", ")}</span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue"></span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel Good"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Good")} </b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue"></span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">{good.join(", ")}</span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue"></span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel NotGood"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Not Good")} </b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue"></span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">{notgood.join(", ")}</span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue"></span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel Bad"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Bad")}</b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel "></span>
                </td>
                <td className="td4">
                  <span className="tablevalue"></span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">{bad.join(", ")}</span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue"></span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel VeryBad"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">
                    <i>
                      <b>{t("Totally Bad")} </b>
                    </i>{" "}
                    {t("for Janma Nakshtra")}:
                  </span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue"></span>
                </td>
              </tr>
              <tr className="taraBalamTable">
                <td className="td1">
                  <span className="tablelabel"></span>
                </td>
                <td className="td2">
                  <span className="tablevalue">{verybad.join(", ")}</span>
                </td>
                <td className="td3">
                  <span className="tablelabel"></span>
                </td>
                <td className="td4">
                  <span className="tablevalue"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
  } else {
    return null;
  }
};

export default TaraBalamHTML;
