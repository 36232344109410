import { withRouter, useHistory } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import DashboardTopBar from "../../DashBoardComponents/DashboardTopBar";
import CelebrityUserSidebar from "../../DashBoardComponents/DashboardSideBarTypes/CelebrityUserSideBar";
import { motionToNumMap, getPlanetNameFromPlanetCode, nakshatraNameToNumMap, planetCodes, rashiNumberToNameMap } from "../astralConstants"
import { InNakshatra, InRashi, exhaustive, hasMotion, nonExhaustive } from "./operators";
import { D1, D2, D3, D4, D7, D9, D10, D12, D16, D20, D24, D27, D30, D40, D45, D60 } from "./charts";
import { saveTemplate, getTemplateNames, getAllTemplates } from "../../../API/CelebrityManagement_API";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import DisplayResults from "./displayResults";
import "./querybuilder.css"

const QueryBuilder = (props) => {
    let history = useHistory();
    const [rowCount, setRowCount] = useState(0);
    const [form, setForm] = useState('')
    // const [planetNames, setPlanetNames] = useState(["Ascendant", 'Sun', 'Moon', 'Mars', 'Mercury', 'Jupiter', 'Venus', 'Saturn', 'Rahu', 'Ketu'])
    const [formArray, setFormArray] = useState([]);
    const [query, setQuery] = useState({});
    const [options, setOptions] = useState([])
    const [chartTypes, setChartTypes] = useState(["Natal Chart", 'D2', 'D3', 'D4',
        'D7', 'D9', 'D10', 'D12',
        'D16', 'D20', 'D24', 'D27',
        'D30', 'D40', 'D45', 'D60'])
    const [category, setCategory] = useState(1);
    const chartIndex = 0;
    const planetIndex = 1;
    const operatorIndex = 2;
    const valueIndex = 3;
    const noOfColumns = 4;



    const [name_to_planet_obj_map, setNameToOperatorMap] = useState({});
    const [chart_name_to_obj_map, setChartNameToObjectMap] = useState({});
    const [showTemplateSaveModal, setShowTemplateSaveModal] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [templateDescription, setTemplateDescription] = useState('')
    const [templateDescriptionDisplay, setTemplateDescriptionDisplay] = useState('')
    const [templateCategory, setTemplatecategory] = useState()
    const [responseModal, setResponseModal] = useState('')
    const [initailResponse, setInitialResponse] = useState();

    const [showQueryDiv, setShowQueryDiv] = useState(true);
    const [showResultDiv, setShowResultDiv] = useState(true);
    const [templateNameValidatorString, setTemplateNameValidatorString] = useState("");


    const [searchbytemplatename, setSearchbytemplatename] = useState(false);
    const [templateNameToSearch, setTemplateToSearch] = useState(undefined);
    const [searchByTemplateQuery, setsearchByTemplateQuery] = useState({});
    const [allTemplateNames, setAllTemplateNames] = useState([]);

    const [loading, setLoading] = useState(false);

    const [resultsComponent, setResultsConponents] = useState("")
    const [deleteRowHoverText, setDeleteRowHoverText] = useState(false)
    const [activeIndex, setActiveIndex] = useState(-1);
    const [showhelp, setShowhelp] = useState(false);

    // const [filters, setFilters] = useState(JSON.parse(localStorage.getItem(filterStorageKeyName))||
    // {gender:undefined, country: undefined, from_year: undefined,to_year:undefined,tags:undefined})

    const [aliastoOperatorNameMap, setAliastoOperatorNameMap] = useState({})
    const planet_name_to_alias_map = {
        'Ascendant': 'asc', 'Sun': 'su', 'Moon': 'mo', 'Mars': 'ma', 'Mercury': 'me',
        'Jupiter': 'ju', 'Venus': 've', 'Saturn': 'sa', 'Rahu': 'ra', "Ketu": 'ke',
        'Lord of 1st house': 'h1l', 'Lord of 2nd house': 'h2l',
        'Lord of 3rd house': 'h3l', 'Lord of 4th house': 'h4l',
        'Lord of 5th house': 'h5l', 'Lord of 6th house': 'h6l',
        'Lord of 7th house': 'h7l', 'Lord of 8th house': 'h8l',
        'Lord of 9th house': 'h9l', 'Lord of 10th house': 'h10l',
        'Lord of 11th house': 'h11l', 'Lord of 12th house': 'h12l'
    };
    const planet_alias_to_name_map = {};
    for (var p_name in planet_name_to_alias_map) {
        var val = planet_name_to_alias_map[p_name];
        planet_alias_to_name_map[val] = p_name;
    }

    const OK = 0;
    const STOP = 1;

    const createChartMap = () => {
        var d1 = new D1()
        var d2 = new D2()
        var d3 = new D3();
        var d4 = new D4();
        var d7 = new D7();
        var d9 = new D9();
        var d10 = new D10();
        var d12 = new D12();
        var d16 = new D16();
        var d20 = new D20();
        var d24 = new D24();
        var d27 = new D27();
        var d30 = new D30();
        var d40 = new D40();
        var d45 = new D45()
        var d60 = new D60();

        var n_o_m = {
            "Natal Chart": d1, 'D2': d2, 'D3': d3, 'D4': d4,
            'D7': d7, 'D9': d9, 'D10': d10, 'D12': d12,
            'D16': d16, 'D20': d20, 'D24': d24, 'D27': d27,
            'D30': d30, 'D40': d40, 'D45': d45, 'D60': d60
        };
        return n_o_m;
    }

    const toggleSection = (index) => {
        setActiveIndex(activeIndex === index ? -1 : index);
    };

    useEffect(() => {
        var n_o_m = createChartMap();
        setChartNameToObjectMap(n_o_m);

        const setTempNames = async () => {
            // var temp_names = await getTemplateNames();
            var temp_names = await getAllTemplates();
            var t_array = temp_names.detail;
            setAllTemplateNames(t_array);
            console.log(t_array, "All templates");
        }
        setTempNames();

        //Not using asc , sun or moon beacuseit does not have all operators
        //Set aliase to operator name map , used to decode query when quering by template name
        var t_map = {};
        var operators = n_o_m["Natal Chart"].planet_name_to_object_map.Mars.operators
        operators.forEach(op => t_map[op.alias] = op.name)
        setAliastoOperatorNameMap(t_map);
    }, []);

    useEffect(() => {
        setForm(renderForm());
    }, [rowCount, formArray,]);

    useEffect(() => {
        decodeAndSetQuery(searchByTemplateQuery);
    }, [searchByTemplateQuery])


    const renderResults = (query, _searchbyTemplateName = searchbytemplatename, _temp_name = templateNameToSearch) => {
        return (<>
            <DisplayResults
                query={query}
                searchByTemplateNameFlag={_searchbyTemplateName}
                templateName={_temp_name}
                setCategory={setCategory}
                category={category}
                setLoading={setLoading}
                setsearchByTemplateQuery={setsearchByTemplateQuery}

            />
        </>)
    }

    const chartTypeSelected = (chartType) => {
        var temp = [...formArray];
        temp[rowCount - 1] = []
        temp[rowCount - 1][chartIndex] = chartType;
        setFormArray(temp);
    }
    const planetNameSelected = (planetname) => {
        // isAscendantSelectedForChart(rowCount-1);
        var temp = [...formArray];
        if (!temp[rowCount - 1] || temp[rowCount - 1].length < planetIndex) {
            alert('Chart not selected but trying to set planet');
            return;
        }
        if (temp[rowCount - 1].length > planetIndex) {
            temp[rowCount - 1] = temp[rowCount - 1].slice(0, planetIndex);
        }
        temp[rowCount - 1][planetIndex] = planetname;
        setFormArray(temp);
    }

    const operatorSelected = (operatorName) => {
        // isAscendantSelectedForChart(rowCount-1);
        var temp = [...formArray];
        if (!temp[rowCount - 1] || temp[rowCount - 1].length < planetIndex) {
            alert('planet or chart not set but tring to set operator Aborting...');
            return;
        }
        if (temp[rowCount - 1].length > operatorIndex) {
            temp[rowCount - 1] = temp[rowCount - 1].slice(0, operatorIndex);
        }
        temp[rowCount - 1][operatorIndex] = operatorName;
        setFormArray(temp);
        setOptionsForOperator(rowCount - 1, temp);
    }

    const setOptionsForOperator = (index, formarray) => {
        // var p = chart_name_to_obj_map[formArray[index][chartIndex]].planet_name_to_object_map[formArray[index][planetIndex]]
        var p = getPlanetObjectForIndex(index);

        //name_to_planet_obj_map[formarray[index][planetIndex]]
        var v;
        p.operators.forEach(element => {
            if (element.name === formarray[index][operatorIndex]) {
                v = element.values;
            }
        });
        if (v) {
            var k = [...options]
            k[index] = v
            setOptions(k);
            return;
        }
        alert(index + "operator not found so values can't be loaded")

    }

    const getOperatorObject = (index, formarray) => {
        // var p = chart_name_to_obj_map[formArray[index][chartIndex]].planet_name_to_object_map[formarray[index][planetIndex]]
        var p = getPlanetObjectForIndex(index);
        var v;
        p.operators.forEach(element => {
            if (element.name === formarray[index][operatorIndex]) {
                v = element;
            }
        });
        return v;
    }

    const isAscendantSelectedForChart = (index) => {
        var temp = [...formArray]
        var chartType = temp[index][chartIndex]
        if (!chart_name_to_obj_map.hasOwnProperty(chartType)) {
            alert('Please select ascendant rashi for this chart or you cannot select it later');
            return;
        }
        var planetname = temp[index][planetIndex]
        if (!chart_name_to_obj_map.hasOwnProperty(chartType) && planetname !== 'Ascendant') {
            alert('Please select ascendant rashi for this chart or you cannot select it later');
            return;
        }
        var operator = temp[index][planetIndex]
        if (!chart_name_to_obj_map.hasOwnProperty(chartType) && operator !== InRashi.name) {
            alert('Please select ascendant rashi for this chart or you cannot select it later');
            return;
        }
        var val = temp[index][valueIndex]

        // else if(!chart_name_to_obj_map.hasOwnProperty(chartType)&&planetname==='Ascendant'){
        // var k = chartType.toLowerCase();
        var k = chartType;
        if (chartType === chartTypes[0]) {
            k = 'D1'
        }
        var n = { ...chart_name_to_obj_map };
        n[chartType] = new k(val);
        setChartNameToObjectMap(n);
        // }
    }

    const valueSelected = async (val) => {
        var temp = [...formArray];
        // isAscendantSelectedForChart(rowCount-1);
        if (!temp[rowCount - 1] || temp[rowCount - 1].length < valueIndex) {
            alert('planet not set or operator not set at ${0} but tring to set value Aborting...', rowCount - 1);
            return;
        }
        if (temp[rowCount - 1].length > valueIndex) {
            temp[rowCount - 1] = temp[rowCount - 1].slice(0, valueIndex);
        }
        temp[rowCount - 1][valueIndex] = val;
        setFormArray(temp);
        // alert('updating planets');
        var r = await updatePlanets();
        // alert('planets updated');
    }

    const getPlanetObjectForIndex = (index) => {
        var chart_obj = chart_name_to_obj_map[formArray[index][chartIndex]];
        var planet_map = chart_obj.planet_name_to_object_map;
        var planet_obj = planet_map[formArray[index][planetIndex]];
        return planet_obj;
    }

    const deleteRow = (index) => {
        //delete that row, shift array and deselect all the settings
        var rowArray = formArray[index];
        var temp = [];
        if (rowArray.length < noOfColumns) {
        }
        else {
            var selected_planet_object = getPlanetObjectForIndex(index);
            // var planet_map = chart_name_to_obj_map[rowArray[chartIndex]].planet_name_to_object_map;
            var deselectfunction;
            var op;
            selected_planet_object.operators.forEach(element => {
                if (element.name === rowArray[operatorIndex]) {
                    deselectfunction = element.deselectFunction;
                    op = element;
                }
            });

            if (op.alias === 'm') {
                deselectfunction(rowArray[valueIndex])
            }
            deselectfunction(true, rowArray[valueIndex])
        }

        for (var i = 0; i < formArray.length; i++) {
            if (i !== index) {
                temp.push(formArray[i]);
            }
        }
        var tempOptions = []
        for (var i = 0; i < options.length; i++) {
            if (i !== index) {
                tempOptions.push(options[i]);
            }
        }
        // console.log(temp);
        setOptions(tempOptions)
        setFormArray(temp);
        setRowCount(rowCount - 1)
    }

    const updatePlanets = () => {
        var rowArray = formArray[rowCount - 1];
        // var p = chart_name_to_obj_map[formArray[index][chartIndex]].planet_name_to_object_map[rowArray[planetIndex]];
        var selected_planet_object = getPlanetObjectForIndex(rowCount - 1);
        var planet_map = chart_name_to_obj_map[rowArray[chartIndex]].planet_name_to_object_map;
        var v;
        var op;
        selected_planet_object.operators.forEach(element => {
            if (element.name === rowArray[operatorIndex]) {
                v = element.callFunction;
                op = element;
            }
        });

        if (rowArray[planetIndex] !== "Ascendant") {
            var otherPlanets = []
            for (var i in planet_map) {
                if (i !== rowArray[planetIndex]) {
                    otherPlanets.push(planet_map[i])
                }
            }
            var currentsession = [];
            var data;
            if (['nk', 'asp_p', 'conj'].includes(op.alias)) {
                data = rowArray[valueIndex]
            } else {
                data = parseInt(rowArray[valueIndex])
            }
            if (op.alias === 'm') {
                v(parseInt(rowArray[valueIndex]))
            }
            else {
                v(currentsession, [data], otherPlanets, planet_map.Ascendant, true);
            }

        }
        else {
            if (op.alias === 'nk') {
                v([rowArray[valueIndex]])
            } else {
                v([parseInt(rowArray[valueIndex])], true)
            }
        }

    }

    const getOptionLabelValue = (index, item) => {
        return formArray[index][operatorIndex] === "In Nakshatra"
            ? `${String(item)}(${nakshatraNameToNumMap[String(item)]})` : (formArray[index][operatorIndex] === "In Rashi"
                ? `${rashiNumberToNameMap[parseInt(item)]}(${String(item)})` : String(item));
    }
    const renderForm = () => {
        //TODO: freeze possible options of last selected planet operator set,with its value
        var a = [...Array(rowCount).keys()]
        return (<div>
            {(formArray?.length > 0) ?
                <>
                    <div className="row">
                        <div className="col border bg-light">
                            Chart Type
                        </div>
                        <div className="col border bg-light">
                            Planet
                        </div>
                        <div className="col border bg-light">
                            Operator
                        </div>
                        <div className="col border bg-light">
                            Value
                        </div>
                        <div className="col">

                        </div>
                    </div>
                </> : ''}
            {a.map((index) => {
                return (
                    <>
                        <div className="row" id={index}>

                            {(formArray?.[index]?.length === noOfColumns) ? (
                                <>
                                    <div className="col select-dropdown border">
                                        {formArray[index][chartIndex]}
                                    </div>
                                    <div className="col select-dropdown border">
                                        {formArray[index][planetIndex]}
                                    </div>
                                    <div className="col select-dropdown border">
                                        {formArray[index][operatorIndex]}
                                    </div>
                                    <div className="col select-dropdown border">
                                        {/* {formArray[index][valueIndex]} */}
                                        {getOptionLabelValue(index, formArray[index][valueIndex])}
                                    </div>
                                    {!searchbytemplatename ?
                                        <div className="col select-dropdown">
                                            <Button className="btn btn-danger" onClick={e => deleteRow(index)}
                                            >
                                                <i class="fa fa-trash" aria-hidden="true"></i>{deleteRowHoverText ? "Delete row" : ""}
                                            </Button>
                                        </div> : ""}
                                </>) : (<>
                                    <div className="col select-dropdown">
                                        <select className="form-control" onChange={e => chartTypeSelected(e.target.value)}
                                            value={formArray?.[index] ? formArray[index][chartIndex] : "Select Chart Type"}
                                        >
                                            <option disabled={true} selected={true}>Select Chart Type</option>
                                            {chartTypes.map(item => {
                                                return (<option>{item}</option>)
                                            })}
                                        </select>
                                    </div>
                                    <div className="col select-dropdown">
                                        {formArray?.[index]?.length >= planetIndex ? (
                                            <select className="form-control" onChange={e => planetNameSelected(e.target.value)} value={formArray[index].length === planetIndex ? "Select Planet" : formArray[index][planetIndex]}>
                                                <option disabled={true} selected={true} value="Select Planet">Select Planet</option>
                                                {/* {planetNames.map(item => { */}
                                                {Object.keys(chart_name_to_obj_map[formArray[index][chartIndex]].planet_name_to_object_map).map(item => {
                                                    return chart_name_to_obj_map[formArray[index][chartIndex]].planet_name_to_object_map[item].selected ? "" : (<option value={item}>{item}</option>)
                                                })}
                                            </select>
                                        ) : ""}
                                    </div>
                                    <div className="col select-dropdown">
                                        {formArray?.[index]?.length >= operatorIndex ? (
                                            <select className="form-control" onChange={e => operatorSelected(e.target.value)}
                                                value={formArray[index].length === operatorIndex ? "Select Operator" : formArray[index][operatorIndex]}>
                                                <option disabled={true} selected={true} value="Select Operator">Select Operator</option>
                                                {/* {name_to_planet_obj_map[formArray[index][planetIndex]].operators.map(item => { */}
                                                {chart_name_to_obj_map[formArray[index][chartIndex]].planet_name_to_object_map[formArray[index][planetIndex]].operators.map(item => {
                                                    return item.selected ? "" : (<option value={String(item.name)} name={String(item.name)}>{String(item.name)}</option>)
                                                })}
                                            </select>
                                        ) : ''}
                                    </div>
                                    <div className="col select-dropdown">
                                        {formArray?.[index]?.length >= valueIndex ? (
                                            <select className="form-control" onChange={e => valueSelected(e.target.value)}>
                                                <option disabled={true} selected={true}>Select Value</option>
                                                {
                                                    options[index]?.map(item => {
                                                        return (<option value={item} name={item}>{getOptionLabelValue(index, String(item))}</option>)
                                                    })}
                                            </select>
                                        ) : ''}
                                    </div>
                                    {(formArray?.[index]?.length > 0 && !searchbytemplatename) ? <div className="col select-dropdown">
                                        <Button className="btn btn-light" onClick={e => deleteRow(index)}>
                                            <i class="fa fa-trash" aria-hidden="true"></i>
                                        </Button>
                                    </div> : ""}
                                </>)}
                        </div>
                    </>

                )
            })}
        </div>);
    }

    const addRow = () => {
        // TODO:validate that previous row has valid values
        if (rowCount > 0 && rowCount === formArray.length && formArray[formArray.length - 1].length === noOfColumns) {
            setRowCount(rowCount + 1)
        } else if (rowCount === 0) {
            setRowCount(rowCount + 1)
        }
        else {
            alert("Please select values is current row first");
        }

    }

    const runQuery = async () => {
        if (searchbytemplatename === true) {
            setSearchbytemplatename(false);
            setTemplateToSearch(undefined);
            setTemplateDescriptionDisplay("");
            setTemplatecategory(undefined);
            setTemplateName(undefined);
        }
        var q = await createQuery();
        if (q === STOP) {
            return;
        }
        setLoading(true);
        // var res = await queryBuilderSearch(q);
        // console.log(res)
        // if (res !== undefined) {
        //     // localStorage.setItem("queryformArray", JSON.stringify(formArray));

        //     // history.push({
        //     //     pathname: "/celebrity/querybuilder/results",
        //     //     state: { res: res.data, query:q }
        //     // });
        //     if (res.status === 200) {
        //         setInitialResponse({
        //             res: res.data.detail.results, number_of_pages: res.data.detail.number_of_pages,
        //             page_no: res.data.detail.page_no, tags_dict: res.data.detail.tags_dict
        //         });
        //         setShowResultDiv(true);
        //     } else {
        //         alert("An error occured");
        //     }

        // }
        var r = renderResults(q, false, undefined);
        setResultsConponents(r);
    }

    const isQueryEmpty = () => {
        if (formArray.length === 0 || formArray[formArray.length - 1].length !== noOfColumns) {
            return true;
        }
        return false;
    }

    const createQuery = async () => {
        var isqueryempty = isQueryEmpty();
        if (isqueryempty) {
            alert("please create a Valid Query");
            return STOP;
        }
        // const operator_to_alias_map ={'House':'_h','Rashi':'_r','Nakshatra':'_nk',
        // ''}
        // alert('Starting Query Processing')
        var q = {}
        var index = -1;
        formArray.forEach(row => {
            index += 1;
            var chart_json = {}
            var chart = row[chartIndex];
            if (chart === chartTypes[0]) {
                chart = 'D1';
            }
            if (q.hasOwnProperty(chart)) {
                chart_json = q[chart]
            }
            //create key
            var key_ = '';
            var opObj = getOperatorObject(index, formArray);
            key_ = planet_name_to_alias_map[row[planetIndex]] + "_" + opObj.alias;
            if (key_ === 'asc_r') {
                key_ = 'ascendant';
            }
            if (key_ === 'asc_nk') {
                key_ = 'asc_nak';
            }
            // console.log(key_)
            //pass array of planet codes for non exhaustive
            if (opObj.type === nonExhaustive) {
                var arr = []
                if (chart_json.hasOwnProperty(key_)) {
                    arr = chart_json[key_];
                }
                if (opObj.alias === 'conj' || opObj.alias === 'asp_p') {
                    var pl_code = planetCodes[row[valueIndex]];
                    if (arr.includes(pl_code)) {
                        alert(row + 'Same planet code occuring twice index=' + index)
                    }
                    arr.push(pl_code)
                } else {
                    arr.push(parseInt(row[valueIndex]))
                }
                chart_json[key_] = arr;
                q[chart] = chart_json;
                return;
            }
            //pass nak num for nak
            if (opObj.alias === 'nk') {
                var nak_num = nakshatraNameToNumMap[row[valueIndex]];
                chart_json[key_] = nak_num;
                q[chart] = chart_json;
                return;
            }
            if (opObj.alias === 'm') {
                chart_json[key_] = motionToNumMap[row[valueIndex]];
                q[chart] = chart_json;
                return;
            }
            //others are same
            //it will be house rashi or motion
            chart_json[key_] = parseInt(row[valueIndex]);
            q[chart] = chart_json;
            return;

        })
        // console.log('Query', q);
        q["page_number"] = 1;
        q["category"] = category;
        setQuery(q);

        return q;
    }

    const TemplateSaver = async () => {
        //Validate template Name
        var r = RegExp("^[a-zA-Z0-9 ]*$");
        if (r.test(templateName.trim()) && templateName.trim() !== "") {
            if (templateDescription.trim() === "") {
                setTemplateNameValidatorString("Please add description.")
                setShowTemplateSaveModal(true);
                return;
            }
            setTemplateNameValidatorString(" ")
            //Fetch updated template name list
            var templateNames = await getTemplateNames();
            // console.log(templateNames);
            if (templateNames.detail.includes(templateName.trim())) {
                setTemplateNameValidatorString("A template with this name aleady exists. Please choose another name.")
                setShowTemplateSaveModal(true);
                return;
            }
            var q = await createQuery()
            if (q === STOP) {
                return;
            }
            delete q["page_number"]
            q["template_name"] = templateName.trim();
            q["template_description"] = templateDescription.trim();
            await saveTemplate(q).then(res => { alert(res.detail); setShowTemplateSaveModal(false) });
            return;
        }

        setTemplateNameValidatorString("Invalid Template Name. Only alphabets, numbers and space is allowed.")
    }

    const templateInputValidator = (e) => {
    }

    const searchByTemplate = async () => {
        if (templateNameToSearch === undefined) {
            alert("Please select Template to serach");
            return;
        }
        setActiveIndex(-1);
        setLoading(true);
        setQuery({});
        setSearchbytemplatename(true);
        setTemplateToSearch(templateNameToSearch)

        var r = renderResults({}, true, templateNameToSearch);
        setResultsConponents(r);
    }

    const decodeAndSetQuery = (_query) => {
        console.log("Query", _query);
        var row = -1;
        var outerarr = []
        for (var chart in _query) {
            var chart_q = _query[chart];
            for (var p_o in chart_q) {
                var arr = [];
                row = row + 1;
                //set chart name
                if (chart === "D1") {
                    // arr[row][chartIndex] = chartTypes[0]
                    arr.push(chartTypes[0])
                }
                else {
                    // arr[row][chartIndex] = chart
                    arr.push(chart);
                }
                //set planet and operator name
                if (p_o === "ascendant") {
                    // arr[row][planetIndex] = planet_alias_to_name_map["asc"];
                    // arr[row][operatorIndex] = aliastoOperatorNameMap["r"];
                    // arr[row][valueIndex] = chart_q[p_o];
                    arr.push(planet_alias_to_name_map["asc"]);
                    arr.push(aliastoOperatorNameMap["r"]);
                    // arr.push(chart_q[p_o]);
                    arr.push(chart_q[p_o]);
                    outerarr.push(arr);
                } else if (p_o === "asc_nak") {
                    // arr[row][planetIndex] = planet_alias_to_name_map["asc"];
                    // arr[row][operatorIndex] = aliastoOperatorNameMap["nk"];
                    // arr[row][valueIndex] = chart_q[p_o];
                    arr.push(planet_alias_to_name_map["asc"]);
                    arr.push(aliastoOperatorNameMap["nk"]);
                    // arr.push();
                    for (var i in nakshatraNameToNumMap) {
                        if (nakshatraNameToNumMap[i] === parseInt(chart_q[p_o])) {
                            // arr[row][valueIndex]=i;
                            arr.push(i);
                            // outerarr.push(arr);
                            break;
                        }
                    }
                    outerarr.push(arr);
                } else {
                    var sp = p_o.split(/_(.*)/s);
                    var p = sp[0];
                    var o = sp[1];
                    // arr[row][planetIndex] = planet_alias_to_name_map[p];
                    arr.push(planet_alias_to_name_map[p]);
                    if (o === "conj" || o === "asp_h" || o === "asp_p") {
                        var value = chart_q[p_o];
                        // arr[row][operatorIndex] = aliastoOperatorNameMap[o];
                        arr.push(aliastoOperatorNameMap[o]);
                        if (toString.call(value) === "[object Array]") {
                            //value is an array, if there was only one value to be queried like su_asp_p:[7] in input while saving template
                            // then this will be su_asp_p:7 
                            // arr[row][valueIndex] = value[0];
                            if (o === "asp_p" || o === "conj") {
                                arr.push(getPlanetNameFromPlanetCode(parseInt(value[0])))
                            }
                            else {
                                arr.push(value[0]);
                            }
                            outerarr.push(arr);
                            for (var i = 1; i < value.length; i++) {
                                row = row + 1;
                                arr = []
                                // arr[row][chartIndex] = chart;
                                // arr[row][planetIndex] = planet_alias_to_name_map[p];
                                // arr[row][operatorIndex] = aliastoOperatorNameMap[o];
                                arr.push(chart);
                                arr.push(planet_alias_to_name_map[p]);
                                arr.push(aliastoOperatorNameMap[o]);
                                if (o === "asp_h") {
                                    // arr[row][valueIndex] = value[i];
                                    arr.push(value[i]);
                                    outerarr.push(arr);
                                }
                                else {
                                    //get planet name from planet code
                                    var p_name = getPlanetNameFromPlanetCode(parseInt(value[i]));
                                    // arr[row][valueIndex]=p_name;
                                    arr.push(p_name)
                                    outerarr.push(arr);
                                }

                            }
                        }
                        else {
                            if (p_o === "asp_h") {
                                // arr[row][valueIndex] = value;
                                arr.push(value);
                                outerarr.push(arr);
                            }
                            else {
                                arr.push(getPlanetNameFromPlanetCode(parseInt(value)));
                                outerarr.push(arr);
                                // arr[row][valueIndex]=getPlanetNameFromPlanetCode(parseInt(value));
                            }

                        }
                    }
                    else {
                        arr.push(aliastoOperatorNameMap[o]);
                        var value = chart_q[p_o]
                        if (o === "nk") {
                            //get nakshatra name from number
                            for (var i in nakshatraNameToNumMap) {
                                if (nakshatraNameToNumMap[i] === parseInt(value)) {
                                    // arr[row][valueIndex]=i;
                                    arr.push(i);
                                    outerarr.push(arr);
                                    break;
                                }
                            }
                        }
                        else if (o === 'm') {
                            for (i in motionToNumMap) {
                                if (motionToNumMap[i] === parseInt(value)) {
                                    arr.push(i);
                                    outerarr.push(arr);
                                    break;
                                }
                            }
                        }
                        else {
                            //house or rashi
                            arr.push(value);
                            outerarr.push(arr);
                        }
                    }

                }

            }


        }
        console.log(outerarr);
        setFormArray(outerarr);
        setRowCount(outerarr.length);
    }

    const createNewQuery = () => {
        setRowCount(0); setFormArray([]); setQuery({});
        var nom = createChartMap();
        setChartNameToObjectMap(nom);
        setSearchbytemplatename(false);
        setTemplateDescriptionDisplay("");
        setTemplatecategory(undefined);
        setTemplateToSearch(undefined);
        setTemplateName("");
    }

    const handleTemplatenameDropdownSelect = (val, index) => {
        if (val === "Select Template Name") {
            setTemplateToSearch(undefined)
            setTemplateDescriptionDisplay('')
            setTemplatecategory(undefined)
        } else {
            setTemplateToSearch(val);
            var desc = allTemplateNames[index - 1].template_description;
            var c = allTemplateNames[index - 1].category;
            setTemplateDescriptionDisplay(desc);
            setTemplatecategory(c === 1 ? "Human" : "Mundane");
        }

    }

    const helpModal = () =>{
        return(
            <>
             <div className="row">
             <Modal show={showhelp} onHide={e => setShowhelp(false)}>
                    <Modal.Header closeButton>
                       <Modal.Title>Provide Template Details</Modal.Title>
                    </Modal.Header>
                         <Modal.Body>
                           <div>
                             <p>hello</p>
                           </div>
                         </Modal.Body>
                     <Modal.Footer>
                    </Modal.Footer>
            </Modal>
                                                            </div>
            </>
        )
    }

    return (
        <>
            <DashboardTopBar />
            <CelebrityUserSidebar />
            <div className="content-page">
                <div className="content">
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                {/* <center>
                                    <h3>Query Builder <i style={{ fontSize: "13px", cursor: "pointer" }} className="fe-info" onClick={() => { alert('hi') }}></i></h3>
                                </center> */}
                                <div className="text-end" style={{ textAlign: "end" }}>
                                    <a href="/celebrity/querybuilder/instructions" target="_blank">How to use Query Builder</a>
                                    {/* <span onClick={() => { setShowhelp(true)}}>How to use Query Builder</span> */}
                                    
                                </div>
                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                    <div class="panel panel-default">
                                        <div class="panel-heading" role="tab" id="headingOne">
                                            <h4 class="panel-title">
                                                <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    Search using saved templates
                                                </a>
                                            </h4>
                                        </div>
                                        <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                                            <div class="panel-body">
                                                <div className="row">
                                                    <div className="col pb-2">
                                                        <select className="form-control" onChange={e => handleTemplatenameDropdownSelect(e.target.value, e.target.options.selectedIndex)}>
                                                            <option value={undefined} key={0} selected={templateNameToSearch === undefined ? true : false} disabled={templateNameToSearch !== undefined ? false : true}>Select Template Name</option>
                                                            {allTemplateNames?.map((item, index) => {
                                                                return (<option value={item.template_name} key={index + 1}>{item.template_name}</option>);
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="col">
                                                        <Button className="btn btn-primary" onClick={searchByTemplate} disabled={loading}>Search By Template</Button>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    {templateCategory === undefined ? "" :
                                                        <div className="col"><b>Category: </b> {templateCategory}</div>}
                                                </div>
                                                <div className="row pb-2">
                                                    {templateDescriptionDisplay === "" ? "" :
                                                        <div className="col"><b>Description: </b> {templateDescriptionDisplay}</div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="headingTwo">
                                                <h4 class="panel-title">
                                                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="true"
                                                        aria-controls="collapseTwo">
                                                        Search by building your query
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                                <div class="panel-body">
                                                    {/* <Button style={{ float: "right" }} onClick={() => setShowQueryDiv(!showQueryDiv)}>{showQueryDiv ? "Hide Query" : "Show Query"}</Button> */}
                                                    {rowCount > 0 ? (<Button style={{ float: "right" }} className="btn btn-danger" onClick={createNewQuery} > Reset </Button>) : ""}
                                                    <div style={{ display: showQueryDiv ? "block" : "none" }}>

                                                        <div className="row">
                                                            <div className="col-2 category-heading" > Category:
                                                            </div>
                                                            <div className="col-2">
                                                                <select class="form-control" style={{ height: "32px" }} onChange={(e) => setCategory(parseInt(e.target.value))} value={category === undefined ? "Select Category" : category}>
                                                                    <options disabled={true} value="Select Category">Select Category</options>
                                                                    <option value={1} >Human</option>
                                                                    <option value={2}>Mundane</option>
                                                                </select>
                                                            </div>
                                                            <div className="col-2">
                                                                <Button className=".btn btn-blue" onClick={() => addRow()} disabled={loading || searchbytemplatename}>Add Query Row</Button>
                                                            </div>
                                                            <div className="col-3">

                                                            </div>
                                                        </div>
                                                        <div className="build-query-div">
                                                            <div>{form}</div>
                                                        </div>
                                                        {(rowCount > 0) ? <>
                                                            <div class="d-flex justify-content-between bd-highlight ml-2 pb-2">
                                                                <div><Button className="btn btn-primary" onClick={runQuery}>Search using above Query</Button></div>
                                                                <div></div>
                                                                <div><Button className="btn btn-secondary" onClick={e => { setShowTemplateSaveModal(true); setTemplateNameValidatorString("") }}>Save this Query as template</Button></div>
                                                            </div>
                                                        </> : ''}

                                                        <div>
                                                            <div className="row">
                                                                <Modal show={showTemplateSaveModal} onHide={e => setShowTemplateSaveModal(false)}>
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>Provide Template Details</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body>
                                                                        <div>
                                                                            <div className="template-validator-message">{templateNameValidatorString}</div>
                                                                            <div>
                                                                                <div className="row template-modal-rows">
                                                                                    <div className="col">Template Name:</div>
                                                                                </div>
                                                                                <div className="row template-modal-rows">
                                                                                    <div className="col">
                                                                                        <input className="template-input"
                                                                                            placeholder="Please Enter Template Name Here" onKeyUp={e => templateInputValidator}
                                                                                            onChange={e => setTemplateName(e.target.value)} ></input>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row template-modal-rows">
                                                                                    <div className="col">Description:</div>
                                                                                </div>
                                                                                <div className="row template-modal-rows">
                                                                                    <div className="col">
                                                                                        <textarea className="template-input" placeholder="Please Enter Template Name Here"
                                                                                            onChange={e => setTemplateDescription(e.target.value)} ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Modal.Body>
                                                                    <Modal.Footer>
                                                                        {/* <Button variant="secondary" onClick={e => setShowTemplateSaveModal(false)}>
                                                    Close
                                                </Button> */}
                                                                        <Button variant="primary" onClick={TemplateSaver}>
                                                                            Save Changes
                                                                        </Button>
                                                                    </Modal.Footer>
                                                                </Modal>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div >
                                        {/* <Button style={{ float: "right", display: initailResponse ? "block" : "none" }} onClick={() => setShowResultDiv(!showResultDiv)}>{showResultDiv ? "Hide Results" : "Show Results"}</Button> */}
                                        <div style={{ display: showResultDiv ? "block" : "none" }}>

                                            {
                                                // loading?
                                                // (<center className="pt-4 pb-4">
                                                //     <Spinner animation="border" variant="primary" />
                                                //     </center>):
                                                resultsComponent}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
             <Modal className="modal-dialog" size="xl" centered show={showhelp} onHide={e => setShowhelp(false)}>
                    <Modal.Header closeButton>
                       <Modal.Title>How to Details</Modal.Title>
                    </Modal.Header>
                         <Modal.Body>
                           <div>
                            
                           </div>
                         </Modal.Body>
                     <Modal.Footer>
                    </Modal.Footer>
            </Modal>
        </div>
            {/* </div> */}
        </>
    );
}

export default withRouter(QueryBuilder);