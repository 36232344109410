import React, { useEffect, useState } from "react";
import "./YogaAnalizer.css";
import moment from "moment";
import axios from "axios";
import { Form, Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "../../../mycontext";
import SectionComponent from "../CView/SectionComponent";
import { useTranslation } from "react-i18next";
import { downloadReportPDF } from "../../../API/UserAPIs";
import { callAnalizerReportAPI } from "../../../API/VedicYoga_API";
import {getQueryParam} from '../../utils/utility';

const genderMapper = {
  Male: 0,
  Female: 1,
  पुरुष: 0,
  स्त्री: 1,
};

const YogaAnalizer = (props) => {
  const context = React.useContext(GlobalContext);

  const { t, i18n } = useTranslation();
  let [num, setNum] = useState(0);

  const [auspYogas, setAuspYogas] = useState("");
  const [ordinaryYogas, setOrdinaryYogas] = useState("");
  const [inAuspYogas, setInAuspYogas] = useState("");
  const [totalYoga, setTotalYoga] = useState("0");
  const [activeIndex, setActiveIndex] = useState("");
  const [currentSelectedYoga, setCurrentSelectedYoga] = useState("");
  const [yogaName, setYogaName] = useState("");
  const [bookname1, setbookname1] = useState("");
  const [authorName1, setAuthorName1] = useState("");
  const [chapter, setchapter] = useState("");
  const [shloka_number, setshloka_number] = useState("");
  const [sanskrit_shloka, setsanskrit_shloka] = useState("");
  const [shloka_meaning, setshloka_meaning] = useState("");
  const [shloka_result, setshloka_result] = useState("");
  const [is_cancellation_present, setis_cancellation_present] = useState(" ");
  const [cancel_ddl, setcancel_ddl] = useState(false);
  const [cancelsource, setcancelsource] = useState("");
  const [bookname2, setbookname2] = useState("");
  const [authorName2, setAuthorName2] = useState("");
  const [cancelchapter, setcancelchapter] = useState("");
  const [cancelshloka_number, setcancelshloka_number] = useState("");
  const [cancelshloka, setcancelshloka] = useState("");
  const [cancelshloka_meaning, setcancelshloka_meaning] = useState("");
  const [cancelshloka_result, setcancelshloka_result] = useState("");
  const [classification, setclassification] = useState("");
  const [selectedValue, setSelectedValue] = useState(1);
  const [horoscopeData, setHoroscopeData] = useState(null);
  const [requestParamsHoroscope, setRequestParamsHoroscope] = useState(null);
  const [currentchartValue, setCurrentchartValue] = useState("");
  const [currentKey, setCurrentKey] = useState("1");
  const [cb_view,setCb_view] = useState(false);


  const valueOptions = [
    { value: 0, label: "Moon Chart" },
    { value: 1, label: "D1" },
    { value: 2, label: "D2" },
    { value: 3, label: "D3" },
    { value: 4, label: "D4" },
    { value: 5, label: "D7" },
    { value: 6, label: "D9" },
    { value: 7, label: "D10" },
    { value: 8, label: "D12" },
    { value: 9, label: "D16" },
    { value: 10, label: "D20" },
    { value: 11, label: "D24" },
    { value: 12, label: "D27" },
    { value: 13, label: "D30" },
    { value: 14, label: "D40" },
    { value: 15, label: "D45" },
    { value: 16, label: "D60" },
    { value: 17, label: "Before Trikona Reduction" },
    { value: 18, label: "After Trikona Reduction" },
    { value: 19, label: "After EK Reduction" },
    { value: 20, label: "Bhinnashtak Varga - Sun" },
    { value: 21, label: "Bhinnashtak Varga - Moon" },
    { value: 22, label: "Bhinnashtak Varga - Mars" },
    { value: 23, label: "Bhinnashtak Varga - Merc" },
    { value: 24, label: "Bhinnashtak Varga - Jupt" },
    { value: 25, label: "Bhinnashtak Varga - Venu" },
    { value: 26, label: "Bhinnashtak Varga - Satn" },
    { value: 27, label: "Bhinnashtak Varga - Asc" },
    { value: 28, label: "Shadbala ranked bars" },
    { value: 29, label: "Bhavbala ranked bars" },
    { value: 30, label: "Five Fold Relationships" },
    { value: 31, label: "Planet Positions" },
    { value: 32, label: "Transit - Asc Based" },
    { value: 33, label: "Transit - Moon Rashi Based" },
    { value: 34, label: "Swansh Kundali" },
    { value: 35, label: "Karkansh Kundali" },
    { value: 36, label: "Non Luminaries" },
    { value: 37, label: "Special Ascendants" },
  ];

  var Mapper = {
    "Maha Dasha": "vimsottari_dasha_days_only",
    "Yogini Dasha": "yogini_dasha",
  };
  const months = (mon) => {
    if (mon === "Jan") {
      return "01";
    } else if (mon === "Feb") {
      return "02";
    } else if (mon === "Mar") {
      return "03";
    } else if (mon === "Apr") {
      return "04";
    } else if (mon === "May") {
      return "05";
    } else if (mon === "Jun") {
      return "06";
    } else if (mon === "Jul") {
      return "07";
    } else if (mon === "Aug") {
      return "08";
    } else if (mon === "Sep") {
      return "09";
    } else if (mon === "Oct") {
      return "10";
    } else if (mon === "Nov") {
      return "11";
    } else {
      return "12";
    }
  };

  useEffect(() => {
    //console.log('callled');
    setCurrentKey("1");
    let queryString = getQueryParam('cb');
    let horoscopeData;
    let analyzerData;

    if(queryString){
      setCb_view(true);
      horoscopeData = JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`));
      analyzerData = JSON.parse(window.sessionStorage.getItem(`requestYogaAnalyzer_${queryString}`));
      console.log("analyseData",analyzerData);
      setRequestParamsHoroscope(JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope_${queryString}`)));
    }
    else{
    horoscopeData = JSON.parse(window.localStorage.getItem("horoscopeData"));
    analyzerData = JSON.parse(window.localStorage.getItem("requestYogaAnalyzer"));
    setRequestParamsHoroscope(JSON.parse(window.sessionStorage.getItem(`requestParamsHoroscope`)));
  }
  setHoroscopeData(horoscopeData);
    if (analyzerData) {
      setAuspYogas(analyzerData.auspicious_yogas);
      setCurrentSelectedYoga(analyzerData.auspicious_yogas);
      setOrdinaryYogas(analyzerData.ordinary_yogas);
      setInAuspYogas(analyzerData.inauspicious_yogas);
      setTotalYoga(parseInt(analyzerData.number_of_yogas_found, 10));
      if (parseInt(analyzerData.number_of_yogas_found, 10) > 0) {
        fetchYogaValue(analyzerData.auspicious_yogas, num);
      }
    }
    //console.log("print Yoga details", analyzerData);
  }, [localStorage.getItem("requestYogaAnalyzer"), context.horoscopeData,getQueryParam('cb')]);

  const ResetAll = () => {
    setbookname1("");
    setAuthorName1("");
    setchapter("");
    setshloka_number("");
    setsanskrit_shloka(" ");
    setshloka_meaning(" ");
    setshloka_result("");
    setis_cancellation_present("");
    setbookname2("");
    setAuthorName2("");
    setcancelchapter("");
    setcancelshloka_number("");
    setcancelshloka("");
    setcancelshloka_meaning("");
    setcancelshloka_result("");
  };
  const handleTabChange = (newValue) => {
    ResetAll();
    setCurrentKey(newValue);
    if (newValue === "1") {
      setCurrentSelectedYoga(auspYogas);
      setNum(0);
      if (auspYogas.length > 0) {
        fetchYogaValue(auspYogas, 0);
      }
    } else if (newValue === "2") {
      setCurrentSelectedYoga(ordinaryYogas);
      setNum(0);
      if (ordinaryYogas.length > 0) {
        fetchYogaValue(ordinaryYogas, 0);
      }
    }
    else {
      setCurrentSelectedYoga(inAuspYogas);
      setNum(0);
      if (inAuspYogas.length > 0) {
        fetchYogaValue(inAuspYogas, 0);
      }
    }
  };
  const fetchYogaValue = (data, num) => {
    setYogaName(data[num].yoga_name)
    setsanskrit_shloka(data[num].sanskrit_shloka);
    setshloka_meaning(data[num].shloka_meaning);
    setshloka_number(data[num].shloka_number);
    setchapter(data[num].chapter);
    setshloka_result(data[num].shloka_result);
    setbookname1(data[num].book_reference["book_name"]);
    setAuthorName1(data[num].book_reference["author"]);
    setis_cancellation_present(data[num].cancellation);
    if (data[num].cancellation != null) {
      setcancel_ddl(true);
      setbookname2(data[num].cancellation.source["book_name"]);
      setAuthorName2(data[num].cancellation.source["author"]);
      setcancelchapter(data[num].cancellation.chapter);
      setcancelshloka_number(data[num].cancellation.shloka_number);
      setcancelshloka(data[num].cancellation.shloka);
      setcancelshloka_meaning(data[num].cancellation.shloka_meaning);
      setcancelshloka_result(data[num].cancellation.shloka_result);
    }
    setcancel_ddl(false);
  };
  let incNum = () => {
    ResetAll();
    if (num < currentSelectedYoga.length) {
      setNum(Number(num + 1));
      fetchYogaValue(currentSelectedYoga, num + 1)
    }
  };
  let decNum = () => {
    ResetAll();
    if (num > 0) {
      setNum(Number(num - 1));
      fetchYogaValue(currentSelectedYoga, num - 1)
    }
  };

  const handleChangeDiv = (event, i) => {
    let val = event.target.value;

    setSelectedValue(val);
  };
  const handleChartInputchange = (value, i) => {
    setSelectedValue(value[i]);
  };

  //Vedic Yoga Analyzer Report

  let [loading, setLoading] = useState(false);

  const processDates = (date_string) => {
    let parts_of_date = date_string.split("-");

    let output = new Date(
      +parts_of_date[2],
      parts_of_date[1] - 1,
      +parts_of_date[0]
    );
    return output;
  };
  function timeFormatter(name, time) {
    var ampm = time.slice(time.length - 2, time.length);
    var SplitTime = time.slice(0, time.length - 2).split(":");
    var h = parseInt(SplitTime[0]);
    var min = parseInt(SplitTime[1]);
    var sec = parseInt(SplitTime[2].trim());
    if (ampm === "AM" && h === 12) {
      h = 0;
    } else if (ampm === "PM" && h === 12) {
      h = 12;
    } else if (ampm === "PM") {
      h = 12 + h;
    }
  
    if (name === "hour") {
      return h;
    }
    if (name === "min") {
      return min;
    }
    if (name === "sec") {
      return sec;
    }
  }
  let callAnalizerReportAPIHandler = () => {
    setLoading(true);
    try {
      let name = horoscopeData.native_name;
      let gender = horoscopeData.basic_details.gender;
      let year = horoscopeData.date_of_birth
        ? processDates(horoscopeData.date_of_birth).getFullYear()
        : null;

      let month = horoscopeData.date_of_birth
        ? processDates(horoscopeData.date_of_birth).getMonth() + 1
        : null;

      month = parseInt(month);
      let day = horoscopeData.date_of_birth
        ? processDates(horoscopeData.date_of_birth).getDate()
        : null;

      let hour = horoscopeData.time_of_birth
        ? timeFormatter("hour", horoscopeData.time_of_birth)
        : "";

      let min = horoscopeData.time_of_birth
        ? timeFormatter("min", horoscopeData.time_of_birth)
        : "";

      let sec = horoscopeData.time_of_birth
        ? timeFormatter("sec", horoscopeData.time_of_birth)
        : "";

      callAnalizerReportAPI(
        name,
        genderMapper[gender],
        day,
        month,
        year,
        hour,
        min,
        sec,
        requestParamsHoroscope.latitude,
        requestParamsHoroscope.longitude,
        requestParamsHoroscope.offset,
        requestParamsHoroscope.name,
        requestParamsHoroscope.state,
        requestParamsHoroscope.country,
        requestParamsHoroscope.reference_longitude_for_timezone,
        requestParamsHoroscope.timezone,
        localStorage.getItem("omp_token")
      )
        .then((res1) => {
          if (typeof res1 !== "undefined") {
            if (res1 === "API Issue") {
              console.log("Issue with Reports API");
            } else {
              downloadReportPDF(res1.data.url).then((res2) => {
                if (typeof res2 !== "undefined") {
                  console.log(res2);
                  const fileName = "Omparashar-VedicYogaAnalizer-" + name + ".pdf";
                  const blobobject = new Blob([res2.data], {
                    type: "application/pdf",
                  });
                  console.log(blobobject);
                  const link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blobobject);
                  link.setAttribute("download", fileName);
                  link.click();
                  setLoading(false);
                } else {
                  console.log(
                    "issue with Download Report EndPoint in User APIs"
                  );
                  setLoading(false);
                }
              });
            }
          } else {
            console.log(
              "issue with Download Report EndPoint in Image Service APIs"
            );
            setLoading(false);
          }
        });
    } catch (err) {
      console.log("Issue with Reports API");
      console.log(err);
    }
  };

  return (
    <>
      <div className="YogaAnalizerWrapper">
        <div className="card">
          <div className="card-body" style={{ padding: "0px 0px 0" }}>
            <div className="row">
              <div className="col-lg-6 col-12">
                <h2 className="yoga-title float-left">
                  {t("Vedic Yoga Analyzer")} </h2>
              </div>
              <div className="col-lg-6 col-12">
                <div className="btnDownload float-right">
                  <button
                    data-toggle="collapse"
                    type="button"
                    className="btn btn-success mt-1"
                    onClick={() => callAnalizerReportAPIHandler()}
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        {t("Downloading Report")}{" "}
                        <i className="mdi mdi-spin mdi-loading mr-1 font-14"></i>
                      </span>
                    ) : (
                      <span>{t("Download Vedic Yoga Analyzer PDF")}</span>
                    )}
                  </button>

                </div>
              </div>
            </div>
            <hr style={{ margin: "0px" }} />

            <div className="panel with-nav-tabs panel-warning">
              <div className="panel-heading">
                <div className="d-flex">
                  <div className="fbasis">
                    <Tabs className="d-flex my-2"
                      id="controlled-tab-example"
                      activeKey={currentKey}
                      onSelect={(k) => handleTabChange(k)}
                    >
                      <Tab eventKey={1} title={t("Auspicious Yoga") + "(" + (auspYogas.length) + ")"} className={currentKey === 1 ? "active" : ""} disabled={auspYogas.length === 0 ? true : false}></Tab>
                      <Tab eventKey={2} title={t("Ordinary Yoga") + "(" + (ordinaryYogas.length) + ")"} className={currentKey === 2 ? "active" : ""} disabled={ordinaryYogas.length === 0 ? true : false}></Tab>
                      <Tab eventKey={3} title={t("Inauspicious Yoga") + "(" + (inAuspYogas.length) + ")"} className={currentKey === 3 ? "active" : ""} disabled={inAuspYogas.length === 0 ? true : false}></Tab>
                    </Tabs></div>
                  <div>
                    <h5 className="pt-2">
                      {totalYoga} {t("Yogas have been found in your horoscope")}
                    </h5>
                  </div>
                </div>


                <div className="row mb-2">
                  <div className="col-lg-4 col-12">
                    <Button
                      className="btn float-left btn-prev"
                      onClick={decNum}
                      disabled={num == 0 ? true : false}
                    >
                      <img
                        style={{ marginTop: "-5px" }}
                        src={require("../../../assets/img/prev-double-arrow.png")}
                        alt="Prev"
                      />
                      <span> {t("Prev Yoga")}</span> &nbsp;{" "}
                    </Button>
                  </div>
                  <div className="col-lg-4 col-12">
                    <h4 style={{ display: "flex" }} className="mt-3">
                      {currentSelectedYoga === " " && currentSelectedYoga === null && currentSelectedYoga === "undefined" && currentSelectedYoga === undefined
                        ? "" : yogaName}
                      {" "}
                      ({currentSelectedYoga.length !== 0 ? num + 1 : "0"} of{" "}
                      {currentSelectedYoga.length !== 0 ? currentSelectedYoga.length : "0"}) {" "}
                      {is_cancellation_present === null && cancel_ddl === false ? " " : t("Cancellation")}
                    </h4>
                  </div>
                  <div className="col-lg-4 col-12">
                    <Button
                      className="btn float-left btn-prev ml-4"
                      onClick={incNum}
                      disabled={
                        num + 1 == currentSelectedYoga.length ? true : false
                      }
                    >
                      <span> {t("Next Yoga")}</span> &nbsp;{" "}
                      <img
                        style={{ marginTop: "-5px" }}
                        src={require("../../../assets/img/next-double-arrow.png")}
                        alt="Next"
                      />
                    </Button>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-lg-4 col-12">
                    <div className="drpSelect mb-2">
                      
                      <select
                        className="drp custom-select"
                        value={selectedValue}
                        onChange={(e, i) => handleChangeDiv(e, 0)}
                        style={{ width: "400px" }}
                      >
                        {valueOptions.map((option) => (
                          <option key={option + option.value} value={option.value}>
                            {t(option.label)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="chart" style={{ width: "395px" }}>
                      {horoscopeData != null &&
                        Object.keys(horoscopeData).length !== 0 ? (
                        <SectionComponent style={{ width: "100%" }}
                          section="2"
                          horoscopeData={horoscopeData}
                          value={parseInt(selectedValue)}
                          requestParamsHoro={requestParamsHoroscope}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 offset-lg-1  col-12">
                    {currentSelectedYoga.length !== 0 ? (
                      <div className="sanskritShloka_img">
                      <div className="sanskritShloka mb-2">
                        
                        {/* <p>{sanskrit_shloka}</p> */}
                        <p dangerouslySetInnerHTML={{__html:sanskrit_shloka.replace('<br/>',"<br/>")}}/>
                        <div className="source text-right">
                          <p className="mb-0">
                            {" "}
                            {bookname1} {" , "} {authorName1}
                          </p>
                          <p className="my-0" style={{"line-height":"1.3"}}>
                            <span> {chapter != null && chapter != "" ? t("Chapter") : ""} {chapter}</span>
                            <span> {shloka_number != null && shloka_number != "" ? " ," + t("Shloka Number") : ""}  {shloka_number}</span>
                          </p>  </div>
                      </div>
                      </div>
                    ) : ""}
                    <div className="sanskritResult">
                      <p>{shloka_meaning}</p>
                      <p className="my-0">{shloka_result}</p>
                    </div>
                  </div>
                </div>
                <hr></hr>
                {is_cancellation_present != null ? (
                  <FormControl variant="outlined" className="outlineBorder">
                    <div className="row mb-2">
                      <div className="col-lg-12 col-12">
                        <InputLabel id="demo-simple-select-outlined-label">
                          {"Cancellation"}
                        </InputLabel>
                        <div className="row pt-2">
                          <div className="col-lg-7  text-right ">
                          <div className=" ">
                          <div className="sanskritShloka_img">
                          <div className="sanskritShloka mt-3 mb-2">
                            <p>{cancelshloka}</p>
                            <div className="source  text-right">
                              <p className="mb-0">
                                {" "}
                                {bookname2} {" , "} {authorName2}
                              </p>
                              <p className="my-0" style={{"line-height":"1.3"}}>
                                <span> {cancelchapter != null && cancelchapter != "" ? t("Chapter") : ""} {cancelchapter}</span>
                                <span> {cancelshloka_number != null && cancelshloka_number != "" ? " ," + t("Shloka Number") : ""}  {cancelshloka_number}</span>
                              </p>
                            </div>
                          </div>
                          </div>
                          
                        </div>
                          </div>
                          <div className="col-lg-5 text-center">
                           <div className="sanskritResult mt-3 mx-2">
                            <p className="mb-2">{cancelshloka_meaning}</p>
                            <p className="my-0">{cancelshloka_result}</p>
                          </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </FormControl>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(YogaAnalizer);
