import React, { useState, useContext, useEffect } from "react";
import { Link,useLocation} from "react-router-dom";
import {FiAperture,FiPieChart,FiSun,FiBarChart,FiSlack} from "react-icons/fi";
import {FaGalacticRepublic,FaFirstOrder,FaFirstOrderAlt,FaGalacticSenate} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Api from "../../../API/Api";
import { GlobalContext } from "../../../mycontext";
import { downloadReportPDF } from "../../../API/UserAPIs";

const processDates = (date_string) => {
  let parts_of_date = date_string.split("-");
  let output = new Date(
    +parts_of_date[2],
    parts_of_date[1] - 1,
    +parts_of_date[0]
  );
  return output;
};

function timeFormatter(name, time) {
  var ampm = time.slice(time.length - 2, time.length);
  var SplitTime = time.slice(0, time.length - 2).split(":");
  var h = parseInt(SplitTime[0]);
  var min = parseInt(SplitTime[1]);
  var sec = parseInt(SplitTime[2].trim());
  if (ampm === "AM" && h === 12) {
    h = 0;
  } else if (ampm === "PM" && h === 12) {
    h = 12;
  } else if (ampm === "PM") {
    h = 12 + h;
  }
  if (name === "hour") {
    return h;
  }
  if (name === "min") {
    return min;
  }
  if (name === "sec") {
    return sec;
  }
}

const TransitionDashboard = (props) => {
  const context = useContext(GlobalContext);
  const { t, i18n } = useTranslation();
  let [loading, setLoading] = useState(false);
  let [queryString, setQueryString] = useState('');
  let api = new Api();
  const location = useLocation();




  let callReportAPIHandler = () => {
    setLoading(true);
    try {
      let name = context.horoscopeData.native_name;
      let gender = context.horoscopeData.basic_details.gender;
      let year = context.horoscopeData.date_of_birth
        ? processDates(context.horoscopeData.date_of_birth).getFullYear()
        : null;

      let month = context.horoscopeData.date_of_birth
        ? processDates(context.horoscopeData.date_of_birth).getMonth() + 1
        : null;

      month = parseInt(month);
      let day = context.horoscopeData.date_of_birth
        ? processDates(context.horoscopeData.date_of_birth).getDate()
        : null;

      let hour = context.horoscopeData.time_of_birth
        ? timeFormatter("hour", context.horoscopeData.time_of_birth)
        : "";

      let min = context.horoscopeData.time_of_birth
        ? timeFormatter("min", context.horoscopeData.time_of_birth)
        : "";

      let sec = context.horoscopeData.time_of_birth
        ? timeFormatter("sec", context.horoscopeData.time_of_birth)
        : "";

      api
        .callReportAPI(
          name,
          gender,
          day,
          month,
          year,
          hour,
          min,
          sec,
          context.requestParamsHoroscope.latitude,
          context.requestParamsHoroscope.longitude,
          context.requestParamsHoroscope.offset,
          context.requestParamsHoroscope.name,
          context.requestParamsHoroscope.state,
          context.requestParamsHoroscope.country,
          context.requestParamsHoroscope.reference_longitude_for_timezone,
          context.requestParamsHoroscope.timezone,
          localStorage.getItem("omp_token")
        )
        .then((res1) => {
          if (typeof res1 !== "undefined") {
            if (res1 === "API Issue") {
              // console.log("Issue with Reports API");
            } else {
              // console.log("Calling DownloadReportUser API");
              downloadReportPDF(res1.data.url).then((res2) => {
                if (typeof res2 !== "undefined") {
                  // console.log(res2);
                  const fileName = "Omparashar-Horoscope-" + name + ".pdf";
                  const blobobject = new Blob([res2.data], {
                    type: "application/pdf",
                  });
                  // console.log(blobobject);
                  const link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blobobject);
                  link.setAttribute("download", fileName);
                  link.click();
                  setLoading(false);
                  // link.download = "FileName" + new Date() + ".pdf";
                  // link.click();
                } else {
                  //console.log(
                  //  "issue with Download Report EndPoint in User APIs"
                  //);
                  setLoading(false);
                }
              });
            }
          } else {
            //console.log(
            // "issue with Download Report EndPoint in Image Service APIs"
            // );
            setLoading(false);
          }
        });
    } catch (err) {
      // console.log("Issue with Reports API");
      // console.log(err);
    }
  };

  return (
    <>
      <li className="menu-title mt-2">{t("Workspace")}</li>
      <li>
        <Link href="" data-toggle="collapse"
          onClick={() => props.handleView("basicdetails")}
          style={{
            pointerEvents:props.history.location.pathname === "/horoscope/basicdetails"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/basicdetails"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          <FiAperture />
          <span>{t("Basic Details")}</span>
        </Link>
      </li>

      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("cview")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/cview"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/cview"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-merge"></i> */}
          <FiSun />
          <span>{t("C View")}</span>{" "}
        </Link>
      </li>
      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("natalcharts")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/natalcharts"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/natalcharts"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-merge"></i> */}
          <FiPieChart />
          <span>{t("Natal Chart")}</span>{" "}
        </Link>
      </li>

      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("planetPositions")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/planetPositions"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/planetPositions"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-merge"></i> */}
          <FaGalacticRepublic />
          <span>{t("Planet Positions")}</span>{" "}
        </Link>
      </li>

      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("divisionalCharts")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/divisionalCharts"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/divisionalCharts"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-merge"></i> */}
          <FiBarChart />
          <span>{t("Divisional Charts")}</span>{" "}
        </Link>
      </li>

      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("mahaDasha")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/mahaDasha"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/mahaDasha"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-merge"></i> */}
          <FaFirstOrder />
          <span>{t("Dasha")}</span>{" "}
        </Link>
      </li>

      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("planetaryRelations")}
          style={{
            pointerEvents:
              props.history.location.pathname ===
              "/horoscope/planetaryRelations"
                ? "none"
                : "all",
            color:
              props.history.location.pathname ===
              "/horoscope/planetaryRelations"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-merge"></i> */}
          <FaFirstOrderAlt />
          <span>{t("Planetary Relations")}</span>{" "}
        </Link>
      </li>

      {/* <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("ageDashaPlot")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/ageDashaPlot"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/ageDashaPlot"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          <i data-feather="git-merge"></i>
          <span>Age Dasha Plot - remove</span>{" "}
        </Link>
      </li> */}
      {/* <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("mahaDasha")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/mahaDasha"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/mahaDasha"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          <i data-feather="git-merge"></i>
          <span>Maha Dasha - remove</span>{" "}
        </Link>
      </li> */}
      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("astakVarga")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/astakVarga"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/astakVarga"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          {/* <i data-feather="git-merge"></i> */}
          <FaGalacticSenate />
          <span>{t("Astak Varga")}</span>{" "}
        </Link>
      </li>
      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("bhavBala")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/bhavBala"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/bhavBala"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          <FiSlack />
          {/* <i data-feather="git-merge"></i> */}
          <span>{t("Balas")}</span>{" "}
        </Link>
      </li>
      <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("yogaAnalizer")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/yogaAnalizer"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/yogaAnalizer"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          <FaGalacticRepublic />
          <span>{t("Vedic yoga Analyzer")}</span>{" "}
        </Link>
      </li>
      <hr />
      {/* <li class="menu-title mt-10">
        <span className="mdi mdi-download mr-1 font-18"></span>
        {t("Download")}
      </li>
      <li style={{ marginLeft: "10px" }}>
        <button
          data-toggle="collapse"
          type="button"
          className="btn btn-info waves-effect waves-light"
          onClick={() => callReportAPIHandler()}
          disabled={loading}
        >
          {loading ? (
            <span>
              {t("Downloading Report")}{" "}
              <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
            </span>
          ) : (
            <span>{t("Horoscope PDF")}</span>
          )}
        </button>
      </li> */}

      {/* <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("shadBalaBar")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/shadBalaBar"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/shadBalaBar"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          <i data-feather="git-merge"></i>
          <span>Shad Bala Bar</span>{" "}
        </Link>
      </li> */}
      {/* <li>
        <Link
          data-toggle="collapse"
          onClick={() => props.handleView("sunBurst")}
          style={{
            pointerEvents:
              props.history.location.pathname === "/horoscope/sunBurst"
                ? "none"
                : "all",
            color:
              props.history.location.pathname === "/horoscope/sunBurst"
                ? "#3283f6"
                : "#6e768e",
          }}
        >
          <i data-feather="git-merge"></i>
          <span>Sun Burst - remove</span>{" "}
        </Link>
      </li> */}
    </>
  );
};

export default TransitionDashboard;
