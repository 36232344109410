import React, { Component } from "react";

// Import React Table
import "./BhavBala.css";
import Table from "react-bootstrap/Table";
import { withTranslation } from "react-i18next";
class CollapseTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  render() {
    const { data } = this.props;
    const { t, i18n } = this.props;
    return (
      <div className="bhav">
        {/* <ReactTable
          style={{ margin: "25px 0 " }}
          data={data}
          showPagination={false}
          columns={[
            {
              Header: "Column1",
              accessor: "Column1",
              width: 220,
              Cell: (row) => {
                row.styles["textAlign"] = "left";
                row.styles["fontWeight"] =
                  row.value.includes("Total") || row.value.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "1",
              accessor: "1",
              width: 75,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "2",
              accessor: "2",
              width: 75,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "3",
              accessor: "3",
              width: 75,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "4",
              accessor: "4",
              width: 75,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "5",
              accessor: "5",
              width: 75,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "6",
              accessor: "6",
              width: 75,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "7",
              accessor: "7",
              width: 75,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "8",
              accessor: "8",
              width: 75,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "9",
              accessor: "9",
              width: 75,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "10",
              accessor: "10",
              width: 75,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "11",
              accessor: "11",
              width: 75,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
            {
              Header: "12",
              accessor: "12",
              width: 77,
              Cell: (row) => {
                row.styles["textAlign"] = "right";
                row.styles["fontWeight"] =
                  row.original.Column1.includes("Total") ||
                  row.original.Column1.includes("Rank")
                    ? "bold"
                    : "normal";
                return row.value;
              },
            },
          ]}
          defaultPageSize={8}
        /> */}

        <Table responsive="xs" className="content_table_bhav">
          <thead>
            <tr role="row" className="">
              <th></th>
              <th colSpan="12" role="columnheader">
                {t("Bhavas")}
              </th>
            </tr>
            <tr>
              <th>{t("Bhav Bala")}</th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
              <th>7</th>
              <th>8</th>
              <th>9</th>
              <th>10</th>
              <th>11</th>
              <th>12</th>
            </tr>
          </thead>
          <tbody>
            {data.map(function (val, i) {
              return (
                <tr
                  key={val + i}
                  className={val["Column1"].includes("Total") ? "bold" : ""}
                >
                  <td className="left-align-bhav">{t(val["Column1"])}</td>
                  <td className="right-align-bhav">{t(val[1])}</td>
                  <td className="right-align-bhav">{t(val[2])}</td>
                  <td className="right-align-bhav">{t(val[3])}</td>
                  <td className="right-align-bhav">{t(val[4])}</td>
                  <td className="right-align-bhav">{t(val[5])}</td>
                  <td className="right-align-bhav">{t(val[6])}</td>
                  <td className="right-align-bhav">{t(val[7])}</td>
                  <td className="right-align-bhav">{t(val[8])}</td>
                  <td className="right-align-bhav">{t(val[9])}</td>
                  <td className="right-align-bhav">{t(val[10])}</td>
                  <td className="right-align-bhav">{t(val[11])}</td>
                  <td className="right-align-bhav">{t(val[12])}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default withTranslation()(CollapseTable);
