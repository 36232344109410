import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../mycontext";
import {
  FiAperture,
  FiSun
} from "react-icons/fi";
import { useTranslation } from "react-i18next";
import {BiHome} from "react-icons/bi";

const processDates = (date_string) => {
  let parts_of_date = date_string.split("-");
  let output = new Date(
    +parts_of_date[2],
    parts_of_date[1] - 1,
    +parts_of_date[0]
  );
  return output;
};

function timeFormatter(name, time) {
  var ampm = time.slice(time.length - 2, time.length);
  var SplitTime = time.slice(0, time.length - 2).split(":");
  var h = parseInt(SplitTime[0]);
  var min = parseInt(SplitTime[1]);
  var sec = parseInt(SplitTime[2].trim());
  if (ampm === "AM" && h === 12) {
    h = 0;
  } else if (ampm === "PM" && h === 12) {
    h = 12;
  } else if (ampm === "PM") {
    h = 12 + h;
  }
  if (name === "hour") {
    return h;
  }
  if (name === "min") {
    return min;
  }
  if (name === "sec") {
    return sec;
  }
}

const CelebrityManagementSidebar = (props) => {
  const context = useContext(GlobalContext);
  const { t, i18n } = useTranslation();
  let [loading, setLoading] = useState(false);

  return (
    <>
      <div className="left-side-menu">
        <div className="h-100" data-simplebar>
          <div id="sidebar-menu">
            <ul id="side-menu">
      <li className="menu-title mt-2">{t("Workspace")}</li>
      <li>
        <a href="/CelebrityManagement" ><BiHome />
          <span>{t("Celebrity Management")}</span>
        </a>
      </li>
      <li>
        <a href="/CelebrityManagement/tags" ><FiAperture />
          <span>{t("Tags")}</span>
        </a>
      </li>

      <li>
        <a href="/CelebrityManagement/events">
          <FiSun />
          <span>{t("Events")}</span>{" "}
        </a>
      </li>
      {/* <li>
        <a href="/CelebrityManagement/createHoroscope" >
          <FiSun />
          <span>{t("Create Horosocope")}</span>{" "}
        </a>
      </li> */}
</ul>
</div></div></div>
    </>
  );
};

export default CelebrityManagementSidebar;
