import React, { Component } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { withTranslation } from "react-i18next";
import ImageMapper from "../ImageMapper.json";
const deduceColor = (value) => {
  if (value === "Friend") {
    return "color_friend";
  } else if (value === "Neutral") {
    return "color_neutral";
  } else if (value === "Enemy") {
    return "color_enemy";
  } else if (value === "Intimate") {
    return "color_intimate";
  } else if (value === "Bitter") {
    return "color_bitter";
  } else {
    return "color_dash";
  }
};

function extract_image_link(name) {
  // console.log(name);
  let imglink = "/assets/Planets/";
  return imglink + ImageMapper[name] + ".png";
}
function extract_image_link_nakshatra(name) {
  let imglink = "/assets/Nakshatra/";
  return imglink + ImageMapper[name] + ".svg";
}
function extract_image_link_zodiac(name) {
  let str = name.replace(/[^A-Z]/g, "") + name.replace(/[^a-z]/g, "");
  if (str === "Aqu") {
    str = "Aquarius";
  } else if (str === "Ari") {
    str = "Aries";
  } else if (str === "Can") {
    str = "Cancer";
  } else if (str === "Cap") {
    str = "Capricorn";
  } else if (str === "Gem") {
    str = "Gemini";
  } else if (str === "Lib") {
    str = "Libra";
  } else if (str === "Pis") {
    str = "Pisces";
  } else if (str === "Sag") {
    str = "Sagittarius";
  } else if (str === "Sco") {
    str = "Scorpio";
  } else if (str === "Vir") {
    str = "Virgo";
  } else if (str === "Tau") {
    str = "Taurus";
  } else {
    str = "Leo";
  }
  let imglink = "/assets/Zodiac/";
  return imglink + str + ".svg";
}
const radios = [
  { name: "Asc", value: "1" },
  { name: "Sun", value: "2" },
  { name: "Moon", value: "3" },
  { name: "Mars", value: "4" },
  { name: "Mercury", value: "5" },
  { name: "Jupiter", value: "6" },
  { name: "Venus", value: "7" },
  { name: "Saturn", value: "8" },
  { name: "Rahu", value: "9" },
  { name: "Ketu", value: "10" },
];

class TableCView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radioValue: "1",
      radioName: "Asc",
      ppData:
        this.props.horoscopeData.planetary_positions.detailedPlanetPositions[0],
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.horoscopeData !== prevProps.horoscopeData ||
      this.props.value !== prevProps.value
    ) {
      this.filterPPcView(this.state.radioValue);
      this.setState({ radioName: this.state.radioName });
    }
  }

  FilterData = (horoscopeData) => {
    this.setState({
      ppData:
        this.props.horoscopeData.planetary_positions.detailedPlanetPositions[
          parseInt(this.state.radioValue) - 1
        ],
    });
  };
  filterPPcView = (value, name) => {
    this.setState({ radioValue: value, radioName: name }, () =>
      this.FilterData(this.context.horoscopeData)
    );
  };

  findDignity = (data, t) => {
    if (typeof data !== "undefined") {
      for (let i of data) {
        if (i["planet_name"] === t(this.state.radioName)) {
          return i["dignity"];
        }
      }
      return "-";
    }
  };

  render() {
    const { t, i18n } = this.props;
    const { data } = this.props;
    return (
      <div style={{ height: "100%" }}>
        {this.props.value === 30 ? (
          <Table className="cview-table">
            <thead>
              <tr>
                <th>{t("Main Planet")}</th>
                <th>{t("Sun")}</th>
                <th>{t("Moon")}</th>
                <th>{t("Mars")}</th>
                <th>{t("Mercury")}</th>
                <th>{t("Jupiter")}</th>
                <th>{t("Venus")}</th>
                <th>{t("Saturn")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map(function (val, i) {
                return (
                  <tr key={val + i}>
                    <td className="left_align">{val["Main Planet"]}</td>
                    <td className={`${deduceColor(val["Sun"])} bold`}>
                      {val["Sun"]}
                    </td>
                    <td className={`${deduceColor(val["Moon"])} bold`}>
                      {val["Moon"]}
                    </td>
                    <td className={`${deduceColor(val["Mars"])} bold`}>
                      {val["Mars"]}
                    </td>
                    <td className={`${deduceColor(val["Mercury"])} bold`}>
                      {val["Mercury"]}
                    </td>
                    <td className={`${deduceColor(val["Jupiter"])} bold`}>
                      {val["Jupiter"]}
                    </td>
                    <td className={`${deduceColor(val["Venus"])} bold`}>
                      {val["Venus"]}
                    </td>
                    <td className={`${deduceColor(val["Saturn"])} bold`}>
                      {val["Saturn"]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <>
            <ButtonGroup className="btnGrpTabs">
              {radios.map((radio, idx) => (
                <Button
                  className={`${
                    this.state.radioValue === radio.value
                      ? "cview-button-filled"
                      : "cview-button"
                  }`}
                  key={radio + idx}
                  variant="outline-primary"
                  onClick={() => this.filterPPcView(radio.value, radio.name)}
                >
                  {t(radio.name)}
                </Button>
              ))}
            </ButtonGroup>
            <div className="tabName text-center mb-2">
              {this.state.radioName === "Asc" ? (
                <></>
              ) : (
                <img
                  className="planetImages"
                  alt={this.state.radioName}
                  src={extract_image_link(this.state.radioName)}
                ></img>
              )}{" "}
              {t(this.state.radioName)}
            </div>

            <Table className="cview-table-pp">
              <tbody>
                <tr>
                  <td className="left-align heading">{t("Abs Degree")}:</td>
                  <td className="left-align">{this.state.ppData.abs_degree}</td>
                </tr>
                <tr>
                  <td className="left-align heading">{t("Position")}:</td>
                  <td className="left-align">
                    {" "}
                    {
                      <img
                        className="zodiacImages"
                        alt=""
                        src={extract_image_link_zodiac(
                          this.state.ppData.r_d_m_s
                        )}
                      ></img>
                    }
                    &nbsp;{this.state.ppData.r_d_m_s}
                  </td>
                </tr>
                <tr>
                  <td className="left-align heading">{t("Motion")}:</td>
                  <td className="left-align">{this.state.ppData.motion}</td>
                </tr>
                <tr>
                  <td className="left-align heading">{t("Nakshtra")}:</td>
                  <td className="left-align">
                    {
                      <img
                        className="nakshtraImages"
                        alt={this.state.ppData.nakshtra}
                        src={extract_image_link_nakshatra(
                          this.state.ppData.nakshtra
                        )}
                      ></img>
                    }
                    &nbsp;
                    {this.state.ppData.nakshtra}
                  </td>
                </tr>
                <tr>
                  <td className="left-align heading">{t("Pada")}:</td>
                  <td className="left-align">
                    {this.state.ppData["Nakshtra pada"]}
                  </td>
                </tr>
                <tr>
                  <td className="left-align heading">{t("Nak Lord")}:</td>
                  <td className="left-align">
                    {
                      <img
                        className="lordImages"
                        alt={this.state.ppData["Nakshtra lord"]}
                        src={extract_image_link(
                          this.state.ppData["Nakshtra lord"]
                        )}
                      ></img>
                    }{" "}
                    {this.state.ppData["Nakshtra lord"]}
                  </td>
                </tr>
                <tr>
                  <td className="left-align heading">{t("Combust")}:</td>
                  <td className="left-align">
                    {this.state.ppData["combust"] === false
                      ? t("No")
                      : this.state.ppData["combust"] === true
                      ? t("Yes")
                      : this.state.ppData["combust"]}
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td className="left-align heading">{t("Dignity")}:</td>
                  <td className="left-align">
                    {this.findDignity(
                      this.props.horoscopeData.additional_attributes,
                      t
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(TableCView);
