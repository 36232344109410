import React from "react";

import "react-bootstrap";

const PrivacyPolicy = () => {
  function scrollFunction() {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      // document.getElementById("navbar").style.padding = "30px 10px";
      // document.getElementById("logo").style.fontSize = "25px";
    } else {
      //document.getElementById("navbar").style.padding = "80px 10px";
      //document.getElementById("logo").style.fontSize = "35px";
      //alert("2");
    }
  }
  window.onscroll = function () {
    scrollFunction();
  };

  return (
    <>
      <div id="home" className="home">
        <nav
          className="navbar navbar-expand-lg navbar-expand-xl navbar-dark"
          id="mainNav"
        >
          <div
            className="container"
            style={{ marginLeft: "10%", marginRight: "10%" }}
          >
            <a className="navbar-brand js-scroll-trigger" href="/">
              <img src={require("../assets/img/logo.png")} alt="" />
            </a>
           
          </div>
        </nav>
        {/* <!-- Masthead--> */}

        <section className="page-section" id="services">
          <div class="container">
            <div class="row">
              <div id="primary" class="content-area col-md-8 mx-auto">
                <div id="content" class="site-content" role="main" style={{marginTop:"-30px"}}>
                  <article
                    id="post-108"
                    class="post-108 page type-page status-publish hentry"
                  >
                    <header class="entry-header" style={{height:"20%"}}>
                      <h1 class="entry-title">Privacy Policy</h1>
                    </header>

                    <div class="entry-content">
                    <p><strong>PRIVACY POLICY</strong></p>
<p><strong>1. GENERAL</strong></p>
<ol>
    <li>This Website with the URL of <a data-fr-linked="true" href="https://www.omparashar.com/">https://www.omparashar.com</a> (&quot;Website/Site&quot;) is operated by Angee Bridging India Private Limited. We are committed to protecting and respecting your privacy. We do collect your personal information and process your personal data in accordance with the IT Act, 2000 (21 of 2000) and other national and state laws which relate to the processing of personal data. Please read the following carefully to understand our views and practices regarding your personal data.</li>
    <li>We collect your personal information in order to provide and continually improve our products and services.</li>
    <li>Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.</li>
    <li>All partner firms and any third-party working with or for us, and who have access to personal information, will be expected to read and comply with this policy. No third party may access, or process sensitive personal information held by us without having first entered into a confidentiality agreement.</li>
</ol>
<p><strong>2. STATEMENT</strong></p>
<ol>
    <li>The following statement explains our Privacy Policy (&ldquo;Policy&rdquo;) with respect to information including personally identifiable data (&ldquo;Personal Data&rdquo;) and other information that is collected by us when you access and/or use our Services. As you use our Services, we want you to be clear how we are using such Personal Data and the ways in which you can protect your privacy.</li>
    <li>By voluntarily providing us with Personal Data, you are consenting to our use of it in accordance with this Policy. If you provide Personal Data, you acknowledge and agree that such Personal Data may be transferred from your current location to the offices and servers of the Company.</li>
    <li>All words and phrases used in this Policy shall have the same meaning given to them in our Terms of Use. Specifically, the word &ldquo;Authorised Third Party&rdquo; shall mean contractors, agents and/or third party supplier engaged by us from time to time for the purposes of providing certain Services.<br/><strong><br/></strong></li>
    <li><strong>COMMITMENT</strong></li>
    <li>The Website intends to protect the privacy of all kinds of users visiting the platform irrespective whether the user is a registered user or merely a visitor. It is recommended to every user to understand what types of personally identifiable information is collected. The Website employs the personally identifiable information for certain predictions however it is guaranteed that no direct or indirect use of such information which is revealed in the prediction for a member will be done except for the explicit purpose of communicating the horoscope charts and predictions to the member itself disclosing such information. It is further clarified that the Website does not in any manner deal in selling or renting the information supplied to the Website.</li>
    <li>The Website does not commit to treat or provide solutions for users with weak mental health which is inclusive of any user who have thoughts related to committing suicide, self-destruction etc. Such users are advised to stop the use of the present website with immediate effect and any continued use of the website by such person would be considered solely at the user&rsquo;s risk and the Website shall have no liability for any untoward event in such scenario. The Website declares that the information provided by such kind of user can be shared, if required, with law enforcement authorities. Such information is not protected from any kind of non-disclosure or confidential agreements either with the Website or with any third-party involved herein.</li>
    <li>The Website does not commit in any manner whatsoever for the accuracy of the predictions made by the Astrologers to any user. The Website does not take any guarantee/responsibility/liability regarding the reliability or reality of the gems and other related items represented and sold on the website. It is further declared by the Website that no warranty on such service is provided by the Website in any manner.</li>
</ol>
<p><strong>4. INFORMATION COLLECTED BY WEBSITE</strong></p>
<p><strong>1. PERSONAL IDENTIFIABLE INFORMATION:</strong> The information qualifies as personal in nature when the information collected identifies a specific end user. Such information would be collected by the website during the following actions:-</p>
<p><strong>● &nbsp; &nbsp;Creating an account / Registration data:</strong>&nbsp;</p>
<ol>
    <li>While accessing the Website, the User of the Website may be required to create an account. The personal information which may be sought while creating an account shall include, but not limited to the Full name, Address, Telephone Number, Email-address, Date of Birth, Gender, Location, Photograph, any other items of &lsquo;sensitive personal data or information&rdquo; as such term is defined under the Information Technology (Reasonable Security Practices And Procedures And Sensitive Personal Data Of Information) Rules, 2011 enacted under the Information Technology Act, 2000, and any other detail required on the website during registration.</li>
    <li>It is hereby informed to all the Users that the e-mail address or phone number together with a password or OTP is used for the purpose of securing User&rsquo;s profile and for effective implementation of the personalized E-mail and SMS Services provided by the Website to the User. In the event that no registration is made by the User, the Website may not be able to provide any services due to non-availability of the personal identifiable information of the User.</li>
</ol>
<p>● &nbsp; &nbsp;<strong>Booking a paid service:</strong>&nbsp;</p>
<p>While booking a service through Order Form, the personal information which may be sought would include, but not limited to the information as mentioned in Column 1(a), financial information inclusive of bank account information, credit card or debit card details or other payment instrument details through a secure third party gateway, IP (Internet protocol) Address and any other information that a User may provide during booking a paid service on the Website. Such information is kept highly confidential.</p>
<p>● &nbsp; &nbsp;<strong>Miscellaneous Activities:&nbsp;</strong></p>
<p>The Website may collect any other information which may be mandatory to be disclosed and further may receive any other information via email or other method inclusive of contract with regard to specific services availed from the Website or any products bought from the Website, such information may not be made part of the User-Member&rsquo;s Profile but shall be used only for addressing the specific need or concern of the user.</p>
<p><strong>2. NON - PERSONAL IDENTIFIABLE INFORMATION:</strong> The information qualifies as non-personal in nature when the information collected does not identify a specific end user. Such information is collected when the user visits the Website and would include but not limited to the following:</p>
<p>● &nbsp; &nbsp;<strong>URL (Uniform Resource Locator) of the previous website visited by the User before visiting this website or the URL of the website the User visits after visiting this Website.</strong></p>
<p><strong>● &nbsp; &nbsp;Internet service provider / IP Address / Telecom service provider.</strong></p>
<p><strong>● &nbsp; &nbsp;Type of Browser used for accessing the website.</strong></p>
<p><strong>● &nbsp; &nbsp;Geographical Location</strong></p>
<p>Such non-personal identifiable information is used by the Website for the purposes including but not limited to troubleshoot connection problems, administer the website, analyze trends, gather demographic information, frequency of visits to the website, average length of visits, pages viewed during a visit, compliance with applicable law, and cooperate with law enforcement activities, etc.</p>
<p>The information is used for improving the site content and performance and the website may share this information with Third Party Service Providers and Third-Party Advertisers to measure the overall effectiveness of the website&rsquo;s online advertising, content, programming and for other <em>bona fide</em> purpose as required.</p>
<p><strong>THE USER HEREBY REPRESENT AND CONFIRMS THAT THE INFORMATION PROVIDED TO THE WEBSITE IS AUTHENTIC, CORRECT, CURRENT AND UPDATED. THE WEBSITE AND ITS ENTITES SHALL NOT BE RESPONSIBLE FOR THE AUTHENTICITY OF THE INFORMATION THAT THE USER MAY PROVIDE. THE USER SHALL BE PERSONALLY LIABLE AND INDEMNIFY THE WEBSITE FOR THE BREACH OF ANY PROVISION.</strong></p>
<ol>
    <li><strong>USAGE OF THE INFORMATION</strong></li>
</ol>
<p>The information collected by the Website may be used for any purpose as may be permissible under the applicable law and shall include but not limited to the following: -</p>
<p>● &nbsp; &nbsp;For providing a personalised browsing experience. While guaranteeing the anonymity of the user, the personal information collected in Clause &ldquo;Personal Identifiable Information&rdquo; may be used for research purposes, for improving the marketing and promotional efforts, to analyse usage, improve the content of the Website, product offering and for customising the Website&rsquo;s layout for suiting the needs of its Users.</p>
<p>● &nbsp; &nbsp;All information (and copies thereof) collected by Website, including without limitation Personal Information, User Data, and other information related to your access and use of the services offered by Website, may be retained by Website for such period as necessary, including but not limited to, for purposes such as compliance with statutory or legal obligations, tax laws and potential evidentiary purposes and for other reasonable purposes such as to implement, administer, and manage your access and use of our services, or resolution of any disputes.</p>
<p>● &nbsp; &nbsp;To ensure a seamless experience at the Website for you and to ensure your maximum benefit and comfort, the Website may use the data collected through log file, device identifiers, location data and clear gifs information to: (a) remember information so that you will not have to re-enter it during your visit or the next time you visit the site; (b) provide custom, personalized content and information, including advertising; (c) provide and monitor the effectiveness of Services offered by Website; (d) monitor aggregate metrics such as total number of visitors, traffic, usage, and demographic patterns on the Website and its Services; (e) diagnose or fix technology problems; and (f) otherwise to plan for and enhance the service.</p>
<p>● &nbsp; &nbsp;Website uses certain third-party analytics tools to measure traffic and usage trends for the Services. These tools collect information, which is not personal or sensitive in nature sent by the User&rsquo;s device, including the web pages visited, add-ons, and other information that assists the Website in improving the Services. Such information is collected from Users in the form of anonymized logs, so that it cannot reasonably be used to identify any particular individual User.</p>
<p><strong>6. HOW WE SHARE YOUR INFORMATION</strong></p>
<p><strong>1. INFORMATION SHARED WITH ASTROLOGER PARTNER</strong></p>
<ol>
    <li>When you make the communication or request to join a waitlist through our website, we automatically provide your information to the Astrologer partner in order to facilitate your request. This information includes your title, first name, last name.</li>
    <li>We may share with Astrologers summary reports of feedback from guests. If you provide comments about an Astrologer through our website, these comments may be shared with or may be accessible by that Astrologer. We will not tie your comments with other information that can identify you such as email, phone number.</li>
</ol>
<p><strong>2. PAYMENT CARD INFORMATION</strong></p>
<p>To use certain services on our website (such as contacting and communicating with Astrologers), we may require credit or debit card account information. By submitting your credit or debit card account information through our website, to the extent permitted by applicable law, you expressly consent to the sharing of your information with we-Astro, third-party payment processors, and other third-party service providers (including, but not limited to, vendors who provide fraud detection services to us and other third parties), and you further agree to the following terms.</p>
<ol>
    <ol>
        <li>When you use a credit or debit card to recharge your wallet through our website, we provide your credit or debit card account information (including card number and expiration date) to our third-party payment service providers &ndash; this information is not accessible by the Astrologers</li>
        <li>When you initially provide your credit or debit card account information through our website in order to use our payment service, we provide your credit or debit card account information to our third-party payment service providers.</li>
    </ol>
</ol>
<p><strong>3. INFORMATION YOU SHARE SOCIALLY</strong></p>
<ol>
    <li>Our website may allow you to connect and share your actions, comments, content, and information publicly or with friends. Our website may also allow you to connect with us on, share on, and use third-party platforms, including those on which we-</li>
    <li>omparashar</li>
    <li>has a presence.</li>
    <li>Please be mindful of your personal privacy needs and the privacy needs of others as you choose whom to connect with and what to share and make public. We cannot control the privacy or security of information you choose to make public or share with others. We-</li>
    <li>omparashar</li>
    <li>does not control the privacy practices of third-party platforms. Please contact those sites and services directly to learn about their privacy practices.</li>
    <li><strong>OTHER SHARING</strong></li>
    <li>We do not share your information with third parties.</li>
    <li>We may disclose your information to regulatory authorities, courts, government agencies and other third parties where we believe that doing so would be in accordance with or permitted or required by any applicable law, regulation or legal process, to defend the interests, rights, and/or property of we-omparashar</li>
    <li>or others, or to respond to emergencies.We may disclose or transfer your information to a third party if we sell, transfer, divest, or disclose all or a portion of our business or assets to another company in connection with or during negotiation of any merger, financing, acquisition, bankruptcy, dissolution, transaction, or proceeding.</li>
    <li>We may otherwise share your information as directed by you or subject to your consent.</li>
</ol>
<p><strong>7. WITHDRAWAL OF CONSENT</strong></p>
<p>You are entitled to withdraw your consent for use of your Personal Information by emailing a specific request to support@omparashar.com.</p>
<p><strong>(Please note - the exercise of any such right shall prevent you from taking full advantage of the entire scope of features and Services offered through the Website and Mobile Application and We reserve the right not to provide you with our Services.)</strong></p>
<p><strong>9. SECURITY OF YOUR INFORMATION</strong></p>
<ol>
    <li>We-omparashar</li>
    <li>takes commercially-reasonable technical, administrative, and physical security measures designed to protect your information from loss, misuse, unauthorised access, disclosure, alteration and destruction.</li>
    <li>When your credit or debit card account information is being transmitted to our website it will be protected by cryptographic protocols. To be clear, we-omparashar</li>
    <li>does not itself store your credit or debit card account information, and we do not have direct control over or responsibility for your credit or debit card account information. We use third party payment processors that are the controllers of your credit card information. Our contracts with third parties that receive your credit or debit card account information require them to keep it secure and confidential.</li>
    <li>Nonetheless, we cannot guarantee that transmissions of your credit or debit card account information or your other information will always be secure or that unauthorised third parties will never be able to defeat the security measures taken by we-omparashar</li>
    <li>or our third-party service providers. Except to the extent that liability cannot be excluded or limited due to applicable law, we assume no liability or responsibility for disclosure of your information due to errors in transmission, unauthorised third-party access, or other causes beyond our control. You play an important role in keeping your information secure. You should not share your user name, password, or other security information for your we-omparashar</li>
    <li>account with anyone. If we receive instructions using your user name and password, we will consider that you have authorised the instructions. If you have reason to believe that your interaction with us is no longer secure or feel that the security of any account you might have with us has been compromised), please contact us immediately.</li>
</ol>
<p><strong>10. LINKS TO OTHER WEBSITES</strong></p>
<p>Our website may contain links to other websites or services that are not owned or controlled by we-omparashar</p>
<p>, including links to our advertisers, sponsors and partners. This Policy only applies to information collected by our website. We have no control over these third-party websites, and your use of third-party websites and features are subject to privacy policies posted on those websites. We are not responsible or liable for the privacy or business practices of any third-party websites linked to our website. Your use of third parties&apos; websites linked to our website is at your own risk, so we encourage you to read the privacy policies of any linked third-party websites when you leave our website.</p>
<p><strong>11. DISCLOSURE OF YOUR PERSONAL INFORMATION</strong></p>
<p>We are not in the business of selling your information. We consider this information to be a vital part of our relationship with you. As such we will not sell or rent your Personal Data save and except as provided herein. There are, however, certain circumstances in which we may share your Personal Data with certain third parties without further notice to you, as set forth below.</p>
<ol>
    <li><strong>Business Transfer.</strong> As we develop our business, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, dissolution or similar event, Personal Data may be part of the transferred assets.</li>
    <li><strong>Astro Group</strong>. We may also share your Personal Data with Astro Group for purposes consistent with this Policy.</li>
    <li><strong>Legal Requirements</strong>. To comply with any order of court or directive from authorities investigating any alleged offence, misdeed and/or abuse or to enforce any of the terms in the Terms of Use, Privacy Notices or, where such action is necessary to protect and defend the rights or property of the Company.</li>
    <li><strong>Authorised Third Party</strong>. Where services from such Authorised Third Party is required, we will share your Personal Data on a strictly confidential basis. Authorised Third Party will not be allowed to use your personal information disclosed to them except for the limited purpose of providing the particular service.</li>
</ol>
<p><strong>12. SECURITY</strong></p>
<ol>
    <li>The internet is not a secure medium, and communications over the internet may be subject to third-party interception. The Company and/or Astro Group cannot accept responsibility for any unauthorised access or loss of personal information, save in accordance with the Terms of Use.</li>
    <li>We have implemented security policies, rules and technical measures to protect your Personal Data from unauthorised access, improper use or disclosure, unauthorised modification, unlawful destruction or accidental loss. All our employees and data processors, who have access to and are associated with the processing of Personal Data, are obliged to respect the confidentiality of your information.</li>
    <li>While we cannot guarantee that loss, misuse or alteration of data will not occur; we make reasonable efforts to prevent such unfortunate occurrences.</li>
    <li>Personal Data that we collect from you will be stored and backed up securely in an environmentally-controlled location. Only authorized people with user ID and password from our company have access to the information.</li>
    <li>You must remember to log off after accessing and/or using our Services and/or close the browser window after use so that no unauthorized party can have access. You must not, at any time, divulge your Personal Data to any third party.</li>
    <li>Omparashar.com honors users&apos; privacy and employs the best practice to secure the user&apos;s personal details, such as birth details, address, etc., and also financial details such as credit card or debit card transaction details. omparashar.com uses the best encryption methodologies to ensure secure transactions from our side and thus encourages our clients to use their credit/debit cards on</li>
    <li>omparashar</li>
    <li>.com with full confidence. By doing so, we strive for the safety and security of our users, thus making their experience with <a href="//Omparashar.com">Omparashar.com</a> absolutely safe and secure.</li>
</ol>

<p><br/></p>
<p><strong>13. RETENTION AND DELETION OF PERSONAL &nbsp; &nbsp; &nbsp; INFORMATION</strong></p>
<p><strong>RETENTION</strong></p>
<p>The information provided to us by the User or a Registered Member may be retained by us to give effect to the objective for which it was collected or to effectively provide the Services to our Users. Further, We may, even after the termination or deletion of the account of a User, continue to retain such information if it is reasonably necessary to comply with legal obligations, meet regulatory requirements, resolve disputes between Users, prevent fraud, or for any other use.</p>
<p><strong>DELETION</strong></p>
<p>Unless legally allowed or mandated to store such Personal Information, upon receiving a request at support@omparashar.in, We will delete all your Personal Information or anonymize it so that such data is no longer identifiable to you as an individual.</p>
<p><strong>14. CHILDREN PRIVACY POLICY</strong></p>
<p>The Website requires that the User visiting and using the services are above 18 years of age however some service information is accessible to children under the age of 18 as well. However, it is stressed upon that website is not designed or intended to be attractive to be used by children under the age of 13 and no personal identifiable information of children below the age of 13 is collected knowingly.&nbsp;</p>
<p><strong>IF YOU ARE UNDER 13 YEARS OF AGE, PLEASE DO NOT USE ANY OF THE SERVICE PROVIDED BY THE WEBSITE AT ANY TIME OR IN ANY MANNER.</strong> If it comes to the knowledge of the concerned parent regarding sharing of any information of a child under the age of 13, contact the Website immediately. We will take appropriate steps and delete the data from the Website&rsquo;s systems.</p>
<p><strong>15. OPTING-OUT, CHANGE, UPDATE OF AND ACCESS TO YOUR INFORMATION</strong></p>
<ol>
    <li>You may be able to access and/or use our Services without providing us any Personal Data. If you choose not to provide any Personal Data, you may not be able to access and/or use certain Services.</li>
    <li>However, when you choose to provide us with such Personal Data, we may also use your Personal Data and Information to contact you in the future to tell you about our services we believe will be of interest to you. If we do so, each communication we sent you will contain instructions permitting you to &ldquo;opt-out&rdquo; of receiving future marketing communications. In addition, if at any time you wish not to receive any future communications or you wish to have your name deleted from our mailing lists, please contact us or you may opt-out by replying UNSUBCRIBE in the subject line in the email sent or by emailing us.</li>
    <li>You can write to us via pdata@omparashar.com.my at any time to obtain details of the Personal Data we hold about you. Please quote your name, address. It would be helpful if you also provide brief details of what information you want a copy of. We may charge a reasonable sum permitted by existing laws to cover our administration costs.</li>
    <li>We reserve the right to refuse to provide our Users with a copy of their Personal Data, but will give reasons for our refusal.</li>
</ol>
<p><strong>16. CHANGES TO THE POLICY</strong></p>
<p>This Policy may get updated from time to time pursuant to changes in applicable law, the scope of our Services, or otherwise. The date mentioned at the top of this Policy shall reflect the date on which the Policy was last revised. We may or may not inform the Users of any material amendments to this Policy by posting the updated Policy on our Website. We encourage you to review this Policy regularly for any such changes. Your continued use of the Services will be subject to the then current Policy.</p>
<p><strong>17. SEVERABILITY AND EXCLUSION</strong></p>
<p>We have taken every effort to ensure that this Policy adheres to the applicable laws. The invalidity or unenforceability of any part of this Policy shall not prejudice or affect the validity or enforceability of the remainder of this Policy. This Policy does not apply to any information other than the Personal Information collected by us through the Website. This Privacy Policy shall be inapplicable to any unsolicited information you provide us through the Website or through any other means or through public channels. This includes, but is not limited to, information posted in any public areas of the Website. All unsolicited information shall be deemed to be non-confidential and We shall be free to use and/ or disclose such unsolicited information without any limitations.</p>
<p><strong>18. APPLICABLE LAW AND DISPUTE RESOLUTION:</strong></p>
<ol>
    <li>This Policy shall be governed by and construed in accordance with Indian laws. All disputes under this Policy shall be referred exclusively to courts at New Delhi India. Any dispute or claim arising out of or relating to this Policy including the determination of the scope or applicability of this Policy, or any information provided thereof, shall be resolved through arbitration conducted by a sole arbitrator appointed by mutual consent of the User or Registered Member and the Website. The arbitration shall be governed by the Arbitration and Conciliation Act, 1996, and the rules made thereunder. The venue of the arbitration shall be New Delhi and the language of such arbitral proceedings and award rendered shall be English. The award delivered pursuant to such arbitral proceedings shall be final and binding on the parties to the dispute.</li>
    <li>Notwithstanding anything contained herein above, every party to a dispute shall have the right to seek interim relief from a court of competent jurisdiction in New Delhi, India to safeguard the rights of such party pending any arbitration proceeding initiated.</li>
</ol>
<p><strong>19. NEWSLETTER</strong></p>
<ol>
    <li>Following subscription to the newsletter, your e-mail address is used for our advertising purposes until you cancel the newsletter again. Cancellation is possible at any time. The following consent has been expressly granted by you separately, or possibly in the course of an ordering process: (I am accepting to receive newsletter from this website), you may revoke your consent at any time with future effect. If you no longer want to receive the newsletter, then unsubscribe by clicking on unsubscribe option given in the email footer.</li>
    <li>If you have any concern about privacy or grievances with Us, please contact us with a thorough description and we will try to resolve the&nbsp;issue&nbsp;for&nbsp;you.</li>
</ol>
<p><strong>20. CONTACT INFORMATION</strong></p>
<p>In case of any complaints or grievances, please contact us by sending your complaint containing the following information to email id support@omparashar.com</p>
<p>● &nbsp; &nbsp;Your name, address, contact number, and email address</p>
<p>● &nbsp; &nbsp;description of the complaint with a clear identification of the Users&rsquo; Content in relation to which such complaint is being made</p>
<p>● &nbsp; &nbsp;an acknowledgement by you that all information contained in the complaint is true and accurate.</p>
<p>On receiving the complaint, we reserve the right to investigate and/or take such actions as We may deem appropriate. We may also reach out to you to seek further clarification or assistance with the investigation, or verify the statements made in your complaint and you acknowledge that your timely assistance with the investigation would facilitate the swift redressal of the same.</p>
<p><strong>21. GRIEVANCE OFFICER</strong></p>
<p>Email: support_ak@omparashar.com</p>
<p>The above Officer is appointed in accordance with the Information Technology Act 2000 and rules made there under. The Officer can be contacted if there are any discrepancies found on the website or there is any breach of Terms of Use, Privacy Policy or other policies or any other complaints or concerns.</p>
<p><strong>22. DISCLAIMER</strong></p>
<p><strong>THE WEBSITE IS NOT RESPONSIBLE FOR ANY COMMUNICATION BETWEEN THE USER AND THE THIRD-PARTY WEBSITE. THE USER IS ADVISED TO READ THE PRIVACY POLICY AND OTHER POLICIES OF THE THIRD PARTY ON THEIR WEBSITES AND THIS WEBSITE SHALL NOT BE HELD LIABLE FOR SUCH USAGE MADE ONLY BECAUSE A LINK TO THE THIRD-PARTY WEBSITE WAS PROVIDED ON THE PAGE OF THIS WEBSITE.</strong></p>
<p><br/></p>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;
