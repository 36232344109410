import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
const AuspiciousTimingsHTML = (props) => {
  
  const { t, i18n } = useTranslation();
  return (
    <>
      <table
        className="tablesaw table mb-0 tablesaw-stack"
        id="tablesaw-802"
        style={{ border: "none", borderTop: "none" }}
      >
        <tbody>
          <tr className="table_head_tr" style={{}}>
            <th scope="col" colSpan="5" className="sectionheader">
              {t("Auspicious Timings")}
            </th>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Amrit Kal")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.amritkaal}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Abhijit Muhurat")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">{props.abhijit}</span>
            </td>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Brahma Muhurat")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.brahma}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Nishita Muhurat")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">{props.nishita}</span>
            </td>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Pratha Sandhya")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.psandhya}</span>
            </td>
            <td className="td3">
              <span className="tablelabel">{t("Sayan Sandhya")}</span>
            </td>
            <td className="td4">
              <span className="tablevalue">{props.ssandhya}</span>
            </td>
          </tr>
          <tr>
            <td className="td1">
              <span className="tablelabel">{t("Vijaya Muhurat")}</span>
            </td>
            <td className="td2">
              <span className="tablevalue">{props.vijay}</span>
            </td>
            <td className="td3">
              <span className="tablelabel"></span>
            </td>
            <td className="td4">
              <span className="tablevalue">{}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default AuspiciousTimingsHTML;
