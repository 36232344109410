import React, { Component } from 'react';
import {GoogleLogout} from 'react-google-login';
import { FaSignOutAlt } from 'react-icons/fa';

const clientID = "1029976973836-6fbs7jup27deu5qsl2s1rmgc5vpuu9ff.apps.googleusercontent.com";

function Logout() {
    const onSuccess=(res)=>{
        //alert("Logout Successfully")
        //remove token from local storage
        localStorage.removeItem("omp_token");
        localStorage.removeItem("omp_email");
        localStorage.removeItem("omp_name");
        localStorage.removeItem("omp_profile_url");
        window.location.href="/";

    }

    return(
        <div>
             <GoogleLogout 
                        clientId={clientID}
                        render={renderProps => (
                            <button className="btn btn-danger" onClick={renderProps.onClick} disabled={renderProps.disabled}>Logout <FaSignOutAlt/></button>
                          )}
                        buttonText="Logout"
                        onLogoutSuccess={onSuccess}
                        />
        </div>
    )
    
}

export default Logout;