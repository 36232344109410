import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./autocomplete.css";
import { GlobalContext } from "../../../mycontext";
import Api from "../../../API/Api";

var _ = require("lodash");

class CountryAutocomplete extends Component {
  static contextType = GlobalContext;
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
  };

  static defaultProps = {
    suggestions: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: this.props.city,
    };
    this.Api = new Api();
    this.fetchSuggestions = _.debounce(this.fetchSuggestions, 500);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resetInputText) {
      this.setState({ userInput: "" });
      this.props.handleChange("reset");
    }
  }
  fetchSuggestions(userInput) {
    this.Api.fetchSuggestionsCountry(userInput).then((response) => {
      if (response) {
        if (response.data) {
          // console.log(response.data.Patterns_matched.slice(0, 6));
          this.setState({
            filteredSuggestions: response.data,
          });
        }
      } else {
        // console.log("Could not fetch data");
      }
    });
  }

  // Event fired when the input value is changed
  onChange = (e) => {
    this.props.handleChange("userInput");
    const userInput = e.currentTarget.value;
    this.fetchSuggestions(userInput);
    // if (this.props.mode === "Transition") {
    //   this.props.handleUserInput(e.currentTarget.value);
    // }
    this.setState({
      activeSuggestion: 0,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = async (e) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
    });

    if (this.props.mode === "Horoscope") {
      this.context.setCountry(e.currentTarget.innerText, "Horoscope");
      this.context.setCityAndState("", "Horoscope");
    } else if (this.props.mode === "Panchang") {
      this.context.setCountry(e.currentTarget.innerText, "Panchang");
      this.context.setCityAndState("", "Panchang");
    } else if (this.props.mode === "Transit") {
      this.context.setCountry(e.currentTarget.innerText, "Transit");
      this.context.setCityAndState("", "Transit");
    }
  };

  // Event fired when the user presses a key down
  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    if (e.keyCode === 13) {
      // User pressed the enter key, update the input and close the
      // suggestions
      e.preventDefault();
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
      if (this.props.mode === "Horoscope") {
        this.context.setCountry(
          filteredSuggestions[activeSuggestion],
          "Horoscope"
        );
        this.context.setCityAndState("", "Horoscope");
      } else if (this.props.mode === "Panchang") {
        this.context.setCountry(
          filteredSuggestions[activeSuggestion],
          "Panchang"
        );
        this.context.setCityAndState("", "Panchang");
      } else if (this.props.mode === "Transit") {
        // this.props.handleUserInput(filteredSuggestions[activeSuggestion]);
        this.context.setCountry(
          filteredSuggestions[activeSuggestion],
          "Transit"
        );
        this.context.setCityAndState("", "Transit");
      }
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  checkInput = () => {
    if (this.props.mode === "Horoscope") {
      if (this.state.userInput === "") {
        this.context.setCountry(null, "Horoscope");
      }
    } else if (this.props.mode === "Panchang") {
      if (this.state.userInput === "") {
        this.context.setCountry(null, "Panchang");
      }
    } else if (this.props.mode === "Transit") {
      if (this.state.userInput === "") {
        this.context.setCountry(null, "Transit");
      }
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;
    let suggestionsListComponent;
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions" style={{ width: "70%" }}>
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else if (filteredSuggestions.length === 0) {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No suggestions, you're on your own!</em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <input
           //autoComplete="off"
          autoComplete="new-password"
          // id="auto_completecountry"
          ref="cpDev1"
          type="search"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={this.state.userInput}
          className="form-control"
          style={{ width: "70%" }}
          onBlur={() => this.checkInput()}
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default CountryAutocomplete;
