import AutoCompleteCountry from "../../CelebrityManagement/Autosuggests/AutoCompleteCountry";
import { useEffect, useState } from "react";
import { TagSelector } from "../QueryBuilder/tagSelector"
import {getGender} from "../../../API/CelebrityManagement_API";
import React from "react";
import {useTranslation} from "react-i18next";
import { FiInfo } from "react-icons/fi";


export const Filters = (props) => {
    const {t, i18n} = useTranslation();

    const [allGenders,setAllGenders]=useState([]);
    // const [filters, setFilters] = useState(JSON.parse(localStorage.getItem(props.filterName)) ||
    //     { gender: undefined, country: undefined, from_year: undefined, to_year: undefined })

    const [filters, setFilters] = useState(
        // JSON.parse(localStorage.getItem(props.filterName))||
        props.filters)
  
    //will store selected filters till "apply filters" button is clicked
    const [tempfilters, setTempFilters] = useState(
        // JSON.parse(localStorage.getItem(props.filterName)) || 
        props.filters)
        // { gender: undefined, country: undefined, from_year: undefined, to_year: undefined, tags: undefined })

    // const [tagOptions, setTagOptions] = useState([])
    const [selectedTags,setSelectedTags]=useState([])
    const [resetTagSelection,setResetTagSelection]=useState(false)

    const yearArray = [];

    for (let x = 1900; x <= 2399; x++) {
        yearArray.push(x)
    }

    const c = async () => {
        if(parseInt(props.category)===2){
            //mundane
            setAllGenders([]);
            return;
        }
        else if(parseInt(props.category)===1){
            //Human
            var data = await getGender();
            data = data.filter(i=>
                i!=="N/A"
            )
            setAllGenders(data);
        }
        
        // console.log(allGenders)
    }

    useEffect(() => {
        // c();
    }, [])

    useEffect(()=>{
        c();
    },[props.category])

    useEffect(()=>{
        props.setfilters(filters);
        // localStorage.setItem(props.filterName, JSON.stringify(filters));
    },[filters])

    useEffect(()=>{
        setTempFilters(props.filters);
        if(props?.filters?.tag_options?.length===0){
            setResetTagSelection(true);
        }
        //Not setting filters to avoid circular calling as useEffect on filters sets props.filters
    },[props.filters])

    const resetFilterData = () => {
        var temp = {
            gender: undefined,
            country: undefined,
            from_year: undefined,
            to_year: undefined,
            tags:undefined,
            tag_options:undefined
        }
        setFilters(temp);
        setTempFilters(temp);
        // localStorage.setItem(props.filterName, JSON.stringify(temp));
        setResetTagSelection(true);
        // console.log(filters,temp);
    }

    const applyTempFilter = (_key, value) => {
        var temp = {...tempfilters};
        if(value==="placeholder"){
            value=undefined;
        }
        temp[_key] = value;
        setTempFilters(temp);
    }

    const applyFilters = () => {
        var temp = { ...tempfilters };
        if(temp?.to_year && temp?.from_year===undefined){
            alert("Please select a valid from year.");
            return;
        }
        if(temp?.from_year && temp?.to_year===undefined){
            alert("Please select a valid to year.");
            return;
        }
        var temp_tags = [];
        selectedTags.forEach(i=>{
            temp_tags.push(i.value);
        })
        temp["tags"]=temp_tags;
        temp["tag_options"]=selectedTags;
        setFilters(temp);
        // localStorage.setItem(props.filterName, JSON.stringify(temp));
    }

    return (<>
        <div className="row">
            <div className="col-md-12">
            <div>
          <h3 class="h6"><FiInfo/> To filter data for single year select that year in both to year and from year </h3>
          <div class="row">
            <div class="col-lg-2">
                <label class="form-label">Gender</label>
                   <select className="form-control"
                                value={tempfilters.gender ? tempfilters.gender : "placeholder"}
                                onChange={(e) => applyTempFilter("gender", e.target.value)}>
                                <option key="Select Gender" value="placeholder">Select Gender</option>
                                {allGenders.map((item) => (
                                    <option key={item} value={item}>{item}</option>
                                ))}
                    </select>
            </div>
            <div class="col-lg-2">
                <label class="form-label">Country</label>
                           <AutoCompleteCountry
                                autoComplete="off"
                                city={tempfilters.country ? tempfilters.country : ""}
                                setCountry={(country) => { applyTempFilter("country", country); }}
                                isDisabled={false}
                                onReset={() => { applyTempFilter("country", undefined); }}
                            />
            </div>
            <div class="col-lg-2">
                <label class="form-label">From Year</label>
                            <select className="form-control"
                                value={tempfilters.from_year ? tempfilters.from_year : "placeholder"}
                                onChange={(e) => applyTempFilter("from_year", e.target.value)}>
                                <option key="Select Year" value="placeholder">Select Year</option>
                                {yearArray.map((item) => (
                                    <option key={item} value={item}>{item}</option>
                                ))}
                            </select>
            </div>
            <div class="col-lg-2">
                <label class="form-label">To Year</label>
                            <select className="form-control"
                                value={tempfilters.to_year ? tempfilters.to_year : "placeholder"}
                                onChange={(e) => applyTempFilter("to_year", e.target.value)}>
                                <option key="Select Year" value="placeholder">Select Year</option>
                                {yearArray.map((item) => {
                                    if(tempfilters?.from_year!=="placeholder" && item<tempfilters?.from_year){
                                        return;
                                    }
                                    return(<option key={item} value={item}>{item}</option>);
                                })}
                            </select>
            </div>
         
                    {props.basicSearchFlag===true?"":(<>
                        <div className="col-lg-2">
                    <label className="form-label">Tags</label>
                        <div className="col-md-12" style={{paddingLeft:"1px"}}>

                        <TagSelector
                                tags={props.tags}
                                setter={(val)=>{setSelectedTags(val); applyTempFilter("tag_options",val)}}
                                reset={resetTagSelection}
                                setReset={setResetTagSelection}
                                selectedValues={tempfilters?.tag_options}
                            />
                            </div>
                            </div>
                            </>)}
                        
                       
            <div class="col-lg-2">
            <label class="form-label">-</label>
            <div className="d-flex flex-row">
            <div> <button className="btn btn-success btn-sm" onClick={applyFilters}>{t("Apply")}</button></div>
                <div>
                <button className="btn btn-secondary btn-sm ml-1" onClick={resetFilterData}>{t("Reset")}</button>
                </div>
            </div>
               
           
           
            </div>
           
          </div>
          <hr/>
          </div>
                {/* <fieldset className="form-group border p-3">
                
                    <div className="form-group row">
                        <label className="col-md-2 col-form-label">Gender</label>
                        <div className="col-md-4">
                            <select className="form-control" style={{ color: "black", border: "1px solid black" }}
                                value={tempfilters.gender ? tempfilters.gender : "placeholder"}
                                onChange={(e) => applyTempFilter("gender", e.target.value)}>
                                <option key="Select Gender" value="placeholder">Select Gender</option>
                                {allGenders.map((item) => (
                                    <option key={item} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <label className="col-md-2 col-form-label">Country</label>
                        <div className="col-md-4">
                            <AutoCompleteCountry
                                autoComplete="off"
                                city={tempfilters.country ? tempfilters.country : ""}
                                setCountry={(country) => { applyTempFilter("country", country); }}
                                isDisabled={false}
                                border_color="black"
                                onReset={() => { applyTempFilter("country", undefined); }}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <p className="template-validator-message col-12"><FiInfo/> To filter data for single year select that year in both to year and from year </p>
                    <label className="col-md-2 col-form-label">From Year</label>
                        <div className="col-md-4">
                            <select className="form-control" style={{ color: "black", border: "1px solid black" }}
                                value={tempfilters.from_year ? tempfilters.from_year : "placeholder"}
                                onChange={(e) => applyTempFilter("from_year", e.target.value)}>
                                <option key="Select Year" value="placeholder">Select Year</option>
                                {yearArray.map((item) => (
                                    <option key={item} value={item}>{item}</option>
                                ))}
                            </select>
                        </div>
                        <label className="col-md-2 col-form-label">To Year</label>
                        <div className="col-md-4">
                            <select className="form-control" style={{ color: "black", border: "1px solid black" }}
                                value={tempfilters.to_year ? tempfilters.to_year : "placeholder"}
                                onChange={(e) => applyTempFilter("to_year", e.target.value)}>
                                <option key="Select Year" value="placeholder">Select Year</option>
                                {yearArray.map((item) => {
                                    if(tempfilters?.from_year!=="placeholder" && item<tempfilters?.from_year){
                                        return;
                                    }
                                    return(<option key={item} value={item}>{item}</option>);
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                    {props.basicSearchFlag===true?"":(<><label className="col-md-2 col-form-label">Tags</label>
                        <div className="col-md-10">

                        <TagSelector
                                tags={props.tags}
                                setter={(val)=>{setSelectedTags(val); applyTempFilter("tag_options",val)}}
                                reset={resetTagSelection}
                                setReset={setResetTagSelection}
                                selectedValues={tempfilters?.tag_options}
                            />
                            </div>
                            </>)
}
                        
                        </div>
                        <div className="form-group row">
                        <div className="col-md-6 text-right"></div>
                        <div className="col-md-3 text-right">
                            <button className="btn btn-secondary" onClick={resetFilterData}>{t("Clear Filters")}</button>
                        </div>
                        <div className="col-md-3 ">
                            <button className="btn btn-success" onClick={applyFilters}>{t("Apply Filters")}</button>
                        </div>
                        </div>
                </fieldset> */}
            </div>
           
        </div></>);
}

// export default withRouter(Filters);