/* eslint-disable no-useless-concat */
import React, { useState, useEffect, useContext } from "react";
import "./dash-panchang.css";
import "./Holistic.css";
import { GlobalContext } from "../../mycontext";
import axios from "axios";
import background from "../../assets/img/bg.jpg";
import moment from "moment";
import Sideform from "../SideComponents/sideform";
import DashboardTopBar from "../DashBoardComponents/DashboardTopBar";
import DashboardSideBar from "../DashBoardComponents/DashboardSideBar";

import loadjs from "loadjs";
import Api from "../../API/Api";
import SunriseMoonrise from "./SunRiseMoonRise";
import AscendantTableHTML from "./AscendantTable";
import InauspiciousTimingsHTML from "./InauspiciousTimings";
import AuspiciousTimingsHTML from "./AuspiciousTimings";
import RituAndAyanHTML from "./RituAndAyan";
import RashiHTML from "./Rashi";
import SamvatsaraHTML from "./Samvatsara";
import PanchangHTML from "./Panchang";
import NakshtraHTML from "./Nakshtra";
import ChoghadiyaHTML from "./Choghadiya";
import TaraBalamHTML from "./Tarabalam";
import ChandraBalamHTML from "./ChandraBalam";
import { useTranslation } from "react-i18next";
import Login from "../GoogleLogin/login";
import ImageMapper from "./ImageMapper";

const setTimeFormat = (timestring) => {
  timestring = timestring.split(":");
  var hours = timestring[0];
  var minutes = timestring[1];
  hours = hours % 12;
  hours = hours ? hours : 12;
  // hours = hours < 10 ? hours.substring(1): hours;
  return hours + ":" + minutes;
};

const setTimeFormatNaks = (timestring) => {
  timestring = timestring.split(":");
  var hours = timestring[0];
  var minutes = timestring[1];
  var ampm = hours > 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  // hours = hours < 10 ? hours.substring(1): hours;
  return hours + ":" + minutes + " " + ampm;
};

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function remove_character(str, char_pos, str1) {
  if (str !== null) {
    str = str.split(",")[1];
    let part1 = str.substring(0, char_pos + 1);
    let part2 = str.substring(char_pos + 4, str.length);
    return setTimeFormat(part1) + part2;
  }
}

function extract_current_tithi(obj, t) {
  if (obj !== null) {
    let tithi_name = obj.current.Tithi.split(",")[0];
    let current_end_time = setTimeFormat(obj["current"]["Ends on"]["time"]);
    let current_end_date = obj["current"]["Ends on"]["date"].split("-");
    let month_name = t(monthNames[current_end_date[1] - 1]);
    let day = current_end_date[0];
    if (localStorage.getItem("UserDefaultLanguage") === "English") {
      return ` ${tithi_name} ${t(
        "upto"
      )} ${current_end_time}, ${month_name} ${day}`;
    } else {
      return `${current_end_time}, ${month_name} ${day} ${t(
        "upto"
      )} ${tithi_name}`;
    }
  }
}

function extract_current_yoga(obj, t) {
  if (obj !== null) {
    let yoga_name = obj.current.Yoga.split(",")[0];
    let current_end_time = setTimeFormat(obj["current"]["Ends on"]["time"]);
    let current_end_date = obj["current"]["Ends on"]["date"].split("-");
    let month_name = t(monthNames[current_end_date[1] - 1]);
    let day = current_end_date[0];
    if (localStorage.getItem("UserDefaultLanguage") === "English") {
      return ` ${yoga_name} ${t(
        "upto"
      )} ${current_end_time}, ${month_name} ${day}`;
    } else {
      return `${current_end_time}, ${month_name} ${day} ${t(
        "upto"
      )} ${yoga_name}`;
    }
  }
}

function extract_current_karan(obj, t) {
  if (obj !== null) {
    let karan_name = obj.current.Karan.split(",")[0];
    let next_end_time = setTimeFormat(obj["current"]["Ends on"]["time"]);
    let next_end_date = obj["current"]["Ends on"]["date"].split("-");
    let month_name = t(monthNames[next_end_date[1] - 1]);
    let day = next_end_date[0];
    if (localStorage.getItem("UserDefaultLanguage") === "English") {
      return ` ${karan_name} ${t(
        "upto"
      )} ${next_end_time}, ${month_name} ${day}`;
    } else {
      return `${next_end_time}, ${month_name} ${day} ${t(
        "upto"
      )} ${karan_name}`;
    }
  }
}

function extract_next_tithi(obj, t) {
  if (obj !== null) {
    let tithi_name = obj.next.Tithi.split(",")[0];
    let next_end_time = setTimeFormat(obj["next"]["Ends on"]["time"]);
    let next_end_date = obj["next"]["Ends on"]["date"].split("-");
    let month_name = t(monthNames[next_end_date[1] - 1]);
    let day = next_end_date[0];
    if (localStorage.getItem("UserDefaultLanguage") === "English") {
      return ` ${tithi_name} ${t(
        "upto"
      )} ${next_end_time}, ${month_name} ${day}`;
    } else {
      return `${next_end_time}, ${month_name} ${day} ${t(
        "upto"
      )} ${tithi_name}`;
    }
  }
}

function extract_next_yoga(obj, t) {
  if (obj !== null) {
    let yoga_name = obj.next.Yoga.split(",")[0];
    let next_end_time = setTimeFormat(obj["next"]["Ends on"]["time"]);
    let next_end_date = obj["next"]["Ends on"]["date"].split("-");
    let month_name = t(monthNames[next_end_date[1] - 1]);
    let day = next_end_date[0];
    if (localStorage.getItem("UserDefaultLanguage") === "English") {
      return `${yoga_name} ${t(
        "upto"
      )} ${next_end_time}, ${month_name} ${day} `;
    } else {
      return `${next_end_time}, ${month_name} ${day} ${t("upto")} ${yoga_name}`;
    }
  }
}

function extract_next_karan(obj, t) {
  if (obj !== null) {
    let karan_name = obj.next.Karan.split(",")[0];
    let next_end_time = setTimeFormat(obj["next"]["Ends on"]["time"]);
    let next_end_date = obj["next"]["Ends on"]["date"].split("-");
    let month_name = t(monthNames[next_end_date[1] - 1]);
    let day = next_end_date[0];

    if (localStorage.getItem("UserDefaultLanguage") === "English") {
      return ` ${karan_name} ${t(
        "upto"
      )} ${next_end_time}, ${month_name} ${day}`;
    } else {
      return `${next_end_time}, ${month_name} ${day} ${t(
        "upto"
      )} ${karan_name}`;
    }
  }
}

function extract_next_next_karan(obj, t) {
  if (obj !== null) {
    let karan_name = obj.next_next.Karan.split(",")[0];
    let next_end_time = setTimeFormat(obj["next_next"]["Ends on"]["time"]);
    let next_end_date = obj["next_next"]["Ends on"]["date"].split("-");
    let month_name = t(monthNames[next_end_date[1] - 1]);
    let day = next_end_date[0];
    if (localStorage.getItem("UserDefaultLanguage") === "English") {
      return ` ${karan_name} ${t(
        "upto"
      )} ${next_end_time}, ${month_name} ${day}`;
    } else {
      return `${next_end_time}, ${month_name} ${day} ${t(
        "upto"
      )} ${karan_name}`;
    }
  }
}

function extract_auspicious_string(obj, str, t) {
  if (obj !== null) {
    if (str === "Nishita") {
      const times = [];
      for (const inner in obj) {
        let start_date = obj[inner].split(",")[0].split("/");
        let month_name = t(monthNames[start_date[1] - 1]);
        let day = start_date[0];
        times.push(remove_character(obj[inner], 5));
        times.push(month_name);
        times.push(day);
      };

      

      if (localStorage.getItem("UserDefaultLanguage") === "English") {
        return (
          times[0] +
          "," +
          " " +
          times[1] +
          " " +
          times[2] +
          " " +
          t("to") +
          " " +
          times[3] +
          "," +
          " " +
          times[4] +
          " " +
          times[5]
        );
      } else {
        return (
          times[0] +
          "," +
          " " +
          times[1] +
          " " +
          times[2] +
          " " +
          t("FromPanchang") +
          " " +
          times[3] +
          "," +
          " " +
          times[4] +
          " " +
          times[5] +
          " " +
          t("to")
        );
      }
    } else if (str === "Sandhya") {
      const times = [];
      for (const key in obj) {
        for (const inner in obj[key]) {
          times.push(remove_character(obj[key][inner], 5));
        }
      }
      return times;
    } else {
      const times = [];
      for (const inner in obj) {
        times.push(remove_character(obj[inner], 5));
      }
      if (localStorage.getItem("UserDefaultLanguage") === "English") {
        return (
          setTimeFormat(times[0]) +
          " " +
          t("to") +
          " " +
          setTimeFormat(times[1])
        );
      } else {
        return (
          setTimeFormat(times[0]) +
          " " +
          t("FromPanchang") +
          " " +
          setTimeFormat(times[1]) +
          " " +
          t("to")
        );
      }
    }
  }
}

function extract_ayan(obj) {
  if (obj !== null) {
    const arr = [];
    for (const key in obj) {
      arr.push(obj[key]);
    }
    return arr;
  }
}

function extract_image_link(obj) {
  if (obj !== null) {
    let tithi_name = obj[0][0] + ", " + obj[0][1];
    let imglink = "assets/Moons/";

    tithi_name = ImageMapper[tithi_name] + ".png";
    return imglink + tithi_name;
  }
}

function extract_nakshtra_string(obj, t) {
  if (obj !== null) {
    let end_time = obj["Nakshtra at Sunrise"]["Nakshtra End Time"].split(" ");

    end_time[4] = end_time[4].substring(0, 5);

    if (localStorage.getItem("UserDefaultLanguage") === "English") {
      return `${obj["Nakshtra at Sunrise"]["Nakshtra Name"]} ${t("upto")} ${
        end_time[4]
      }, ${t(end_time[2])} ${end_time[1]} `;
    } else {
      return `${end_time[4]}, ${t(end_time[2])} ${end_time[1]} ${t("upto")} ${
        obj["Nakshtra at Sunrise"]["Nakshtra Name"]
      }`;
    }
  }
}

function extract_abhijit_string(obj, t) {
  if (obj !== null) {
    if (localStorage.getItem("UserDefaultLanguage") === "English") {
      return obj.start + " " + t("to") + " " + obj.end;
    } else {
      return (
        obj.start + " " + t("FromPanchang") + " " + obj.end + " " + t("to")
      );
    }
  }
}

function extract_trikaal(obj) {
  let times = [];
  if (obj !== null) {
    for (const key in obj) {
      for (const inner in obj[key]) {
        times.push(setTimeFormat(obj[key][inner]));
      }
    }
  }
  return times;
}

function extract_ascendant_table(obj) {
  let rashi_mapper = {
    Aries: "../../assets/Zodiac/Aries.svg",
    Cancer: "../../assets/Zodiac/Cancer.svg",
    Taurus: "../../assets/Zodiac/Taurus.svg",
    Sagittarius: "../../assets/Zodiac/Sagittarius.svg",
    Leo: "../../assets/Zodiac/Leo.svg",
    Scorpio: "../../assets/Zodiac/Scorpio.svg",
    Aquarius: "../../assets/Zodiac/Aquarius.svg",
    Virgo: "../../assets/Zodiac/Virgo.svg",
    Capricorn: "../../assets/Zodiac/Capricorn.svg",
    Pisces: "../../assets/Zodiac/Pisces.svg",
    Gemini: "../../assets/Zodiac/Gemini.svg",
    Libra: "../../assets/Zodiac/Libra.svg",
    मेष: "../../assets/Zodiac/Aries.svg",
    कर्क: "../../assets/Zodiac/Cancer.svg",
    वृषभ: "../../assets/Zodiac/Taurus.svg",
    धनु: "../../assets/Zodiac/Sagittarius.svg",
    सिंह: "../../assets/Zodiac/Leo.svg",
    वृश्चिक: "../../assets/Zodiac/Scorpio.svg",
    कुम्भ: "../../assets/Zodiac/Aquarius.svg",
    कन्या: "../../assets/Zodiac/Virgo.svg",
    मकर: "../../assets/Zodiac/Capricorn.svg",
    मीन: "../../assets/Zodiac/Pisces.svg",
    मिथुन: "../../assets/Zodiac/Gemini.svg",
    तुला: "../../assets/Zodiac/Libra.svg",
  };

  let ascendants = [];
  if (obj !== null) {
    for (const key in obj) {
      let times = [];
      for (const inner in obj[key]) {
        if (inner === "start_time" || inner === "end_time") {
          times.push(setTimeFormat(obj[key][inner]));
        } else {
          times.push(obj[key][inner]);
        }
      }
      times.push(rashi_mapper[obj[key]["Ascendant"]]);
      ascendants.push(times);
    }
  }
  return ascendants;
}

function extract_madhyahna(obj, t) {
  if (obj !== null) {
    let obj_str = obj.split(",");
    let date_madhyahna = obj_str[0].split("/");
    let month_name = t(monthNames[date_madhyahna[1] - 1]);
    let day = date_madhyahna[0];
    let time_split = obj_str[1].split(":");
    let period = time_split[2].split(" ");
    return (
      time_split[0] +
      ":" +
      time_split[1] +
      " " +
      period[1] +
      ", " +
      month_name +
      " " +
      day
    );
  }
}

function extract_ascendant_sunrise(obj) {
  if (obj !== null) {
    let rashi_name = obj["Rashi"];
    let dms = obj["D_M_S"];
    return (
      dms[0] + "\u00b0" + " " + rashi_name + " " + dms[1] + "' " + dms[2] + '"'
    );
  }
}
function extract_duration(obj, t) {
  if (obj !== null) {
    let duration = obj[0];
    return (
      duration[0] +
      ` ${t("hours")}, ` +
      duration[1] +
      ` ${t("minutes")}, ` +
      duration[2] +
      ` ${t("seconds")}, `
    );
  }
}

function extract_naktable(obj, t) {
  if (obj !== null) {
    let nak = [];
    for (var key in obj) {
      for (var inner in obj[key].milestones) {
        if (
          obj[key].event_type === "Pada Event" ||
          obj[key].event_type === "पद संचार"
        ) {
          var datetime = obj[key].milestones[inner].event_datetime.split(" ");

          var desc = obj[key].milestones[inner].desc;
          desc = desc.substr(desc.indexOf(" ") + 1);
          desc = desc.substr(desc.indexOf(" ") + 1);

          if (localStorage.getItem("UserDefaultLanguage") === "English") {
            nak.push(
              `${desc} ${t("from")} ${setTimeFormatNaks(datetime[4])}, ${t(
                datetime[2]
              )}, ${datetime[1]} `
            );
          } else {
            nak.push(
              `${setTimeFormatNaks(datetime[4])}, ${t(datetime[2])} ${
                datetime[1]
              } ${t("from")}  ${desc}`
            );
          }
        }
      }
    }
    return nak;
  }
}

function extract_cho(obj, t) {
  let cho = [];
  let months = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };
  if (obj !== null) {
    for (var inner in obj["day_time"]) {
      cho.push(obj["day_time"][inner].chogadiya_name);
      cho.push(obj["day_time"][inner].quality);

      if (localStorage.getItem("UserDefaultLanguage") === "English") {
        cho.push(
          setTimeFormat(obj["day_time"][inner].start_time) +
            " " +
            t("to") +
            " " +
            setTimeFormat(obj["day_time"][inner].end_time)
        );
      } else {
        cho.push(
          setTimeFormat(obj["day_time"][inner].start_time) +
            " " +
            t("FromPanchang") +
            " " +
            setTimeFormat(obj["day_time"][inner].end_time) +
            " " +
            t("to")
        );
      }
    }
    for (var inner2 in obj["night_time"]) {
      cho.push(obj["night_time"][inner2].chogadiya_name);
      cho.push(obj["night_time"][inner2].quality);
      var date = obj["night_time"][inner2].end_date.split("-");

      if (localStorage.getItem("UserDefaultLanguage") === "English") {
        cho.push(
          setTimeFormat(obj["night_time"][inner2].start_time) +
            " " +
            t("to") +
            " " +
            setTimeFormat(obj["night_time"][inner2].end_time) +
            ", " +
            t(months[date[1]]) +
            " " +
            date[0]
        );
      } else {
        cho.push(
          setTimeFormat(obj["night_time"][inner2].start_time) +
            " " +
            t("FromPanchang") +
            " " +
            setTimeFormat(obj["night_time"][inner2].end_time) +
            ", " +
            t(months[date[1]]) +
            " " +
            date[0] +
            " " +
            t("to")
        );
      }
    }
    for (var i in cho) {
      if (cho[i] === "Auspicious") {
        cho[i] = "Auspicious";
      } else if (cho[i] === "Inauspicious") {
        cho[i] = "Inauspicious";
      } else if (cho[i] === "Neutral") {
        cho[i] = "Neutral";
      }
    }
  }
  return cho;
}

function extract_chandrabalam(obj, t) {
  let rashi_mapper = {
    Aries: "../../assets/Zodiac/Aries.svg",
    Cancer: "../../assets/Zodiac/Cancer.svg",
    Taurus: "../../assets/Zodiac/Taurus.svg",
    Sagittarius: "../../assets/Zodiac/Sagittarius.svg",
    Leo: "../../assets/Zodiac/Leo.svg",
    Scorpio: "../../assets/Zodiac/Scorpio.svg",
    Aquarius: "../../assets/Zodiac/Aquarius.svg",
    Virgo: "../../assets/Zodiac/Virgo.svg",
    Capricorn: "../../assets/Zodiac/Capricorn.svg",
    Pisces: "../../assets/Zodiac/Pisces.svg",
    Gemini: "../../assets/Zodiac/Gemini.svg",
    Libra: "../../assets/Zodiac/Libra.svg",
    मेष: "../../assets/Zodiac/Aries.svg",
    कर्क: "../../assets/Zodiac/Cancer.svg",
    वृषभ: "../../assets/Zodiac/Taurus.svg",
    धनु: "../../assets/Zodiac/Sagittarius.svg",
    सिंह: "../../assets/Zodiac/Leo.svg",
    वृश्चिक: "../../assets/Zodiac/Scorpio.svg",
    कुम्भ: "../../assets/Zodiac/Aquarius.svg",
    कन्या: "../../assets/Zodiac/Virgo.svg",
    मकर: "../../assets/Zodiac/Capricorn.svg",
    मीन: "../../assets/Zodiac/Pisces.svg",
    मिथुन: "../../assets/Zodiac/Gemini.svg",
    तुला: "../../assets/Zodiac/Libra.svg",
  };

  let chandra = [];

  if (obj !== null) {
    for (const key in obj.Janma_Rashi_Qualities) {
      chandra.push(rashi_mapper[key]);
    }
    for (const key in obj.Janma_Rashi_Qualities) {
      chandra.push(obj.Janma_Rashi_Qualities[key]);
    }
    if (
      obj.Next_Janma_Rashi_Qualities !== null ||
      typeof obj.Next_Janma_Rashi_Qualities !== "undefined"
    ) {
      let date = new Date(obj.Upto);
      let time =
        setTimeFormatNaks(
          date.getHours() +
            ":" +
            (date.getMinutes() < 10 ? "0" : "") +
            date.getMinutes()
        ) +
        ", " +
        t(monthNames[date.getMonth()]) +
        " " +
        date.getDate();
      if (localStorage.getItem("UserDefaultLanguage") === "English") {
        chandra.push("after " + time);
      } else {
        chandra.push(time + " " + t("after"));
      }
      for (const key in obj.Next_Janma_Rashi_Qualities) {
        chandra.push(obj.Next_Janma_Rashi_Qualities[key]);
      }
    }
  }
  return chandra;
}

async function calculateParamsDate(value) {
  let y = "";
  let m = "";
  let d = "";
  try {
    y = value.getFullYear();
    m = value.getMonth() + 1;
    d = value.getDate();
  } catch (err) {
    d = moment(value).format("DD");
    m = moment(value).format("MM");
    y = moment(value).format("YYYY");
  }
  let params = "?year=" + y + "&month=" + m + "&day=" + d;
  return params;
}
async function calculateParamsPlace(result) {
  let lat = "",
    long = "";
  let offset = "";

  lat = result.latitude;
  long = result.longitude;
  offset = result.offset;
  let params =
    "&lat=" +
    lat +
    "&long=" +
    long +
    "&offset=" +
    offset +
    "&city=" +
    result.name +
    "&state=" +
    result.state +
    "&country=" +
    result.country +
    "&reflong=" +
    result.reference_longitude_for_timezone +
    "&timezone=" +
    result.timezone +
    "&token=" +
    localStorage.getItem("omp_token");
  return params;
}

const Dash_Panchang = () => {
  const contextType = useContext(GlobalContext);
  let [value, setValue] = useState(new Date());
  let [placeDetails, setPlaceDetails] = useState(
    JSON.parse(window.localStorage.getItem("requestParamsPanchang")) || null
  );
  let [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  let [displayError, setDisplayError] = useState(false);
  let [sunriseTime, setsunriseTime] = useState(null);
  let [sunsetTime, setsunsetTime] = useState(null);
  let [moonriseTime, setmoonriseTime] = useState(null);
  let [moonsetTime, setmoonsetTime] = useState(null);
  let [tithiobject, settithiobject] = useState(null);
  let [tithisunrise, settithisunrise] = useState(null);
  let [yogaobject, setyogaobject] = useState(null);
  let [karanobject, setkaranobject] = useState(null);
  let [weekday, setweekday] = useState(null);
  let [kulikavalue, setkulikavalue] = useState(null);
  let [amritkaalvalue, setamritkaalvalue] = useState(null);
  let [brahmavalue, setbrahmavalue] = useState(null);
  let [nishitavalue, setnishitavalue] = useState(null);
  let [vijayvalue, setvijayvalue] = useState(null);
  let [sandhyavalue, setsandhyavalue] = useState(null);
  let [ayanavalue, setayanavalue] = useState(null);
  let [dayduration, setdayduration] = useState(null);
  let [nightduration, setnightduration] = useState(null);
  let [varjyavalue, setvarjyavalue] = useState(null);
  let [naksvalue, setnaksvalue] = useState(null);
  let [abhijitvalue, setabhijitvalue] = useState(null);
  let [ritu, setritu] = useState(null);
  let [sunsign, setsunsign] = useState(null);
  let [moonsign, setmoonsign] = useState(null);
  let [samvatsara, setsamvatsara] = useState(null);
  let [trikaalvalue, settrikaalvalue] = useState(null);
  let [ascendanttable, setascendanttable] = useState(null);
  let [madhyahna, setmadhyahna] = useState(null);
  let [suryanakshtra, setsuryanakshtra] = useState(null);
  let [ascendantsunrise, setascendantsunrise] = useState(null);
  let [nakshtratable, setnakshtratable] = useState(null);
  let [chogadiya, setchogadiya] = useState(null);
  let [generictarabalam, setgenerictarabalam] = useState(null);
  let [generichandrabalam, setGenerichandrabalam] = useState(null);
  let [masa, setMasa] = useState(null);
  let [samvata, setSamvata] = useState(null);
  let [shaka, setShaka] = useState(null);
  let [gola, setGola] = useState(null);
  //API Requirements
  let holistic_api = axios.create({
    baseURL: "https://api.omparashar.com/panchang/dailypanchang",
  });
  let holistic_api1 = axios.create({
    baseURL: "https://apiv2.omparashar.com/core/panchang",
  });

  useEffect(() => {
    loadjs("assets/js/vendor.min.js", () => {});
    // loadjs("assets/js/app.min.js", () => {});
    let requestParams = JSON.parse(
      window.localStorage.getItem("requestParamsPanchang")
    );
    if (
      requestParams !== null &&
      Object.keys(contextType.requestParamsPanchang).length === 0
    ) {
      contextType.setRequestParamsPanchang(requestParams);
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoading(true);
    (async () => {
      let callPanchangAPI = async () => {
        let connection = new Api();
        let result = null;
        let param1, param2, params;
        if (placeDetails === null) {
          handleCalendar();
        } else {
          param1 = await calculateParamsPlace(placeDetails);
          param2 = await calculateParamsDate(value);
          params = param2 + param1;
          try {
            setDisplayError(false);
            var panchangResult = await holistic_api1.get(
              `dailypanchang${params}`
            );
            // console.log(panchangResult);
            if (panchangResult.data.message === "Token expired") {
              contextType.handleShow(`/panchang`);
              callPanchangAPI();
            } else {
              setsunriseTime(panchangResult.data.sun_and_moon.sunrise);
              setsunsetTime(panchangResult.data.sun_and_moon.sunset);
              setmoonriseTime(panchangResult.data.sun_and_moon.moonrise);
              setmoonsetTime(panchangResult.data.sun_and_moon.moonset);
              settithiobject(panchangResult.data.panchang.tithi);
              settithisunrise(panchangResult.data.panchang.tithi_at_sunrise);
              setyogaobject(panchangResult.data.panchang.yoga);
              setkaranobject(panchangResult.data.panchang.karan);
              setweekday(panchangResult.data.panchang.weekday);
              setkulikavalue(
                panchangResult.data.inauspicious_timings["Kulika Kaal"]
              );
              setamritkaalvalue(
                panchangResult.data.auspicious_timings["Amrit Kaal"]
              );
              setbrahmavalue(
                panchangResult.data.auspicious_timings["Brahma Muhurat"]
              );
              setnishitavalue(
                panchangResult.data.auspicious_timings["Nishita Muhurat"]
              );
              setvijayvalue(
                panchangResult.data.auspicious_timings["Vijay Muhurat"]
              );
              setsandhyavalue(
                panchangResult.data.auspicious_timings["sandhya"]
              );
              setayanavalue(panchangResult.data.ritu_and_ayan.ayana);
              setdayduration(panchangResult.data.ritu_and_ayan.dayduration);
              setnightduration(panchangResult.data.ritu_and_ayan.nightduration);
              setvarjyavalue(
                panchangResult.data.inauspicious_timings["Varjya Kaal"]
              );
              setnaksvalue(panchangResult.data.nakshtra.nakshtra);
              setabhijitvalue(
                panchangResult.data.auspicious_timings.abhijitmuhurat
              );
              setritu(panchangResult.data.ritu_and_ayan.ritu);
              setGola(panchangResult.data.ritu_and_ayan.gola);
              setsunsign(panchangResult.data.rashi.sunsign.current);
              setmoonsign(panchangResult.data.rashi.moonsign.current);
              setsamvatsara(panchangResult.data.samvatsara.samvatsara);
              settrikaalvalue(panchangResult.data.inauspicious_timings.trikaal);
              setascendanttable(panchangResult.data.ascendanttable);
              setmadhyahna(panchangResult.data.ritu_and_ayan.madhyahna);
              setsuryanakshtra(panchangResult.data.rashi.suryanakshtra);
              setascendantsunrise(panchangResult.data.rashi.ascendant);
              setnakshtratable(panchangResult.data.nakshtra.nakshtratable);
              setchogadiya(panchangResult.data.gaurichogadiya);
              setgenerictarabalam(panchangResult.data.tarabalam);
              setGenerichandrabalam(panchangResult.data.chandrabalam);
              setMasa(panchangResult.data.panchang.masa);
              setSamvata(panchangResult.data.panchang.samvata);
              setShaka(panchangResult.data.panchang.shaka);
              setLoading(false);
            }
          } catch (e) {
            // console.log(e);
            setDisplayError(true);
          }
        }
      };
      callPanchangAPI();
    })();
  }, [value, placeDetails, localStorage.getItem("UserDefaultLanguage")]);

  let moonriseTimedisplay = remove_character(moonriseTime, 5);
  let sunriseTimedisplay = remove_character(sunriseTime, 5);
  let sunsetTimedisplay = remove_character(sunsetTime, 5);
  let moonsetTimedisplay = remove_character(moonsetTime, 5);
  let tithicurrent = extract_current_tithi(tithiobject, t);
  let yogacurrent = extract_current_yoga(yogaobject, t);
  let karancurrent = extract_current_karan(karanobject, t);
  let tithinext = extract_next_tithi(tithiobject, t);
  let yoganext = extract_next_yoga(yogaobject, t);
  let karannext = extract_next_karan(karanobject, t);
  let karan_next_next = extract_next_next_karan(karanobject, t);
  let kulika = extract_auspicious_string(kulikavalue, "Kulika", t);
  let amritkaal = extract_auspicious_string(amritkaalvalue, "Amrit", t);
  let brahma = extract_auspicious_string(brahmavalue, "Brahma", t);
  let nishita = extract_auspicious_string(nishitavalue, "Nishita", t);
  let vijay = extract_auspicious_string(vijayvalue, "Vijay", t);
  let sandhya = extract_auspicious_string(sandhyavalue, "Sandhya", t);
  let ayan = extract_ayan(ayanavalue);
  let asc = extract_ascendant_table(ascendanttable);
  let madhya = extract_madhyahna(madhyahna, t);
  let ascendantsun = extract_ascendant_sunrise(ascendantsunrise);
  let daytime = extract_duration(dayduration, t);
  let nighttime = extract_duration(nightduration, t);
  let varjya = extract_auspicious_string(varjyavalue, "Nishita", t);
  let nakshtra = extract_nakshtra_string(naksvalue, t);
  let abhijit = extract_abhijit_string(abhijitvalue, t);
  let trikaal = extract_trikaal(trikaalvalue);
  let naks = extract_naktable(nakshtratable, t);
  let cho = extract_cho(chogadiya, t);
  let chandrabalam = extract_chandrabalam(generichandrabalam, t);

  let dkayan = "";
  let vdayan = "";

  if (typeof ayan !== "undefined") {
    dkayan = ayan[0];
    vdayan = ayan[0];
  }

  let psandhya = "";
  let ssandhya = "";
  if (typeof sandhya !== "undefined") {
    if (localStorage.getItem("UserDefaultLanguage") === "English") {
      psandhya = sandhya[0] + " " + t("to") + " " + sandhya[1];
      ssandhya = sandhya[2] + " " + t("to") + " " + sandhya[3];
    } else {
      psandhya =
        sandhya[0] + " " + t("FromPanchang") + " " + sandhya[1] + " " + t("to");
      ssandhya =
        sandhya[2] + " " + t("FromPanchang") + " " + sandhya[3] + " " + t("to");
    }
  }

  let rkaal = "";
  let ykaal = "";

  if (typeof trikaal !== "undefined") {
    if (localStorage.getItem("UserDefaultLanguage") === "English") {
      rkaal = trikaal[0] + " " + t("to") + " " + trikaal[1];
      ykaal = trikaal[4] + " " + t("to") + " " + trikaal[5];
    } else {
      rkaal =
        trikaal[0] + " " + t("FromPanchang") + " " + trikaal[1] + " " + t("to");
      ykaal =
        trikaal[4] + " " + t("FromPanchang") + " " + trikaal[5] + " " + t("to");
    }
  }

  let tithiname_1 = "";
  let tithiname_2 = "";
  let imgsrc = null;
  let link = "";

  imgsrc = extract_image_link(tithisunrise);
  if (typeof imgsrc !== "undefined") {
    link = imgsrc;
  }

  if (tithisunrise !== null) {
    tithiname_1 = tithisunrise[0][0];
    tithiname_2 = tithisunrise[0][1];
  }
  let day = "";
  let month = "";
  let year = "";
  let month_string = "";

  if (value !== null) {
    day = moment(value).format("DD");
    month = monthNames[moment(value).format("MM") - 1];
    year = moment(value).format("YYYY");
    month_string = t(month) + ", " + year;
  }

  const handleCalendar = () => {
    if (Object.keys(contextType.requestParamsPanchang).length !== 0) {
      if (
        contextType.panchangCountry === null ||
        contextType.panchangCountry === ""
      ) {
        contextType.setCountry(
          contextType.requestParamsPanchang.country,
          "Panchang"
        );
      }
      if (
        contextType.panchangCityState === null ||
        contextType.panchangCityState === ""
      )
        contextType.setCityAndState(
          `${contextType.requestParamsPanchang.name},${contextType.requestParamsPanchang.state}`,
          "Panchang"
        );
    }
    contextType.handlePanchangForm(true);
  };
  useEffect(() => {
    window.localStorage.setItem("PanchangDate", new Date());
  }, [value]);
  const handleToday = () => {
    // contextType.panchang_date_change("today");
    setValue(new Date());
  };

  const nextDay = () => {
    // contextType.panchang_date_change("next");
    setValue(moment(value).add(1, "days"));
  };

  const prevDay = () => {
    // contextType.panchang_date_change("previous");
    setValue(moment(value).subtract(1, "days"));
  };

  const HeaderPanchang = () => {
    return (
      <>
        <div className="row" style={{ paddingTop: "2px" }}>
          <div className="col-md-12 col-xl-12">
            <div
              className="card-box"
              style={{
                padding: "10px",
                backgroundImage: `url(${background})`,
                width: "100%",
                height: "75%",
                marginBottom: "0px",
                boxShadow: "10px 10px 10px #333",
              }}
            >
              <div
                className="card"
                style={{ background: "none", fontSize: "0.9rem" }}
              >
                <div className="card-body-holistic">
                  <div className="dpPHeaderLeftContent">
                    <img className="dpPHeaderImage" src={link} alt="Thithi" />
                    <div className="dpPHeaderLeftTitle">
                      <u>{tithiname_1}</u>
                    </div>
                    <div className="dpPHeaderLeftSubTitle">
                      <u>{tithiname_2}</u>
                    </div>
                  </div>
                  <div className="dpPHeaderRightContent">
                    <div className="dpPHeaderRightTitle">
                      <u>
                        {day} {month_string}
                      </u>
                      <i
                        onClick={handleCalendar}
                        style={{
                          fontSize: "20px",
                          marginLeft: "4%",
                          cursor: "pointer",
                        }}
                        className="dpPHeaderRightTitle fa"
                      >
                        &#xf073;
                      </i>
                    </div>
                    <div className="dpPHeaderRightTitle">
                      <u>
                        {placeDetails ? placeDetails.name : ""},
                        {placeDetails ? placeDetails.country : ""}
                      </u>{" "}
                      <img
                        onClick={handleCalendar}
                        src={require("../../assets/img/map.png")}
                        alt="Map"
                        className="MapIcon"
                      />
                    </div>
                  </div>
                  <div className="dpPHeaderCenterContent">
                    <div className="dpPHeaderCenterTitle">
                      <u>{t("Daily Panchang")}</u>
                    </div>
                    <div className="button-list">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm waves-effect waves-light buttonpanchang"
                        onClick={() => prevDay()}
                      >
                        {t("Prev Day")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm waves-effect waves-light buttonpanchang"
                        onClick={() => handleToday()}
                      >
                        {t("Today")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm waves-effect waves-light buttonpanchang"
                        onClick={() => nextDay()}
                      >
                        {t("Next Day")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- end row --> */}
      </>
    );
  };

  const BreakLine = () => {
    return (
      <>
        <table
          className="tablesaw table mb-0 tablesaw-stack"
          id="tablesaw-802"
          style={{ border: "none", borderTop: "none" }}
        >
          <tbody>
            <tr>
              <td colSpan="4">
                <hr className="style-seven"></hr>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
      <DashboardTopBar />
      <DashboardSideBar />

      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <HeaderPanchang />
            {displayError ? (
              <>
                <center>
                  <h2>Unable to display Data</h2>
                </center>
              </>
            ) : (
              <>
                <div className="row">
                  <div
                    className="col-12"
                    style={{ maxHeight: "620px", overflowY: "scroll" }}
                  >
                    <div className="card-box" style={{ paddingTop: "2px" }}>
                      {loading ? (
                        <div style={{ minHeight: "350px" }}>
                          <div id="loader">
                            <div className="planet-top"></div>
                            <div className="planet-bottom"></div>
                            <div className="ring"></div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <SunriseMoonrise
                            sunriseTimedisplay={sunriseTimedisplay}
                            sunsetTimedisplay={sunsetTimedisplay}
                            moonriseTimedisplay={moonriseTimedisplay}
                            moonsetTimedisplay={moonsetTimedisplay}
                          />
                          <BreakLine />
                          <PanchangHTML
                            tithicurrent={tithicurrent}
                            tithinext={tithinext}
                            karancurrent={karancurrent}
                            karannext={karannext}
                            karan_next_next={karan_next_next}
                            yogacurrent={yogacurrent}
                            yoganext={yoganext}
                            weekday={weekday}
                            nakshtra={nakshtra}
                            masa={masa}
                            samvata={samvata}
                            shaka={shaka}
                          />
                          <BreakLine />
                          <SamvatsaraHTML samvatsara={samvatsara} />
                          <BreakLine />
                          <RashiHTML
                            sunsign={sunsign}
                            moonsign={moonsign}
                            suryanakshtra={suryanakshtra}
                            ascendantsun={ascendantsun}
                          />
                          <BreakLine />
                          <NakshtraHTML naks={naks} nakshtra={nakshtra} />
                          <BreakLine />
                          <RituAndAyanHTML
                            dkayan={dkayan}
                            daytime={daytime}
                            vdayan={vdayan}
                            nighttime={nighttime}
                            ritu={ritu}
                            madhya={madhya}
                            gola={gola}
                          />
                          <BreakLine />
                          <AuspiciousTimingsHTML
                            amritkaal={amritkaal}
                            abhijit={abhijit}
                            brahma={brahma}
                            nishita={nishita}
                            psandhya={psandhya}
                            ssandhya={ssandhya}
                            vijay={vijay}
                          />
                          <BreakLine />
                          <InauspiciousTimingsHTML
                            kulika={kulika}
                            varjya={varjya}
                            rkaal={rkaal}
                            ykaal={ykaal}
                          />
                          <BreakLine />
                          <AscendantTableHTML
                            asc={asc}
                            language={localStorage.getItem(
                              "UserDefaultLanguage"
                            )}
                          />
                          <BreakLine />
                          <ChoghadiyaHTML cho={cho} />
                          <BreakLine />
                          <TaraBalamHTML
                            setTimeFormatNaks={setTimeFormatNaks}
                            generictarabalam={generictarabalam}
                            language={localStorage.getItem(
                              "UserDefaultLanguage"
                            )}
                          />
                          <BreakLine />
                          <ChandraBalamHTML chandrabalam={chandrabalam} />
                        </>
                      )}
                      <Sideform
                        mode="panchangView"
                        handleClose={handleCalendar}
                        date={value}
                        changeDate={setValue}
                        setPlaceDetails={setPlaceDetails}
                        placeDetails={placeDetails}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* <DashboardFooter /> */}
      </div>
      {/* <Login
        page={contextType.currentPage}
        show={contextType.loginModalShow}
        handleClose={contextType.closeLoginModal}
      /> */}
    </>
  );
};

export default Dash_Panchang;
