import React, { useEffect, useRef } from "react";
import "react-bootstrap";
import DashboardTopBar from "../DashboardTopBar";
import DashboardSideBar from "../DashboardSideBar";
import DashboardFooter from "../DashboardFooter";
import { GlobalContext } from "../../../mycontext";
import InitDashboard from "../InitDashboards/InitTransitionDashboard";
import { Route, withRouter } from "react-router-dom";
import D3graph from "../../TransitionComponents/GalacticView/d3graph";
import Plotly_NAKS from "../../TransitionComponents/Traces/plotly_Naks";
import Journey from "../../TransitionComponents/TransitJourney/Journey";
import Snapshot from "../../TransitionComponents/Snapshot/Snapshot";
import NatalChartLayout from "../../TransitionComponents/NatalChart/NatalChartLayout";
import SunandMoonPhases from "../../TransitionComponents/SunandMoonPhases/SunandMoonPhases";
import PlanetRelations from "../../HoroscopeComponents/PlanetRelationships/PlanetRelations";
import ConjunctionGraph from "../../TransitionComponents/PlanetaryConjunctions/plotConjunctionGraph";
import loadjs from "loadjs";
import Testview from "../../TransitionComponents/Testview/testview";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

const TransitionDashboard = () => {
  const { t, i18n } = useTranslation();
  const context = React.useContext(GlobalContext);
  if (context.currentView === "Dashboard") {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  useEffect(() => {
    // loadjs("assets/js/app.js", () => {});
    loadjs("assets/js/vendor.min.js", () => {});

    let requestParams = JSON.parse(
      window.localStorage.getItem("requestParams")
    );
    if (requestParams !== null) {
      context.setRequestParams(requestParams);
    }
    let start = JSON.parse(window.localStorage.getItem("startDate"));
    if (start !== null) {
      context.setStartOrEndDate("start", start);
    }
    let end = JSON.parse(window.localStorage.getItem("endDate"));
    if (end !== null) {
      context.setStartOrEndDate("end", end);
    }
    let api = JSON.parse(window.localStorage.getItem("APIData"));
    if (api !== null) {
      context.setAPIData(api);
    }
    let language = localStorage.getItem("UserDefaultLanguage");
    if (language !== null) {
      if (language === "Hindi") {
        i18n.changeLanguage("hi");
      } else {
        i18n.changeLanguage("en");
      }
    }

    let planetaryConjunctionAPIData = JSON.parse(window.localStorage.getItem("planetaryConjunction"))
    if(planetaryConjunctionAPIData!==null){
      context.setPlanetaryConjunctionAPIData(planetaryConjunctionAPIData);
    }
    let conjunctionStart = window.localStorage.getItem("conjunctionStartYear")
    if(conjunctionStart !== null){
      context.setPlanetConjunctionStartorEnd("start",conjunctionStart);
    }
    let conjunctionEnd = window.localStorage.getItem("conjunctionEndYear")
    if(conjunctionEnd !== null){
      context.setPlanetConjunctionStartorEnd("end",conjunctionEnd);
    }
    let selectedconjArray = JSON.parse(window.localStorage.getItem("selectedConjunctionArray"))
    if(selectedconjArray !== null){
      context.setSelectedConjunctionPlanets(selectedconjArray);
    }
    let selectedRashiArray = JSON.parse(window.localStorage.getItem("selectedRashiArray"))
    if(selectedRashiArray !== null){
      context.setSelectedRashiArray(selectedRashiArray);
    }
  }, []);
  return (
    <>
      <DashboardTopBar />
      <DashboardSideBar />

      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <Route exact path="/dashboard/" component={InitDashboard} />
            <Route
              exact
              path="/dashboard/galacticView"
              render={() => (
                <D3graph
                  language={localStorage.getItem("UserDefaultLanguage")}
                  t={t}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/tracesView"
              render={(props) => (
                <Plotly_NAKS
                  city={context.requestParams.name}
                  start={context.startDate}
                  end={context.endDate}
                  selectedLanguage={localStorage.getItem(
                    "UserDefaultLanguage"
                  )}
                  tokenExpiredCount={context.tokenExpiredCount}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/journeyView"
              render={(props) => (
                <Journey
                  city={context.requestParams.name}
                  start={context.startDate}
                  end={context.endDate}
                  selectedLanguage={localStorage.getItem(
                    "UserDefaultLanguage"
                  )}
                  tokenExpiredCount={context.tokenExpiredCount}
                />
              )}
            />
            <Route exact path="/dashboard/snapshotView" component={Snapshot} />
            <Route
              exact
              path="/dashboard/kaalpurushView"
              render={(props) => (
                <NatalChartLayout
                  city={context.requestParams.name}
                  start={context.startDate}
                  end={context.endDate}
                  selectedLanguage={localStorage.getItem(
                    "UserDefaultLanguage"
                  )}
                  tokenExpiredCount={context.tokenExpiredCount}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/sunAndMoonView"
              render={(props) => (
                <SunandMoonPhases
                  city={context.requestParams.name}
                  start={context.startDate}
                  end={context.endDate}
                  selectedLanguage={localStorage.getItem(
                    "UserDefaultLanguage"
                  )}
                  tokenExpiredCount={context.tokenExpiredCount}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/planetaryConjunctions" 
              // component={ConjunctionGraph}

              render={(props) => (
                <ConjunctionGraph
                  city={context.requestParams.name}
                  start={context.planetConjunctionStart }
                  // ||localStorage.getItem("conjunctionStartYear")}
                  end={context.planetConjunctionEnd}
                  //  ||localStorage.getItem("conjunctionEndYear")}
                  selectedLanguage={localStorage.getItem(
                    "UserDefaultLanguage"
                  )}
                  selectedConjunctionArray={context.selectedConjunctionArray}
                  selectedRashiArray = {context.selectedRashiArray}
                  apiData = {context.planetaryConjunctionAPIData}
                  tokenExpiredCount={context.tokenExpiredCount}
                  history={context.history}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/scope"
              render={(props) => <Testview />}
            />
            <Route
              exact
              path="/dashboard/basicdetails"
              component={PlanetRelations}
            />
          </div>
        </div>
        {/* <DashboardFooter /> */}
      </div>
    </>
  );
};
export default withRouter(TransitionDashboard);
