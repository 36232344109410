import React, { useState,useEffect } from "react";
import "./homestyles.css";
import "./main.css";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { GlobalContext } from "../mycontext";
import Login from "../components/GoogleLogin/login";

const HomePage = (props) => {
  const context = React.useContext(GlobalContext);
  const [show, setShow] = useState(false);
  const [islogin, setislogin] = useState(false);
  const [page, setPage] = useState("");


  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    // these changes are only for removing Google Login
    const storedLanguage = localStorage.getItem('omp_token');

    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    } else {
      localStorage.setItem('omp_token', 'English');
      localStorage.setItem("UserDefaultLanguage", 'English');
      setSelectedLanguage('English');
    }
  }, []);



  const handleClose = () => setShow(false);
  const handleShow = (val) => {
    // console.log(val);
    setPage(val);
    setShow(true);
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 80 ||
      document.documentElement.scrollTop > 80
    ) {
      // document.getElementById("navbar").style.padding = "30px 10px";
      // document.getElementById("logo").style.fontSize = "25px";
    } else {
      //document.getElementById("navbar").style.padding = "80px 10px";
      //document.getElementById("logo").style.fontSize = "35px";
      //alert("2");
    }
  }
  window.onscroll = function () {
    scrollFunction();
  };

  return (
    <>
      <div id="home" className="home">
        {/* <!-- Masthead--> */}
        <header className="masthead relative">
          <div className="container">
            <p className="banner-caption">
              A humble journey into the world of astrology.
            </p>
          </div>
        </header>
        <div className="container banner-bottom">
          <div className="row">
            <div className="col-lg-5 col-xs-12">
              <div className="logo-center">
                <img src={require("../assets/img/logo-center.png")} alt="" />
              </div>
            </div>

            <div className="col-lg-7 col-md-12 col-xs-12">
              <div className="about-desc">
                <p className="p1">
                  यदुपचितमन्यजन्मनि शुभाऽशुभं तस्य कर्मणः पंक्तिम्।
                  <br />
                  व्यञ्जयति शास्त्रमेतत् तमसि द्रव्याणि दीप इव।।
                </p>
                <p className="p2">
                  ~ श्रीमद्वराहमिहिराचार्य विरचितं लघुजातकम् (प्रथम अध्याय,
                  श्लोक 3)
                </p>
                <p className="desc">
                  The way a lamp lights up the objects kept in dark, the same
                  way, astrology lights up the results of sins and virtuous
                  deeds that we have accumulated through our good and bad karmas
                  in our past lives.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="feature-container visible-lg visible-md d-xs-none">
          <div className="container">
            <div className="lets-started pl-2"> Let us get started….</div>
            <div className="feature-grid">
              <div className="feature-col">
                <div class="feature-card">
                  <Link to='/dashboard' onClick={() => context.change_View("Dashboard")} className="btn-explore"> 
                  {/* <Link
                    onClick={() => context.handleShow("/dashboard")}
                    className="btn-explore"
                  > */}
                    <img
                      src={require("../assets/img/icons/Transition.png")}
                      alt="Transition of Planets Icon"
                    />
                    <h4>Transition of Planets</h4>
                  </Link>
                </div>
              </div>
              <div className="feature-col">
                <div className="feature-card">
                  <Link to='/panchang' onClick={() => context.change_View("Panchang View")} className="btn-explore">  
                  {/* <Link
                    onClick={() => context.handleShow("/panchang")}
                    className="btn-explore"
                  > */}
                    <img
                      src={require("../assets/img/icons/Panchang.png")}
                      alt="Panchang Icon"
                    />
                    <h4>Panchang</h4>
                  </Link>
                </div>
              </div>
              <div className="feature-col">
                <div className="feature-card">
                  <Link to='/horoscope/basicdetails' onClick={() => context.change_View("Dashboard View")} className="btn-explore"> 
                  {/* <Link
                    onClick={() =>
                      context.handleShow("/horoscope/basicdetails")
                    }
                    className="btn-explore"
                  > */}
                    <img
                      src={require("../assets/img/icons/Horoscope.png")}
                      alt="Horoscope Icon"
                    />
                    <h4>Horoscope</h4>
                  </Link>
                </div>
              </div>
              <div className="feature-col">
                <p className="more-sections">And much more….</p>
              </div>
              <div></div>
            </div>
          </div>
        </div>

        <section className="page-section visible-xs d-lg-none" id="services">
          <div className="container">
            <div className="row text-center justify-content-center">
              <div className="col-lg-4 col-sm-4 col-xs-12">
                <div className="team-member">
                  <Link
                    to="/dashboard"
                    onClick={() => context.change_View("Dashboard")}
                  >
                    <img
                      className="mx-auto"
                      src={require("../assets/img/icons/Transition.png")}
                      alt=""
                    />
                    <h4 className="my-3">Transition of Planets</h4>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-12">
                <div className="team-member">
                  <Link
                    to="/panchang"
                    onClick={() => context.change_View("Panchang View")}
                  >
                    <img
                      className="mx-auto"
                      src={require("../assets/img/icons/Panchang.png")}
                      alt=""
                    />
                    <h4 className="my-3">Panchang</h4>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-12">
                <div className="team-member">
                  <Link
                    to="/dashboard"
                    onClick={() => context.change_View("Dashboard View")}
                  >
                    <img
                      className="mx-auto"
                      src={require("../assets/img/icons/Horoscope.png")}
                      alt=""
                    />
                    <h4 className="my-3">Horoscope</h4>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="footer-container py-4">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-xs-12">
                <ul>
                  <li>
                    <Link to="/"> Home</Link>
                  </li>
                  <li>
                    <Link to="/"> Services</Link>
                  </li>
                  <li>
                    <Link to="/"> Frequently Asked Questions (FAQs)</Link>
                  </li>
                  <li>
                    <Link to="/"> About</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-12">
                <div className="footer-logo">
                  <img
                    className="mx-auto"
                    src={require("../assets/img/logo.png")}
                    alt=""
                  />
                </div>
                <div className="social-links">
                  <a className="btn-social mx-2" href="#">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a className="btn-social mx-2" href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a className="btn-social mx-2" href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a className="btn-social mx-2" href="#">
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a className="btn-social mx-2" href="#">
                    <i className="fab fa-pinterest"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-12">
                <div className="footer-address">
                  <p>
                    Model Town, 368 Browning Road, Woodstock Pennis, United
                    States, 7925
                  </p>
                  {/* <div className="offices">
                    <p>
                      {" "}
                      <b>US</b>
                      <span className="contact">+44-26462326</span>
                    </p>
                    <p>
                      {" "}
                      <b>India</b>
                      <span className="contact">+91- 8568992350</span>
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="row copyright">
                <div className="col-md-12">
                  <center>
                <a href="/terms-and-conditions">Terms & Conditions of Usage</a> | <span> </span> 
                <a href="/privacy-policy">Privacy Policy</a>
                </center>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* <Login
          page={context.currentPage}
          show={context.loginModalShow}
          handleClose={context.closeLoginModal}
        /> */}
      </div>
    </>
  );
};

export default withRouter(HomePage);
