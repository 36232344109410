import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
const NakshtraHTML = (props) => {
  const { t, i18n } = useTranslation();
 
  if (typeof props.naks !== "undefined") {
    return (
      <>
        <table
          className="tablesaw table mb-0 tablesaw-stack"
          id="tablesaw-802"
          style={{ border: "none", borderTop: "none" }}
        >
          <tbody>
            <tr className="table_head_tr" style={{}}>
              <th scope="col" colSpan="5" className="sectionheader">
                {t("Nakshtra")}
              </th>
            </tr>
          </tbody>
        </table>

        <table
          className="tablesaw table mb-0 tablesaw-stack"
          id="tablesaw-802"
          style={{ border: "none", borderTop: "none" }}
        >
          <tbody>
            <tr>
              <td className="td1">
                <span className="tablelabel">{t("Nakshtra")}</span>
              </td>
              <td className="td2">
                <span className="tablevalue"> {props.nakshtra}</span>
              </td>
              <td className="td3">
                <span className="tablelabel">{t("Nakshtra Pada")}</span>{" "}
              </td>
              <td className="td4">
                <span className="tablevalue">{props.naks[0]}</span>
                <br /> <span className="tablevalue">{props.naks[1]}</span>
                <br /> <span className="tablevalue">{props.naks[2]}</span>
                <br /> <span className="tablevalue">{props.naks[3]}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  } else {
    return null;
  }
};

export default NakshtraHTML;
