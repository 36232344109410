import { withRouter } from "react-router-dom";
import React from "react";
import "./querybuilder.css"
const Instructions = (props) => {
    return (<>
    <div className="instructions-wrapper">
        <center><h4>How To Build A Query</h4></center>
        <p>To create a Query click on Button <b>Add Query Row</b> you will be shown a dropdown.
            Now Follow with us to create a Query.</p>
        <p>
            Lets say you want to query all horoscopes with <b>Sun in 1st house of the Natal Chart</b>.
            <p><ol>
                <li>First select chart as <b>"Natal Chart"</b> from the first dropdown.</li>
                <li>Now Another dropdwon will be displayed, select planet in this dropdown in our case we will select <b>"Sun"</b>.</li>
                <li> Now a third dropdown will be displayed,select operator from this dropdown, in our case we will select <b>"In House"</b>.</li>
                <li> Again another dropdown will be displayed, select the operator value from this forth dropdown, in our case we will select the house number <b>1</b>.</li>
            </ol>
            </p>
            Like this, we can create a query with multiple selction by adding rows and selecting what we wamt to search. Let's see a few more examples.
        </p>

        <p>
            Lets say you want to query all horoscopes where <b>Mercury and Venus are conjunct in the Natal Chart</b>.
            <p><ol>
                <li>First select chart as <b>"Natal Chart"</b> from the first dropdown.</li>
                <li>Now Another dropdwon will be displayed, select planet in this dropdown in our case we will select <b>"Mercury/Venus"</b>.</li>
                <li> Now a third dropdown will be displayed,select operator from this dropdown, in our case we will select <b>"Conjunct With"</b>.</li>
                <li> Again another dropdown will be displayed, select the operator value from this forth dropdown, in our case we will select planet with which planet selected in second dropdown is conjunct with i.e. <b>Mercury/Venus</b>.</li>
            </ol>
            </p>
        </p>

        <p>
            Lets say you want to query all horoscopes where <b>Mars is Retrogrde in the Natal Chart</b>.
            <p><ol>
                <li>First select chart as <b>"Natal Chart"</b> from the first dropdown.</li>
                <li>Now Another dropdwon will be displayed, select planet in this dropdown in our case we will select <b>"Mars"</b>.</li>
                <li> Now a third dropdown will be displayed,select operator from this dropdown, in our case we will select <b>"Has Motion"</b>.</li>
                <li> Again another dropdown will be displayed, select the operator value from this forth dropdown, in our case we will select Mars' Motion <b>"Retrograde"</b>.</li>
            </ol>
            </p>
        </p>

        <p>
            Lets say you want to query all horoscopes where <b>Jupiter is in Swati Nakshatra in the Natal Chart</b>.
            <p><ol>
                <li>First select chart as <b>"Natal Chart"</b> from the first dropdown.</li>
                <li>Now Another dropdwon will be displayed, select planet in this dropdown in our case we will select <b>"Jupiter"</b>.</li>
                <li> Now a third dropdown will be displayed,select operator from this dropdown, in our case we will select <b>"In Nakshatra"</b>.</li>
                <li> Again another dropdown will be displayed, select the operator value from this forth dropdown, in our case we will select Jupiter's nakshatra i.e.<b>"Swati"</b>.</li>
            </ol>
            </p>
        </p>

        <p>
            Lets say you want to query all horoscopes where <b>Jupiter is in Swati Nakshatra and in the 10th house in the Natal Chart</b>. In this case we will breakdown our query in two parts.
            <p><ol>
                <li>Create first part of the query:</li>
                <li>First select chart as <b>"Natal Chart"</b> from the first dropdown.</li>
                <li>Now Another dropdwon will be displayed, select planet in this dropdown in our case we will select <b>"Jupiter"</b>.</li>
                <li> Now a third dropdown will be displayed,select operator from this dropdown, in our case we will select <b>"In Nakshatra"</b>.</li>
                <li> Again another dropdown will be displayed, select the operator value from this forth dropdown, in our case we will select Jupiter's nakshatra i.e.<b>"Swati"</b>.</li>
                <li>Now Again Click on "Add Query Row" button. A new row will be added,follow these steps to create next part of the query.</li>
                <li>Select <b>"Natal Chart"</b> from the fist dropdown.</li>
                <li>Select planet from the second dropdown in our case we will select <b>"Jupiter"</b> again.</li>
                <li> Select operator from third dropdown, in our case we will select <b>"In House"</b>.</li>
                <li> Select the operator value from this forth dropdown, in our case we will select Jupiter's house number i.e.<b>"10"</b>.</li>
            </ol>
            </p>
            Notice, How we break larger queries into small parts and then each row will contain one part of the Query.
        </p>

        <p>
            To query all horoscopes in which <b>Mars aspects 7th and 10th house in the D10 chart</b>.
            Again,we will breakdown our query in two parts.
            <p><ol>
                <li>Create first part of the query:</li>
                <li>Select chart as <b>"D10"</b></li>
                <li>Now select planet as <b>"Mars"</b>.</li>
                <li> Now select operator from the third dropdown in
                    the current row, in our case we will select <b>"Aspects House"</b>.</li>
                <li> Again another dropdown will be displayed, select the operator value from this forth dropdown, in our case we will select <b>"7"</b>.</li>
                <li>Now Again Click on "Add Query Row" button. A new row will be added,follow these steps to create next part of the query.</li>
                <li>Repeat steps 2 to 4 and then select operator value as <b>"10"</b> in place of step 5</li>
            </ol>
            </p>
        </p>

        <p>
            If We want to query all horoscopes in which <b>Lord of 1st house and lord of 10th house are conjunct in any house in the Natal chart</b>.
            <p><ol>
                <li>Select chart as <b>"Natal Chart"</b></li>
                <li>Now select planet as <b>"Lord of 1st house"</b>.</li>
                <li> Now select operator from the third dropdown in
                    the current row, in our case we will select <b>"Conjunct With"</b>.</li>
                <li> Again another dropdown will be displayed, select the operator value from this forth dropdown, in our case we will select <b>"Lord of 10th House"</b>.</li>
            </ol>
            </p>
        </p>


        <p>
            Lets say you want to query all horoscopes where <b>Lord of 1st house, lord of 10th house are conjunct in 5th house in the Natal Chart</b>. Again we will breakdwon the query in two parts, first we will select conjunction between 1st and 10th house lords and then select the house number for any of these planets.
            <p><ol>
                <li>Create first part of the query:</li>
                <li>First select chart as <b>"Natal Chart"</b> from the first dropdown.</li>
                <li>Now Another dropdwon will be displayed, select planet in this dropdown in our case we will select <b>"Lord of 1st House"</b>.</li>
                <li> Now a third dropdown will be displayed,select operator from this dropdown, in our case we will select <b>"Conjunct with"</b>.</li>
                <li> Again another dropdown will be displayed, select the operator value from this forth dropdown.<b>"Lord of 10th House"</b>.</li>
                <li>Now Again Click on "Add Query Row" button. A new row will be added,follow these steps to create next part of the query.</li>
                <li>Select <b>"Natal Chart"</b> from the fist dropdown.</li>
                <li>Select planet from the second dropdown in our case we will select <b>"Lord of 10th House"</b> again.</li>
                <li> Select operator from third dropdown, in our case we will select <b>"In House"</b>.</li>
                <li> Select the operator value from this forth dropdown, in our case we will select <b>"5"</b>.</li>
            </ol>
            </p>
        </p>

        <p>
            Lets say you want to query all horoscopes where <b>Mars, Sun and Saturn are conjunct in 1st house in the Natal Chart</b>.
            Now we will breakdwon the query into three parts.
            <p><ol>
                <li>Mars is First house.</li>
                <li>Mars Conjuct with Sun.</li>
                <li>Mars Conjuct with Saturn</li>
            </ol>
            </p>
            This query can be written differently as well like: Mars in house 1,Sun in house 1 and Saturn in house 1.
        </p>

        <p>A query can be created in multiple ways. Show your creativity and unlock the potential of <b>"Query Builder".</b>  </p>
        </div>
    </>);
}

export default withRouter(Instructions);