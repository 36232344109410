export class Variables {
    constructor() {
        this.house_to_rashi_map = {
            1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            2: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            3: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            4: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            5: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            6: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            7: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            8: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            9: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            11: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            12: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        }
    }
    getHouseToRashiMap = () => {
        return this.house_to_rashi_map;
    }
    setHouseToRashiMap = (data) => {
        this.house_to_rashi_map = data;
    }
}