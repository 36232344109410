import React, { Component } from "react";
import Plotly from "plotly.js";
import moment from "moment";
import { GlobalContext } from "../../../mycontext";
import "../../TransitionComponents/Traces/plotly.css";
import { withTranslation } from "react-i18next";
import {getQueryParam} from '../../utils/utility';

class ChalitVimshopakBala extends Component {
  static contextType = GlobalContext;
  constructor(props) {
    super(props);
    this.state = {
      view_type: "ordered",
      horoscopeData:
      JSON.parse(window.localStorage.getItem("horoscopeData")) || null,

      cb_horoscopeData: null,
      cb_requestParamsHoroscope:null,
      cb_view:false
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let horoscopeData;
    let queryString = getQueryParam('cb');
    if(queryString){
      horoscopeData = JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`));
      this.setState({horoscopeData:JSON.parse(window.sessionStorage.getItem(`horoscopeData_${queryString}`))});
      this.setState({cb_view:true});
    }
    else{
      horoscopeData = JSON.parse(window.localStorage.getItem("horoscopeData"));
    }
    
    if(queryString){

    }
    else{
      if (
        horoscopeData !== null &&
        Object.keys(this.context.horoscopeData).length === 0
      ) {
        this.context.setHoroscopeData(horoscopeData);
      }
    }
   
    if (horoscopeData === null) {
      this.context.handleHoroscopeForm(true, "open");
    } else {
      this.apidate_format_ordered(horoscopeData);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.horoscopeData !== prevProps.horoscopeData) {
      if (this.state.view_type === "ordered")
        this.apidate_format_ordered(this.props.horoscopeData);
      else this.apidate_format_ranked(this.props.horoscopeData);
    }
  }

  apidate_format_ordered = (horoscopeData) => {
    let gd1 = document.getElementById("myDiv_NAKS");
    this.view_name = "ordered";
    let planetNames = [];
    let values = [];
    let data = horoscopeData.chalit_vimshopak_bala;
    // planetNames=Object.keys(data);
    let language = Object.keys(data);
    for(let i in language){
       planetNames.push(this.props.t(language[i]))
    };
    for (let i in data) {
      values.push(data[i]);
    };

    let plotData = [
      {
        x: planetNames,
        y: values,
        type: "bar",
        text: planetNames,
        textposition: "auto",
        marker: {
          color: [
            "#d6edd9",
            "#bae1c1",
            "#a1d2aa",
            "#84c88f",
            "#6bbc77",
            "#51b15e",
            "#449751",
            "#367a41",
            "#2b5e33",
            "#1c4525",
            "#132915",
            "#070e07",
          ],
        },
      },
    ];

    let layout = {
      width: "100%",
      title: this.props.t("Chalit Vimshopak Bala Values - Ordered"),
      height: 500,
      xaxis: {
        title: this.props.t("Planets"),
        tickfont: {
          family: "Lucida Bright",
          size: 12,
          color: "rgb(82, 82, 82)",
        },
      },
    };
    var config = { responsive: true };
    Plotly.newPlot(gd1, plotData, layout, config);
  };

  apidate_format_ranked = (horoscopeData) => {
    let gd1 = document.getElementById("myDiv_NAKS");
    this.view_name = "ranked";
    let planetNames = [];
    let values = [];
    let datamodified = horoscopeData.chalit_vimshopak_bala;

    const sortable = Object.entries(datamodified)
      .sort(([, a], [, b]) => a - b)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    for (let i in sortable) {
      planetNames.push(this.props.t(i));
      //planetNames.push(i);
      values.push(datamodified[i]);
    }
    let plotData = [
      {
        x: planetNames,
        y: values,
        type: "bar",
        text: planetNames,
        textposition: "auto",
        marker: {
          color: [
            "#d6edd9",
            "#bae1c1",
            "#a1d2aa",
            "#84c88f",
            "#6bbc77",
            "#51b15e",
            "#449751",
            "#367a41",
            "#2b5e33",
            "#1c4525",
            "#132915",
            "#070e07",
          ],
        },
      },
    ];

    let layout = {
      width: "100%",
      title: this.props.t("Chalit Vimshopak Bala Values - Ranked"),
      height: 500,
      xaxis: {
        title: this.props.t("Planets"),
        type: "category",
        tickfont: {
          family: "Montserrat",
          size: 10,
          color: "rgb(82, 82, 82)",
        },
      },
    };
    var config = { responsive: true };
    Plotly.newPlot(gd1, plotData, layout, config);
  };

  switch_rankedview = () => {
    this.setState({ view_type: "ranked" });
    this.apidate_format_ranked(this.state.horoscopeData);
  };
  switch_orderedview = () => {
    this.setState({ view_type: "ordered" });
    this.apidate_format_ordered(this.state.horoscopeData);
  };

  toggleClass = () => {
    this.state.currentClass === "col-lg-10 col-md-12"
      ? this.setState({ currentClass: "fullscreen" })
      : this.setState({ currentClass: "col-lg-10 col-md-12" });
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <>
        {/* <button onClick={this.apidate_format}>click me</button> */}
        <div className="col-lg-14 bhav-chart-div">
          <div id="d3graph">
            <div className="card">
              <div className="card-body" style={{}}>
                {/* <div class="card-widgets">
                                   
                                    <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light"
                                        data-toggle="fullscreen" href="/#">
                                        <i class="fe-maximize noti-icon"></i></a>
                                </div> */}
                <div className="row">
                  <span
                    className="header-title"
                    style={{ color: "#fff", width: "280px" }}
                  >
                    <div className="btn-group" style={{ width: "100%" }}>
                      <button
                        className={`btn btn-primary btn-md ${
                          this.state.view_type === "ordered"
                            ? "BhavBalaButtons"
                            : "BhavBalaButtons-not"
                        }`}
                        onClick={this.switch_orderedview}
                      >
                        {t("Ordered View")}
                      </button>
                      <button
                        className={`btn btn-primary btn-md ${
                          this.state.view_type === "ranked"
                            ? "BhavBalaButtons"
                            : "BhavBalaButtons-not"
                        }`}
                        onClick={this.switch_rankedview}
                      >
                        {t("Rank View")}
                      </button>
                    </div>
                  </span>
                  <div className="row">
                    <div className="col-lg-12">
                      <form
                        className="form-inline"
                        style={{ fontSize: "1em" }}
                      ></form>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <center>
                      <div id="myDiv_NAKS"></div>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ChalitVimshopakBala);
