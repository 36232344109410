import DashboardTopBar from "../DashBoardComponents/DashboardTopBar";
import CelebrityUserSidebar from "../DashBoardComponents/DashboardSideBarTypes/CelebrityUserSideBar";
import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { getAllData } from "../../API/CelebrityManagement_API";
import { Spinner } from "react-bootstrap";
import Api from "../../API/Api";
import { useHistory } from 'react-router-dom';

const ShowHoroscope = props => {
    const aws_image_base = "https://omparashar-dev.s3.ap-south-1.amazonaws.com/images/"
    const [data, setData] = useState();
    const [oid, setOid] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const [queryBuilderActivated,setQueryBuilderActivated]=useState(false);
    const history = useHistory();

    useEffect(async() => {
        if (props?.location?.state?.res) {
            setData(props.location.state.res)
            setOid(props.location.state.oid)
        }
        else {

            const params = new URLSearchParams(location.search);
            const id = params.get('i');
            const CollectionName = params.get('collCode');
            var r = await getAllData(id, CollectionName);
            // console.log('param1:', param1);console.log('param2:', param2);
            setData(r);
            console.log(r);
            setOid(id);
            setQueryBuilderActivated(true);
        }
    }, []);

    const formatTime = (hour, minute, second) => {
        return `${("0" + hour).slice(-2)}:${("0" + minute).slice(-2)}:${("0" + second).slice(-2)}`;
    }
    const genderMapper = {
        Male: 0,
        Female: 1,
        पुरुष: 0,
        स्त्री: 1,
      };

      const callHoroscopeAPI = async (name, gender, day, month, year, hour, min, sec, latitude, longitude, offset, city, state, country, reference_longitude_for_timezone, timezone) => {
        try {
            let ApiInstant = new Api();
            const result = await ApiInstant.fetchUserHoroscope(name, genderMapper[gender], day, month, year, hour, min, sec, latitude, longitude, offset, city, state, country, reference_longitude_for_timezone, timezone, localStorage.getItem("omp_token"));
            
            if (result.data) {
                // window.sessionStorage.setItem("requestYogaAnalyzer", JSON.stringify(result.data));
                 return result.data;
             }
        } catch (error) {
            // Handle errors
            console.error(error);
        }
    };
    
    const callAnalyzeHoroscope = async (name, gender, day, month, year, hour, min, sec, latitude, longitude, offset, city, state, country, reference_longitude_for_timezone, timezone) => {
        try {
            let ApiYoga = new Api();
            const result = await ApiYoga.fetchAnalyzeHoroscopeAPI(name, genderMapper[gender], day, month, year, hour, min, sec, latitude, longitude, offset, city, state, country, reference_longitude_for_timezone, timezone, localStorage.getItem("omp_token"));
    
            if (result.data) {
               // window.sessionStorage.setItem("requestYogaAnalyzer", JSON.stringify(result.data));
                return result.data;
            }
        } catch (error) {
            console.error(error);
        }
    };

    const callParamsHoroscope = async (cityId) => {
        try {
            let ApiYoga = new Api();
            const result = await ApiYoga.fetchLocationDetails(cityId);
            if (result.data) {
               // window.sessionStorage.setItem("requestYogaAnalyzer", JSON.stringify(result.data));
                return result.data;
            }
        } catch (error) {
            console.error(error);
        }
    };


    
    const generateHoroscope = async () => {
        setIsLoading(true);
        let profileDetails = data;
       //console.log(profileDetails);
        let birthDetails = data.primary_birth_details;
        let p_name = profileDetails.first_name+' '+profileDetails.last_name;

        let horoscopeAPIData = await callHoroscopeAPI(p_name, profileDetails.gender, birthDetails.day, birthDetails.month, birthDetails.year, birthDetails.hour, birthDetails.minute, birthDetails.second, birthDetails.lat, birthDetails.long, birthDetails.offset, birthDetails.city,birthDetails.state, birthDetails.country, birthDetails.reflong, birthDetails.timezone);
        if (horoscopeAPIData) {
            let horoscopeAnalyseData = await callAnalyzeHoroscope(p_name, profileDetails.gender, birthDetails.day, birthDetails.month, birthDetails.year, birthDetails.hour, birthDetails.minute, birthDetails.second, birthDetails.lat, birthDetails.long, birthDetails.offset, birthDetails.city,birthDetails.state, birthDetails.country, birthDetails.reflong, birthDetails.timezone);
            //let requestparams = await callParamsHoroscope(cityId);
           // let requestparams = await callParamsHoroscope(2428015);
           let requestparams = {
            "name": p_name,
            "latitude": birthDetails.lat,
            "longitude": birthDetails.long,
            "country": birthDetails.country,
            "state": birthDetails.state,
            "timezone": birthDetails.timezone,
            "time": `${new Date().toISOString().replace('T', ' ').replace('Z', ' WITA+0800')}`,
            "offset": birthDetails.offset,
            "reference_longitude_for_timezone": birthDetails.reflong
        }
           // console.log("requestparams",requestparams);

            // get some random number of 4 digits 
            const randomNumber = Math.floor(1000 + Math.random() * 9000);

            const key_horoscope = `horoscopeData_${randomNumber}`;
            window.sessionStorage.setItem(key_horoscope, JSON.stringify(horoscopeAPIData));

            const key_analyser = `requestYogaAnalyzer_${randomNumber}`;
            window.sessionStorage.setItem(key_analyser, JSON.stringify(horoscopeAnalyseData));

            const key_params = `requestParamsHoroscope_${randomNumber}`;
            window.sessionStorage.setItem(key_params, JSON.stringify(requestparams));

             // history.push(`/horoscope/basicdetails?cb=${randomNumber}`);
             const newUrl = `/horoscope/basicdetails?cb=${randomNumber}`;
             setIsLoading(false);
             window.location.href = newUrl;

        }
    };
    
    return (
        <>
           {!queryBuilderActivated?(<><DashboardTopBar />
            <CelebrityUserSidebar /></>):""}
            <div className="content-page">
                <div className="content">
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                {/*{data.category}*/}
                                {data?(<>
                                    <div className="container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row"><h4>Birth Details</h4></div>
                                            <div className="row">Name: {data.first_name} {data.last_name}</div>
                                            <div className="row">Gender: {data.gender}</div>
                                            <div
                                                className="row">Date Of Birth: {data.primary_birth_details.day}/{data.primary_birth_details.month}/{data.primary_birth_details.year}</div>
                                            <div
                                                className="row">Time Of Birth: {formatTime(data.primary_birth_details.hour, data.primary_birth_details.minute, data.primary_birth_details.second)}</div>
                                            <div
                                                className="row">Place Of Birth: {data.primary_birth_details.city}, {data.primary_birth_details.state}, {data.primary_birth_details.country}</div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="row"><h4>Rodden Rating</h4></div>
                                            <div className="row">{data.rating}</div>
                                           
                                        </div>
                                        <div className="col-md-4">
                                            {data.photo_name ? (
                                                <img src={aws_image_base + data.photo_name} className="img-photo"
                                                    alt="" />) : ""}
                                                    {/* <br/>
                                         <button className="btn btn-sm btn-danger mt-1" onClick={()=>generateHoroscope()}>
                                         {(isLoading) ? (
                          <span>Generating horoscope ...
                            <i className="mdi mdi-spin mdi-loading mr-1 font-16"></i>
                          </span>
                        ) : (<span> View horoscope</span>)}
                                         </button> */}
                                           
                                        </div>
                                    </div>
                                    <hr />
                                    {data.alternate_birth_details ? (
                                        <>
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <fieldset>
                                                        <legend><h4>Alternate Birth Details</h4></legend>
                                                        <div
                                                            className="row">Date Of Birth: {data.alternate_birth_details.day}/{data.alternate_birth_details.month}/{data.alternate_birth_details.year}</div>
                                                        <div
                                                            className="row">Time Of Birth: {formatTime(data.alternate_birth_details.hour, data.alternate_birth_details.minute, data.alternate_birth_details.second)}</div>
                                                        <div
                                                            className="row">Place Of Birth: {data.alternate_birth_details.city}, {data.alternate_birth_details.state}, {data.alternate_birth_details.country}</div>
                                                    </fieldset>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    ) : ""}
                                    {data.biography ? (
                                        <>
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <div className="row"><h4>Biography</h4></div>
                                                    <div className="row"
                                                        dangerouslySetInnerHTML={{ __html: data.biography }}></div>
                                                </div>
                                            </div>
                                            <hr />
                                        </>) : ""}
                                    {data.wikipedia_link ? (
                                        <>
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <div className="row"><h4>Wikipedia link</h4></div>
                                                    <div className="row">{data.wikipedia_link}</div>
                                                </div>
                                            </div>
                                            <hr />
                                        </>) : ''
                                    }
                                    {data.tags ? (
                                        <>
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <div className="row"><h4>Tags</h4></div>
                                                    <div className="row">{data.tags.map((item,i,arr) => {
                                                        if(arr.length-1===i){
                                                            return <div>{item}</div>;
                                                        }
                                                        else{
                                                            return <div>{item},</div>;
                                                        }
                                                    })}</div>
                                                </div>

                                            </div>
                                            <hr />
                                        </>) : ""}
                                    <div className="row">
                                        <div className="col-md-10">
                                            <div className="row"><h4>Source</h4></div>
                                            <div className="row">{data.source_url}</div>
                                        </div>
                                    </div>
                                </div>
                                </>):(<><center className="pt-4 pb-4"><Spinner animation="border" variant="primary" /></center></>)}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(ShowHoroscope);