import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./autocomplete.css";

import Api from "../../../API/Api";
//for auto translate
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";

var _ = require("lodash");

class AutocompleteCityAndState extends Component {

    static propTypes = {
        suggestions: PropTypes.instanceOf(Array),
    };

    static defaultProps = {
        suggestions: [],
    };
    constructor(props) {
        super(props);
        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: this.props.city,
            cityMap: {},
            flag: false,
            text: "",
            reset : false
        };

        this.Api = new Api();
        this.fetchSuggestions = _.debounce(this.fetchSuggestions, 500);
    }


    componentDidUpdate = (prevProps) => {
        if (prevProps.country !== this.props.country) {
            this.setState({userInput: ""});
            this.props.setLocation("reset");
        }
        else if(this.state.userInput === "" && this.props.city !== "" && this.state.reset === false) {
            this.setState({ userInput: this.props.city });
        }
    };

    fetchSuggestions(userInput) {
            this.Api.fetchSuggestionsCityAndState(
                userInput,
                this.props.country
            ).then((response) => {
                if (response) {
                    if (response.data) {

                        let reqData = response.data.Patterns_matched;

                        if (reqData.length === 0) {

                            this.setState({
                                filteredSuggestions: response.data.Patterns_matched,
                            });
                        } else {
                            // this.props.horoscopePlaceErrorChange(null);
                            let cityMap = {};
                            for (let i in reqData) {
                                cityMap[reqData[i][0]] = reqData[i][1];
                            }
                            this.setState({
                                filteredSuggestions: response.data.Patterns_matched,
                                cityMap: cityMap,
                            });
                        }
                    }
                } else {
                    // console.log("Could not fetch data");
                }
            });





////////
    }
    // handleAutoCompleterChange = (type) => {
    //     if (type === "userInput") {
    //         let errors = this.state.errors;
    //         errors["observation"] = "";
    //         this.setState({ errors: errors });
    //     } else if (type === "reset") {
    //         this.setState({ resetInputText: false });
    //     }
    // };

    // Event fired when the input value is changed
    onChange = (e) => {
        // this.handleAutoCompleterChange("userInput");
        const userInput = e.currentTarget.value;
        if(userInput === ""){
            this.setState({"userInput":"","reset":true})
            return ;
        }
        if (userInput.match(/^ *$/) === null) {

            this.fetchSuggestions(userInput);
        }

        this.setState({
            activeSuggestion: 0,
            showSuggestions: true,
            userInput: e.currentTarget.value,
        });
    };

    // Event fired when the user clicks on a suggestion
    onClick = async (e) => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText,
        });


        let cityId = this.state.cityMap[e.currentTarget.innerText];

        let result = await this.Api.fetchLocationDetails(cityId);
        this.props.setLocation(result.data);

        //   this.props.handlePlaceDetailsChange(e.currentTarget.innerText);
        // }
    };

    // Event fired when the user presses a key down
    onKeyDown = async (e) => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        // User pressed the enter key, update the input and close the
        // suggestions
        if (e.keyCode === 13) {
            e.preventDefault();
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion][0],
            });
            let cityId = filteredSuggestions[activeSuggestion][1];

            let result = await this.Api.fetchLocationDetails(cityId);

            this.props.setLocation(result.data);

        }
        // User pressed the up arrow, decrement the index
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {

        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput,
            },
        } = this;
        let suggestionsListComponent;
        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions" style={{ width: "70%" }}>
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={className} key={suggestion} onClick={onClick}>
                                    {suggestion[0]}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else if (filteredSuggestions.length === 0) {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions, you're on your own!</em>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <input
                    autoComplete="new-password"
                    id="auto_complete1"
                    ref="cpDev1"
                    type="search"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={this.state.userInput}
                    className="form-control"
                    style={{ width: "100%" }}
                    disabled={this.props.isDisabled}
                    style ={{ borderColor: this.props.border_color, background:(this.props.isDisabled?"#dddddd":"white") }}
                />
                {suggestionsListComponent}
            </Fragment>
        );
    }
}

export default AutocompleteCityAndState;
