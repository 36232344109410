import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./autocomplete.css";
import {searchbyName} from "../../../API/CelebrityManagement_API";

// import Api from "../../../API/Api";
//for auto translate
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";

var _ = require("lodash");

class SearchByNameAutosuggest extends Component {

    static propTypes = {
        suggestions: PropTypes.instanceOf(Array),
    };

    static defaultProps = {
        suggestions: [],
    };
    constructor(props) {
        super(props);
        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
            // What the user has entered
            userInput: this.props.name,
            cityMap: {},
            flag: false,
            text: "",
            // reset : false
        };

        // this.Api = new Api();
        this.fetchSuggestions = _.debounce(this.fetchSuggestions, 500);
    }


    componentDidUpdate = (prevProps) => {
        if(prevProps.category !== this.props.category) {
            this.setState({ userInput: ""});
        }
    };

    fetchSuggestions(userInput) {
        searchbyName(userInput,this.props.category).then((response) => {
            if (response) {
                if (response.data) {
                    let reqData = response.data;
                    if (reqData.length === 0) {
                        this.setState({
                            filteredSuggestions: response.data,
                        });
                    } else {
                        let cityMap = {};
                        let a = []
                        for (let i in reqData) {
                            // cityMap[reqData[i].id] = reqData[i].event_name;
                            cityMap[reqData[i].horoscope_name.trim()] = reqData[i];
                            a.push(reqData[i].horoscope_name);
                        }
                        console.log(cityMap,'citymap');
                        this.setState({
                            filteredSuggestions: a,
                            cityMap: cityMap,
                        });
                    }
                }
            } else {
                console.log("Could not fetch event suggestions");
            }
        });
    }

    // Event fired when the input value is changed
    onChange = (e) => {
        if(!this.props.category){
            alert("Please Select Category");
            return;
        }
        // this.handleAutoCompleterChange("userInput");
        const userInput = e.currentTarget.value;
        if(userInput === ""){
            this.setState({"userInput":"","reset":true})
            return ;
        }
        if (userInput.match(/^ *$/) === null) {

            this.fetchSuggestions(userInput);
        }

        this.setState({
            activeSuggestion: 0,
            showSuggestions: true,
            userInput: e.currentTarget.value,
        });
    };

    // Event fired when the user clicks on a suggestion
    onClick = async (e) => {
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText,
        });

        let data = this.state.cityMap[e.currentTarget.innerText];
        // console.log(e.currentTarget.innerText,data,'matches');
        this.props.setLocation(data,e.currentTarget.innerText);
    };

    // Event fired when the user presses a key down
    onKeyDown = async (e) => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        // User pressed the enter key, update the input and close the
        // suggestions
        if (e.keyCode === 13) {
            e.preventDefault();
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion],
            });
            let data = this.state.cityMap[filteredSuggestions[activeSuggestion]];
            this.props.setLocation(data,filteredSuggestions[activeSuggestion]);
        }
        // User pressed the up arrow, decrement the index
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
                activeSuggestion,
                filteredSuggestions,
                showSuggestions,
                userInput,
            },
        } = this;
        let suggestionsListComponent;
        if (showSuggestions && userInput) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <ul className="suggestions" style={{ width: "70%" }}>
                        {filteredSuggestions.map((suggestion, index) => {
                            let className;

                            // Flag the active suggestion with a class
                            if (index === activeSuggestion) {
                                className = "suggestion-active";
                            }

                            return (
                                <li className={className} key={suggestion} onClick={onClick}>
                                    {suggestion}
                                </li>
                            );
                        })}
                    </ul>
                );
            } else if (filteredSuggestions.length === 0) {
                suggestionsListComponent = (
                    <div className="no-suggestions">
                        <em>No suggestions, you're on your own!</em>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                <input
                    // autoComplete="new-password"
                    autoComplete="off"
                    id="auto_complete1"
                    ref="cpDev1"
                    type="search"
                    name = "event_name"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={this.state.userInput}
                    className="form-control"
                    style={{ width: "100%" }}
                    placeholder={"Name..."}
                />
                {suggestionsListComponent}
            </Fragment>
        );
    }
}

export default SearchByNameAutosuggest;
