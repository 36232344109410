import React, { useState, useEffect } from "react";
import "react-bootstrap";
import { GlobalContext } from "../../../mycontext";
import Sideform from "../../SideComponents/sideform";
import { Link, withRouter } from "react-router-dom";
import "../InitDashboard.css";
import galactic_icon from "../../../assets/svg/galactic.svg";
import traces_icon from "../../../assets/svg/traces.svg";
import journey_icon from "../../../assets/svg/journey.svg";
import snapshot_icon from "../../../assets/svg/snapshot.svg";
import kaal_icon from "../../../assets/svg/kaal_purush.svg";
import sun_moon from "../../../assets/svg/sunmoon.svg";

const InitDashboard = (props) => {
  const context = React.useContext(GlobalContext);
  let [localview, setLocalview] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(!show);
  };
  const moveToHoroscope = (view) => {
    setLocalview(view);
    if (Object.keys(this.context.horoscopeData).length === 0) {
      setShow(!show);
    } else {
      props.history.push(`/horoscope/${view}`);
    }
  };
  return (
    <>
      <Sideform
        show={show}
        mode={"Horoscope"}
        view={localview}
        handleClose={handleClose}
        reset={false}
        history={props.history}
      />

      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-galactic">
            <div className="card-body ">
              <h4 className="header-title mb-0">Chakras</h4>

              <div id="cardCollpase_galactic" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      Lorem Ipsum is the simple dummy content when you don’t
                      know what will come here so you place it like placeholder
                      text. orem Ipsum is the simple dummy content when you
                      don’t
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => moveToHoroscope("basicdetails")}
                    >
                      {" "}
                      Explore Here
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="galactic view"
                      className="introimage mb-auto"
                      src={galactic_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-traces">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase2"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title mb-0">Planet Relations</h4>

              <div id="cardCollpase2" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      Lorem Ipsum is the simple dummy content when you don’t
                      know what will come here so you place it like placeholder
                      text. orem Ipsum is the simple dummy content when you
                      don’t
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => moveToHoroscope("planetaryRelations")}
                    >
                      {" "}
                      Explore Here
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="Traces view"
                      className="introimage mb-auto"
                      src={traces_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-journey">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase1"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title mb-0">Age Dasha Plot</h4>

              <div id="cardCollpase1" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      Lorem Ipsum is the simple dummy content when you don’t
                      know what will come here so you place it like placeholder
                      text. orem Ipsum is the simple dummy content when you
                      don’t
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => moveToHoroscope("ageDashaPlot")}
                    >
                      Explore Here
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="Journey view"
                      className="introimage mb-auto"
                      src={journey_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-journey">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase1"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title mb-0">Maha Dasha</h4>

              <div id="cardCollpase1" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      Lorem Ipsum is the simple dummy content when you don’t
                      know what will come here so you place it like placeholder
                      text. orem Ipsum is the simple dummy content when you
                      don’t
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => moveToHoroscope("mahaDasha")}
                    >
                      Explore Here
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="Journey view"
                      className="introimage mb-auto"
                      src={journey_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-snapshot">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase3"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase3"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title mb-0">Astak Varga</h4>

              <div id="cardCollpase3" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      Lorem Ipsum is the simple dummy content when you don’t
                      know what will come here so you place it like placeholder
                      text. orem Ipsum is the simple dummy content when you
                      don’t
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => moveToHoroscope("astakVarga")}
                    >
                      Explore Here
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="snapshot"
                      className="introimage mb-auto"
                      src={snapshot_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-kaal">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase2"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title mb-0">Bhav Bala</h4>

              <div id="cardCollpase2" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      Lorem Ipsum is the simple dummy content when you don’t
                      know what will come here so you place it like placeholder
                      text. orem Ipsum is the simple dummy content when you
                      don’t
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => moveToHoroscope("bhavBala")}
                    >
                      Explore Here
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="kaal purush"
                      className="introimage mb-auto"
                      src={kaal_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-journey">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase1"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title mb-0">Shad Bala</h4>

              <div id="cardCollpase1" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      Lorem Ipsum is the simple dummy content when you don’t
                      know what will come here so you place it like placeholder
                      text. orem Ipsum is the simple dummy content when you
                      don’t
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => moveToHoroscope("shadBala")}
                    >
                      Explore Here
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="Journey view"
                      className="introimage mb-auto"
                      src={journey_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-journey">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase1"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title mb-0">Shad Bala Bar Chart</h4>

              <div id="cardCollpase1" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      Lorem Ipsum is the simple dummy content when you don’t
                      know what will come here so you place it like placeholder
                      text. orem Ipsum is the simple dummy content when you
                      don’t
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => moveToHoroscope("shadBalaBar")}
                    >
                      Explore Here
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="Journey view"
                      className="introimage mb-auto"
                      src={journey_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-snapshot">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase3"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase3"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> */}
              <h4 className="header-title mb-0">Sun Burst Chart</h4>

              <div id="cardCollpase3" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      Lorem Ipsum is the simple dummy content when you don’t
                      know what will come here so you place it like placeholder
                      text. orem Ipsum is the simple dummy content when you
                      don’t
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => moveToHoroscope("sunBurst")}
                    >
                      Explore Here
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="snapshot"
                      className="introimage mb-auto"
                      src={snapshot_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="card card-figma card-kaal">
            <div className="card-body">
              {/* <div className="card-widgets">
                <a href="/" data-toggle="reload">
                  <i className="mdi mdi-refresh"></i>
                </a>
                <a
                  data-toggle="collapse"
                  href="#cardCollpase2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cardCollpase2"
                >
                  <i className="mdi mdi-minus"></i>
                </a>
                <a href="/" data-toggle="remove">
                  <i className="mdi mdi-close"></i>
                </a>
              </div> 
              <h4 className="header-title mb-0">Divisional Charts</h4>

              <div id="cardCollpase2" className="collapse pt-3 show">
                <div className="d-flex">
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="mr-5">
                      Lorem Ipsum is the simple dummy content when you don’t
                      know what will come here so you place it like placeholder
                      text. orem Ipsum is the simple dummy content when you
                      don’t
                    </p>
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect waves-light btn-figma"
                      onClick={() => moveToHoroscope("divisionalCharts")}
                    >
                      Explore Here
                    </button>
                  </div>
                  <div className="d-flex align-items-end">
                    <img
                      alt="kaal purush"
                      className="introimage mb-auto"
                      src={kaal_icon}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>  */}
      </div>
    </>
  );
};

export default withRouter(InitDashboard);
